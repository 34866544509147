import React from 'react';
import Buttons from '../Buttons';
import Typography from '../Typography';
import styles from './latest-news.module.css';

interface ILatestNewsCard {
  className?: string,
  headlineContent: string,
  headlineDate: string,
  headerTitle: string,
  imageSrc: string,
  type?: 'default' | 'flex',
  onViewMore(): void,
  writtenBy: string,
}

const { Text } = Typography;

const LatestNewsCard = ({
  className,
  headlineContent,
  headlineDate,
  headerTitle,
  imageSrc,
  onViewMore,
  type,
  writtenBy,
}: ILatestNewsCard) => (
  <div className={[
    styles.con,
    type === 'flex' && styles.conFlexType,
    className].join(' ')}
  >
    <Text className={styles.headerText}>
      Latest News
    </Text>

    <div className={styles.headerCon}>
      <div
        className={styles.headlineImage}
        style={{ backgroundImage: `url(${imageSrc})` }}
      />

      <div className={styles.metaData}>
        <div>
          <Text className={styles.headlineDate}>
            {headlineDate}
          </Text>
          <Text className={styles.writtenBy}>
            {writtenBy}
          </Text>
        </div>

        <Text className={styles.headerTitle}>
          {headerTitle}
        </Text>
      </div>
    </div>

    <Text className={styles.headlineContent}>
      {headlineContent}
    </Text>

    <div className={styles.viewMoreBtnCon}>
      <Buttons.SecondaryBtn
        className={styles.viewMoreBtn}
        onClick={onViewMore}
        type="link"
      >
        Read more
      </Buttons.SecondaryBtn>
    </div>

  </div>
);

LatestNewsCard.defaultProps = {
  className: undefined,
  type: 'default',
};

export default LatestNewsCard;
