import React, { useEffect } from 'react';
import { Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import Form from '../../components/Form';
import Buttons from '../../components/Buttons';
import Spin from '../../components/Spin/Spin';
import { useAuthContext } from '../../context/AuthContext';
import { CheckoutPath } from '../../utils/checkout';

const { Text, Title } = Typography;
const CheckoutAccount = () => {
  const { isLoggedIn } = useAuthContext();

  const navigate = useHistory();

  const handleEnter = () => {
    navigate.push('/account/register');
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate.push('/');
    } else {
      navigate.push(CheckoutPath.CART);
    }
  }, [isLoggedIn]);

  const loginForm = [
    {
      label: 'Email address',
      required: true,
      formType: 'input',
      input: <Form.Input placeholder="Enter your email address" />,
    },
    {
      label: 'Password',
      required: true,
      formType: 'input',
      input: <Form.Input placeholder="Enter your password" />,
    },
  ];
  return (
    <main id="cart">
      <Helmet>
        <title>Checkout - G. Fox</title>
      </Helmet>
      <Spin
        spinning
      >
        <div className={styles.loginCon}>
          <div className={styles.loginFormCon}>
            <Title
              className={styles.loginFormTitle}
              level={2}
            >
              Login
            </Title>

            {React.Children.toArray(
              loginForm.map((item) => (
                <div className={styles.loginFormItemCon}>
                  <Text className={styles.loginFormItemLabel}>
                    {item.label}
                  </Text>
                  <div className={styles.loginFormItem}>
                    {item.input}
                  </div>
                </div>
              )),
            )}

            <div className={styles.loginFormOptsCon}>
              <Radio>
                <Text className={styles.loginFormRememberRadio}>
                  Remember me
                </Text>
              </Radio>
              <Buttons.PrimaryBtn
                className={styles.loginFormResetBtn}
                type="text"
              >
                Forgot password
              </Buttons.PrimaryBtn>
            </div>

            <div>
              <Buttons.PrimaryBtn
                className={styles.loginFormSubmitBtn}
                htmlType="submit"
              >
                Login
              </Buttons.PrimaryBtn>
            </div>

            <div>
              <Text className={styles.loginFormSignUpText}>
                Don’t have an account?
                {' '}
              </Text>
              <Buttons.PrimaryBtn
                className={styles.loginFormSignUpBtn}
                type="text"
                onClick={handleEnter}
              >
                Sign Up
              </Buttons.PrimaryBtn>
            </div>
          </div>
        </div>
      </Spin>
    </main>
  );
};

export default CheckoutAccount;
