import React from 'react';
import {
  Empty, Image, Skeleton, Table,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { ICart } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './checkout-item.module.css';
import { calcProductPrice, formatPrice } from '../../utils/pricing';
import stores from '../../stores/stores';
import Images from '../../assets';
import PricingInformation from '../Pricing/PricingInformation';
import { getStockMessage } from '../../utils/order/stockMessage';
import { useAuthContext } from '../../context/AuthContext';
import { imgURLPrefix } from '../../config/image';

const { Paragraph, Link, Text } = Typography;

const ImageView = ({ isLoading, src }: {
  isLoading: boolean
  src: string,
}) => (
  <>
    {isLoading ? (
      <Skeleton
        active
        loading
        className={styles.carouselImgCon}
      />
    ) : (
      <Image
        src={`${imgURLPrefix}${src}`}
        alt="Product image"
        className={styles.cartItemImg}
        rootClassName={styles.cartItemImgCon}
        fallback={Images.FallbackImage}
      />
    )}
  </>
);

// eslint-disable-next-line no-unused-vars
const CloseBtn = observer(({ cartId, edit, onRemoveProduct }: { cartId: string, edit: boolean, onRemoveProduct: (cartId: string) => void }) => {
  if (!edit) {
    return null;
  }
  return (
    <span className={styles.cartItemCloseIconCon}>
      <Buttons.PrimaryBtn
        type="text"
        icon={<IoClose className={styles.cartItemCloseIcon} />}
        onClick={() => onRemoveProduct(cartId)}
      />
    </span>
  );
});

interface ISelectedItemsTableProps {
  edit?: boolean,
  // eslint-disable-next-line no-unused-vars
  onRemoveProduct: (cartId: string) => void,
  items: ICart[]
  loading?: boolean
}

const SelectedItemsTable = observer(({
  edit = true,
  onRemoveProduct,
  items,
  loading = false,
}: ISelectedItemsTableProps) => {
  const { cartStore } = stores;
  const cartItems = cartStore.cartItems.value.data.slice().sort((a, b) => b?.inventoryMasterId?.localeCompare(a?.inventoryMasterId));
  const isLoading = cartStore.cartItems.isLoading || cartItems.length === 0;
  const { currentClient } = useAuthContext();

  const tableColumns = [
    {
      title: '',
      dataIndex: 'imageSrc',
      key: 'imageSrc',
      width: '12%',
      render: (text: string, record: ICart) => (
        <ImageView
          isLoading={loading ?? isLoading}
          src={record?.inventoryMaster?.productImages?.length > 0
            ? record?.inventoryMaster?.productImages[0]
            : Images.FallbackImage}
        />
      ),
    },
    {
      title: 'Product',
      dataIndex: 'sku',
      key: 'sku',
      width: '60%',
      render: (text: string, record: ICart) => (
        (
          <div className={styles.cartTableProductInfo}>
            <Text className={styles.cartItemName}>
              {record.inventoryMaster?.description}
            </Text>
            <div>
              {record.inventoryMaster?.categorySpecificInfo?.map((value) => (
                <span key={value.name} style={{ paddingRight: '1em' }}>
                  <Text className={styles.cartItemDetailsFieldName}>
                    {value.name}
                    { ': '}
                  </Text>
                  <Text className={styles.cartItemDetailsFieldValue}>
                    {value.value}
                  </Text>
                </span>
              ))}
            </div>
            <Text className={styles.cartItemDetailsSku}>
              {record.inventoryMaster?.sku}
            </Text>

          </div>
        )
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      width: '20%',
      render: (text: string, record: ICart) => {
        const stockMessage = getStockMessage({ lineItemCount: record.quantity }, record.inventoryMaster.masterStock, currentClient);
        return (
          <>
            <Buttons.CartCountBtn
              quantity={record.quantity}
              showEditButton={edit}
              size="small"
              onAddClick={() => cartStore.updateQuantity(items, false, record?.inventoryMasterId, 1)}
              onMinusClick={() => cartStore.updateQuantity(items, false, record?.inventoryMasterId, -1)}
              handleQuantityChange={async (e) => {
                const value = e.target.value === '' ? 0 : Number(e.target.value);
                await cartStore.updateQuantity(items, true, record?.inventoryMasterId, Number(value));
              }}
            />
            <div>
              <PricingInformation
                pricing={record?.inventoryMaster}
                lineItems={record?.quantity}
                className={styles.priceConSelectedTbl}
                textClassName={styles.priceText}
                text2ClassName={styles.priceText2}
              />
              <Paragraph className={styles.backOrderText}>
                {stockMessage.message}
              </Paragraph>
            </div>
          </>
        );
      },
    },
    {
      title: '',
      width: '8%',
      dataIndex: 'close',
      key: 'close',
      render: (a: string, record: ICart) => <CloseBtn cartId={record.inventoryMasterId} edit={edit} onRemoveProduct={onRemoveProduct} />,
    },
  ];

  const getInvItemPrice = (invSku: string, qty: number) => {
    const invItem = cartStore.invList.value.data.find((x) => x.sku === invSku);
    const price = calcProductPrice(invItem).excludingTax * qty;
    return price > 0 ? formatPrice(price) : '';
  };

  return (
    <>
      <div className={['checkout-items-table', styles.cartMobView].join(' ')}>
        {React.Children.toArray(
          (items ?? cartItems).map((item) => (
            <div className={styles.cartItemCon}>
              <CloseBtn cartId={item.inventoryMasterId} edit={edit} onRemoveProduct={onRemoveProduct} />
              <Text className={styles.cartItemName}>
                {item?.inventoryMaster?.description}
              </Text>
              <div className={styles.cartItemDetailsCon}>
                <ImageView
                  isLoading={false}
                  src={item?.inventoryMaster?.productImages?.length > 0
                    ? item?.inventoryMaster?.productImages[0]
                    : Images.FallbackImage}
                />
                <div className={styles.cartItemDetails}>
                  <Text className={styles.cartItemDetailsSku}>
                    {item.inventoryMaster?.sku}
                  </Text>
                  {item.inventoryMaster?.categorySpecificInfo?.map((value) => (

                    <div key={value.name}>
                      <Text className={styles.cartItemDetailsFieldName}>
                        {value.name}
                        {': '}
                      </Text>
                      <Text className={styles.cartItemDetailsFieldValue}>
                        {value.value}
                      </Text>
                    </div>

                  ))}
                  <Paragraph className={styles.backOrderText}>
                    {getStockMessage({ lineItemCount: item.quantity }, item.inventoryMaster.masterStock, currentClient).message}
                  </Paragraph>
                </div>
              </div>
              <div className={styles.cartItemPriceCon}>
                <Buttons.CartCountBtn
                  quantity={item.quantity}
                  size="small"
                  showEditButton={edit}
                  onAddClick={() => cartStore.updateQuantity(items, false, item?.inventoryMasterId, 1)}
                  onMinusClick={() => cartStore.updateQuantity(items, false, item?.inventoryMasterId, -1)}
                  handleQuantityChange={async (e) => {
                    const value = e.target.value === '' ? 0 : Number(e.target.value);
                    await cartStore.updateQuantity(items, true, item?.inventoryMasterId, Number(value));
                  }}
                />
                <Text className={styles.cartItemPrice}>
                  {getInvItemPrice(item.inventoryMaster?.sku ?? '', item.quantity)}
                </Text>
              </div>
            </div>
          )),
        )}
      </div>
      <div className={styles.cartTableView}>
        {/* TODO: format table header & add spacing between rows */}
        <Table
          dataSource={items.slice()}
          columns={tableColumns}
          pagination={false}
          size="small"
          bordered={false}
          rowClassName={styles.cartTableRow}
          locale={{
            emptyText: (
              <div className={styles.emptyListCon}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
                <Link
                  className={styles.emptyListShop}
                  href="/specials"
                >
                  Continue Shopping
                </Link>
              </div>
            ),
          }}
        />
      </div>

    </>
  );
});

export default SelectedItemsTable;
