import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ICategory } from '@bridgelabsdesign/gfox-api-client';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import Typography from '../Typography';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';

interface IProductListMenuProps {
  onNavItemClick: () => void;
}

const { Text, Link } = Typography;

const ProductListMenu = observer(({
  onNavItemClick,
}: IProductListMenuProps) => {
  const [selectedParentCategory, setSelectedParentCategory] = useState<ICategory | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<{
      id: string;
      refNo: string;
      name: string;
  }[]>([]);
  const isSelectedCategory = (refNo: string) => refNo === selectedParentCategory?.refNo;
  const { categoryStore } = stores;
  const { isLoggedIn } = useAuthContext();
  const history = useHistory();

  const handleClick = () => {
    history.push('/products/favorites');
  };

  useEffect(() => {
    if (categoryStore.categories.value.data.length === 0) {
      return;
    }

    setSelectedParentCategory(categoryStore.categories.value.data[0]);
    setSelectedCategory(categoryStore.categories.value.data[0].inverseParentCategory);
  }, [categoryStore.categories.value.data]);

  return (
    <div
      className={['navbar-padding', styles.productListContainer].join(' ')}
    >
      <div className={styles.productList}>
        <div className={styles.productListCategories}>
          {isLoggedIn && (
            <span
              key="favorites"
            >
              <Text
                onClick={handleClick}
                className={styles.favoriteProduct}
              >
                My Favorites
              </Text>
            </span>
          )}
          {categoryStore.categories.value.data.map((item) => (
            <span
              key={item.refNo}
              onMouseEnter={() => {
                setSelectedParentCategory(item);
                setSelectedCategory(item.inverseParentCategory);
              }}
            >
              <Text
                className={[
                  styles.productListText,
                  isSelectedCategory(item.refNo) && styles.productListActive,
                ].join(' ')}
              >
                {item.name}
              </Text>
            </span>
          ))}
        </div>

        <div className={styles.productListSubCategoryCon}>
          <div className={styles.productListSubCategoryItem}>
            {selectedCategory.map((item) => (
              <div
                key={item.refNo}
                className={styles.productListSubCategory}
              >
                <Link
                  className={styles.productListSubCategoryText}
                  href={`/products/${selectedParentCategory?.refNo}/${item.refNo}`}
                  // TODO: keep state
                  onClick={() => {
                    onNavItemClick();
                    setTimeout(() => window.location.reload(), 500);
                  }}
                >
                  {item.name.toLowerCase()}
                </Link>
              </div>
            ))}
          </div>

          {selectedParentCategory?.imageWideUrl && (
            <img
              className={styles.productListBanner}
              src={selectedParentCategory?.imageWideUrl}
              alt="Product banner"
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ProductListMenu;
