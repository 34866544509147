import React, {
  useEffect, useMemo, useState,
} from 'react';
import Icon from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { Empty } from 'antd';
import { IoClose } from 'react-icons/io5';
import { Helmet } from 'react-helmet';
import { FiChevronRight } from 'react-icons/fi';
import { runInAction } from 'mobx';
import { ICategorySpecificInfo } from '@bridgelabsdesign/gfox-api-client';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import Buttons from '../../components/Buttons';
import Images from '../../assets';
import Form from '../../components/Form';
import Cards from '../../components/Cards';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import { isEmptyString } from '../../utils/strings';
import Spin from '../../components/Spin/Spin';
import { ProductSortByType } from '../../stores/inventory';
import useWindowSize from '../../utils/hooks/useWindowSize';
import ProductFilterBy from './ProductFilterBy';
import { sortByOptions } from '../../utils/form-helpers/sort-by';
import useQuery from '../../utils/hooks/useQuery';
import { imgURLPrefix } from '../../config/image';

const { Link, Title, Text } = Typography;

const Products = observer(() => {
  const [showFilter, setShowFilter] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [queryParams, setQueryParams] = useState<string | undefined>();
  const [categoryPathQuery, setCategoryPathQuery] = useState('');
  const [showProducts, setShowProducts] = useState(false);

  const { isLoggedIn } = useAuthContext();
  const {
    categoryStore,
    cartStore,
    inventoryStore,
  } = stores;
  const { isTabletWindow } = useWindowSize();
  const history = useHistory();

  const { categoryRef, subCategoryRef } = useParams<{
    categoryRef?: string,
    subCategoryRef?: string,
  }>();

  // /products/A/AA/?path=A,AA,AAA
  const query = useQuery();

  const isActiveSubCategory = (refNo: string) => refNo === categoryStore.currentSubCategoryRef;

  const showFilterOptions = useMemo(() => (isTabletWindow ? showFilter : true), [isTabletWindow, showFilter]);

  const isSkuListEmpty = () => inventoryStore.filteredSearchResults.length === 0 && !inventoryStore.isLoading;
  const [imageIndices, setImageIndices] = useState<Record<string, number>>({});

  const handleCategoryChange = async () => {
    categoryStore.setCurrentRef(categoryRef ?? '');
    categoryStore.setCurrentSubCategoryRef(subCategoryRef ?? categoryRef ?? '');

    const categoryPath = query.get('path') ?? subCategoryRef ?? categoryRef;
    if (!isEmptyString(categoryPath)) {
      const paths = categoryPath!.split(',');
      await categoryStore.loadNestedCategories(paths);
    }
    setCategoryPathQuery(categoryPath ?? '');

    // products to fetch (items without child parents
    categoryStore.setProductRefNos(new Set<string>());

    // eslint-disable-next-line
    for (const item of categoryStore.nestedCategories.value.data[0]?.inverseParentCategory) {
      if (item.inverseParentCategory.length === 0) {
        categoryStore.productRefNos.add(item.refNo);
      }
    }

    setShowProducts(categoryStore.productRefNos.size > 0);
    if (categoryStore.productRefNos.size > 0) {
      const ref = categoryStore.breadCrumb[categoryStore.breadCrumb.length - 1]?.refNo;
      inventoryStore.setCategoryRefNo(ref);
      const refs = Array.from(categoryStore.productRefNos).join(',');

      // note: prevents loading all products given an empty ref ie. "" or ''
      if (!isEmptyString(refs)) {
        await inventoryStore.loadSearchResults({ withPrice: true, categoryRefNo: refs });
      }
    } else {
      runInAction(() => { inventoryStore.searchResult.value.data = []; });
    }

    categoryStore.setFilteredNestedCategories(inventoryStore.filteredSearchResults);
  };

  const getCategoryCardViewLink = (refNo: string, idx: number): string => {
    let newPathQuery = categoryStore.breadCrumb.slice(0, idx).map((x) => x.refNo).join(',');
    if (newPathQuery.length > 0) {
      newPathQuery += ',';
    }
    return `/products/${categoryRef ?? 'category'}/${subCategoryRef ?? refNo}?path=${newPathQuery}${refNo}`;
  };

  const handleOnSearchText = (value: string) => {
    // TODO: how how we handle cases where products searched same view as a categories
    const refs = Array.from(categoryStore.productRefNos).join(',');
    inventoryStore.setSearchQuery(value, refs);
  };

  const handleOnSortByChange = (value: ProductSortByType) => {
    inventoryStore.setSortBy(value);
  };

  const handleOnSelectFilter = (selectedColors: string[], selectedValues: ICategorySpecificInfo[]): string => {
    let params = '';

    if (selectedColors.length > 0) {
      const colors = JSON.stringify(selectedColors);
      const base64Buf = Buffer.from(colors).toString('base64');
      params += `colors=${base64Buf}&`;
    }

    if (selectedValues.length > 0) {
      const filterBy = JSON.stringify(selectedValues);
      const base64Buf = Buffer.from(filterBy).toString('base64');
      params += `filterBy=${base64Buf}&`;
    }

    return params;
  };
  const handlePrevImage = (sku: string, imageCount: number) => {
    setImageIndices((prevIndices) => {
      const newIndex = (prevIndices[sku] ?? 0) - 1;
      return {
        ...prevIndices,
        [sku]: (newIndex + imageCount) % imageCount,
      };
    });
  };

  const handleNextImage = (sku: string, imageCount: number) => {
    setImageIndices((prevIndices) => {
      const newIndex = (prevIndices[sku] ?? 0) + 1;
      return {
        ...prevIndices,
        [sku]: newIndex % imageCount,
      };
    });
  };

  useEffect(() => {
    if (categoryStore.categories.value.data.length > 0) {
      handleCategoryChange();
    }
  }, [categoryStore.categories.value, categoryRef, subCategoryRef, query, isLoggedIn]);

  useEffect(() => {
    const params = handleOnSelectFilter(inventoryStore.selectedColors, inventoryStore.filterBySelectedValues);
    setQueryParams(params);
  }, [inventoryStore.selectedColors, inventoryStore.filterBySelectedValues]);

  return (
    <main
      id="products"
      className={styles.products}
    >
      <Helmet>
        <title>{`${categoryStore?.currentSubCategory?.name ?? 'Products'} - G. Fox`}</title>
      </Helmet>
      <div className={styles.titleCon}>
        <Title
          level={2}
          className={styles.titleText}
        >
          {categoryStore?.currentSubCategory?.name ?? ''}
        </Title>
      </div>
      {showProducts && (
        <div className={styles.filtersCon}>
          <Buttons.PrimaryBtn
            className={styles.filtersBtn}
            type="default"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilterOptions && <IoClose />}
            Filters
          </Buttons.PrimaryBtn>

          <div className={styles.filtersOptCon}>
            <Icon
              className={styles.filterSearchIcon}
              component={Images.SearchIcon}
              onClick={() => setShowMobileSearch(!showMobileSearch)}
            />
            <div className={styles.filterSearchInputCon}>
              <Form.SearchInput
                className={styles.filterSearchInput}
                placeholder="Search products"
                onChange={(e) => handleOnSearchText(e.target.value)}
                value={inventoryStore.searchQuery}
              />
            </div>
            <div>
              <Form.Select
                className={styles.filterSelect}
                defaultValue={[sortByOptions[0].value]}
                options={sortByOptions}
                onChange={handleOnSortByChange}
              />
            </div>
          </div>
        </div>
      )}

      <div className={styles.filterMenuResultsCon}>
        <div className={styles.filterMenuCon}>
          <Text
            className={[
              styles.filterMenuHeader,
              styles.filterMenuHeaderCategories,
            ].join(' ')}
          >
            Categories
          </Text>
          <Text className={styles.filterMenuSubHeader}>
            {categoryStore?.currentCategory?.name}
          </Text>
          <div className={styles.filterMenuCategories}>
            {categoryStore?.currentCategory?.inverseParentCategory.map((item) => (
              <Link
                href={`/products/${categoryRef}/${item.refNo}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/products/${categoryRef}/${item.refNo}`);
                }}
              >
                <Text
                  key={item.refNo}
                  className={[
                    styles.filterMenuSubHeaderItems,
                    isActiveSubCategory(item.refNo) && styles.filterMenuSubHeaderItemsActive,
                  ].join(' ')}
                >
                  <span>{item.name.toLowerCase()}</span>
                  <span className={styles.filterMenuSubHeaderCount}>
                    {/* TODO: display all filters options length */}
                    {/* {filterOptions.length} */}
                  </span>
                </Text>
              </Link>
            ))}
          </div>

          {showMobileSearch && (
            <div
              className={[
                styles.filterSearchInputCon,
                styles.filterSearchInputConMobile,
              ].join(' ')}
            >
              <Form.SearchInput
                className={styles.filterSearchInput}
                placeholder="Search products"
                size="large"
                onChange={(e) => handleOnSearchText(e.target.value)}
                value={inventoryStore.searchQuery}
              />
            </div>
          )}

          {showFilterOptions && showProducts && categoryStore.filteredNestedCategories.length === 0 && (
          <ProductFilterBy
            colors={inventoryStore.filterByColors}
            filterBy={inventoryStore.filterBy}
            onSelectColour={(v) => inventoryStore.setSelectedColors(v)}
            onSelectFilterBy={(v) => inventoryStore.setFilterBySelectedValues(v)}
            isSelectedColour={(n) => inventoryStore.isSelectedColour(n)}
            isFilterByValuesSelected={(v) => inventoryStore.isFilterByValuesSelected(v)}
          />
          )}
        </div>

        <div className={styles.filterResultCon}>
          <Spin
            spinning={inventoryStore.isLoading}
          >
            <div className={styles.filterBreadCrumb}>
              {categoryStore.breadCrumb.map((item, idx) => (
                <Link
                  key={item.refNo}
                  className={styles.filterBreadCrumbItem}
                  href={getCategoryCardViewLink(item.refNo, idx)}
                  onClick={(e) => {
                    e.preventDefault();
                    const link = getCategoryCardViewLink(item.refNo, idx);
                    history.push(link);
                  }}
                >
                  <FiChevronRight />
                  <Text className={styles.filterBreadCrumbText}>{item.title}</Text>
                </Link>
              ))}
            </div>

            {showProducts && (
              <div className={styles.filterResultsTextCon}>
                <Text
                  className={styles.filterResultsText}
                >
                  {!inventoryStore.isLoading
                    && `Result: ${categoryStore.filteredNestedCategories.length + inventoryStore.filteredSearchResults.length}`}
                </Text>
              </div>
            )}

            <div className={styles.filterResultCardCon}>
              {React.Children.toArray(
                categoryStore.filteredNestedCategories?.map((item) => (
                  item.active
                  && (
                  <Cards.ProductCard
                    className={styles.filterResultCard}
                    color="red"
                    imageSrc={`${imgURLPrefix}${item.imageUrl}`}
                    imgClassName={styles.filterResultImg}
                    productName={item.name ?? ''}
                    shopLink={`/products/${categoryRef}/${subCategoryRef ?? item.refNo}?path=${categoryPathQuery},${item.refNo}`}
                    productNameClassName={styles.filterResultPName}
                    optType="viewBtn"
                    removeBg
                    separator
                    viewLink={`/products/${categoryRef}/${subCategoryRef ?? item.refNo}?path=${categoryPathQuery},${item.refNo}`}
                    onViewLinkClick={(e) => {
                      e.preventDefault();
                      history.push(`/products/${categoryRef}/${subCategoryRef ?? item.refNo}?path=${categoryPathQuery},${item.refNo}`);
                    }}
                  />
                  )
                )),
              )}
              {React.Children.toArray(
                inventoryStore.filteredSearchResults.map((item) => {
                  const imageCount = (item.productImages?.length ?? 0) + 1;
                  const currentImageIndex = imageIndices[item.sku] ?? 0;
                  const currentImageSrc = `${imgURLPrefix}${
                    [item.subCategoryLongImage, ...(item.productImages ?? [])][currentImageIndex]
                  }`;
                  const hasArrows = item.productImages?.length > 0;

                  return (
                    <Cards.ProductCard
                      className={styles.filterResultCard}
                      color="red"
                      imageSrc={currentImageSrc}
                      imgClassName={styles.filterResultImg}
                      productName={item.productName ?? ''}
                      productNameClassName={styles.filterResultPName}
                      optType="viewBtn"
                      removeBg
                      separator
                      shopLink={`/products/${categoryRef}/${subCategoryRef}/${item.sku}?${queryParams}`}
                      viewLink={`/products/${categoryRef}/${subCategoryRef}/${item.sku}?${queryParams}`}
                      pricing={{
                        masterStock: item.masterStock,
                        accountPricing: item.accountPricing,
                      }}
                      isNew={item.new || undefined}
                      onViewLinkClick={() => {
                        cartStore.setSku(item);
                      }}
                      showArrows={hasArrows}
                      onPrevClick={() => handlePrevImage(item.sku, imageCount)}
                      onNextClick={() => handleNextImage(item.sku, imageCount)}
                    />
                  );
                }),
              )}
              {isSkuListEmpty() && categoryStore.filteredNestedCategories.length === 0 && (
                <Empty
                  className={styles.emptyList}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </Spin>
        </div>
      </div>

      {/* TODO: currently no pagination in place
        <div className={styles.moreTextCon}>
          <Buttons.PrimaryBtn
            className={styles.moreTextBtn}
            type="ghost"
          >
            Load More
          </Buttons.PrimaryBtn>
        </div>
      */}
    </main>

  );
});

export default Products;
