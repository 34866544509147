import APIClient, { IInventoryMaster, IVoucherClaim } from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import { isEmptyString } from '../utils/strings';

class VoucherStore {
  voucherClaims: IVoucherClaim[] = [];

  isLoading = false;

  constructor() {
    makeObservable(this, {
      voucherClaims: observable,
      isLoading: observable,
      loadVoucherClaims: action,
      claimVoucher: action,
    });
  }

  async loadVoucherClaims(clientId: string) {
    this.isLoading = true;

    try {
      this.voucherClaims = await APIClient.VoucherClaim.getClientClaims(clientId);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('vouchersStore load claims err:', err);
      return false;
    } finally {
      this.isLoading = false;
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  async setApplyVoucherPricing(claimId: string, invItems: IInventoryMaster[]): Promise<IInventoryMaster[]> {
    const refNo = this.voucherClaims.find((x) => x.id === claimId)?.voucher?.refNo;
    if (!refNo) {
      throw new Error('voucherStore apply pricing: refNo no found');
    }
    const res = await APIClient.Voucher.addInventoryPricing(refNo, invItems);
    return res;
  }

  async claimVoucher(clientId: string, refNo: string) : Promise<IVoucherClaim | undefined> {
    if (isEmptyString(refNo)) {
      message.error('Please enter the voucher code');
      return undefined;
    }

    let claim: IVoucherClaim | undefined;
    const hide = message.loading(`Claiming voucher #${refNo}`);
    try {
      claim = await APIClient.VoucherClaim.addClaimByRefNo(refNo);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('voucherStore claim err:', err);
      message.error('Could not claim voucher.');
    } finally {
      hide();
      this.isLoading = false;
    }

    await this.loadVoucherClaims(clientId);
    return claim;
  }
}

export default VoucherStore;
