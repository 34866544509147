import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IOrder } from '@bridgelabsdesign/gfox-api-client';
import { Helmet } from 'react-helmet';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Cards from '../../components/Cards';
import stores from '../../stores/stores';
import Spin from '../../components/Spin/Spin';
import { useAuthContext } from '../../context/AuthContext';

const { Title } = Typography;

const AccountOrdersTrack = observer(() => {
  const [order, setOrder] = useState<IOrder | null>(null);
  const { currentClient } = useAuthContext();
  const { orderStore } = stores;

  const history = useHistory();
  const { refNo } = useParams<{ refNo?: string }>();

  const handleGetOrderInfo = async () => {
    if (!refNo) {
      history.push('/account/orders');
      return;
    }
    await orderStore.loadPreviousOrders(currentClient?.id);
    const orderInfo = orderStore.previousOrders?.orders?.find((x) => x.refNo === refNo);
    if (!orderInfo) {
      history.push('/account/orders');
      return;
    }
    setOrder(orderInfo);
  };

  useEffect(() => {
    if (currentClient?.id) {
      handleGetOrderInfo();
    }
  }, [currentClient?.id]);

  return (
    <main id="account-invoices">
      <Helmet>
        <title>Track Order - G. Fox</title>
      </Helmet>
      <Spin
        spinning={!!orderStore.isLoadingHistory}
      >
        <Title
          className={styles.headerText}
          level={2}
        >
          Track Orders
        </Title>
        <div className={styles.tableCon}>
          <Cards.LastOrderCard order={order} />
        </div>
      </Spin>
    </main>
  );
});

export default AccountOrdersTrack;
