import styled from 'styled-components';

const Body = styled.div`
    height: 100%;
    margin: 0px;
    display: flex;

    .column {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    #left {
        flex-shrink: 0;
    }
    #right {
        width: 100%;
        flex-shrink: 0;
    }
    .top-left {
        flex-shrink: 0;
        color: white;
        padding: 20px;
    } 
    .top-right {
        display: flex;
        flex-shrink: 0;
        padding: 10px;
    }

    .bottom {
        position: absolute;
        width: 100%;
        z-index: -1;
        background: green;
    }

    // Without a top right
    .right-full {
        flex-grow: 1;
        overflow-y: auto;
    }
    ul{
        display: inline-flex;
        list-style: none;
        margin: 0;
    }
    li{
        margin-right: 20px;
    }
`;

export default Body;
