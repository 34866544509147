import Tooltip from 'antd/es/tooltip';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { PhoneOutlined } from '@ant-design/icons';
import Images from '../../assets';
import Buttons from '../../components/Buttons';
import Modals from '../../components/Modals';
import Typography from '../../components/Typography';
import styles from './styles.module.css';

const { Text, Title } = Typography;

const Account = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  return (
    <main id="account">
      <Helmet>
        <title>Products - G. Fox</title>
      </Helmet>
      <div className={styles.loginCon}>
        <div className={styles.loginFormCon}>
          <Title
            className={styles.loginFormTitle}
            level={2}
          >
            The New
            <br />
            G. Fox Experience
          </Title>

          <Text className={styles.loginFormSubText}>
            We are committed to continuously improving and enhancing your shopping experience, and this upgrade is just the beginning.
          </Text>

          <div className={styles.loginFormOptsCon}>
            <Tooltip title="Migrate your account details to the new G. Fox experience">
              <Buttons.PrimaryBtn
                className={styles.loginFormSubmitBtn}
                onClick={() => setIsFormModalOpen(true)}
              >
                I HAVE NOT changed my password and logged into the NEW G.Fox website
              </Buttons.PrimaryBtn>
            </Tooltip>
            <br />
            <Text
              className={styles.loginFormOptsMigrateText}
              style={{ display: 'block' }}
            >
              Migrate my account details to the new G. Fox experience.
              For security reasons you will be required to update your password.
            </Text>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <div className={styles.loginAltOpts}>
              <Buttons.LinkBtn
                className={styles.loginFormSubmitBtn}
                link={{ href: '/account/login' }}
                onClick={() => {}}
                type="ghost"
              >
                I HAVE changed my password and am ready to log into the NEW G.Fox website
              </Buttons.LinkBtn>

              <Buttons.LinkBtn
                className={styles.loginFormSubmitBtn}
                link={{ href: '/account/register' }}
                type="ghost"
                onClick={() => {}}
              >
                I have not used the OLD G.Fox website before
              </Buttons.LinkBtn>
            </div>
          </div>

        </div>
        <div
          className={styles.loginFormBanner}
          style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${Images.WorkerPullingPalletTruck})` }}
        >
          <div className={styles.bannerTextCon}>
            <Text className={styles.bannerText}>
              Contact Us, Let us know how we can help:
              <br />
              <PhoneOutlined />
              {' '}
              065 980 2421
            </Text>
          </div>
          <ul>
            <li>Faster and Seamless Browsing</li>
            <li>Price display for COD customers</li>
            <li>Multiple buyers on a single account</li>
            <li>Enhanced Product Discovery</li>
            <li>Mobile-First Shopping</li>
            <li>Personalized Shopping</li>
            <li>Responsive Customer Support</li>
            <li>Exclusive Deals and Promotions</li>
            <li>Secure and Hassle-Free Checkout</li>
            <li>Sleek and User-Friendly Design</li>
          </ul>
        </div>
      </div>
      <Modals.MigrateAccountModal
        isVisible={isFormModalOpen}
        setIsVisible={setIsFormModalOpen}
      />
    </main>
  );
};

export default Account;
