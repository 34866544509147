import { IInventoryMaster } from '@bridgelabsdesign/gfox-api-client';
import { IProductSelectionQuery } from '../stores/cart';
import { FilterColorType } from '../stores/inventory'; // FIXME: circular dependencies
import { colourNameToHex } from './colors';
import { compareStrings, isEmptyString } from './strings';

export const commonSizingOptions = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];

export const findSizingIndex = (size: string) => {
  const sizingIndex = commonSizingOptions.findIndex((x) => compareStrings(x, size));
  if (sizingIndex >= 0) {
    return sizingIndex;
  }
  const num = Number(size);
  return !Number.isNaN(num) ? num : 100;
};

export const colorSelection = (invItems: IInventoryMaster[]): FilterColorType[] => {
  const ret: FilterColorType[] = [];
  const cachedValues: { [T: string]: boolean } = {};

  for (let index = 0; index < invItems.length; index += 1) {
    const element = invItems[index].categorySpecificInfo;
    const colorInfo = element?.find((x) => compareStrings(x.name, 'colour') || compareStrings(x.name, 'color'));
    if (!isEmptyString(colorInfo?.value as any) && !cachedValues[colorInfo?.value as string]) {
      cachedValues[colorInfo?.value as string] = true;
      const hex = colourNameToHex(colorInfo?.value as string);
      ret[ret.length] = {
        name: colorInfo?.value as string,
        hex: hex ?? colorInfo?.value as string,
      };
    }
  }
  return ret;
};

export const selectedInvItem = (invItems: IInventoryMaster[], { colors, filterBy }: IProductSelectionQuery): IInventoryMaster[] => {
  let data = invItems.slice();
  // no item to set
  if (data.length === 0 || (colors.length === 0 && filterBy.length === 0)) {
    return [];
  }

  // filter options have been set
  if (filterBy.length > 0) {
    // eslint-disable-next-line arrow-body-style

    filterBy.forEach((f) => {
      data = data.filter((x) => x.categorySpecificInfo?.some((item) => {
        const res = compareStrings(f.name, item.name) && f.value === item.value;
        return res;
      }));
    });
  }

  // color options set
  if (colors.length > 0) {
    // filter by selected color last
    data = data.filter((x) => x.categorySpecificInfo?.some((item) => {
      if (compareStrings(item.name, 'colour') || compareStrings(item.name, 'color')) {
        return colors?.some((c) => c === item.value);
      }
      return false;
    }));
  }

  return data; // ?? data[0];
};
