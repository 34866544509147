import React from 'react';
import { IoCallOutline, IoLocationOutline, IoMailOutline } from 'react-icons/io5';
import Typography from '../Typography';
import styles from './Team-card.module.css';

export interface ITeamProps {
  className?: string;
  imageSrc: string;
  imgClassName?: string;
  EmployeeName: string;
  EmployeeNameClassName?: string;
  EmployeePosition: String;
  separator?:boolean
  phone: string;
  location: string;
  Email: string;
}

const { Text } = Typography;

const TeamCard = ({
  className,
  imageSrc,
  imgClassName,
  EmployeeName,
  separator,
  EmployeeNameClassName,
  EmployeePosition,
  phone,
  location,
  Email,

}: ITeamProps) => (

  <div
    className={[styles.container, className].join(' ')}
  >
    <div
      className={[
        styles.imgContainer,
        imgClassName,
      ].join(' ')}
    >
      <img
        src={imageSrc}
        alt={EmployeeName}
      />
    </div>

    {separator && (
      <div className={styles.separatorCon} />
    )}

    <div className={styles.infoContainer}>
      <div className={styles.infoProdNameCon}>
        <Text
          className={[
            styles.infoProdName,
            EmployeeNameClassName,
          ].join(' ')}
        >
          {EmployeeName}
        </Text>
        <Text
          className={[
            styles.positionName,
          ].join(' ')}
        >
          {EmployeePosition}
        </Text>
      </div>
      <div>
        <IoCallOutline className={[
          styles.iconColor,
        ].join(' ')}
        />
        <Text
          className={[
            styles.detailsName,
          ].join(' ')}
        >
          {phone}
        </Text>
      </div>
      <div>
        <IoLocationOutline className={[
          styles.iconColor,
        ].join(' ')}
        />
        <Text
          className={[
            styles.detailsName,
          ].join(' ')}
        >
          {location}
        </Text>
      </div>
      <div>
        <IoMailOutline className={[
          styles.iconColor,
        ].join(' ')}
        />
        <Text
          className={[
            styles.detailsName,
          ].join(' ')}
        >
          {Email}
        </Text>
      </div>
    </div>
  </div>
);

TeamCard.defaultProps = {
  className: undefined,
  imgClassName: undefined,
  EmployeeNameClassName: undefined,
  separator: false,
};

export default TeamCard;
