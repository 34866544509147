/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Typography from '../../components/Typography';
// eslint-disable-next-line no-unused-vars
import styles from './styles.module.css';

const LegalPDF = () => (
  <main id="privacyPolicy">
    <div className={styles.privacyDiv}>
      <br />
      <Typography.Title level={3}>
        PRIVACY NOTICE FOR SUPPLIER INTERACTORS / USERS OF OUR PLATFORMS AND SERVICES
      </Typography.Title>
      <br />
      <Typography.Paragraph>
        <Typography.Title level={4}>PURPOSE OF THIS STATEMENT</Typography.Title>
        <br />
        We, G. Fox & Co Proprietary Limited and our subsidiaries and trading partners, collectively referred
        to as "we", "us", the "Group", in our capacity as a Responsible Party, in order to engage with you, will
        have to process your Personal Information, and in doing so, will have to comply with a law known as
        the Protection of Personal Information Act, 2013 (“POPIA”), which regulates and controls the
        processing of a person’s Personal Information in South Africa, this includes the collection, use,
        transfer and destruction of a person’s Personal Information.
        <br />
        <Typography.Text>
          1.2 For the purpose of this Processing Notice, please take note of the following words and phrases
          which will be used throughout this Processing Notice:
        </Typography.Text>
        <br />
        <Typography.Text>
          <ul>
            <li>
              <strong>"consent"</strong>
              , means the consent which you give to us to process your Personal Information. This
              consent must be voluntary, specific, and informed. Following this, once we have explained to you why
              we need your Personal Information and what we will be doing with it, you are then, in relation to certain
              uses of the information, required to give us your permission to use it, which permission or consent
              can be express or implied; implied meaning that consent may be demonstrated by way of your actions.
            </li>
            <li>
              <strong>"Data Subject"</strong>
              , means the person to whom Personal Information relates. A Data Subject is
              usually the individual, and in this Processing Notice, it will be you. The law provides that this person
              has the right to know when their Personal Information is being collected, the reasons why it is being
              collected, and the name of the person who is collecting it.
            </li>
            <li>
              <strong>"Personal Information"</strong>
              , means information relating to an identifiable, living, natural person,
              and where applicable, an identifiable, existing juristic person, including but not limited to, information
              relating to race, gender, sex, pregnancy, marital status, nationality, ethnic or social origin, colour,
              sexual orientation, age, physical or mental health, well-being, disability, religion, conscience, belief,
              culture, language, birth, education, identity number, telephone number, email, postal or street address,
              biometric information and financial, criminal or employment history, as well as any other information
              considered to be personal in nature in terms of POPIA.
            </li>
            <li>
              <strong>"POPIA"</strong>
              , means the Protection of Personal Information Act, 2013 (Act No. 4 of 2013), as
              amended from time to time, and any regulations promulgated under it.
            </li>
          </ul>
        </Typography.Text>
        <Typography.Text>
          <strong>
            1.3 In terms of POPIA, where a person processes another’s Personal Information, such processing
            must be done in a lawful, legitimate, and responsible manner and in accordance with the provisions,
            principles and conditions set out under POPIA.
          </strong>
          <br />
          <strong>1.4 In order to comply with POPIA, a person processing another’s Personal Information must:</strong>
          <br />
          1.4.1 provide the Data Subject or owner of the Personal Information with several details pertaining
          to the processing of the Personal Information, before such information is processed; and
          <br />
          1.4.2 get permission or consent, explicitly or implied, from the Data Subject, to process the Personal
          Information, unless such processing:
          <ul>
            <li>
              is necessary to carry out actions for the conclusion or performance of a contract to which the
              owner / Data Subject of the Personal Information is a party;
            </li>
            <li>is required to comply with an obligation imposed by law;</li>
            <li>
              is for a legitimate purpose or is necessary to protect the legitimate interest(s) and / or for
              pursuing the legitimate interests of i) the owner / Data Subject of the Personal Information; ii)
              the person processing the Personal Information; or iii) that of a third party to whom the Personal
              Information is supplied; or
            </li>
            <li>
              is necessary for the proper performance of a public law duty by a public body or on behalf of a
              public body.
            </li>
          </ul>
        </Typography.Text>
        <Typography.Text>
          <strong>
            1.5 In accordance with the requirements of POPIA, and because your privacy and trust are important to
            us, we set out below how we collect, use, share and otherwise process your Personal Information
            and the reasons why we need to use and process your Personal Information.
          </strong>
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>2. APPLICATION</Typography.Title>
        <br />
        <Typography.Text>
          <ul>
            <li>
              <strong>Interactors:</strong>
              {' '}
              persons who interact with us, physically or via email or via our websites, applications,
              mobile applications, or social media portals or platforms, or who come onto our sites and / or who
              enter our offices or facilities.
            </li>
            <li>
              <strong>Users of our Sites:</strong>
              {' '}
              persons who use our websites, applications, mobile applications, or social
              media portals or platforms whether to find out more about us, to make enquiries about us, or our
              products or services or where persons want to do business with us be it providing or selling to us or
              receiving or buying from us, certain goods, and services, etc.
            </li>
            <li>
              <strong>Customers and Clients:</strong>
              {' '}
              persons who want to purchase our products and / or who receive
              marketing communications and / or who communicate with us physically or via email or via our
              websites, applications, mobile applications, or social media portals or platforms, and / or who come
              onto our sites, facilities and / or who enter our offices.
            </li>
            <li>
              <strong>Contractors, Vendors and Service Providers:</strong>
              {' '}
              persons who want to provide us with goods, and
              services, or who we provide goods and services to, including consultancy and infrastructure related
              services and who we interact and communicate with, either physically or via email or via our
              websites, applications, mobile applications, or social media portals or platforms, and / or who come
              onto our sites, facilities and / or who enter our offices.
            </li>
            <li>
              <strong>Regulators and Public Bodies:</strong>
              {' '}
              persons whom we engage with to discharge legal duty
              obligations, including SARS, the Information Regulator, National Treasury, Department of Labour,
              the Financial Sector Conduct Authority.
            </li>
            <li>
              <strong>Business Partners:</strong>
              {' '}
              whether in their capacity as operators or not, who provide services, goods and
              other benefits to us, our employees or to our customers, clients, and service providers, such as
              administrators, app/site developers and operators, financial service providers, advertising, and
              marketing or PR agencies.
            </li>
          </ul>
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>3. PURPOSE FOR PROCESSING YOUR PERSONAL INFORMATION</Typography.Title>
        <br />
        <Typography.Text>
          3.1 Your personal information will be processed by us for the following purposes:
          <ul>
            <li>
              <strong>Due diligence purposes (legitimate purpose):</strong>
              {' '}
              To carry out a due diligence before we decide to
              engage or interact with you or to do business with you, including obtaining and verifying your
              credentials, including your business details, credit and financial status and history, tax status, B-BBEE
              status, and or any performance or vendor related history.
            </li>
            <li>
              <strong>Contract purposes:</strong>
              {' '}
              To investigate whether we are able or willing to conclude a contract with you
              based on the findings of any due diligence detailed above, and if the assessment is in order, to
              conclude a contract with you.
            </li>
            <li>
              <strong>To process transactions and provide or receive goods and services:</strong>
              {' '}
              To perform under any
              contract which has been concluded with you, including carrying out all contractual obligations,
              exercising all contractual rights, sending products to you that you have purchased, supplying services
              to you that you have purchased, assessing or communicating requirements, manufacturing,
              packaging, ordering, delivering, and / or responding to, or submitting queries, complaints, returns or
              engaging in general feedback, or acting in such a manner as to personalize any goods or services,
              and to make recommendations related to us or our or your operations.
            </li>
            <li>
              <strong>Attending to financial matters pertaining to any transaction:</strong>
              {' '}
              To administer accounts or profiles
              related to you or your organization including registrations, subscriptions, purchases, billing events,
              fees, costs and charges calculations, quoting, invoicing, receipt of payments or payment of refunds,
              reconciliations, and financial management in general.
            </li>
            <li>
              <strong>Communication:</strong>
              {' '}
              To contact you and to communicate with you generally or in respect of our or your
              requirements, or instructions.
            </li>
            <li>
              <strong>Platform administration and management:</strong>
              {' '}
              To administer our websites, applications, mobile
              applications, or social media portals or platforms (collectively "Platforms”), personalize our Platforms
              for you, enable your access to and use of the Platforms and the services we offer on such Platforms,
              and publish information about you on the Platforms.
            </li>
            <li>
              <strong>Risk assessment and anti-bribery and corruption matters:</strong>
              {' '}
              To carry out vendor, organizational
              and enterprise wide risk assessments, in order to detect and prevent bribery, corruption, fraud and
              abuse, to comply with all applicable laws, as well as to identify and authenticate your access to and
              to provide you with access to our goods, services or premises and generally to ensure the security
              and protection of all persons including employees, and persons when entering or leaving our sites
              and operations or facilities and / or to exercise our rights and to protect our and others’ rights and / or
              property, including to take action against those that seek to violate or abuse our systems, services,
              customers or employees and / or other third parties where applicable.
            </li>
            <li>
              <strong>Legal obligation:</strong>
              {' '}
              To comply with the law and our legal obligations, including to register with
              Regulators, obtain and hold permits and certificates, register for VAT, Tax, etc. and to submit reports
              or provide various notices or returns, to litigate and / or to respond to a request or order from an SAP
              official, investigator or court official, regulator, or public authority.
            </li>
            <li>
              <strong>Security purposes:</strong>
              {' '}
              To permit you access to our offices, facilities, manufacturing, or parking areas,
              as well as to controlled areas, for the purposes of monitoring via CCTV, your interaction and access
              in and from our facilities described above, and for general risk management, security, and emergency
              incident control purposes as well as for data and cybersecurity purposes.
            </li>
            <li>
              <strong>Internal research and development purposes:</strong>
              {' '}
              To conduct internal research and development for
              new content, products, and services, and to improve, test, and enhance the features and functions
              of our current goods and services. Data subjects will need to provide consent to participate.
            </li>
            <li>
              <strong>Sale, merger, acquisition, or other disposition of our business:</strong>
              {' '}
              To proceed with any proposed
              or actual sale, merger, acquisition, or other disposition of our business (including in connection with
              any bankruptcy or similar proceedings).
            </li>
            <li>
              <strong>Marketing and electronic communications:</strong>
              {' '}
              To provide you with communications regarding us, our
              goods and / or other notifications, programs, events, or updates that you may have registered or
              asked for or which we are otherwise permitted by applicable law to send to you, and to send you
              offers, advertising, and marketing materials, including providing personalized advertising to you, save
              where you have opted out of this activity.
            </li>
            <li>
              <strong>Events, advertising and public relations materials and publications:</strong>
              {' '}
              For the purposes of making
              contact with you.
              <ul>
                <li>
                  attending to your enquiries and requests in relation to our advertising and public relations
                  materials and publications
                </li>
                <li>
                  for providing you from time to time with information pertaining to the Group, or its subsidiary
                  businesses’ advertising and public relations materials and publications
                </li>
                <li>to invite you to attend functions and events</li>
                <li>our request for your appearance in advertising and public relation materials and publications.</li>
              </ul>
            </li>
          </ul>
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>4. WHAT PERSONAL INFORMATION OR INFORMATION DO WE COLLECT FROM YOU?</Typography.Title>
        <br />
        To engage and / or interact with you, for the purposes described above, we may process certain types
        of your personal information, as described below:
        <ul>
          <li>
            <strong>You or your organization’s contact information,</strong>
            {' '}
            such as name, alias, address, identity number,
            passport number, phone number, cell phone number, vehicle make and registration number, email
            address, and similar contact data, serial numbers of equipment, details regards the possession of
            dangerous weapons, and other contact information including details of your employer, memberships
            or affiliations, such as the name of your employer or organization that you are a member of, information
            about your colleagues or those within your organization, your status with an organization, and similar
            data, which are required for various legitimate interests, contractual and / or lawful reasons.
          </li>
          <li>
            <strong>Specific identifiers,</strong>
            {' '}
            which are required in some instances in order to protect legitimate interests,
            comply with legal obligations, or, and financial, credit, deviant and criminal history ( to protect our
            legitimate interests and to perform risk assessments).
          </li>
          <li>
            <strong>Account Information,</strong>
            {' '}
            including banking details, security-related information (including usernames and
            passwords, authentication methods, and roles), service-related information (including purchase history and
            account profiles), billing-related information (including payment, shipping, and billing information), and
            similar data, all which are required to perform contractual matters and / or to provide you access to services.
          </li>
          <li>
            <strong>User Content,</strong>
            {' '}
            such as content of communications, suggestions, questions, comments, feedback, and
            other information you send to us, that you provide to us when you contact us, or that you post on our
            websites, applications, mobile applications, or social media portals or platforms including information
            in alerts, folders, notes, and shares of content), and similar data which are required to perform
            contractual matters and / or in order to provide you access to services or attend to queries.
          </li>
          <li>
            <strong>Device & Browser Information,</strong>
            {' '}
            such as network and connection information (including Internet
            Service Provider (ISP) and Internet Protocol (IP) addresses), device and browser identifiers and
            information (including device, application, or browser type, version, plug-in type and version, operating
            system, user agent, language and time zone settings, and other technical information), advertising
            identifiers, cookie identifiers and information, and similar data, which are required to perform
            contractual matters and / or in order to provide you access to services or attend to queries or to ensure
            that security safeguards are in place.
          </li>
          <li>
            <strong>Usage Information and Browsing History,</strong>
            {' '}
            such as usage metrics (including usage rates,
            occurrences of technical errors, diagnostic reports, settings preferences, backup information, API calls,
            and other logs), content interactions (including searches, views, downloads, prints, shares, streams,
            and display or playback details), and user journey history (including clickstreams and page navigation,
            URLs, timestamps, content viewed or searched for, page response times, page interaction information
            (such as scrolling, clicks, and mouse-overs), and download errors), advertising interactions (including
            when and how you interact with marketing and advertising materials, click rates, purchases or next
            steps you may make after seeing an advertisement, and marketing preferences), and similar data
            which are required to perform contractual matters and / or in order to provide you access to services
            or attend to queries or to ensure that security safeguards are in place.
          </li>
          <li>
            <strong>Location Data,</strong>
            {' '}
            such as the location of your device, your household, and similar location data, which
            are required to perform contractual matters and / or to provide you access to services or attend to
            queries or to ensure that security safeguards are in place.
          </li>
          <li>
            <strong>Your Image,</strong>
            {' '}
            such as still pictures, video, voice, and other similar data, which are required to perform
            contractual matters and / or to provide you access to services or attend to queries or to ensure that
            security safeguards are in place.
          </li>
          <li>
            <strong>Identity Information,</strong>
            {' '}
            such as government-issued identification information, tax identifiers, other
            government-issued identifiers, and similar data, which are required to comply with laws.
          </li>
          <li>
            <strong>Financial Information,</strong>
            {' '}
            such as billing address, credit card information, billing contact details, and
            similar data, tax numbers and VAT numbers, which are required to perform contractual matters and /
            or to provide you access to services or attend to queries or to ensure that security safeguards are in
            place and / or which are required to comply with laws.
          </li>
          <li>
            <strong>Social Media and Online Content,</strong>
            {' '}
            such as information placed or posted in social media and online
            profiles, online posts, and similar data, which are required to perform contractual matters and / or to
            provide you access to services or attend to queries and generally for the purposes of advertising,
            marketing, and related communications.
          </li>
        </ul>
      </Typography.Paragraph>
      <Typography.Title level={2}>Privacy Statement</Typography.Title>
      <Typography.Paragraph>
        <Typography.Title level={4}>5. SOURCES OF INFORMATION - HOW AND WHERE DO WE COLLECT YOUR PERSONAL INFORMATION FROM?</Typography.Title>
        <br />
        <Typography.Text>
          5.1 Depending on your requirements,
          we will collect and obtain personal information about you either directly from you, from certain third parties
          (such as your employer or regulators), or from other sources which are described below:
          <ul>
            <li>
              <strong>5.1.1. Direct collection:</strong>
              {' '}
              You provide personal information to us when you:
              <ul>
                <li>Use our websites, applications, mobile applications, or social media portals or platforms.</li>
                <li>Interact with us.</li>
                <li>Enquire about, or search for our goods or services.</li>
                <li>Create an account with us.</li>
                <li>Conclude a contract with us.</li>
                <li>Purchase or use our goods.</li>
                <li>
                  Purchase, use, or otherwise interact with content,
                  products, or services from third-party providers
                  who have a relationship with us.
                </li>
                <li>
                  Create, post, or submit user content on our websites, applications, mobile applications, or social media
                  portals or platforms.
                </li>
                <li>Register for or attend one of our events, functions, or locations.</li>
                <li>Request or sign up for information, including marketing material.</li>
                <li>Communicate with us by phone, email, chat, in person, or otherwise.</li>
                <li>Complete a questionnaire, survey, support ticket, or other information request form.</li>
                <li>When you submit a quotation, or offer to do business with us, a tender or when you conclude a contract with us.</li>
              </ul>
            </li>
            <li>
              <strong>5.1.2 Automatic collection:</strong>
              {' '}
              We collect personal information automatically from you when you:
              <ul>
                <li>
                  Search for, visit, interact with, or use our websites, applications, mobile applications, or
                  social media portals or platforms.
                </li>
                <li>Access, use, or download content from us.</li>
                <li>Open emails or click on links in emails or advertisements from us.</li>
                <li>
                  Otherwise interact or communicate with us
                  (such as when you attend one of our events, functions, or locations, when you request
                  support or send us information, or when you mention or post to our social media accounts).
                </li>
              </ul>
            </li>
            <li>
              <strong>5.1.3 Collection from third parties:</strong>
              {' '}
              We collect personal information about you from third parties, such as:
              <ul>
                <li>
                  Your organization and others with whom you have a relationship with that
                  provide or publish personal information related to you, such as from our customers
                  or from others when they create, post, or submit user content that may include your personal information.
                </li>
                <li>
                  Regulators, professional or industry organizations and certification
                  / licensure agencies that provide or publish personal information related to you.
                </li>
                <li>Third parties and affiliates who deal with or interact with us or you.</li>
                <li>
                  Service providers and business partners who work with us and that we may utilize to
                  deliver certain content, products, or services or to enhance your experience.
                </li>
                <li>Marketing, sales generation, and recruiting business partners.</li>
                <li>SAP, Home Affairs, Credit bureaus and other similar agencies.</li>
                <li>Government agencies, regulators and others who release or publish public records.</li>
                <li>
                  Other publicly or generally available sources, such as social media sites, public and online websites,
                  open databases, and data in the public domain.
                </li>
              </ul>
            </li>
          </ul>
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>6. HOW WE SHARE INFORMATION</Typography.Title>
        <br />
        <Typography.Text>
          We share personal information for the purposes set out in this Privacy Statement and with the following categories of recipients:
          <ul>
            <li>
              <strong>the Group, our employees, and our affiliates:</strong>
              {' '}
              We may share your personal information amongst our employees, affiliates,
              and the companies within our Group for business and operational purposes.
            </li>
            <li>
              <strong>Your Organization and Contacts:</strong>
              {' '}
              We may share your personal information with your organization and others with whom you have a
              relationship to fulfil or perform a contract or other legal obligation,
              including with third parties that arrange or provide you with access to our
              goods or services and who pay us in connection with such access.
              We may also share your personal information with your contacts if you are in the same
              organization or to facilitate the exchange of information between you and the contact(s).
            </li>
            <li>
              <strong>Business Partners:</strong>
              {' '}
              We may share your personal information with our business partners to jointly offer, provide, deliver, analyze,
              administer, improve, and personalize products or services or to host events and functions. We may also pass
              certain requests from you or your organization to these business providers.
            </li>
            <li>
              <strong>Third Party Content Providers:</strong>
              {' '}
              We may share your personal information with our third-party content providers to perform tasks on our
              behalf and to assist us in providing, delivering, analyzing, administering, improving, and personalizing
              content related to our relationship with you, including app developers, financial, benefits, etc. and
              may to this end pass certain requests from you or your organization to these providers.
            </li>
            <li>
              <strong>Third Party Service Providers:</strong>
              {' '}
              We may share your personal information with our third-party service providers to perform tasks
              on our behalf and which are related to our relationship with you, including app developers,
              financial, benefits etc. and to assist us in offering, providing, delivering, analyzing,
              administering, improving, and personalizing such services or products.
            </li>
            <li>
              <strong>Cyber Third-Party Service Providers:</strong>
              {' '}
              We may share your personal information with our third-party cyber
              service providers to perform tasks on our behalf and which are related to
              our relationship with you, including those who provide technical and/or
              customer support on our behalf, who provide application or software development and quality assurance,
              who provide tracking and reporting functions, research on user demographics, interests, and behavior,
              and other products or services. These third-party service providers may also collect personal information
              about or from you in performing their services and/or functions on our Services. We may also pass certain
              requests from you or your organization to these third-party service providers.
            </li>
            <li>
              <strong>Advertisers:</strong>
              {' '}
              We may share your personal information with advertisers, advertising exchanges, and
              marketing agencies that we engage for advertising services, to deliver advertising, and to assist
              us in advertising our brand and products and services. Those advertising services may also target
              advertisements on third party websites based on cookies or other information indicating previous interaction with us and/or ourselves.
            </li>
            <li>
              <strong>Users:</strong>
              {' '}
              We aggregate information from public records, phone books, social networks, marketing surveys,
              business websites, and other sources made available to us to create listings and profiles that
              are placed into user listings and directories. Additionally, if you choose to include your
              personal information in any reviews, comments, or other posts that you create, then that
              personal information may be displayed other users as part of your posting.
            </li>
            <li>
              <strong>In the Event of Merger, Sale, or Change of Control:</strong>
              {' '}
              We may transfer this Privacy Statement and your personal information to a third-party entity that acquires or
              is merged with us as part of a merger, acquisition, sale, or other change of control (such as the result of a bankruptcy proceeding).
            </li>
            <li>
              <strong>Regulators and law enforcement agencies:</strong>
              {' '}
              We may disclose your personal information to regulators and other bodies to comply with any
              applicable law or regulation, to comply with or respond to a legal process or law enforcement or governmental request.
            </li>
            <li>
              <strong>Other Disclosures:</strong>
              {' '}
              We may disclose your personal information to third parties if we reasonably believe that
              disclosure of such information is helpful or reasonably necessary to enforce our terms and
              conditions or other rights (including investigations of potential violations of our rights),
              to detect, prevent, or address fraud or security issues, or to protect against harm to the
              rights, property, or safety of the group, our employees, any users, or the public.
            </li>
          </ul>
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Title level={2}>Privacy Statement - Security and Rights</Typography.Title>
      <Typography.Paragraph>
        <Typography.Title level={4}>7. SECURITY OF INFORMATION</Typography.Title>
        <br />
        <Typography.Text>
          7.1 The security of your Personal Information is important to us. Considering the nature,
          scope, context, and purposes of processing personal information, as well as the risks to
          individuals of varying likelihood and severity, we have implemented technical and organizational
          measures designed to protect the security of personal information. In this regard, we will conduct
          regular audits regarding the safety and the security of your Personal Information.
          <br />
          7.2 Your Personal Information will be stored electronically which information, for operational reasons,
          will be accessible to persons employed or contracted by us on a need-to-know basis, save that where
          appropriate, some of your Personal Information may be retained in hard copy.
          <br />
          7.3 Once your Personal Information is no longer required since the purpose for which the Personal
          Information was held has come to an end, such Personal Information will be retained in accordance
          with our applicable Group records retention schedules, which varies depending on the type of
          processing, the purpose for such processing, the business function, record classes, and record
          types. We calculate retention periods based upon and reserve the right to retain Personal Information
          for the periods that the Personal Information is needed to: (a) fulfil the purposes described in this
          Privacy Statement, (b) meet the timelines determined or recommended by regulators, professional bodies,
          or associations, (c) comply with applicable laws, legal holds, and other legal obligations
          (including contractual obligations), and (d) comply with your requests.
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>8. ACCESS BY OTHERS AND CROSS BORDER TRANSFER</Typography.Title>
        <br />
        <Typography.Text>
          8.1 We may from time to time have to disclose your Personal Information to other parties
          (see section 6 above), but such disclosure will always be subject to a lawful mechanism
          for example an agreement between ourselves and the third party to whom we are disclosing
          your Personal Information to, which contractually obliges the recipient of your Personal
          Information to comply with strict confidentiality and data security conditions.
          <br />
          8.2 Where Personal Information and related data is transferred to a country which is situated
          outside South Africa, your Personal Information will only be transferred to those countries
          which have similar data privacy laws in place or where the recipient of the Personal Information
          concludes an agreement which contractually obliges the recipient to comply with strict confidentiality
          and data security conditions and which in particular will be to a no lesser set of standards than those imposed by POPIA.
          <br />
          8.3 However, please note that no method of transmission over the Internet or method of electronic
          storage is 100% secure. Therefore, while we strive to use commercially acceptable measures designed
          to protect personal information, we cannot guarantee its absolute security.
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>9. YOUR RIGHTS</Typography.Title>
        <br />
        <Typography.Text>
          9.1 You as a Data Subject have certain rights, which are detailed below, forms are available on request from Information Officer.
          <ul>
            <li>The right of access.</li>
            <li>The right to rectification.</li>
            <li>The right to erasure (the ‘right to be forgotten’).</li>
            <li>The right to object to and restrict further processing.</li>
            <li>The right to withdraw consent.</li>
            <li>The right to data portability.</li>
          </ul>
          <br />
          <Typography.Title level={4}>10. FAILURE TO PROVIDE PERSONAL INFORMATION:</Typography.Title>
          {' '}
          Should you be asked to provide personal information
          to us, this is generally done so voluntarily. However, should you fail to provide the information
          when requested, we may not be able to provide you with a service (for example, deliver products to
          you or respond to an enquiry you might have regarding one of our platforms or services).
          <br />
          <Typography.Title level={4}> 11. CHANGES TO THIS PRIVACY STATEMENT:</Typography.Title>
          {' '}
          As our Group changes over time, this Processing Notice is
          expected to change as well. We reserve the right to amend the Processing Notice at any time, for
          any reason, and without notice to you other than the posting of the updated Processing Notice on
          Website and in this regard encourage you to visit our website frequently to keep abreast with any changes.
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>12. CONTACT US</Typography.Title>
        <br />
        <Typography.Text>
          Any comments, questions, or suggestions about this privacy notice or our handling of your Personal
          Information should be emailed to
          <Typography.Link href="">sales@gfox.co.za.</Typography.Link>
          {' '}
          Alternatively, you can contact our Information Officers:
          <br />
          Information Officers:
          <ul>
            <li>
              Sibusiso Khumalo –
              <Typography.Link href="">sibusisok@gfox.co.za</Typography.Link>
            </li>
            <li>
              Alan Muir –
              <Typography.Link href="">alanm@gfox.co.za</Typography.Link>
            </li>
          </ul>
          Phone: +27 – 011 417 9300 / 9301
          <br />
          Our telephone switchboard is open 8:00 – 16:30 GMT, Monday to Thursday and 8:00 – 15:30 GMT, Friday.
          Our switchboard team will take a message and ensure the appropriate person responds as soon as possible.
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>12. COMPLAINTS</Typography.Title>
        <br />
        <Typography.Text>
          12.1 Should you wish to discuss a complaint, please feel free to contact us using the details provided above.
          <br />
          12.2 All complaints will be treated in a confidential manner.
          <br />
          12.3 Should you feel unsatisfied with our handling of your Personal Information, or about any complaint
          that you have made to us, you are entitled to escalate your complaint to the South African, Information
          Regulator who can be contacted at
          {' '}
          <Typography.Link href="">POPIAComplaints@inforegulator.org.za.</Typography.Link>
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Title level={4}>13. ACCEPTANCE</Typography.Title>
        <br />
        <Typography.Text>
          13.1 By providing us with the Personal Information which we require from you as listed under this Processing Notice:
          <ul>
            <li>You acknowledge that you understand why your Personal Information needs to be processed.</li>
            <li>
              You accept the terms which will apply to such processing, including the terms applicable to the
              transfer of such Personal Information cross border.
            </li>
            <li>
              Where consent is required for any processing as reflected in this Processing notice, you agree that
              we may process this Personal Information.
            </li>
            <li>
              You confirm that you have shared this Processing Notice with employees, contractors, and subcontractors
              and have received from them the required consent to provide us with their respective Personal Information
              for processing as provided for and described under this Processing Notice, and where consent is required
              for any processing as reflected in this Processing notice, such persons have agreed that we may process
              this personal information.
            </li>
          </ul>
          13.2 Furthermore, should any of the Personal Information concern or pertain to a legal entity whom you represent,
          you confirm that you have the necessary authority to act on behalf of such legal entity and that you are authorized
          to provide the Personal Information and / or the required permissions in respect of the processing of that organization
          or entities’ Personal Information.
        </Typography.Text>
      </Typography.Paragraph>
    </div>
  </main>
);

export default LegalPDF;
