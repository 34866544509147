export enum OrderStatus {
  // eslint-disable-next-line no-unused-vars
  ESTIMATED_DELIVERED = 'ESTIMATED_DELIVERED',
  // eslint-disable-next-line no-unused-vars
  DELIVERED = 'DELIVERED',
  // eslint-disable-next-line no-unused-vars
  IN_TRANSIT = 'IN_TRANSIT',
  // eslint-disable-next-line no-unused-vars
  PACKED = 'PACKED',
  // eslint-disable-next-line no-unused-vars
  RECEIVED = 'RECEIVED',
  // eslint-disable-next-line no-unused-vars
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
  // eslint-disable-next-line no-unused-vars
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  // eslint-disable-next-line no-unused-vars
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  // eslint-disable-next-line no-unused-vars
  CANCELLED = 'CANCELLED',
}

export type OrderHistoryType = {
  color: string;
  status: OrderStatus;
  readableText: string;
  readableText2: string;
  currentStatus: boolean;
  // icon: React.FC;
  hidden: boolean;
}

export const orderHistorySteps: OrderHistoryType[] = [
  {
    color: '#40EAA2',
    status: OrderStatus.DELIVERED,
    readableText: 'Estimate Delivery',
    readableText2: 'Estimate Delivery',
    currentStatus: false,
    // // Icon: HomeLocationIcon
    hidden: false,
  },
  {
    color: '#40EAA2',
    status: OrderStatus.DELIVERED,
    readableText: 'Order Delivered',
    readableText2: 'Delivered',
    currentStatus: false,
    // // Icon: HomeLocationIcon
    hidden: false,
  },
  {
    color: '#F4DA4E',
    status: OrderStatus.IN_TRANSIT,
    readableText: 'Order In Transit',
    readableText2: 'In-Transit',
    currentStatus: false,
    // // Icon: TruckIcon'
    hidden: false,
  },
  {
    color: '#F4DA4E',
    status: OrderStatus.PACKED,
    readableText: 'Order Packed',
    readableText2: 'Packed',
    currentStatus: false,
    // // Icon: PackageIcon
    hidden: false,
  },
  {
    color: '#F4DA4E',
    status: OrderStatus.RECEIVED,
    readableText: 'Order Received',
    readableText2: 'Received',
    currentStatus: false,
    hidden: false,
    // // Icon: BoxesIcon
  },
  {
    color: '#535353',
    status: OrderStatus.PROCESSING_PAYMENT,
    readableText: 'Processing Payment',
    readableText2: 'Processing',
    currentStatus: false,
    hidden: true,
    // // Icon: BoxesIcon
  },
  {
    color: '##C7C7C7',
    status: OrderStatus.PAYMENT_FAILED,
    readableText: 'Payment Failed',
    readableText2: 'Failed',
    currentStatus: false,
    hidden: true,
    // // Icon: BoxesIcon
  },
  {
    color: '##C7C7C7',
    status: OrderStatus.PAYMENT_SUCCESSFUL,
    readableText: 'Payment Failed',
    readableText2: 'Processing',
    currentStatus: false,
    hidden: true,
    // // Icon: BoxesIcon
  },
  {
    color: '##C7C7C7',
    status: OrderStatus.CANCELLED,
    readableText: 'Order Cancelled',
    readableText2: 'Cancelled',
    currentStatus: false,
    hidden: true,
    // // Icon: BoxesIcon
  },
];
