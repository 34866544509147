import AccountLayout from './Account';
import CheckoutLayout from './Checkout';
import HeaderLayout from './Header';
import PlainLayout from './Plain';
import SideBarLayout from './SideBar';
import SideBarAndHeaderLayout from './SideBarAndHeader';

const Layouts = {
  AccountLayout,
  CheckoutLayout,
  HeaderLayout,
  PlainLayout,
  SideBarLayout,
  SideBarAndHeaderLayout,
};

export default Layouts;
