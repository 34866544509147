import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { Typography } from 'antd';
import styles from './styles.module.css';
import { trendingProducts } from './data';
import Cards from '../../components/Cards';

const {
  Title,
} = Typography;
const TrendingProducts = observer(() => (
  <main id="home">
    <Helmet>
      <title>Trending Products - G. Fox</title>
    </Helmet>

    <div className={styles.trendingShowcaseCon}>
      <Title
        className={styles.trendHeader}
        level={2}
      >
        Trending Products
      </Title>

      <div className={styles.trendCon}>
        {React.Children.toArray(
          trendingProducts.map((item) => (
            <Cards.ProductCard
              className={styles.productCard}
            //   categoryRefNo={item.categoryRefNo}
              // color={item.color}
              optType="viewBtn"
              imageSrc={item.imageSrc}
              productName={item.productName}
            />
          )),
        )}
      </div>
    </div>
  </main>
));

export default TrendingProducts;
