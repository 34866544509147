import React from 'react';
import { Helmet } from 'react-helmet';
import Cards from '../../components/Cards';
import styles from './styles.module.css';
import { TeamGen } from './data';
import Typography from '../../components/Typography';

const { Title } = Typography;
const Meetteam = () => (
  <main
    id="team"
  >
    <Helmet>
      <title>Team - G. Fox</title>
    </Helmet>

    <div className={styles.filterMenuResultsCon}>
      <div>
        <div className={styles.filterResultCardCon}>
          {React.Children.toArray(
            TeamGen.map((item) => (
              <div>
                <div className={styles.titleCon}>
                  <Title
                    level={2}
                    className={styles.titleText}
                  >
                    {item.Header}
                  </Title>
                </div>
                {item.items.map((cards) => (
                  <Cards.TeamCard
                    className={styles.filterResultCard}
                    imageSrc={cards.imageSrc}
                    imgClassName={styles.filterResultImg}
                    EmployeeName={cards.EmployeeName}
                    EmployeeNameClassName={styles.filterResultPName}
                    EmployeePosition={cards.EmployeePosition}
                    separator
                    location={cards.location}
                    Email={cards.Email}
                    phone={cards.phone}
                  />
                ))}
              </div>
            )),
          )}
        </div>
      </div>
    </div>
  </main>
);

export default Meetteam;
