import APIClient, {
  LoadManager, IFavoriteProducts, IInventoryMaster, ISkuMaster,
  IClient, ICategory, ICategorySpecificInfo, IStockQuantityResponse, IStockQuantityRequest,
  ICart,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import {
  action,
  computed, flow, makeObservable, observable,
} from 'mobx';
import { calcProductPrice } from '../utils/pricing';
import QuoteReorder from '../utils/quotes/quote-reorder';
import { ILoadInventoryListParams } from './inventory';
import * as favoriteStorage from '../utils/favoriteProductStorage';
import { findSizingIndex, selectedInvItem } from '../utils/productSelection';
import { getStockMessage } from '../utils/order/stockMessage';
import { compareStrings } from '../utils/strings';
import * as cartStorage from '../utils/cartStorage';

export type IProductSelectionQuery = {
    colors: string[];
    filterBy: ICategorySpecificInfo[],
  }

class FavoritesStore {
    private apIFavoriteProductsItems: LoadManager<IFavoriteProducts>= new LoadManager<IFavoriteProducts>({ data: [] },
      APIClient.Favorites.getFavorites,
      APIClient.Favorites.addFavorites,
      APIClient.Favorites.updateFavorites,
      APIClient.Favorites.deleteFavorites);

    private localFavoritesItems: LoadManager<IFavoriteProducts> = new LoadManager<IFavoriteProducts>({ data: [] },
      favoriteStorage.getFavoritess,
      favoriteStorage.addFavorites,
      favoriteStorage.updateFavorites,
      favoriteStorage.deleteFavorites);

    FavoritesItems: LoadManager<IFavoriteProducts> = this.localFavoritesItems;

    skuList = new LoadManager<ISkuMaster>({ data: [] }, APIClient.SKUMasterV2.getSkuMasters);

    invList = new LoadManager<IInventoryMaster>({ data: [] }, APIClient.InventoryV2.getInventoryMasters);

    sku: ISkuMaster | undefined = undefined;

    activeImageIdx = 0;

    lineItemCount = 1;

    selectedProductItems: IFavoriteProducts[] = []

    realTimeStockQuantity: IStockQuantityResponse[] = [];

    productSelectionQuery: IProductSelectionQuery = { colors: [], filterBy: [] };

    productQueryFilterBy: ICategory[] = [];

    voucherClaim?: { refNo: string, claimId: string } | undefined;

    pageInventoryMasterSku?: string | null;

    constructor() {
      makeObservable(this, {
        FavoritesItems: observable,
        // getSkuById: action,
        sku: observable,
        setVoucherClaim: action,
        invList: observable,
        activeImageIdx: observable,
        realTimeStockQuantity: observable,
        fetchRealTimeStockQuantity: action,
        clearSelectedProductItems: action,
        FavoritesMatchedProductSelection: action,
        lineItemCount: observable,
        productSelectionQuery: observable,
        setSelectedProductItems: action,
        updateQuantity: action,
        voucherClaim: observable,
        pageInventoryMasterSku: observable,
        FavoritesItemsInvPricing: computed,
        isLoadingFavoritesItems: computed,
        selectedProductItems: observable,
        removeProduct: action,
        FavoritesCount: computed,
        currentSkuItem: computed,
        currentInvItem: computed,
        skuDescription: computed,
        setProductSelectionQuery: action,
        setLineItemCount: action,
        updateToFavoritesItems: action,
        removeFavoritesItem: action,
        clearFavorites: action,
        addToFavorites: action,
        setActiveImageIdx: action,
        addLineItemToFavorites: action,
        loadFavoritesItems: flow,
        loadFavoritesInfo: flow,
        loadSkuList: flow,
        loadInventoryList: flow,
      });
    }

    setSku = action((sku: ISkuMaster | undefined) => {
      this.sku = sku;
    });

    setVoucherClaim(value: { refNo: string, claimId: string } | undefined) {
      this.voucherClaim = value;
    }

    get FavoritesItemsInvPricing() {
      const FavoritesItems = this.FavoritesItems.value.data.slice();
      const invList = this.invList.value.data.slice();

      const pricing = {
        vatTotal: 0,
        totalWithVat: 0,
      };

      if (invList.length > 0 && FavoritesItems.length > 0) {
        for (let i = 0; i < FavoritesItems.length; i += 1) {
          const invItem = invList.find((inv) => inv?.id === FavoritesItems[i].inventoryMasterId);
          const productPrice = calcProductPrice(invItem);
          pricing.totalWithVat += (productPrice.withTax * FavoritesItems[i].quantity);
          if (productPrice.withTax !== productPrice.excludingTax) {
            const vat = (productPrice.withTax - productPrice.excludingTax);
            pricing.vatTotal += (vat * FavoritesItems[i].quantity);
          }
        }
      }

      return pricing;
    }

    get isLoadingFavoritesItems(): boolean {
      return this.FavoritesItems.isLoading || this.skuList.isLoading || this.invList.isLoading;
    }

    get FavoritesCount(): number {
      if (this.FavoritesItems.value.data.length === 0) {
        return 0;
      }
      return this.FavoritesItems.value.data.reduce((p, c) => p + c.quantity, 0);
    }

    get currentSkuItem(): ISkuMaster | undefined {
      if (this.skuList?.value.data.length > 0) {
        return this.skuList?.value.data[0];
      }
      return undefined;
    }

    get currentInvItem(): IInventoryMaster | undefined {
      if (this.pageInventoryMasterSku) {
        const invItem = this.invList.value.data.find((x) => x.sku === this.pageInventoryMasterSku);
        if (invItem) {
          return invItem;
        }
      }

      const data = selectedInvItem(this.invList.value.data, this.productSelectionQuery);

      if (data.length > 0) {
        return data[0];
      }

      let smallestItem = data[0];
      let smallestValue = Infinity;

      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.invList.value.data) {
        const sizes = item.categorySpecificInfo
          ?.filter((info) => compareStrings(info.name, 'Size'))
          .map((info) => findSizingIndex(info.value.toString()));

        if (sizes && sizes.length > 0) {
          const minValue = Math.min(...sizes);
          if (minValue < smallestValue) {
            smallestValue = minValue;
            smallestItem = item;
          }
        }
      }

      return smallestItem ?? this.invList.value.data[0];
    }

    get skuDescription(): string[] {
      const item = this.currentSkuItem;
      if (!item?.fullDescription) {
        return [];
      }
      return item?.fullDescription?.split('|') ?? [];
    }

    setProductSelectionQuery(values: IProductSelectionQuery) {
      this.productSelectionQuery = values;
    }

    setLineItemCount(count?: number, isDirectInput: boolean = false, invItemId:string | undefined = undefined) {
      if (isDirectInput) {
        if (count !== undefined) {
        // Directly set the count for input changes
          this.lineItemCount = count;
        }
      } else {
        let updatedCount = this.lineItemCount + (count ?? 0);
        if ((updatedCount === this.lineItemCount)) {
          if (invItemId === undefined) {
          // eslint-disable-next-line no-param-reassign
            invItemId = this.currentInvItem?.id;
          }
          const itemQty = this.FavoritesItems.value.data.find((x) => x?.inventoryMasterId === invItemId)?.quantity;
          if (invItemId !== undefined && itemQty !== undefined) {
            updatedCount = itemQty;
          }
        }
        // Ensure the updated count is positive
        if (updatedCount > 0) {
          this.lineItemCount = updatedCount;
        }
      }
    }

    setActiveImageIdx(idx: number) {
      this.activeImageIdx = idx;
    }

  updateQuantity = (localItems: IFavoriteProducts[], isDirectInput: boolean = false, inventoryMasterId:string | undefined, change:number) => {
    const updatedItems = localItems?.map((item) => {
      if (item.inventoryMasterId === inventoryMasterId) {
        if (isDirectInput) {
          if (change !== undefined) {
            // eslint-disable-next-line no-param-reassign
            item.quantity = change;
            return item;
          }
        }
        // return { ...item, quantity: Math.max(item.quantity + change, 1) };
        // eslint-disable-next-line no-param-reassign
        item.quantity = Math.max(item.quantity + change, 1); // Prevents quantity from going negative
      }
      return item;
    });
    this.selectedProductItems = updatedItems;
  };

    setSelectedProductItems = (localItems: IFavoriteProducts[]) => {
      localItems.forEach((localItem) => {
        const existingItem = this.selectedProductItems.find((item) => item.inventoryMasterId === localItem.inventoryMasterId);
        if (!existingItem) {
          this.selectedProductItems.push(localItem);
        }
      });
    }

    clearSelectedProductItems=() => {
      this.selectedProductItems = [];
    }

    removeProduct = (productId:string) => {
      this.selectedProductItems = this.selectedProductItems.filter(
        (product) => product.inventoryMasterId !== productId,
      );
    }

    async addLineItemToFavorites(client: IClient | null | undefined, onAdd: () => void) {
      const invItem = this.invList.value.data.find((x) => x.sku === this.currentInvItem?.sku!);
      if (!invItem) {
        message.error('Could not add items to Favorites.');
        return;
      }

      // eslint-disable-next-line max-len
      const stockMessage = getStockMessage({ lineItemCount: this.lineItemCount }, this.currentInvItem?.masterStock, client);
      // const outOfStock = itemOutOfStock(this.lineItemCount, this.currentInvItem?.masterStock);
      if (stockMessage.isCOD && (stockMessage.isOutOfStock || stockMessage.isAppPrice)) {
        message.error(stockMessage.message);
        return;
      }

      const body = this.FavoritesItems.value.data.slice();
      let itemIdx = body.findIndex((x) => x.inventoryMasterId === invItem.id);
      if (itemIdx < 0) {
        itemIdx = body.length;
        body[itemIdx] = {
          clientId: client?.id,
          inventoryMasterId: invItem.id,
          quantity: this.lineItemCount,
        } as IFavoriteProducts;
      } else {
        if (this.lineItemCount === body[itemIdx].quantity) {
          return;
        }
        body[itemIdx].quantity = this.lineItemCount;
      }

      // needed for local Favorites details
      if (!client) {
        body[itemIdx].inventoryMaster = invItem;
      }

      try {
        await favoriteStorage.addRange(body, client ? 'api' : 'local');
        await this.loadFavoritesInfo(this.currentSkuItem?.sku!, client?.id);
        message.success('Added items to Favorites.');
      } catch (error) {
        message.error('Could not add item to Favorites...');
        // eslint-disable-next-line no-console
        console.error('FavoritesStore addLineItemToFavorites err:', error);
        return;
      }

      onAdd();
    }

    async addMultipleLineItems(client: IClient | null | undefined, invItems: IInventoryMaster[], onAdd: () => void) {
      const body = this.FavoritesItems.value.data.slice();

      // eslint-disable-next-line no-restricted-syntax
      for (const invItem of invItems) {
        const stockMessage = getStockMessage({ lineItemCount: invItem.orderQty }, invItem.masterStock, client);
        if (stockMessage.isCOD && (stockMessage.isOutOfStock || stockMessage.isAppPrice)) {
          message.error(stockMessage.message);
          return;
        }

        const itemIdx = body.findIndex((x) => x.inventoryMasterId === invItem.id);
        if (itemIdx < 0) {
          body.push({
            clientId: client?.id,
            inventoryMasterId: invItem.id,
            quantity: invItem.orderQty,
            inventoryMaster: !client ? invItem : undefined,
          } as IFavoriteProducts);
        } else {
          body[itemIdx].quantity = invItem.orderQty;
        }
      }

      try {
        await favoriteStorage.addRange(body, client ? 'api' : 'local');
        await this.loadFavoritesInfo(this.currentSkuItem?.sku!, client?.id);
        message.success('Added items to Favorites.');
      } catch (error) {
        message.error('Could not add item to Favorites.');
        console.error('FavoritesStore addLineItemsToFavorites err:', error);
        return;
      }

      onAdd();
    }

    updateToFavoritesItemsDebounceId = 0; // debounce add to Favorites requests

    async updateToFavoritesItems(clientId: string | null, FavoritesId: string, quantity = 1, isDirectInput: boolean = false) {
      if (!this.FavoritesItems) {
        return;
      }
      // Map the data to update the quantities
      const data = this.FavoritesItems.value.data.map((x) => ({
        ...x,
        client: null,
        inventoryMaster: clientId ? null : x.inventoryMaster, // needed for local Favorites storage
      }));

      // Find the index of the item in the Favorites
      const idx = data.findIndex((x) => x.id === FavoritesId);
      if (idx < 0) {
        message.error('Could not add items to Favorites.');
        return;
      }

      // Set or update the quantity based on isDirectInput flag
      if (isDirectInput) {
        data[idx].quantity = quantity;
      } else {
        data[idx].quantity += quantity;
      }

      // Handle cases where quantity becomes zero or negative
      if (data[idx].quantity <= 0) {
        await this.loadFavoritesItems(clientId);
        return;
      }

      // Debounce logic for Favorites update
      this.updateToFavoritesItemsDebounceId += 1;
      const id = this.updateToFavoritesItemsDebounceId;
      setTimeout(async () => {
        if (id === this.updateToFavoritesItemsDebounceId) {
          try {
            if (this.FavoritesItems) {
              this.FavoritesItems.isLoading = true;
              await favoriteStorage.addRange(data as IFavoriteProducts[], clientId ? 'api' : 'local');
            }
          } catch (error) {
            message.error('Could not add items to Favorites.');
            console.error('FavoritesStore updateToFavoritesItems error:', error);
          }
          await this.loadFavoritesItems(clientId);
        }
      }, clientId ? 800 : 0); // Apply debounce for API calls, immediate for local storage
    }

    async removeFavoritesItem(clientId: string, FavoritesIds: string[], showMessage = true) {
      const data = this.FavoritesItems.value.data.filter((x) => FavoritesIds.some((c) => c === x.id));

      let hide: any | undefined;
      if (showMessage) {
        hide = message.loading('Removing item from Favorites...', 0);
      }
      try {
        const items = data.map((x) => x.id);
        await favoriteStorage.deleteRange(items, clientId ? 'api' : 'local');
        await this.loadFavoritesItems(clientId);
      } catch (error) {
        message.error('Could not remove item');
        // eslint-disable-next-line no-console
        console.error('Favorite Store removeFavoritesItem error', error);
      } finally {
        if (hide) hide();
      }
    }

    async clearFavorites(clientId: string, showMessage = true) {
      const ids = this.FavoritesItems.value.data.map((x) => x.id);
      await this.removeFavoritesItem(clientId, ids, showMessage);
    }

    async addToFavorites(clientId: string, invList: IInventoryMaster[]) {
      // const data = this.FavoritesItems.value.data.slice();
      const body: Partial<IFavoriteProducts>[] = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < invList.length; i++) {
        if (invList[i].orderQty <= 0) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const quantity = invList[i].orderQty;
        const inventoryMasterId = invList[i].id;
        body[body.length] = { clientId, inventoryMasterId, quantity } as IFavoriteProducts;
      }

      await favoriteStorage.addRange(body as IFavoriteProducts[], clientId ? 'api' : 'local');
      await this.loadFavoritesInfo(clientId);
    }

    async onReorder(clientId: string, invList: IInventoryMaster[], quoteRefNo?: string) {
      const hide = message.loading('Adding items to Favorites...', 0);
      try {
        await this.clearFavorites(clientId, false);
        await this.addToFavorites(clientId, invList);
        await this.loadFavoritesItems(clientId);
        hide();

        if (quoteRefNo) {
          await QuoteReorder.saveRefNo(quoteRefNo);
        }
        return true;
      } catch (error) {
        hide();
        message.error('Could not reorder items.');
        // eslint-disable-next-line no-console
        console.error('FavoritesStore onReorder err: ', error);
        return false;
      }
    }

    async fetchRealTimeStockQuantity(clientId: string, stockQuantityRequests: IStockQuantityRequest[]) {
      try {
        const response = await APIClient.Favorites.getRealTimeStockQuantity(clientId, stockQuantityRequests);
        this.realTimeStockQuantity = response.data;
      } catch (error) {
        console.error('Could not fetch real-time stock quantity:', error);
      }
    }

    FavoritesMatchedProductSelection() {
      const filter: IFavoriteProducts[] = [];
      this.FavoritesItems.value.data.forEach((item) => {
        const inv = this.invList.value.data.find((invItem) => invItem.id === item.inventoryMasterId);
        if (inv) {
          filter.push({ ...item, inventoryMaster: inv });
        }
      });
      return this.setSelectedProductItems(filter);
    }

    * loadFavoritesInfo(sku: string, clientId?: string): {} {
      if (!this.FavoritesItems) {
        return;
      }
      yield this.loadFavoritesItems(clientId);
      yield this.loadSkuList({ skuList: sku, withPrice: true });
      yield this.loadInventoryList({ parentSku: sku, withPrice: true });
      this.FavoritesMatchedProductSelection();
      // new method, load selelction table items
      this.sku = this.skuList.value.data.find((x) => x.sku === sku);
      this.setLineItemCount();
      if (this.skuList.error || this.invList.error) {
        // TODO: Toast.show({ type: 'error', text1: 'Could not load Favorites.' });
        const error = this.skuList.error ?? this.invList;
        // eslint-disable-next-line no-console
        console.warn(error);
      }
    }

    private loadFavoritesItemsClientId?: string;

    async* loadFavoritesItems(clientId?: string | null) {
      if (clientId) {
        this.loadFavoritesItemsClientId = clientId;
      }

      const queryParams = `clientId=${this.loadFavoritesItemsClientId ?? ''}`;

      if (!this.loadFavoritesItemsClientId) {
        this.FavoritesItems = this.localFavoritesItems;
      } else {
        this.FavoritesItems = this.apIFavoriteProductsItems;

        const localFavoritesHasItems = await favoriteStorage.hasItems();
        if (localFavoritesHasItems) {
          try {
            const items = await favoriteStorage.getFavoritess();
            this.addToFavorites(this.loadFavoritesItemsClientId, items.data.map((x) => x.inventoryMaster));
            await favoriteStorage.clearItems();
          } catch (error) {
            message.error('Could not load Favorites details.');
            // eslint-disable-next-line no-console
          }
        }
      }

      yield this.FavoritesItems.fetch(queryParams);
      if (this.FavoritesItems.error) {
        // eslint-disable-next-line no-console
        console.warn(this.FavoritesItems.error);
      }
    }

    * loadSkuList({
      active = true, categoryRefNo, withPrice = true, skuList,
    }: any) {
      let queryParams = '';
      queryParams += `active=${active ? 'true' : 'false'}&`;
      queryParams += `categoryRefNo=${categoryRefNo ?? ''}&`;
      queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
      queryParams += `skuList=${skuList ?? ''}`;
      yield this.skuList.fetch(queryParams);
    }

    * loadInventoryList({
      active = true, parentSku, skuList, withPrice = true,
    }: ILoadInventoryListParams) {
      let queryParams = '';
      queryParams += `active=${active ? 'true' : 'false'}&`;
      queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
      queryParams += parentSku ? `parentSku=${parentSku ?? ''}&` : '';
      queryParams += skuList ? `skuList=${skuList ?? ''}&` : '';
      queryParams += `voucherRefNo=${this.voucherClaim?.refNo ?? ''}`;
      yield this.invList.fetch(queryParams);
    }

    RealTimeStockQuantityLoader = async (FavoritesItems: IFavoriteProducts[], currentClientId: string | undefined) => {
      const stockQuantityRequests = FavoritesItems
        .map((item) => {
          const masterStock = this.invList.value.data.find((x) => x.id === item.inventoryMasterId)?.masterStock;
          return {
            sku: masterStock?.stockCode!,
            qty: masterStock?.qtyAvailable || 0,
          };
        });

      if (currentClientId) {
        await this.fetchRealTimeStockQuantity(currentClientId, stockQuantityRequests);
      }
    };

    async transferFavoritesToCart(currentClient: IClient, cartList: ICart[], favoriteList: IFavoriteProducts[]) {
      if (favoriteList.length === 0 || !currentClient?.id) {
        return;
      }

      const hide = message.loading('Adding items to cart...', 0);

      let cartContent = favoriteList.map((item) => ({
        clientId: currentClient.id,
        inventoryMasterId: item.inventoryMaster.id,
        quantity: item.inventoryMaster.orderQty,
        createdAt: item.createdAt,
      }as IFavoriteProducts));

      // Filter cartContent for items that aren't in cartList.
      cartContent = cartContent.filter((item) =>
        // Assuming the unique identifier of each item is 'inventoryMasterId'.
        // eslint-disable-next-line implicit-arrow-linebreak
        !cartList.some((cartItem) => cartItem.inventoryMasterId === item.inventoryMasterId));

      try {
        if (cartContent.length > 0) {
          await cartStorage.addRange(cartContent, 'api');
        }

        const wishRemoveIds = favoriteList.map((x) => x.id);
        await this.removeFavoritesItem(currentClient.id, wishRemoveIds, false);
      } catch (error) {
        message.error('Could not add items to cart');
        console.error(error);
      } finally {
        hide();
      }
    }
}

export default FavoritesStore;
