import React, { useState } from 'react';
import { Drawer } from 'antd';
import Buttons from '../Buttons';
import styles from './styles.module.css';
import { LegalDocuments } from '../../utils/legal';

const Cookie = () => {
  const [visible, setVisible] = useState(!localStorage.getItem('cookieAccepted'));

  const handleOk = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieAccepted', 'false');
    setVisible(false);
  };

  return (
    <Drawer
      title="Cookie Notification"
      placement="bottom"
      closable={false}
      visible={visible}
      height="auto"
      mask={false}
      headerStyle={{ borderBottom: 'none' }}
    >
      <div className={styles.cookieContent}>
        <div>
          <p className={styles.cookieText}>
            Welcome to the GFox family! We&apos;re committed to enhancing your experience. Our
            <a href={LegalDocuments.COOKIE_POLICY} className={styles.cookieBtn} target="_blank" rel="noreferrer"> Cookie Policy </a>
            allows us to personalise your journey, while our
            <a href={LegalDocuments.PRIVACY_POLICY} className={styles.cookieBtn} target="_blank" rel="noreferrer"> Privacy Policy </a>
            ensures your data is protected. By continuing with us, you acknowledge our
            <a href={LegalDocuments.TRADING_TERMS} className={styles.cookieBtn} target="_blank" rel="noreferrer"> Trading Terms </a>
            .
            Thank you for your trust in us.
          </p>

        </div>
        <div className={styles.cookieDiv}>
          <Buttons.PrimaryBtn type="ghost" onClick={handleDecline}>
            Decline
          </Buttons.PrimaryBtn>

          <Buttons.PrimaryBtn type="primary" onClick={handleOk}>
            Accept
          </Buttons.PrimaryBtn>
        </div>
      </div>
    </Drawer>
  );
};

export default Cookie;
