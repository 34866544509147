import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ICategory } from '@bridgelabsdesign/gfox-api-client';
import styles from './styles.module.css';
import MobileNavList from './MobileNavList';
import MobileCategoryOptions from './MobileCategoryOptions';
import Buttons from '../Buttons';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';

interface IMobileMenuProps {
  onNavigate(): void;
}

const MobileMenu = observer(({
  onNavigate,
}: IMobileMenuProps) => {
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
  const { isLoggedIn } = useAuthContext();
  const history = useHistory();
  const { categoryStore } = stores;

  const handleProductCategoryClick = useCallback((refNo: string) => {
    const category = categoryStore.categories.value.data.find((x) => x.refNo === refNo);
    if (category === undefined) {
      return;
    }
    setSelectedCategory(category as any);
    setShowSubCategories(true);
  }, []);
  useEffect(() => {
    if (categoryStore.categories.value.data.length === 0) {
      return;
    }
    setSelectedCategory(categoryStore.categories.value.data[0]);
  }, [categoryStore.categories.value.data]);
  const handleSubCategoryClick = useCallback((parentRefNo: string, refNo: string) => {
    history.push(`/products/${parentRefNo}/${refNo}`);
    onNavigate();
  }, []);

  return (
    <div className={styles.mobileMenuCon}>
      {!showSubCategories ? (
        <MobileNavList
          onNavItemClick={() => onNavigate()}
          onProductCategoryClick={handleProductCategoryClick}
        />
      ) : (
        <MobileCategoryOptions
          category={selectedCategory ?? undefined}
          onClose={() => setShowSubCategories(false)}
          onSubCategoryClick={handleSubCategoryClick}
        />
      )}
      {!isLoggedIn && (
        <div className={styles.menuLoginBtnCon}>
          <Buttons.LinkBtn
            link={{ href: '/account/login' }}
          >
            Login
          </Buttons.LinkBtn>
          <Buttons.LinkBtn
            link={{ href: '/account/signup' }}
          >
            Sign up
          </Buttons.LinkBtn>
        </div>
      )}
    </div>
  );
});

export default MobileMenu;
