import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form as AntdForm } from 'antd';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { IAddress } from '@bridgelabsdesign/gfox-api-client';
import Form from '../../components/Form';
import { Details } from './data';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Buttons from '../../components/Buttons';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import AddressModal from '../Register/AddressModal';
import { addressLineString } from '../../utils/strings';
import { validateMessages } from '../../utils/form';
import FilteredAddressModal from './FilteredAddressModal';

const { Text, Title } = Typography;

const CheckoutCustomerDetails = observer(() => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showFilteredAddressModal, setShowFilteredAddressModal] = useState(false);
  const [form] = AntdForm.useForm();
  const [addressForm] = AntdForm.useForm();
  const { currentClient, setCurrentClient } = useAuthContext();
  const { addressStore, checkoutStore } = stores;
  const shouldRenderDeliveryAddress = !currentClient?.accountNumber; // COD
  useEffect(() => {
    form.setFieldsValue({
      accountNumber: currentClient?.accountNumber,
      billingCompany: currentClient?.billingCompany,
      emailAddress: currentClient?.userAuth?.emailAddress,
      billingPhone: currentClient?.billingPhone,
      branchName: currentClient?.branch?.branchName,
    });
  }, [currentClient]);

  useEffect(() => {
    form.setFieldsValue({
      address1: addressStore?.selectedAddress?.address1,
      address2: addressStore?.selectedAddress?.address2,
      address3: addressStore?.selectedAddress?.address3,
      city: addressStore?.selectedAddress?.city,
      province: addressStore?.selectedAddress?.province,
      country: addressStore?.selectedAddress?.country,
      postalCode: addressStore?.selectedAddress?.postalCode,
      hubCode: addressStore?.selectedAddress?.hubCode,
    });
  }, [addressStore?.selectedAddress]);

  useEffect(() => {
    // Set default value for requestedShipDate as tomorrow's date
    const tomorrow = moment().add(1, 'days');
    checkoutStore.setRequestedShipDate(tomorrow.toDate());
    form.setFieldsValue({
      requestedShipDate: moment(tomorrow),
    });
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      customerOrderNo: checkoutStore.customerOrderNo,
      comments: checkoutStore?.orderComments ?? '',
    });
  }, []);

  const handleOkFilteredAddressModal = (selectedAddress: IAddress) => {
    // Update the form fields with the selected address
    form.setFieldsValue({
      address1: selectedAddress.address1,
      address2: selectedAddress.address2,
      address3: selectedAddress.address3,
      city: selectedAddress.city,
      province: selectedAddress.province,
      country: selectedAddress.country,
      postalCode: selectedAddress.postalCode,
      hubCode: selectedAddress.hubCode,
    });

    // Update the selected address in the address store
    addressStore.setSelectedAddress(selectedAddress);

    // Close the modal
    setShowFilteredAddressModal(false);
  };

  const handleCancelFilteredAddressModal = () => {
    setShowFilteredAddressModal(false);
  };

  const handleAddressSelect = (value: string) => {
    const filteredAddress = addressStore.addresses.find((addr) => addr.id === value);
    if (filteredAddress) {
      form.setFieldsValue({
        address1: filteredAddress.address1,
        address2: filteredAddress.address2,
        address3: filteredAddress.address3,
        city: filteredAddress.city,
        province: filteredAddress.province,
        country: 'South Africa',
        postalCode: filteredAddress.postalCode,
        hubCode: filteredAddress.hubCode,
      });
    }
    // Update the selectedAddress in the addressStore
    addressStore.setSelectedAddress(filteredAddress);
  };

  useEffect(() => {
    if (currentClient) {
      addressStore.fetchAddresses(currentClient?.id);
    }
  }, [currentClient]);

  return (
    <main id="customer">
      <Helmet>
        <title>Order Details - G. Fox</title>
      </Helmet>
      <AntdForm
        form={form}
        name="login"
        className="custom-form"
        layout="vertical"
        requiredMark={false}
        validateMessages={validateMessages}
        onFieldsChange={(changedFields) => {
          try {
            const commentsValue = changedFields.find((f) => (f.name as any[]).some((x) => x === 'comments'))?.value;
            checkoutStore.setOrderComments(commentsValue ?? '');

            // For customerOrderNo
            const customerOrderNoValue = changedFields.find((f) => (f.name as any[]).some((x) => x === 'customerOrderNo'))?.value;
            if (customerOrderNoValue !== undefined) {
              checkoutStore.customerOrderNo = customerOrderNoValue;
            }

            // For requestedShipDate
            const requestedShipDateValue = changedFields.find((f) => (f.name as any[]).some((x) => x === 'requestedShipDate'))?.value;
            if (requestedShipDateValue !== undefined) {
              checkoutStore.requestedShipDate = requestedShipDateValue;
            }
          } catch (e) {
            checkoutStore.setOrderComments('');
            // Set default value for requestedShipDate as tomorrow's date
            const tomorrow = moment().add(1, 'days');
            checkoutStore.setRequestedShipDate(tomorrow.toDate());
          }
        }}
      >
        <div className={styles.detailsCon}>
          {React.Children.toArray(
            Details.map((item) => (
              <div key={item.key}>
                <div
                  className={[
                    styles.detailsCon,
                    styles.detailsConHeader,
                  ].join(' ')}
                >
                  <Title
                    level={2}
                    className={styles.detailsText}
                  >
                    {item.Header}
                  </Title>
                  {item.key === 'deliveryAddress' && shouldRenderDeliveryAddress && (
                  <Buttons.LinkBtn
                    link={{ href: '/account/profile/address' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAddressModal(true);
                    }}
                    type="text"
                    icon={<FiPlus className={styles.cartCountIcon} />}
                  />
                  )}
                </div>
                {item.key === 'deliveryAddress' && (
                  <div className={[
                    styles.registerFormCon,
                    styles.registerFormConInput,
                    styles.registerFormConTextArea,
                  ].join(' ')}
                  >
                    <div className={styles.addressContainer}>
                      <Text className={styles.registerLabel}>Selected Delivery Address</Text>
                      <Buttons.LinkBtn
                        link={{ href: '#' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowFilteredAddressModal(true);
                        }}
                        type="text"
                        icon={<FiSearch className={styles.searchIcon} />}
                      />
                    </div>
                    <div className={styles.regsiterFormItem}>
                      <AntdForm.Item
                        name="selectedDeliveryAddress"
                        required
                        rules={[{ required: true }]}
                      >
                        <Form.Select
                          onChange={handleAddressSelect}
                          options={addressStore.addresses.map((address) => ({
                            label: addressLineString(address).trim().toUpperCase(),
                            value: address.id,
                          }))}
                          placeholder="Select a Delivery Address"
                        />
                      </AntdForm.Item>
                    </div>
                  </div>
                )}
                <div className={styles.inputcon}>
                  {item.items.map((detail) => (
                    <div
                      key={detail.label}
                      className={[
                        styles.registerFormCon,
                        detail.formType === 'input' && styles.registerFormConInput,
                        detail.formType === 'datePicker' && styles.registerFormConInput,
                        detail.formType === 'textarea' && styles.registerFormConTextArea,
                      ].join(' ')}
                    >
                      <Text className={styles.registerLabel}>{detail.label}</Text>

                      <div className={styles.regsiterFormItem}>
                        <AntdForm.Item
                          name={detail.name}
                          required={detail.required}
                          rules={[{ required: detail.required }]}
                        >
                          {detail.input}
                        </AntdForm.Item>
                      </div>
                    </div>

                  ))}
                  <br />
                </div>
              </div>
            )),
          )}
        </div>
      </AntdForm>

      <AntdForm
        form={addressForm}
        name="addresForm"
        className="custom-form"
        layout="vertical"
        requiredMark={false}
        onFinish={async (values) => {
          setShowAddressModal(false);
          const addr = await addressStore.addClientAddress(currentClient?.id!, values);
          addressStore.selectedAddress = addr?.address;
          const updatedClient = structuredClone(currentClient);
          updatedClient?.clientAddresses.push(addr!);
          setCurrentClient(updatedClient);
        }}
      >
        <AddressModal
          form={addressForm}
          isOpen={showAddressModal}
          handleOk={() => addressForm.submit()}
          handleCancel={() => setShowAddressModal(false)}
        />
        <FilteredAddressModal
          form={addressForm}
          isOpen={showFilteredAddressModal}
          handleOk={handleOkFilteredAddressModal}
          handleCancel={() => handleCancelFilteredAddressModal()}
          addresses={addressStore.addresses}
        />
      </AntdForm>
    </main>
  );
});

export default CheckoutCustomerDetails;
