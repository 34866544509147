/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Table } from 'antd';
import { IOrder } from '@bridgelabsdesign/gfox-api-client';
import { useHistory } from 'react-router-dom';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './quotes-summary.module.css';
import { isEmptyString } from '../../utils/strings';
import { orderHistorySteps } from '../../utils/order/status';

const { Link, Text } = Typography;

interface IOrdersSummaryTableProps {
  orders: IOrder[]
}

const OrdersSummaryTable = ({
  orders,
}: IOrdersSummaryTableProps) => {
  const history = useHistory();

  const handleOnOrderView = (orderRefNo: string) => {
    if (!isEmptyString(orderRefNo)) {
      history.push(`/account/orders/${orderRefNo}`);
    }
  };

  const tableColumns = [
    {
      title: 'Order ID:',
      dataIndex: 'refNo',
      key: 'refNo',
      render: (text: string, record: IOrder) => {
        const orderStatus = orderHistorySteps.find((x) => x.status === record.statusCode);
        const displayText = !isEmptyString(text) ? text : orderStatus?.readableText2 ?? '';
        return (
          <Text className={styles.colRefNo}>
            {displayText}
          </Text>
        );
      },
    },
    {
      title: 'Status:',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: IOrder) => (
        <Text className={styles.colStatus}>
          {`${!isEmptyString(record.statusCode) ? 'Ready' : 'Processing'}`}
        </Text>
      ),
    },
    {
      title: 'Action:',
      dataIndex: 'id',
      key: 'action',
      render: (text: string, record: IOrder) => (
        <Buttons.SecondaryBtn
          className={styles.colViewBtn}
          type="text"
          disabled={isEmptyString(record.refNo)}
          onClick={() => handleOnOrderView(record.refNo)}
        >
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOnOrderView(record.refNo);
            }}
          >
            View
          </Link>
        </Buttons.SecondaryBtn>
      ),
    },

  ];

  return (
    <>
      <div className={styles.tableCon}>
        {/* TODO: format table header & add spacing between rows */}
        <Table
          className="account-table"
          dataSource={orders}
          columns={tableColumns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
        />
      </div>
    </>
  );
};

export default OrdersSummaryTable;
