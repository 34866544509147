import React, { useEffect, useState } from 'react';
import {
  Form as AntdForm, message, Popconfirm, Radio,
} from 'antd';
import { FiChevronRight } from 'react-icons/fi';
import { useForm } from 'antd/lib/form/Form';

import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import Avatar from '../../components/Avatar/Avatar';
import Buttons from '../../components/Buttons';
import Form from '../../components/Form';
import accountProfileForm from '../../utils/form-helpers/account-profile-form';
import { useAuthContext } from '../../context/AuthContext';
import { validateMessages } from '../../utils/form';
import stores from '../../stores/stores';
import { UpdateClientValuesType } from '../../stores/userAuth';
import { addressLineString } from '../../utils/strings';

const { Text, Title } = Typography;
export interface ICheckoutPaymentDetails {
  cardIcon: string;
  cardName: string;
  firstName: string;
  lastName: string;
  cardNumber: string;
  expiryDate: string;
  cardCvv: string;
}

type AccountProfileFormType = Omit<UpdateClientValuesType, 'shippingAddressId'> & {
  shippingAddress: { value: string, label: string };
  branchName: string;
};

const AccountProfile = observer(() => {
  const [form] = useForm<AccountProfileFormType>();
  const [fieldsTouched, setFieldsTouched] = useState(false);
  const { currentClient, setCurrentClient } = useAuthContext();
  const { userAuthStore, authStore } = stores;
  const [consent, setConsent] = useState(false); // added ??

  const { addressStore } = stores;
  const history = useHistory();
  const handleAddNewAddress = () => {
    history.push('/account/profile/address');
  };

  const onFinish = async (values: AccountProfileFormType) => {
    // eslint-disable-next-line prefer-object-spread
    const profileInfo: UpdateClientValuesType = Object.assign({}, values, {
      shippingAddressId: values.shippingAddress.value,
    });

    const updatedClient = await userAuthStore.updateProfile(profileInfo, consent, currentClient!);
    // updatess addressStore here
    if (updatedClient) {
      // Update the user's profile in the application context
      setCurrentClient(updatedClient);
      // update the form fields
      form.setFieldsValue({
        firstName: updatedClient.userAuth.firstName,
        lastName: updatedClient.userAuth.lastName,
        emailAddress: updatedClient.userAuth.emailAddress,
        billingCompany: updatedClient.billingCompany,
        billingPhone: updatedClient.billingPhone,
        // TODO: Look for places where set the addreesss based on the [0] address and change thhat
        branchName: updatedClient.branch.branchName,
        accountNumber: updatedClient.accountNumber,
        consent: updatedClient.communicationConsent, // TODO: sub or unsub to mailing list
        // ...
      });
    }
  };

  useEffect(() => {
    if (currentClient && currentClient.userAuth) {
      addressStore.setShippingAddress(currentClient.clientAddresses);

      setConsent(!!currentClient?.communicationConsent);
      form.setFieldValue('firstName', currentClient?.userAuth.firstName);
      form.setFieldValue('lastName', currentClient?.userAuth.lastName);
      form.setFieldValue('emailAddress', currentClient?.userAuth.emailAddress);
      form.setFieldValue('billingCompany', currentClient?.billingCompany);
      form.setFieldValue('billingPhone', currentClient?.billingPhone);
      form.setFieldValue('communicationConsent', consent);
      form.setFieldValue('branchName', currentClient?.branch?.branchName);
      form.setFieldValue('accountNumber', currentClient?.accountNumber);
      if (addressStore.shippingAddress) {
        form.setFieldValue('shippingAddress', {
          value: addressStore.shippingAddress.id,
          label: addressLineString(addressStore.shippingAddress!),
        });
      }
      setConsent(!!currentClient.communicationConsent);
    }
  }, [currentClient, form]);

  const RenderAccountNumberLabel = !!currentClient?.accountNumber;

  const handleResetPassword = () => {
    if (currentClient?.userAuth?.emailAddress) {
      // TODO: redirect admin portal to website
      authStore.requestPasswordReset({ emailAddress: currentClient?.userAuth?.emailAddress });
    }
  };

  const handleGetAddresses = async (clientId: string) => {
    await addressStore.fetchAddresses(clientId);
  };

  useEffect(() => {
    setConsent(currentClient?.communicationConsent ?? false);
  }, [currentClient?.communicationConsent]);

  useEffect(() => {
    if (currentClient?.id) {
      handleGetAddresses(currentClient.id);
    }
  }, [currentClient?.id]);

  return (
    <main id="account-profile">
      <Helmet>
        <title>Profile - G. Fox</title>
      </Helmet>
      <AntdForm<AccountProfileFormType>
        form={form}
        name="update"
        requiredMark={false}
        onFinishFailed={(v) => {
          if (v.errorFields.length > 0) {
            message.error(`Could not update profile: ${v.errorFields[0].errors[0]}`);
            return;
          }
          message.error('Could not login');
        }}
        validateMessages={validateMessages}
        onFinish={onFinish}
        onFieldsChange={() => setFieldsTouched(true)}
      >
        <Title
          className={styles.headerText}
          level={2}
        >
          My Profile
        </Title>

        <div className={styles.contentCon}>
          <div className={styles.userInfoCon}>
            <Avatar
              className={styles.userAvatar}
              size="large"
            />
            <div className={styles.userInfo}>
              <Text className={styles.userName}>
                {currentClient?.userAuth?.firstName}
              </Text>
              <Text className={styles.userEmail}>
                {currentClient?.userAuth?.emailAddress}
              </Text>
              <Text className={styles.userPhone}>
                {currentClient?.billingPhone}
              </Text>
              <Text className={styles.userAddress}>
                <>
                  {/* TODO: index of 0 concerning adresses is the default address */}
                  {currentClient?.clientAddresses && currentClient.clientAddresses.length > 0 && (
                    <p>
                      {'Address: '}
                      {addressLineString(addressStore.shippingAddress)}
                    </p>
                  )}
                </>

              </Text>
            </div>
            <div className={styles.addAddressCon}>
              <Buttons.SecondaryBtn
                className={styles.addAddress}
                type="ghost"
                onClick={handleAddNewAddress}
              >
                Add new address
              </Buttons.SecondaryBtn>
            </div>
          </div>
          <div className={styles.getInTouchCon}>
            {React.Children.toArray(
              accountProfileForm.map((item) => (
                <div className={styles.formCon}>
                  <Text className={styles.getInTouchText}>
                    <div className={styles.linkButton}>
                      {item.header}
                      {' '}
                      {item.headerJSX}
                    </div>
                  </Text>

                  {item.section === 'address' ? (
                    <AntdForm.Item
                      name="shippingAddress"
                      required
                      rules={[{ required: true }]}
                      className={styles.getInTouchFormItem}
                    >
                      <Form.Select
                        placeholder="Select a Delivery Address"
                        rounded
                        labelInValue
                        options={addressStore.addresses.map((address) => ({
                          label: addressLineString(address).trim().toUpperCase(),
                          value: address.id,
                        }))}
                      />
                    </AntdForm.Item>
                  ) : (
                    <div className={styles.getInTouchFormItems}>
                      {React.Children.toArray(
                        item.forms.map((formItem) => (
                          <div
                            className={[
                              styles.getInTouchFormCon,
                              formItem.responsive && styles.getInTouchFormConResponsive,
                              formItem.formType === 'input' && styles.getInTouchFormConInput,
                              formItem.formType === 'textarea' && styles.getInTouchFormConTextArea,
                            ].join(' ')}
                          >
                            <div>
                              <Text className={styles.getInTouchLabel}>
                                {formItem.label}
                              </Text>
                            </div>
                            {formItem?.note && RenderAccountNumberLabel && (
                            <Text className={styles.getInTouchNote}>
                              {formItem.note}
                            </Text>
                            )}
                            <AntdForm.Item
                              name={formItem.name}
                              required={formItem.required}
                              rules={[{ required: formItem.required },
                                { whitespace: formItem.whitespace },
                                { pattern: formItem.pattern, message: 'invalid format' },
                                { type: formItem.type, message: 'Please enter a valid email' }]}
                              className={styles.getInTouchFormItem}
                            >

                              {formItem.input}

                            </AntdForm.Item>
                          </div>
                        )),
                      )}
                    </div>
                  )}
                </div>
              )),
            )}
            <Popconfirm
              title="Are you sure to change your password?"
              onConfirm={handleResetPassword}
              okText="Yes"
              cancelText="No"
            >
              <Buttons.PrimaryBtn
                className={styles.resetPassword}
                htmlType="button"
                type="text"
              >
                <FiChevronRight />
                {' '}
                Change your password
              </Buttons.PrimaryBtn>
            </Popconfirm>

            <div className={styles.getInTouchCommsCon}>
              <Radio
                checked={!!consent}
                onClick={() => {
                  setConsent(!consent);
                }}
              >
                <Text className={styles.getInTouchComms}>
                  Receive future communication and promotional special from G.Fox
                </Text>
              </Radio>
            </div>
          </div>
          <Buttons.PrimaryBtn
            htmlType="submit"
            disabled={!fieldsTouched}
          >
            Save changes
          </Buttons.PrimaryBtn>
        </div>
      </AntdForm>
    </main>

  );
});
export default AccountProfile;
