import React from 'react';
import { notification } from 'antd';
import Buttons from '../Buttons';
import styles from './styles.module.css';
import history from '../../utils/history';

const accountDeactivated = (accountNumber?: string) => {
  const key = 'accountDeactivated';
  notification.info({
    key,
    message: `Account is On Hold ${accountNumber ?? ''}`,
    type: 'info',
    description: (
      <div className={styles.unverifiedCon}>
        Unable to place order as account is On Hold. Please contact accounts at your local G.Fox branch accounts department to rectify.
      </div>
    ),
    duration: 0,
  });
};

const unverifiedAccountsAdmin = (accountNumber: string) => {
  const key = 'accountVerificationAdmin';
  notification.info({
    key,
    message: `Unverified Accounts: ${accountNumber}`,
    type: 'info',
    description: (
      <div className={styles.unverifiedCon}>
        One or more Accounts have not yet been verified. Please check your email to verify the accounts for users to transact
        <Buttons.PrimaryBtn
          className={styles.unverifiedBtn}
          type="ghost"
          onClick={() => {
            history.push('/account/members');
            notification.close(key);
          }}
        >
          Verify Account/s
        </Buttons.PrimaryBtn>
      </div>
    ),
    duration: 0,
  });
};

const unverifiedAccountStatus = (accountNumber: string, onRequestVerification: () => void) => {
  const key = 'accountVerificationStatus';
  notification.info({
    key,
    message: `Account ${accountNumber ?? ''} verification`,
    description: (
      <div className={styles.unverifiedCon}>
        Account has not yet been verified. Note: Your account will not be active until your account number has been verified
        <Buttons.PrimaryBtn
          className={styles.unverifiedBtn}
          type="ghost"
          onClick={() => {
            onRequestVerification();
            notification.close(key);
          }}
        >
          Request Account Verification
        </Buttons.PrimaryBtn>
      </div>
    ),
    duration: 0,
  });
};

const notifications = {
  accountDeactivated,
  unverifiedAccountsAdmin,
  unverifiedAccountStatus,
};

export default notifications;
