import APIClient, {
  IDelivery, IInventoryMaster, IQuote, LoadManager, OrderPlatform,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import {
  action, flow, makeObservable, observable, runInAction,
} from 'mobx';
import moment, { Moment } from 'moment';
import QuoteReorder from '../utils/quotes/quote-reorder';

type PickedDateRange = [Moment | null, Moment | null] | null;
class QuoteStore {
  quotes = new LoadManager<IQuote>({ data: [] }, APIClient.Quotes.getQuotes, undefined, APIClient.Quotes.deleteQuoteV2)

  query = '';

  searchId = 0;

  isSearching = false;

  searchResults: IQuote[] = [];

  dateRange: PickedDateRange = [moment().add(-31, 'days'), moment()];

  isLoadingAPI = false;

  constructor() {
    makeObservable(this, {
      declineQuote: action,
      loadQuotes: flow,
      generateQuote: action,
      linkOrder: action,
      reset: action,
      setQuery: action,
      query: observable,
      searchResults: observable, // Add searchResults as an observable property
      isSearching: observable,
      dateRange: observable,
      setDateRange: action,
      isLoadingAPI: observable,

    });
  }

  setDateRange(range: PickedDateRange) {
    if (range && range[0] && range[1]) {
      this.dateRange = range;
      this.searchResults = this.quotes.value.data.filter((quote) => {
        const createdAtMoment = moment(quote.createdAt);
        return createdAtMoment.isBetween(range[0], range[1]);
      });
    } else {
      message.error('Select date range.');
    }
  }

  get isLoading(): boolean {
    return this.quotes.isLoading || this.isLoadingAPI;
  }

  setLoadingAPI(value: boolean) {
    this.isLoadingAPI = value;
  }

  async declineQuote(item: IQuote, reason: string) {
    // eslint-disable-next-line no-param-reassign
    item.reason = reason;
    try {
      await APIClient.Quotes.deleteQuoteV2(item, this.quotes.value.data);
      const itemIndex = this.quotes.value.data.findIndex((x) => x.id === item.id);
      if (itemIndex >= 0) {
        this.quotes.value.data[itemIndex].deleted = true;
      }
    } catch (error) {
      console.log(error, 'error');
      message.error('Could not decline quote.');
    }
  }

  * loadQuotes(clientId: string) {
    const queryParams = `clientId=${clientId}&`;
    yield this.quotes.fetch(queryParams);

    // Update searchResults after fetching the quotes
    runInAction(() => {
      this.searchResults = this.quotes.value.data;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async generateQuote(clientId: string,
    addressId: string,
    invList: IInventoryMaster[],
    delivery?: IDelivery | null,
    customerOrderNo?: string,
    voucherClaimId?:string)
    : Promise<boolean> {
    if (invList.length === 0 || !delivery) {
      message.error('Could not generate quote.');
      return false;
    }

    const hide = message.loading('Generating quote...', 0);
    try {
      const inventoryList = invList.slice();
      const deliveryOrder = delivery.deliveryOrders[0];
      const queryParameters = `platform=${OrderPlatform.WEB}&voucherClaimId=${voucherClaimId ?? ''}`;
      await APIClient.Quotes.generateQuote(clientId, {
        inventoryList, addressId, deliveryOrder, customerOrderNo,
      }, queryParameters);
      hide();
      message.success('Quote will be sent to your email address.');
      return true;
    } catch (error) {
      hide();
      message.error('Could not generate quote.');
      return false;
    }
  }

  setQuery(s: string) {
    this.query = s.trim().toLowerCase(); // convert query to lowercase

    if (this.query.length === 0) {
      this.searchResults = this.quotes.value.data;
      return;
    }

    setTimeout(() => {
      const filteredData = this.quotes.value.data.filter((quote) => {
        const refNo = quote.refNo.toLowerCase(); // convert refNo to lowercase
        return refNo.includes(this.query);
      });

      runInAction(() => {
        this.searchResults = filteredData;
        this.isSearching = false;
      });
    }, 800);
  }

  linkOrder = async (clientId: string, orderInvList: IInventoryMaster[]): Promise<string | null> => {
    const quoteRefno = QuoteReorder.getRefNo();
    if (quoteRefno === null) {
      return null;
    }

    let { data } = this.quotes.value;
    if (data.length === 0) {
      message.loading('Linking quote...'); // TODO: hide msg & remove auto hide
      await this.loadQuotes(clientId);
      data = this.quotes.value.data;
    }

    const quote = data.find((x) => x.refNo === quoteRefno);
    const quoteInv = quote?.quoteInventories;
    if (!quote || !quoteInv || orderInvList.length !== quoteInv.length) {
      return null;
    }

    let linkToOrder = true;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < orderInvList.length; i++) {
      const orderItem = orderInvList[i];
      const itemFound = quoteInv.some((x) => x.inventoryMaster.sku === orderItem.sku && x.orderQty === orderItem.orderQty);
      if (!itemFound) {
        linkToOrder = false;
        break;
      }
    }

    return linkToOrder ? quote.id : null;
  }

  reset() {
    this.quotes.value.data = [];
  }
}

export default QuoteStore;
