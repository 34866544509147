import {
  action, computed, makeObservable, observable,
} from 'mobx';
import APIClient, { IContentSlider, LoadManager } from '@bridgelabsdesign/gfox-api-client';

export class ContentSliderStore {
  sliders = new LoadManager<IContentSlider>({ data: [] }, APIClient.ContentSlider.getContentSliders);

  isLoading = false; // Track loading state

  constructor() {
    makeObservable(this, {
      sliders: observable,
      isLoading: observable,
      loadContentSliders: action,
      contentSliderList: computed,
    });
  }

  get contentSliderList(): IContentSlider[] {
    return this.sliders.value.data.slice();
  }

  loadContentSliders = async () => {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      await this.sliders.fetch();
    } catch (error) {
      console.error('Error loading content sliders:', error);
    } finally {
      this.isLoading = false;
    }
  };
}

export default ContentSliderStore;
