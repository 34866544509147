import React, { useEffect, useState } from 'react';
import {
  Form as AntdForm,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { IPostLoyaltyCardRequest } from '@bridgelabsdesign/gfox-api-client';
import Typography from '../Typography';
import styles from './allocate-loyalty-card.module.css';
import Avatar from '../Avatar/Avatar';
import Buttons from '../Buttons';
import allocateLoyaltyCardForm from '../../utils/form-helpers/allocate-loyalty-card-form';
import { useAuthContext } from '../../context/AuthContext';
import { validateMessages } from '../../utils/form';
import stores from '../../stores/stores';

const { Text } = Typography;

const AllocateLoyaltyCard = observer(() => {
  const [form] = useForm<IPostLoyaltyCardRequest>();
  const [fieldsTouched, setFieldsTouched] = useState(false);
  const { currentClient, setCurrentClient } = useAuthContext();
  const { clientStore } = stores;
  const onFinish = async (values: IPostLoyaltyCardRequest) => {
    const loyaltyCardRequest: IPostLoyaltyCardRequest = {
      dateOfBirth: values.dateOfBirth,
      industry: values.industry,
    };
    if (currentClient) {
      const cardNo = await clientStore.allocateLoyaltyCardForClient(currentClient.id, loyaltyCardRequest);
      if (cardNo) {
        setCurrentClient!({ ...currentClient, loyaltyCardNo: cardNo });
      }
    }
  };

  useEffect(() => {
    if (currentClient && currentClient.userAuth) {
      form.setFieldValue('firstName', currentClient?.userAuth.firstName);
      form.setFieldValue('lastName', currentClient?.userAuth.lastName);
      form.setFieldValue('emailAddress', currentClient?.userAuth.emailAddress);
      form.setFieldValue('billingCompany', currentClient?.billingCompany);
      form.setFieldValue('billingPhone', currentClient?.billingPhone);
    }
  }, [currentClient, form]);

  return (
    <main id="allocate-loyalty-card">
      <Helmet>
        <title>Allocate - Card - G. Fox</title>
      </Helmet>
      <AntdForm<IPostLoyaltyCardRequest>
        form={form}
        name="update"
        requiredMark={false}
        validateMessages={validateMessages}
        onFinish={onFinish}
        onFieldsChange={() => setFieldsTouched(true)}
      >
        <div className={styles.contentCon}>
          <div className={styles.userInfoCon}>
            <Avatar
              className={styles.userAvatar}
              size="large"
            />
          </div>
          <div className={styles.getInTouchCon}>
            {React.Children.toArray(
              allocateLoyaltyCardForm.map((item) => (
                <div className={styles.formCon}>
                  <Text className={styles.getInTouchText}>
                    <div className={styles.linkButton}>
                      {item.header}
                      {' '}
                      {item.headerJSX}
                    </div>
                  </Text>
                  <div className={styles.getInTouchFormItems}>
                    {React.Children.toArray(
                      item.forms.map((formItem) => (
                        <div
                          className={[
                            styles.getInTouchFormCon,
                            formItem.responsive && styles.getInTouchFormConResponsive,
                            formItem.formType === 'input' && styles.getInTouchFormConInput,
                            formItem.formType === 'textarea' && styles.getInTouchFormConTextArea,
                          ].join(' ')}
                        >
                          <div>
                            <Text className={styles.getInTouchLabel}>
                              {formItem.label}
                            </Text>
                          </div>
                          <AntdForm.Item
                            name={formItem.name}
                            required={formItem.required}
                            rules={[{ required: formItem.required }]}
                            className={styles.getInTouchFormItem}
                          >

                            {formItem.input}

                          </AntdForm.Item>
                        </div>
                      )),
                    )}
                  </div>
                </div>

              )),
            )}
          </div>
          <Buttons.PrimaryBtn
            htmlType="submit"
            disabled={!fieldsTouched}
          >
            Save changes
          </Buttons.PrimaryBtn>
        </div>
      </AntdForm>
    </main>

  );
});
export default AllocateLoyaltyCard;
