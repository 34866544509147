import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ICart } from '@bridgelabsdesign/gfox-api-client';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './styles.module.css';
import Tables from '../../components/Tables';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';

const AccountOrdersInfo = observer(() => {
  const [items, setItems] = useState<ICart[]>([]);

  const { currentClient } = useAuthContext();
  const { orderStore } = stores;
  const { refNo } = useParams<{ refNo?: string }>();

  const history = useHistory();

  const handleGoBack = () => {
    history.push('../orders');
  };
  const isItemDeletedFromInventory = (item: ICart) => {
    const isInOrders = orderStore.previousOrders?.orders.some((order) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      order.orderInfo.some((orderItem) => orderItem.sku === item.inventoryMaster?.sku));
    return isInOrders && !item.inventoryMaster.description && !item.inventoryMaster.productImages;
  };

  const handleSetItems = () => {
    const order = orderStore.previousOrders?.orders.find((x) => x.refNo === refNo);
    if (!order || !refNo) {
      handleGoBack();
      return;
    }
    const temp = order.orderInfo.map<ICart>((x) => {
      const invItem = orderStore.previousOrders?.inventoryMasters.find((inv) => inv.sku === x.sku);
      return {
        clientId: currentClient?.id,
        inventoryMaster: invItem || { sku: x.sku },
        quantity: x.orderQty,
      } as ICart;
    });
    setItems(temp);
  };

  useEffect(() => {

  }, [refNo]);

  useEffect(() => {
    if (currentClient?.id) {
      orderStore.loadPreviousOrders(currentClient?.id);
    }
  }, [currentClient?.id]);

  useEffect(() => {
    handleSetItems();
  }, [orderStore.previousOrders]);

  return (
    <main id="checkout-cart" className={styles.cartCon}>
      <Helmet>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <title>{refNo ? `Order #${refNo}` : 'Order'} - G.Fox</title>
      </Helmet>
      <Tables.CheckoutItemsTable
        edit={false}
        items={items}
        loading={!!orderStore.isLoadingHistory}
        isItemDeletedFromInventory={(item) => !!isItemDeletedFromInventory(item)}
      />
    </main>
  );
});

export default AccountOrdersInfo;
