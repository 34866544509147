import React from 'react';
import { Input, InputProps } from 'antd';
import Icon from '@ant-design/icons';
import styles from './styles.module.css';
import Images from '../../assets';

interface SearchInputProps extends InputProps {
  className?: string;
  placeholder?: string
}

const SearchInput = ({
  className,
  placeholder,
  ...props
}: SearchInputProps) => (
  <Input
    allowClear
    placeholder={placeholder}
    // TODO: search bar color based on navbar transparent
    className={[styles.searchInput, className].join(' ')}
    suffix={(
      <Icon
        className="navbar-icon"
        component={Images.SearchIcon}
      />
    )}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

SearchInput.defaultProps = {
  className: undefined,
  placeholder: 'Search',
};

export default SearchInput;
