import React, { useMemo } from 'react';
import { Table } from 'antd';
import Typography from '../Typography';
import styles from './quotes-summary.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';

const { Text } = Typography;

const tableColumns = [
  {
    title: 'Date:',
    dataIndex: 'dates',
    showMobile: false,
    key: 'dates',
    render: (text: string) => (
      <Text className={styles.colDateNo}>
        {text}
      </Text>
    ),
  },
  {
    title: 'Description:',
    dataIndex: 'description',
    showMobile: true,
    key: 'description',
    render: (text: string) => (
      <Text className={styles.colDescriptionNo}>
        {text}
      </Text>
    ),
  },

  {
    title: 'Amount:',
    dataIndex: 'amount',
    showMobile: true,
    key: 'amount',
    render: (text: string) => (
      <Text className={styles.colStatus}>
        {text}
      </Text>
    ),
  },

];

interface ICreditTableProps {
  creditNotes: [],
}

const CreditsTable = ({
  creditNotes,
}: ICreditTableProps) => {
  const { isMobileWindow } = useWindowSize();

  // eslint-disable-next-line arrow-body-style
  const columns = useMemo(() => {
    return !isMobileWindow ? tableColumns : tableColumns.filter((x) => x.showMobile);
  }, [isMobileWindow, tableColumns]);

  return (
    <>
      <div className={styles.tableCon}>
        <Table
          className="account-table"
          dataSource={creditNotes}
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
        />
      </div>
    </>
  );
};

export default CreditsTable;
