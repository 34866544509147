import React, { } from 'react';
import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { StockHoldOptions } from '@bridgelabsdesign/gfox-api-client/dist/utils/enums';
import Typography from '../Typography';
import Images from '../../assets';
import Buttons from '../Buttons';
import styles from './product-card.module.css';
import { IProductPricing } from '../../utils/pricing';
import PricingInformation from '../Pricing/PricingInformation';

export interface IProductCardProps {
  className?: string;
  pricing?: IProductPricing,
  color?: string;
  imageSrc: string;
  imgClassName?: string;
  productName: string;
  productNameClassName?: string;
  optType?: 'colors' | 'viewBtn';
  removeBg?: boolean;
  separator?: boolean;
  shopLink?: string;
  viewLink?: string;
  // eslint-disable-next-line react/require-default-props
  isNew?:boolean,
  // eslint-disable-next-line no-unused-vars
  onViewLinkClick?(e: React.MouseEvent<Element, MouseEvent>): void;
   showArrows?: boolean;
  onPrevClick?(): void;
  onNextClick?(): void;
}

const { Link, Text } = Typography;

const defaultColor = 'standard';

const ColorIndicator = ({ color }: {color: string}) => (
  <div className={styles.colorIndicatorCon}>
    <div
      className={styles.colorIndicatorColor}
      style={{ backgroundColor: color !== defaultColor ? color : '#656565' }}
    />
  </div>
);

const ProductCard = ({
  className,
  pricing,
  color,
  imageSrc,
  imgClassName,
  productName,
  productNameClassName,
  optType,
  removeBg,
  separator,
  shopLink, // TODO: shop button add to cart
  viewLink,
  isNew,
  onViewLinkClick,
  showArrows = false,
  onPrevClick,
  onNextClick,
}: IProductCardProps) => (
  <div
    className={[styles.container, className].join(' ')}
  >
    <div className={styles.labelHold}>
      <div className={styles.stockLabelCon}>
        {pricing?.masterStock?.onHold === StockHoldOptions.PartialHold && <div className={styles.onHoldLabel}>Limited Stock</div>}
      </div>
      <div className={styles.stockLabelCon}>
        {isNew && <div className={styles.onHoldLabel}>New</div>}
      </div>
    </div>
    <div className={styles.imageWrapper}>
      <Link
        className={[
          styles.imgContainer,
          removeBg && styles.imgContainerNoBG,
          imgClassName,
        ].join(' ')}
        href={shopLink ?? ''}
      >
        <img
          src={imageSrc ?? Images.FallbackImage}
          alt={productName}
        />
      </Link>
      {showArrows && (
        <div className={styles.imageNavigation}>
          <LeftOutlined
            className={styles.prevArrow}
            onClick={(e) => {
              e.stopPropagation();
              if (onPrevClick) onPrevClick();
            }}
          />
          <RightOutlined
            className={styles.nextArrow}
            onClick={(e) => {
              e.stopPropagation();
              if (onNextClick) onNextClick();
            }}
          />
        </div>
      )}
    </div>
    {separator && (
      <div className={styles.separatorCon} />
    )}

    <div
      className={styles.infoContainer}
    >
      <div className={styles.infoProdNameCon}>
        <Text
          className={[
            styles.infoProdName,
            productNameClassName,
          ].join(' ')}
        >
          {productName}
        </Text>
      </div>

      {optType === 'colors' && (
        <div className={styles.infoOptionsCon}>
          <div className={styles.infoOptionsColorCon}>
            <ColorIndicator color={color!} />
            <Text className={styles.infoOptionsColorName}>
              {`${color !== defaultColor ? color : defaultColor}`}
            </Text>
          </div>

          <Buttons.LinkBtn
            className={styles.infoOptionShopBtn}
            link={{ href: shopLink ?? '' }}
            icon={(
              <Icon
                style={{ fontSize: '10em' }}
                className={styles.infoOptionShopBtnIcon}
                component={Images.ShoppingCartIcon}
              />
            )}
          />
        </div>
      )}

      <PricingInformation
        className={styles.pricingCon}
        textClassName={styles.pricingText}
        pricing={pricing}
      />

      {optType === 'viewBtn' && (
        <div
          className={[
            styles.infoOptionsCon,
            styles.infoOptionsConViewBtn,
          ].join(' ')}
        >
          <Buttons.LinkBtn
            containerClassName={styles.infoOptionViewBtnCon}
            className={styles.infoOptionViewBtn}
            link={{ href: viewLink ?? '' }}
            type="default"
            onClick={onViewLinkClick}
          >
            View
          </Buttons.LinkBtn>
          {shopLink !== undefined && (
            <Buttons.LinkBtn
              className={styles.infoOptionShopBtn}
              link={{ href: shopLink ?? '' }}
              icon={(
                <Icon
                  style={{ fontSize: '10em' }}
                  className={styles.infoOptionShopBtnIcon}
                  component={Images.ShoppingCartIcon}
                />
              )}
            />
          )}
        </div>
      )}
    </div>
  </div>
);

ProductCard.defaultProps = {
  className: undefined,
  pricing: undefined,
  color: defaultColor,
  imgClassName: undefined,
  productNameClassName: undefined,
  optType: 'colors',
  removeBg: undefined,
  separator: false,
  shopLink: undefined,
  viewLink: undefined,
  onViewLinkClick: undefined,
  showArrows: false,
  onPrevClick: undefined,
  onNextClick: undefined,
};

export default ProductCard;
