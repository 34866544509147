import APIClient, { IBranch, LoadManager } from '@bridgelabsdesign/gfox-api-client';
import { action, makeObservable, observable } from 'mobx';

export class BranchStore {
    branches = new LoadManager<IBranch>({ data: [] }, APIClient.Branch.getBranchNames)

    constructor() {
      makeObservable(this, {
        branches: observable,
        loadBranchNames: action,
      });
    }

    get branchList(): IBranch[] {
      return this.branches.value.data.slice()
        .sort((a, b) => a.branchName.localeCompare(b.branchName))
        .sort((a, b) => +b.active - +a.active);
    }

    async loadBranchNames() {
      if (this.branchList.length === 0) {
        await this.branches.fetch();
      }
    }
}
export default BranchStore;
