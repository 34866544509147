import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { IoClose } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';
import mixpanel from 'mixpanel-browser';
import Spin from '../Spin/Spin';
import styles from './search-results.module.css';
import stores from '../../stores/stores';
import Form from '../Form';
import Buttons from '../Buttons';
import Typography from '../Typography';
import PricingInformation from '../Pricing/PricingInformation';
import { imgURLPrefix } from '../../config/image';

const { Text } = Typography;

const SearchResults = observer(() => {
  const { searchStore, cartStore } = stores;
  const history = useHistory();

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchStore.setSearchQuery(e.target.value);
  };

  const handleSearchItemClick = (sku: string, inventoryMasterSku?: string | null) => {
    // eslint-disable-next-line prefer-destructuring
    const searchQuery = searchStore.searchQuery;
    cartStore.clearSelectedProductItems();
    const params = inventoryMasterSku ? `stockCode=${inventoryMasterSku}` : '';

    const baseUrl = `/products/category/sub-category/${sku}`;
    const queryStringBackButton = params ? `${params}&search=${searchQuery}` : `search=${searchQuery}`;
    history.push(`?${queryStringBackButton}`);
    history.push(`${baseUrl}?${params}`);
    searchStore.toggleShowSearchPanel(false);

    try {
      mixpanel.track('Product Search Click', {
        sku: inventoryMasterSku,
        parentSku: sku,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowPanel = (value?: boolean) => {
    searchStore.toggleShowSearchPanel(value);
  };

  const handleCategoryClick = (categoryId: string) => {
    // eslint-disable-next-line prefer-destructuring
    const searchQuery = searchStore.searchQuery;

    const baseUrl = `/products/category/${categoryId}`;
    const queryString = `search=${searchQuery}`;
    history.push(`?${queryString}`);
    history.push(`${baseUrl}`);

    searchStore.toggleShowSearchPanel(false);
  };

  useEffect(() => {
    const unListen = history.listen(() => {
      handleShowPanel(false);
    });
    return () => {
      unListen();
    };
  }, []);

  return (
    <div className={[styles.con].join(' ')}>
      <div className={styles.contentCon}>
        <Buttons.PrimaryBtn
          className={styles.searchCloseBtn}
          icon={<IoClose className={styles.searchCloseIcon} />}
          onClick={() => handleShowPanel()}
          type="text"
        />

        <div className={styles.searchInputCon}>
          <Form.SearchInput
            placeholder="Search products"
            onChange={handleSearchInput}
            className={styles.searchInput}
            size="large"
            value={searchStore.searchQuery}
          />
        </div>

        {searchStore.showErrorPanel && (
          <div className={styles.searchSpinCon}>
            <Alert
              afterClose={() => handleShowPanel(false)}
              closable
              message="No results found."
              showIcon
              type="error"
            />
          </div>
        )}

        {searchStore.isLoading && (
          <div className={styles.searchSpinCon}>
            <Spin tip="Searching..." />
          </div>
        )}

        {searchStore.showResultsPanel && (
          <div className={styles.searchItemsCon}>
            <div className={styles.viewAllButtonContainer}>
              <Buttons.LinkBtn
                type="text"
                link={{
                  href: `/products?search=${searchStore.searchQuery}`,
                }}
              >
                View all
              </Buttons.LinkBtn>
            </div>

            {/* SKU Masters */}
            {searchStore.searchResult.skuMaster.map((item) => (
              <React.Fragment key={item.sku}>
                {item.inventoryMasters.length > 0 ? (
                // Display InventoryMasters
                  item.inventoryMasters.map((invMaster) => (
                    <div
                      key={invMaster.sku}
                      className={styles.searchItem}
                      onClick={() => handleSearchItemClick(item.sku, invMaster.sku)}
                      aria-hidden
                    >
                      <div className={styles.searchItemImg}>
                        <img
                          src={`${imgURLPrefix}${item.subCategoryLongImage}`}
                          alt="Preview"
                        />
                      </div>
                      <div className={styles.searchItemInfo}>
                        <Text className={styles.searchItemName}>
                          {invMaster.description || item.productName}
                        </Text>
                        <PricingInformation
                          textClassName={styles.searchItemPriceText}
                          text2ClassName={styles.searchItemPriceText2}
                          pricing={{
                            masterStock: invMaster.masterStock,
                            accountPricing: item.accountPricing,
                          }}
                          showExclVAT={false}
                        />
                      </div>
                      {!invMaster.active && (
                      <Text className={styles.searchItemInactive}>
                        Coming soon
                      </Text>
                      )}
                    </div>
                  ))
                ) : (
                // Display SkuMaster
                  <div
                    className={styles.searchItem}
                    onClick={() => handleSearchItemClick(item.sku)}
                    aria-hidden
                  >
                    <div className={styles.searchItemImg}>
                      <img
                        src={`${imgURLPrefix}${item.subCategoryLongImage}`}
                        alt="Preview"
                      />
                    </div>

                    <div className={styles.searchItemInfo}>
                      <Text className={styles.searchItemName}>
                        {item.productName}
                      </Text>
                      <PricingInformation
                        textClassName={styles.searchItemPriceText}
                        text2ClassName={styles.searchItemPriceText2}
                        pricing={{
                          masterStock: item.masterStock,
                          accountPricing: item.accountPricing,
                        }}
                        showExclVAT={false}
                      />
                    </div>

                    {!item.active && (
                    <Text className={styles.searchItemInactive}>
                      Coming soon
                    </Text>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
            {/* Categories */}
            {searchStore.searchResult.category.map((category) => (
              <div
                key={category.id}
                className={styles.searchItem}
                onClick={() => handleCategoryClick(category.refNo)}
                aria-hidden
              >
                {/* <div className={styles.searchItemImg}>
                  <img
                    src={`${imgURLPrefix}${category.imageUrl}`}
                    alt={category.name}
                  />
                </div> */}

                <div className={styles.searchItemInfo}>
                  <Text className={styles.searchItemName}>
                    {category.name}
                  </Text>
                  <Text className={styles.searchItemCategory}>
                    Category
                  </Text>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default SearchResults;
