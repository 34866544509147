import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';

const AccountOrdersChat = observer(() => {
  const { refNo } = useParams<{ refNo?: string }>();
  const { chatStore, orderStore } = stores;
  const history = useHistory();
  const { currentClient } = useAuthContext();
  const messages = chatStore.orderMessages.value.data;
  const formatSentAt = (sentAt: string | Date | null) => moment.utc(sentAt).local().format('DD-MM-YYYY HH:mm');
  const currentOrder = orderStore.previousOrders?.orders?.find((x) => x.refNo === refNo);
  const sendMessage = async () => {
    if (!refNo) {
      history.push('/account/orders');
      return;
    }
    if (currentClient?.id && currentOrder?.id) { await chatStore.postMessage(currentClient?.id, currentOrder?.id); }
    if (!currentOrder) {
      history.push('/account/orders');
    }
  };
  useEffect(() => {
    if (currentOrder?.id) {
      chatStore.fetchOrderMessages(currentOrder?.id);
    }
  }, [chatStore, currentClient?.id]);

  useEffect(() => {
    if (currentClient?.id) {
      sendMessage();
    }
  }, [currentClient?.id]);
  return (
    <main id="OrdersChat">
      <Helmet>
        <title>Orders Chat G-Fox</title>
      </Helmet>
      <div className={styles.searchDatePickerContainer}>

        <div className={styles.orderMessagesList}>
          {messages.map((msg) => (
            <>
              {msg.fromClientId ? (
                <div className={styles.messageBox}>
                  <div className={styles.columnFlex}>
                    <div className={styles.messageBoxFont} />
                    {msg.message}
                  </div>
                  <div className={styles.messageBoxTime}>
                    {formatSentAt(msg.sentAt)}
                  </div>
                </div>
              ) : (
                <div className={styles.messageBoxTime}>
                  <div className={styles.flexOnly}>
                    <div className={styles.messageBoxSender}>
                      <div className={styles.columnFlex}>
                        {msg.message}
                      </div>
                      <div className={styles.messageBoxTime}>
                        {formatSentAt(msg.sentAt)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <div>
          <div className={styles.messageBottomMargin}>
            <div className={styles.messageBottomStyle}>
              <Input
                placeholder="Write a message... "
                bordered={false}
                onChange={(_) => {
                  chatStore.setMessage(_.target.value);
                }}
              />
              <SendOutlined
                style={{ fontSize: '24px' }}
                className={styles.iconStyleRight}
                onClick={() => sendMessage()}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});
export default AccountOrdersChat;
