import APIClient, { ISurveyResponse } from '@bridgelabsdesign/gfox-api-client';
import { action, makeObservable, observable } from 'mobx';
import { message } from 'antd';

class SurveyResponseStore {
  surveyResponses: ISurveyResponse[] | undefined;

  constructor() {
    makeObservable(this, {
      surveyResponses: observable,
      postSurveyResponse: action,
      fetchSurveyResponses: action,
      updateSurveyResponse: action,
      deleteSurveyResponse: action,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async postSurveyResponse(surveyResponseData: FormData, ResponseModel: ISurveyResponse[]): Promise<boolean> {
    const hide = message.loading('Submitting your survey response...');
    let isSuccess = false;
    try {
      await APIClient.SurveyResponse.createSurveyResponse(surveyResponseData, ResponseModel);
      isSuccess = true;
      message.success('Survey response submitted successfully!');
    } catch (error) {
      console.error('Error posting survey response:', error);
      message.error('Could not submit your survey response. Please try again.');
    }
    hide();
    return isSuccess;
  }

  async fetchSurveyResponses() {
    const hide = message.loading('Loading survey responses...');
    try {
      const response = await APIClient.SurveyResponse.getSurveyResponses();

      this.surveyResponses = response.data;

      message.success('Survey responses loaded successfully!');
    } catch (error) {
      console.error('Error loading survey responses:', error);
    }
    hide();
  }

  // eslint-disable-next-line class-methods-use-this
  async updateSurveyResponse(id: string, surveyResponseModel: ISurveyResponse): Promise<boolean> {
    const hide = message.loading('Updating survey response...');
    let isSuccess = false;
    try {
      await APIClient.SurveyResponse.updateSurveyResponse(id, surveyResponseModel);
      isSuccess = true;
      message.success('Survey response updated successfully!');
    } catch (error) {
      console.error('Error updating survey response:', error);
      message.error('Could not update survey response. Please try again.');
    }
    hide();
    return isSuccess;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteSurveyResponse(id: string): Promise<boolean> {
    const hide = message.loading('Deleting survey response...');
    let isSuccess = false;
    try {
      await APIClient.SurveyResponse.deleteSurveyResponse(id);
      isSuccess = true;
      message.success('Survey response deleted successfully!');
    } catch (error) {
      console.error('Error deleting survey response:', error);
      message.error('Could not delete survey response. Please try again.');
    }
    hide();
    return isSuccess;
  }
}

export default SurveyResponseStore;
