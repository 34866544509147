import React, { useEffect } from 'react';
import Icon, { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { IContentSlider } from '@bridgelabsdesign/gfox-api-client';
import CTA from '../../components/CTA';
import Images from '../../assets';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Buttons from '../../components/Buttons';
import { clientIconList, gfoxValues, productOffers } from './data';
import stores from '../../stores/stores';

const {
  Link, Paragraph, Text, Title,
} = Typography;

const About = observer(() => {
  const androidLink = 'https://play.google.com/store/apps/details?id=za.co.gfox.app';
  const iOSLink = 'https://apps.apple.com/us/app/g-fox/id1633431247';
  const currentPageRefNo = 'about_page';

  const handleAndroidClick = () => {
    window.open(androidLink, '_blank');
  };

  const handleiOSClick = () => {
    window.open(iOSLink, '_blank');
  };
  const { categoryStore, contentSliderStore } = stores;
  const categories = categoryStore.categories.value.data;

  const contentSliders = contentSliderStore.contentSliderList as IContentSlider[];

  const aboutPageContentSliders = contentSliders.filter((item) => item.refNo === currentPageRefNo)[0];

  useEffect(() => {
    contentSliderStore.loadContentSliders(); // Load sliders when component mounts
  }, [contentSliderStore]);

  const generateLink = (refNo?: string): string => {
    const c = categories.find((x) => x.refNo === refNo)?.inverseParentCategory[0]?.refNo;
    return c ? `/products/${refNo}` : '/specials';
  };

  return (
    <main id="home">
      <Helmet>
        <title>About - G. Fox</title>
      </Helmet>

      <CTA.TransparentBgImage
        backgroundImage={{
          mobileScreen: aboutPageContentSliders?.imageMobileUrl,
          tabletScreen: aboutPageContentSliders?.imageTabletUrl,
          laptopScreen: aboutPageContentSliders?.imageLaptopUrl,
          largeScreen: Images.MainAboutSlider1,
        }}
        headerText={aboutPageContentSliders?.headerText}
        subHeaderText={aboutPageContentSliders?.subHeaderText}
        overlay
        subText={(
          <>
            <Text className={styles.ctaSubText}>
              {/* eslint-disable-next-line max-len */}
              {aboutPageContentSliders?.subText}
            </Text>

            <div className={styles.playBtnCon}>
              <div className={styles.rectangle}>
                <p>Download our G.Fox app</p>
              </div>
              <div className={styles.iconContainer}>
                <Buttons.PrimaryBtn
                  icon={(
                    <AppleOutlined />

                  )}
                  onClick={handleiOSClick}
                />
                <Buttons.PrimaryBtn
                  icon={(
                    <AndroidOutlined />
                  )}
                  onClick={handleAndroidClick}
                />
              </div>
            </div>
          </>
        )}
      />

      <div className={styles.aboutUsCon}>
        <div
          className={styles.aboutUsImg}
          style={{ backgroundImage: `url(${Images.WorkerPullingTruckCounting})` }}
        />
        <div className={styles.aboutUsContent}>
          <Title
            className={styles.aboutUsHeader}
            level={2}
          >
            ABOUT US
          </Title>

          <Paragraph className={styles.aboutUsText}>
            {/* eslint-disable-next-line max-len */}
            As a proud member of the Bidvest Group, G. Fox has become a trusted supplier to over 12 000 South African companies. Since our establishment 60 years ago, G. Fox has built a reputation as the first point of call for any business, or individual looking for a reliable and quality supplier for their industrial consumable requirements.
          </Paragraph>
          <Paragraph className={styles.aboutUsText}>
            {/* eslint-disable-next-line max-len */}
            All the G. Fox branches, carry a significant amount of stock, ensuring that we are able to fulfill any requirement, no matter how large, on time and in totality. G. Fox has always sourced premium quality and value-for-money products, from South Africa and globally, and it is this quality that customers have come to recognize and rely on.
          </Paragraph>
          <Paragraph className={styles.aboutUsText}>
            {/* eslint-disable-next-line max-len */}
            All workwear sold by G. Fox is manufactured in our state-of-the-art facility in Swaziland. This factory is company owned and dedicated to manufacturing to G. Fox specifications and standards. All garments are manufactured from the finest quality fabrics, sourced directly by our factory, and are finished to the highest standards in the industry.
          </Paragraph>
        </div>
      </div>

      <div className={styles.productOfferCon}>
        <Title
          className={styles.productOfferHeader}
          level={2}
        >
          AS A ONE STOP SHOP
        </Title>
        <Paragraph className={styles.productOfferText}>
          {/* eslint-disable-next-line max-len */}
          G. Fox is able to supply all of your requirements, in one delivery, with one order from one source. Our product range is vast and includes the following:
        </Paragraph>

        <div className={styles.productOfferItemCon}>
          {React.Children.toArray(
            productOffers.map((item) => (
              <Link
                href={`${generateLink(item?.refNo)}`}
                className={styles.productOfferItem}
              >
                <Icon
                  className={styles.productOfferItemIcon}
                  component={item.image}
                />
                <Text className={styles.productOfferItemName}>
                  {item.name.toLocaleUpperCase()}
                </Text>
              </Link>
            )),
          )}
        </div>

      </div>

      <div className={styles.deliveriesCon}>
        <div className={styles.deliveriesContent}>
          <Title
            className={styles.deliveriesHeader}
            level={2}
          >
            Deliveries
          </Title>

          <Paragraph className={styles.deliveriesText}>
            {/* eslint-disable-next-line max-len */}
            The G. Fox fleet of over 40 vehicles country wide, ensures that our deliveries are prompt and efficient, and that you as the customer can rest easy, with the knowledge that your order will be delivered as requested.
          </Paragraph>
          <Paragraph className={styles.deliveriesText}>
            {/* eslint-disable-next-line max-len */}
            G. Fox continues to expand our product offering as well as service delivery and branch network, in a continual process of improvement and development as South Africa’s premier supplier of industrial consumable products.
          </Paragraph>
        </div>
        <div
          className={styles.deliveriesImg}
          style={{ backgroundImage: `url(${Images.RedDeliveryVanRowWhite})` }}
        />
      </div>

      <div className={styles.valuesCon}>
        <Title
          className={styles.valuesHeader}
          level={2}
        >
          OUR VALUES
        </Title>

        <div className={styles.valuesItemCon}>
          {React.Children.toArray(
            gfoxValues.map((item) => (
              <div className={styles.valuesItem}>
                <Text className={styles.valuesItemTitle}>
                  {item.title}
                </Text>
                <div className={styles.valuesItemTitleLine} />
                <Paragraph className={styles.valuesItemText}>
                  {item.text}
                </Paragraph>
              </div>
            )),
          )}
        </div>

        <div className={styles.valuesMissionCon}>
          <div
            className={styles.valuesMissionImg}
            style={{ backgroundImage: `url(${Images.TwoMenWorkingOnSteel})` }}
          />

          <div className={styles.valuesMissionContent}>
            <Title
              className={styles.valuesMissionHeader}
              level={2}
            >
              OUR MISSION
            </Title>
            <Paragraph className={styles.valuesMissionText}>
              {/* eslint-disable-next-line max-len */}
              G. Fox staff are united by our mission to be South Africa’s premier supplier of personal protective equipment (PPE), cleaning chemicals, paper products, and industrial consumable supplies.
            </Paragraph>
            {/* <Paragraph className={styles.valuesMissionText}>
              We want South Africa to:
            </Paragraph> */}
            <div className={styles.valuesMissionThinkTextCon}>
              <Text className={styles.valuesMissionThinkText}>
                WE KEEP AREAS CLEAN & PEOPLE SAFE
              </Text>
              {/* <Text className={styles.valuesMissionThinkText}>
                THINK SAFE
              </Text>
              <Text className={styles.valuesMissionThinkText}>
                THINK FOX
              </Text> */}
            </div>
            <Paragraph className={styles.valuesMissionText}>
              Its this mission that drives and focusses everything we do from sourcing the best raw materials to delivering that emergency order.
            </Paragraph>
          </div>
        </div>
      </div>

      <div className={styles.clientsCon}>
        <Title
          className={styles.clientsHeader}
          level={2}
        >
          OUR CLIENTS
        </Title>

        <Text className={styles.clientsText}>
          G. Fox is proud to be the supplier of choice for thousands of South Africa’s leading businesses, including:
        </Text>

      </div>
      {/* TODO: carousel arrows */}
      <div className={styles.clientsIconCon}>
        {React.Children.toArray(
          clientIconList.map((item) => (
            <span className={styles.clientsIconItemCon}>
              <img
                className={styles.clientsIcon}
                src={item}
                alt="G. Fox client"
              />
            </span>
          )),
        )}
      </div>
    </main>
  );
});

export default About;
