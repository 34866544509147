import React from 'react';
import { IRouteProps } from '../pages';
import Body from './styles';

const PlainLayout = ({ component: Component }: IRouteProps) => (
  <Body>
    <Component />
  </Body>
);

export default PlainLayout;
