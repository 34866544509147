import { Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { Helmet } from 'react-helmet';
import Images from '../../assets';
import styles from './styles.module.css';

const { Title, Text } = Typography;
const History = () => (
  <main id="History">
    <Helmet>
      <title>History - G. Fox</title>
    </Helmet>
    <div className={styles.tabCon}>
      <Title
        className={styles.historyHeader}
        level={2}
      >
        History
      </Title>
      <div className={styles.yearsConRightBeginning}>
        <Text
          className={styles.yearText}
        >
          1960
        </Text>
      </div>
      <div className={styles.line} />
      <div className={styles.timeLineCon}>
        <div className={styles.yearsLeft}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            1960
          </Title>
        </div>
        <div className={styles.historyConRightFirst}>
          <div />
          <div
            className={styles.otherOfferItemBgRight}
            style={{ backgroundImage: `url(${Images.beginning})` }}
          />
          <div className={styles.historyItemConRight}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              The beginning
            </Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              G. Fox was founded in the early 60’s, as a family business, run by Cecil Fox and his son Gerald.
              Under Gerald, this once humble family concern grew into one of South Africa’s largest suppliers and manufacturers of rags.
              At the height of its rag production, G. Fox was the largest importer of second hand clothing used in rags in Africa.
            </Paragraph>
            <div> </div>
          </div>
        </div>
      </div>
      <div className={styles.yearsConRight}>
        <Text
          className={styles.yearText}
        >
          1968
        </Text>
      </div>
      <div>
        <div className={styles.yearsRight}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            1968
          </Title>
        </div>
        <div className={styles.historyConLeft}>
          <div />
          <div
            className={styles.otherOfferItemBgLeft}
            style={{ backgroundImage: `url(${Images.Growth})` }}
          />
          <div className={styles.historyItemConLeft}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              Growth, expansion, & getting personal
            </Title>
            <Paragraph className={styles.historyParagraph}>
              {/* eslint-disable-next-line max-len */}
              The business expanded its product range to include personal protective equipment (PPE), cleaning products and paper products.
              The 1st Conti Suits were sold in that year.
            </Paragraph>
            <div> </div>
          </div>
        </div>
      </div>
      <div className={styles.yearsConLeft}>
        <Text
          className={styles.yearText}
        >
          2003
        </Text>
      </div>
      <div>
        <div className={styles.yearsLeft}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            2003
          </Title>
        </div>
        <div className={styles.historyConRight}>
          <div
            className={styles.otherOfferItemBgRight}
            style={{ backgroundImage: `url(${Images.handShake})` }}
          />
          <div className={styles.historyItemConRight}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              The next chapter started with a devastating tragedy
            </Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              The next chapter of the ever developing G. Fox began in tragedy, with the untimely murder, of the founder Gerald Fox in 2003.The company was purchased shortly after this by the Bidvest Group. It was as a member of South Africa’s leading industrial conglomerate, that G.
              Fox was able to reach new heights and unprecedented levels of expansion.
            </Paragraph>
            <div> </div>
          </div>
        </div>
      </div>
      <div className={styles.yearsConRight}>
        <Text
          className={styles.yearText}
        >
          2006
        </Text>
      </div>
      <div>
        <div className={styles.yearsRight}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            2006
          </Title>
        </div>
        <div className={styles.historyConLeft}>
          <div />
          <div
            className={styles.otherOfferItemBgLeft}
            style={{ backgroundImage: `url(${Images.Building})` }}
          />
          <div className={styles.historyItemConLeft}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              G. Fox now a national player
            </Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              Under Bidvest, G. Fox was merged with Commercial Sundries, a large distributor of paper products throughout South Africa. G.
              Fox was now a national player with branches in Johannesburg, Cape Town and Durban.
              This new exciting phase culminated in the construction of a state of the art facility in Johannesburg.
              This facility, set a new benchmark for the industry, and incorporated a very successful cash and carry offering.
            </Paragraph>
            <div> </div>
          </div>
        </div>
      </div>
      <div className={styles.yearsConLeft}>
        <Text
          className={styles.yearText}
        >
          2010
        </Text>
      </div>
      <div>
        <div className={styles.yearsLeft}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            2010
          </Title>
        </div>
        <div className={styles.historyConRight}>
          <div />
          <div
            className={styles.otherOfferItemBgRight}
            style={{ backgroundImage: `url(${Images.Swaziland})` }}
          />
          <div className={styles.historyItemConRight}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              Growing from strength to strength nationwide
            </Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              Bidvest owned Giant Clothing was consolidated with G. Fox into a division called Bidserv Industrial Products.
              The Giant Clothing factory was moved to Swaziland in 2009, where a brand new factory was set up.
              The Swaziland factory, has grown to over 750 machines, producing 18 000 garments a day, employing 1500 staff members.
              The consistent reliable supply of workwear from the factory to the branch network of G.
              Fox has proven to be a significant value proposition to the G. Fox customer base.
            </Paragraph>
            <div> </div>
          </div>
        </div>
      </div>
      <div className={styles.yearsConRight}>
        <Text
          className={styles.yearText}
        >
          2011
        </Text>
      </div>
      <div>
        <div className={styles.yearsRight}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            2011
          </Title>
        </div>
        <div className={styles.historyConLeft}>
          <div />
          <div
            className={styles.otherOfferItemBgLeft}
            style={{ backgroundImage: `url(${Images.Building})` }}
          />
          <div className={styles.historyItemConLeft}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              Cementing G. Fox
            </Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              During 2011, G. Fox acquired two more businesses. Alsafe (Pty) Ltd, and Multimerja (Pty) Ltd, both of whom were leaders in their respective areas and product lines. The incorporation of these businesses, into the group, has cemented G.
              Fox as South Africa’s largest supplier of PPE, cleaning chemicals, paper and industrial consumable products.
              The company has flourished from its humble beginnings in a house in Kensington Johannesburg,
              to a significant and reliable partner in the South African industrial supply chain.
              The business now includes a branch network of 12 fully stocked branches, 11 500 active customers and over 1 200 staff members.
              G. Fox is proud of its illustrious history,
              but it’s the great potential of a very bright future that the company is most passionate about.
            </Paragraph>
            <div> </div>
          </div>
        </div>
      </div>
      <div className={styles.yearsConLeftEnd}>
        <Text
          className={styles.yearText}
        >
          2018
        </Text>
      </div>
      <div>
        <div className={styles.yearsLeftLast}>
          <Title
            className={styles.historyYears}
            level={5}
          >
            2018
          </Title>
        </div>
        <div className={styles.historyConRightLast}>
          <div />

          <div className={styles.historyItemConLeftLast}>
            <Title
              className={styles.historyHeader}
              level={2}
            >
              Bidvest Commercial Products (G.Fox (Pty) Ltd)
            </Title>
            <Paragraph>
              {/* eslint-disable-next-line max-len */}
              In early 2018 there has been a name change to our brand.
              We remain a trading agent of Bidvest Commercial Products (Pty) Ltd and a proud member of the Bidvest Group of companies.
            </Paragraph>
            <div> </div>
          </div>
        </div>
        <div className={styles.vLine} />
        <div className={styles.lineEnd} />
      </div>
    </div>
  </main>
);
export default History;
