import ClaimVoucher from './ClaimVoucherModal';
import MigrateAccountModal from './MigrateAccountModal';
import ProductSelectionModal from './ProductSelectionModal';

const Modals = {
  ClaimVoucher,
  MigrateAccountModal,
  ProductSelectionModal,
};

export default Modals;
