import React from 'react';
import Typography from '../Typography';
import { ILinkProps } from '../Typography/Link';
import PrimaryBtn, { IPrimaryBtn } from './PrimaryBtn';
import styles from './styles.module.css';

const { Link } = Typography;

interface ILinkBtnProps extends IPrimaryBtn {
  containerClassName?: string,
  link: Omit<ILinkProps, 'className' | 'children'>;
}

const LinkBtn = ({
  containerClassName,
  link,
  ...rest
}: ILinkBtnProps) => (
  <Link
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...link}
    className={[styles.linkBtn, containerClassName].join(' ')}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <PrimaryBtn {...rest} />
  </Link>
);

LinkBtn.defaultProps = {
  containerClassName: undefined,
};

export default LinkBtn;
