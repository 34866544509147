import Images from '../../assets';
import { IProductCardProps } from '../../components/Cards/ProductCard';

export interface IPopularProducts {
  title: string;
  refNo: string;
  backgroundImage: string;
  products: string[];
}

export const popularProducts: IPopularProducts[] = [
  {
    title: 'COVID-19',
    refNo: 'covid-19',
    backgroundImage: Images.PPEDoctorFemale,
    products: [
      'Face Mask',
      'Thermometer',
      'Face Shield',
      'Sanitizer',
    ],
  },
  {
    title: 'SAFETY EQUIPMENT',
    refNo: 'safety',
    backgroundImage: Images.SafetyHelmet,
    products: [
      'Head Protection',
      'Eye Protection',
      'Hearing Protection',
    ],
  },
  {
    title: 'FOOTWEAR',
    refNo: 'footwear',
    backgroundImage: Images.FootwearMenWorking,
    products: [
      'Gumboots',
      'Safety Boots',
      'Safety Shoes',
    ],
  },
  {
    title: 'WORKWEAR',
    refNo: 'work-wear',
    backgroundImage: Images.WorkWearMachinery,
    products: [
      'Conti Suits',
      'Chef\'s Wear',
      'Corporate Clothing',
    ],
  },
  {
    title: 'CLEANING CONSUMABLES',
    refNo: 'footwear',
    backgroundImage: Images.ClimbingWithSafetyEquipment,
    products: [
      'Wipes',
      'Sponge',
      'Scrubbing Pads',
    ],
  },
];

export const trendingProducts: IProductCardProps[] = [
  {
    // categoryRefNo: 'string',
    color: 'red',
    imageSrc: Images.DuralightMens,
    productName: 'F1681 Duralight Men’s',
    // sku: 'f1111',
  },
  {
    // categoryRefNo: 'string',
    color: 'blue',
    imageSrc: Images.PolyCottonRedLabel,
    productName: 'Poly Cotton – Red Label – STD. Cut Reflective Tape Arms & Legs',
    // sku: 'f1111',
  },
  {
    // categoryRefNo: 'string',
    // color: 'red',
    imageSrc: Images.InfraredThermometer,
    productName: 'Infrared Thermometer',
    // sku: 'f1111',
  },
  {
    // categoryRefNo: 'string',
    color: 'orange',
    imageSrc: Images.HoneyNubuckBoot,
    productName: 'Holton SB-SRA Honey Nubuck Boot',
    // sku: 'f1111',
  },
];

export const recentNews = [
  {
    date: '8 May 2020',
    title: 'Why You Need a 3-Ply Face Mask',
    refNo: 'article-1', // TODO: correct article refNo
    backgroundImage: Images.FaceMaskDoctor,
  },
  {
    date: '8 May 2020',
    title: 'Coronavirus Essential Equipment for Schools',
    refNo: 'article-2',
    backgroundImage: Images.FaceMaskSchoolChild,
  },
];
