import { IMasterStock, ISysProPricing } from '@bridgelabsdesign/gfox-api-client';

export const formatPrice = (number: number | undefined | null) => `R ${(number ?? 0).toFixed(2)}`;

export interface IProductPricing {
  masterStock?: IMasterStock | null,
  accountPricing?: ISysProPricing | null
}

export interface IFinalProductPricing {
  withTax: number
  excludingTax: number,
  crossOutPrice: number | null, // note: only used to display is not used in any actuall pricing calculatios
}

// TODO: needs a set of unit test (NB)
// TODO: calculate prices here on backend and not here
export const calcProductPrice = (item?: IProductPricing | null, lineCount = 1) : Readonly<IFinalProductPricing> => {
  const p: IFinalProductPricing = {
    withTax: 0,
    excludingTax: 0,
    crossOutPrice: null,
  };

  // calc tax
  if (item?.accountPricing?.grossPriceWithTax) {
    p.withTax = Number(item?.accountPricing.grossPriceWithTax);
    p.excludingTax = Number(item?.accountPricing.grossPrice);
  } else if (item?.masterStock?.listPriceWithTax) {
    p.withTax = Number(item?.masterStock.listPriceWithTax);
    p.excludingTax = Number(item?.masterStock.listPrice);
  }

  // show discounts
  if (item?.masterStock?.listPrice) {
    if (item?.accountPricing && p.excludingTax < item.masterStock.listPrice) {
      p.crossOutPrice = Number(item?.masterStock.listPrice);
    } else if (item?.masterStock?.listPriceWithDiscount! < p.excludingTax) {
      p.crossOutPrice = p.excludingTax;
      p.excludingTax = item?.masterStock?.listPriceWithDiscount!;
    }
  }

  if (lineCount > 1) {
    p.withTax *= lineCount;
    p.excludingTax *= lineCount;
    if (p.crossOutPrice) {
      p.crossOutPrice *= lineCount;
    }
  }

  return p;
};
