import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import axios, { AxiosError } from 'axios';
import APIClient, {
  IClient, IClientGetInvoicesResponse, ICustomerInvoice, IFavoriteProducts, LoadManager, IPostLoyaltyCardRequest,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import moment, { Moment } from 'moment';
import { getFavorites } from '@bridgelabsdesign/gfox-api-client/dist/client/client';
import { isEmptyString } from '../utils/strings';
import notifications from '../components/Notifications/notifications';

type PickedDateRange = [Moment | null, Moment | null] | null;
class ClientStore {
  // eslint-disable-next-line max-len
  clients = new LoadManager<IClient>({ data: [] }, APIClient.Client.getClients, APIClient.Client.addClient, APIClient.Client.deleteClient, APIClient.Client.updateClient)

  invoices: IClientGetInvoicesResponse[] = [];

  isLoadingInvoices = false;

  isGeneratingInvoice = false;

    query = '';

  searchId = 0;

  isSearching = false;

  searchResults: IClientGetInvoicesResponse[] = [];

  dateRange: PickedDateRange = [moment().add(-31, 'days'), moment()];

  favoriteProducts: IFavoriteProducts[] = [];

  isLoadingFavorites = false;

  constructor() {
    makeObservable(this, {
      clients: observable,
      invoices: observable,
      isLoadingInvoices: observable,
      isGeneratingInvoice: observable,
      accountValid: action,
      deleteAccount: action,
      fetchInvoices: action,
      generateInvoicePDF: action,
      query: observable,
      searchId: observable,
      isSearching: observable,
      searchResults: observable,
      setQuery: action,
      dateRange: observable,
      setDateRange: action,
      favoriteProducts: observable,
      isLoadingFavorites: observable,
      fetchFavorites: action,
      setFavorites: action,
      setLoading: action,
    });
    this.searchResults = this.invoices;
  }

  async fetchFavorites(clientId:string) {
    this.setLoading(true);
    try {
      const response = await getFavorites(clientId);
      runInAction(() => {
        this.setFavorites(response.data);
        this.setLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.setLoading(false);
      });
      message.error('Failed to fetch favorite products');
    }
  }

  setFavorites(favoriteProducts: IFavoriteProducts[]) {
    this.favoriteProducts = favoriteProducts;
  }

  setLoading(isLoading: boolean) {
    this.isLoadingFavorites = isLoading;
  }

  setQuery(s: string) {
    this.query = s.trim();

    if (this.query.length === 0) {
      this.searchResults = this.invoices;
      return;
    }

    setTimeout(() => {
      const filteredData = this.searchResults.filter((invoiceResponse) => {
        const { invoice } = invoiceResponse;
        const invoiceNumber = invoiceResponse?.invoice?.invoiceNumber?.toLowerCase().includes(this.query.toLowerCase());
        const salesOrder = invoiceResponse?.invoice?.salesOrder?.toLowerCase().includes(this.query.toLowerCase());
        const invoiceIdMatch = invoice.invoiceNumber?.toLowerCase().includes(this.query.toLowerCase()) ?? false;
        // const amountMatch = invoiceResponse.orderGrandTotal;
        return invoiceNumber || salesOrder || invoiceIdMatch;
      });

      runInAction(() => {
        this.searchResults = filteredData;
      });
    }, 800);
  }

  setDateRange(range: PickedDateRange) {
    if (range && range[0] && range[1]) {
      this.dateRange = range;
      this.invoices = this.invoices.filter((invoiceResponse) => {
        const { invoice } = invoiceResponse;
        const createdAtMoment = moment(invoice.invoiceDate);
        return createdAtMoment.isBetween(range[0], range[1]);
      });
    } else {
      message.error('Select date range.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async allocateLoyaltyCardForClient(clientId: string, requestBody: IPostLoyaltyCardRequest): Promise<string | undefined> {
    try {
      const result = await APIClient.Client.allocateLoyaltyCard(clientId, requestBody);
      message.success('Loyalty card allocated successfully.');
      return result.cardNo;
    } catch (error) {
      message.error('Error allocating loyalty card.');
      return undefined;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async accountValid(clientId: string, opts: {
    accountNumber?: string,
    onReRequestValidation: () => void,
    sendOnEmail?: boolean,
  }): Promise<boolean> {
    try {
      const queryParameters = `sendHoldEmail=${opts.sendOnEmail ? 'true' : 'false'}`;
      await APIClient.Client.getAccountStatus(clientId, queryParameters);
      return true;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        // eslint-disable-next-line no-console
        console.error('clientStore accountValid error: ', error);
        return false;
      }

      const err = error as AxiosError;
      type response = { message?: 'deactivated_client' | 'unverified_account' }
      const data = err?.response?.data as response | undefined;
      let msg:string | null = null;
      if (data?.message === 'deactivated_client') {
        notifications.accountDeactivated(opts.accountNumber);
      } else if (data?.message === 'unverified_account') {
        msg = 'Account number is unverified. Verify to place an order.';
        if (!isEmptyString(opts.accountNumber)) {
          notifications.unverifiedAccountStatus(opts.accountNumber!, opts.onReRequestValidation);
        }
      }

      if (msg) {
        message.error(msg);
      }

      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async migrateSysProImports(values: { emailAddress: string, accountNumber?: string | null }) : Promise<IClient | null> {
    const hide = message.loading('Getting account details...', 0);
    let client: IClient | null = null;
    try {
      client = await APIClient.Client.migrateSysProImport(values);
    } catch (error) {
      message.error('Could not get account details.');
      // eslint-disable-next-line no-console
      console.error('clientStore migrate syspro imports error: ', error);
    } finally {
      hide();
    }

    return client;
  }

  async deleteAccount(client: IClient): Promise<boolean> {
    const hide = message.loading('Deleting account....', 0);
    await this.clients.remove(client, 'hardDelete=true');
    hide();
    if (this.clients.error) {
      return false;
    }
    return true;
  }

  async fetchInvoices(client: IClient) {
    if (this.isLoadingInvoices) {
      return;
    }
    this.isLoadingInvoices = true;
    try {
      const res = await APIClient.Client.getInvoices(client);
      this.invoices = res.data;
    } catch (error) {
      message.error('Could not get invoices.');
      // eslint-disable-next-line no-console
      console.error('clientStore fetchInvoices error: ', error);
    }
    this.isLoadingInvoices = false;
  }

  async fetchCreditNotes(client: IClient) {
    if (this.isLoadingInvoices) {
      return;
    }
    this.isLoadingInvoices = true;
    try {
      const res = await APIClient.Client.getInvoices(client, 'creditNotes=true');
      this.invoices = res.data;
    } catch (error) {
      message.error('Could not get credit notes.');
      // eslint-disable-next-line no-console
      console.error('clientStore fetchCreditNotes error: ', error);
    }
    this.isLoadingInvoices = false;
  }

  // eslint-disable-next-line class-methods-use-this
  async generateInvoicePDF(client: IClient, invoice: ICustomerInvoice, creditNote = false) {
    this.isGeneratingInvoice = true;
    let hide;
    if (creditNote) {
      hide = message.loading('Getting credit Note...', 0);
    } else {
      hide = message.loading('Getting invoice...', 0);
    }
    try {
      const queryParameters = `creditNote=${creditNote ? 'true' : 'false'}`;
      const res = await APIClient.Client.getInvoiceDetailByNo(client, invoice, queryParameters);
      window.open(URL.createObjectURL(res));
    } catch (error) {
      message.error('Could not view invoice.');
      // eslint-disable-next-line no-console
      console.error('clientStore fetchInvoicesDoc error: ', error);
    } finally {
      hide();
      this.isGeneratingInvoice = false;
    }
  }
}

export default ClientStore;
