import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IPopularProducts } from './data';
import Buttons from '../../components/Buttons';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import stores from '../../stores/stores';

interface IPopularProductCardProps {
  item: IPopularProducts
}

const { Text } = Typography;

const PopularProductCard = observer(({
  item,
}: IPopularProductCardProps) => {
  const [subRefCategory, setSubRefCategory] = useState<string | undefined>(undefined);

  const { categoryStore } = stores;
  const categories = categoryStore.categories.value.data;

  useEffect(() => {
    const c = categories.find((x) => x.refNo === item.refNo);
    setSubRefCategory(c?.inverseParentCategory[0]?.refNo);
  }, [categories]);

  return (
    <div
      className={styles.popularProdItem}
      style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${item.backgroundImage})` }}
    >
      <Text className={styles.popularProdTitle}>
        {item.title}
      </Text>

      <div className={styles.popularProdCategoryContainer}>
        {React.Children.toArray(
          item.products.map((pItem, idx) => (
            <>
              <Text className={styles.popularProdCategory}>
                {/* bullet symbol */}
                {idx > 0 && <>&#x2022;</>}
                {` ${pItem} `}
              </Text>
            </>
          )),
        )}
      </div>

      <div>
        {/* TODO: default type hover color */}
        <Buttons.LinkBtn
          type="default"
          containerClassName={styles.popularProdBtnContainer}
          className={styles.popularProdBtn}
          link={{ href: subRefCategory ? `/products/${item.refNo}` : '/specials' }}
        >
          Shop all
        </Buttons.LinkBtn>
      </div>
    </div>
  );
});

export default PopularProductCard;
