import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import Spin from '../../components/Spin/Spin';
import Buttons from '../../components/Buttons';
import Tables from '../../components/Tables';
import Modals from '../../components/Modals';

const { Text, Title } = Typography;

const AccountDiscounts = observer(() => {
  const [claimModalVisible, setClaimModalVisible] = useState(false);
  const { currentClient } = useAuthContext();
  const { vouchersStore } = stores;

  useEffect(() => {
    if (currentClient?.id) {
      vouchersStore.loadVoucherClaims(currentClient?.id);
    }
  }, [currentClient?.id]);

  return (
    <main id="account-discounts">
      <Spin
        spinning={vouchersStore.isLoading ?? false}
      >
        <Title
          className={styles.headerText}
          level={2}
        >
          Discounts
        </Title>
        <div className={styles.searchDatePickerContainer}>
          <Buttons.SecondaryBtn
            type="primary"
            onClick={() => setClaimModalVisible(true)}
          >
            Claim Voucher
          </Buttons.SecondaryBtn>
        </div>
        <Text style={{ color: 'red', fontSize: '1.2rem' }}>
          Please note: voucher cannot be used on quotes. Only orders.
        </Text>

        <div className={styles.tableCon}>
          <Tables.VouchersTable
            voucherClaims={vouchersStore.voucherClaims}
          />
        </div>
      </Spin>
      <Modals.ClaimVoucher
        isVisible={claimModalVisible}
        setIsVisible={(v) => setClaimModalVisible(v)}
      />
    </main>
  );
});

export default AccountDiscounts;
