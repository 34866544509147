import React, { forwardRef } from 'react';
import { Card } from 'antd';
import styles from './loyalty-card.module.css';
import Barcode from './BarcodeCard';
import bidvestImg from '../../assets/BidvestImage.png';

interface LoyaltyCardProps {
  cardNumber: string | undefined;
  imageUrl: string;
}

const LoyaltyCard = forwardRef<HTMLDivElement, LoyaltyCardProps>(({ cardNumber, imageUrl }, ref) => (
  <div className={styles.container} ref={ref}>
    <Card className={styles.LoyaltyCard}>
      <div className={styles.loyaltyCardBody}>
        <p className={styles.cardNumber}>
          Member Number
        </p>
        <Barcode text={cardNumber ?? ''} />
        <p className={styles.websiteLink}>
          www.gfox.co.za
        </p>
      </div>
      <div className={styles.loyaltyCardHeader}>
        <div className={styles.foxImageContainer}>
          <div className={styles.foxImage}>
            <img src={imageUrl} alt="GFox" />
          </div>
          <p className={styles.foxText}>Think clean, Think Safe, Think Fox</p>
        </div>
        <img src={bidvestImg} alt="Bidvest" className={styles.bidvestImage} />
      </div>
    </Card>
  </div>
));

export default LoyaltyCard;
