import React from 'react';
import Typography from '../Typography';
import styles from './catalogue-card.module.css';

interface ICatalogueCardProps {
  imageSrc: string;
  onClick?(): void
  title: string;
  subTitle: string;
  href: string | undefined;
}

const { Link, Text } = Typography;

const CatalogueCard = ({
  imageSrc,
  onClick,
  title,
  subTitle,
  href,
}: ICatalogueCardProps) => (
  <div
    className={styles.specialsListItem}
    onClick={() => onClick && onClick()}
    aria-hidden
  >
    <div className={styles.specialsListImgCon}>
      <img
        src={imageSrc}
        alt="Special banner"
      />
    </div>
    <div className={styles.specialsListContent}>
      <div className={styles.specialsListSeprator} />
      <Link
        href={href ?? ''}
        onClick={(e) => {
          if (!href) {
            e.preventDefault();
          }
        }}
      >
        <Text className={styles.specialsListTitle}>
          {title}
        </Text>
      </Link>
      <Text className={styles.specialsListBranch}>
        {subTitle}
      </Text>
    </div>
  </div>
);

CatalogueCard.defaultProps = {
  onClick: undefined,
};

export default CatalogueCard;
