import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Empty, Typography, Pagination } from 'antd';
import Spin from '../../components/Spin/Spin';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import Cards from '../../components/Cards';
import { imgURLPrefix } from '../../config/image';
import NewProductsMap from '../NewProductsMap/NewProductsMap';

const {
  Title,
} = Typography;
const NewProducts = observer(() => {
  const { inventoryStore } = stores;
  const history = useHistory();

  const resultsData = inventoryStore.complementaryProducts.value.data;
  const pagination = inventoryStore?.complementaryProducts.value.pagination;

  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = parseInt(localStorage.getItem('currentPage') || '1', 10);
    return pagination?.PageNumber || storedPage || 1;
  });
  const [pageSize, setPageSize] = useState(() => {
    const storedSize = parseInt(localStorage.getItem('pageSize') || '10', 10);
    return pagination?.PageSize || storedSize || 10;
  });

  useEffect(() => {
    inventoryStore.loadSkuMasters(`newProduct=true&withPrice=true&PageNumber=${currentPage}&PageSize=${pageSize}`);
  }, [currentPage, pageSize]);

  const handlePageChange = (page: number, size: number | undefined) => {
    setCurrentPage(page);
    if (size) {
      setPageSize(size);
    }
    localStorage.setItem('currentPage', page.toString());
  };

  useEffect(() => {
    if (pagination) {
      setCurrentPage(pagination.PageNumber);
      setPageSize(pagination.PageSize);
    }
  }, [pagination]);

  return (
    <main id="home">
      <Helmet>
        <title>New Products - G. Fox</title>
      </Helmet>

      <div className={styles.productShowcaseCon}>
        {
      resultsData.length > 0 && (
      <Title
        className={styles.productHeader}
        level={2}
      >
        New Products:
      </Title>
      )
    }
        <NewProductsMap />
        <div className={styles.productResultCardCon}>
          {inventoryStore.isLoadingProducts ? (
            <div className={styles.searchSpinCon}>
              <Spin tip="Loading..." />
            </div>
          ) : (
            React.Children.toArray(
              resultsData.map((item: any) => (
                <Cards.ProductCard
                  className={styles.productResultCard}
                  color="red"
                  imageSrc={`${imgURLPrefix}${item.subCategoryLongImage}`}
                  imgClassName={styles.productResultImg}
                  productName={item.productName ?? ''}
                  pricing={{
                    masterStock: item.masterStock,
                    accountPricing: item.accountPricing,
                  }}
                  shopLink={`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`}
                  productNameClassName={styles.productResultPName}
                  optType="viewBtn"
                  removeBg
                  separator
                  viewLink={`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`}
                  onViewLinkClick={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line max-len
                    history.push(`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`);
                  }}
                />
              )),
            )
          )}
          {resultsData.length === 0 && !inventoryStore.isLoadingProducts && (
          <Empty
            className={styles.emptyList}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
          )}
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={pagination?.TotalRecords}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={['10', '20', '50']}
        />
      </div>
    </main>
  );
});

export default NewProducts;
