import React from 'react';
import Icon from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import Typography from '../../components/Typography';
import Images from '../../assets';
import styles from './styles.module.css';
import { certificatesDocs } from './data';

const { Link, Text, Title } = Typography;

const Certificates = () => (
  <main id="certificates">
    <Helmet>
      <title>Certificates - G. Fox</title>
    </Helmet>
    <div className={styles.con}>
      <Title
        className={styles.conHeaderTitle}
        level={2}
      >
        Certificates
      </Title>

      <div className={styles.certContainer}>
        <div
          className={styles.certImg}
          style={{ backgroundImage: `url(${Images.WorkerPullingPalletTruck})` }}
        />
        <div className={styles.certContent}>
          {React.Children.toArray(
            certificatesDocs.map((item) => (
              <Link
                href={item.documentUrl}
                external
                className={styles.certDocumentLink}
              >
                <Icon
                  className={styles.pdfIcon}
                  component={(Images.PdfIcon)}
                />
                <Text>
                  {item.title}
                </Text>
              </Link>
            )),
          )}
        </div>
      </div>

    </div>
  </main>
);

export default Certificates;
