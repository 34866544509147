/* eslint-disable import/prefer-default-export */

import Images from '../../assets';

export const productOffers = [
  {
    name: 'Workwear',
    image: Images.ProtectiveWearIcon,
    refNo: 'work-wear',
  },
  {
    name: 'Footwear',
    image: Images.Boot2Icon,
    refNo: 'footwear',
  },
  {
    name: 'Security Wear',
    image: Images.PolicemanIcon,
    refNo: 'security-wear',
  },
  {
    name: 'Hand Protection',
    image: Images.HandProtectionIcon,
    refNo: 'hand-protection',
  },
  {
    name: 'Safety Equipment',
    image: Images.SafetyEquipmentIcon,
    refNo: 'safety',
  },
  {
    name: 'Paper Products',
    image: Images.PaperRollIcon,
    refNo: 'paper',
  },
  {
    name: 'Cleaning & Chemical Products',
    image: Images.CleaningProductsIcon,
    refNo: 'cleaning-products',
  },
  {
    name: 'Cleaning Equipment',
    image: Images.CleaningToolsIcon,
    refNo: 'cleaning-equipment',
  },
  {
    name: 'Cleaning Consumables',
    image: Images.CleaningIcon,
    refNo: 'cleaning-consumables',
  },
  {
    name: 'Office Consumables',
    image: Images.FirstAidKitIcon,
    refNo: 'office-consumables',
  },
];

export const gfoxValues = [
  {
    title: 'Honesty',
    // eslint-disable-next-line max-len
    text: 'In all our customer and supplier interactions, honesty is the guiding principle. We believe that honesty must trump any other consideration, and is vital in building lasting relationships with our customers and suppliers.',
  },
  {
    title: 'Passion',
    // eslint-disable-next-line max-len
    text: 'We love what we do, and we are passionate about giving companies the ability to create a safer and cleaner workplace for themselves and their employees.',
  },
  {
    title: 'Service centric',
    // eslint-disable-next-line max-len
    text: 'The core of our business, centres around service, service and service. We strive to provide our customers with the service they deserve and expect, and endeavour to correct any instance where the service is not optimal.',
  },
];

export const clientIconList = [
  Images.AngloAmericanIcon,
  Images.BidvestPrestigeIcon,
  Images.GroupFiveIcon,
  Images.IllovoGroupIcon,
  Images.NampakIcon,
  Images.RainbowChickensIcon,
  Images.SouthAfricanBreweriesIcon,
  Images.StefanuttiStocksIcon,
];
