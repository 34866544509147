import React, { useCallback, useEffect, useState } from 'react';
import { IoCallOutline, IoLocationOutline, IoMailOutline } from 'react-icons/io5';
import Icon from '@ant-design/icons';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import { branchesData } from './data';
import Images from '../../assets';

const { Text } = Typography;

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    initMap?: () => void;
    google: any;
  }
}

interface Location {
  name: string;
  lat: number;
  lng: number;
}

const mapStyles = [
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#ecf3f4' }], // Pistachio color
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#bfd5df' }], // Zenith blue color
  },
];

const locations: Location[] = [
  { name: 'Lusaka', lat: -15.392551396774827, lng: 28.247473742329603 },
  { name: 'Cape Town', lat: -33.916472017482604, lng: 18.468138154981887 },
  { name: 'Durban', lat: -29.75390829655141, lng: 31.028176284659203 },
  { name: 'Eswatini', lat: -26.512022859965256, lng: 31.2969929841481 },
  { name: 'Gqeberha', lat: -33.9623033839713, lng: 25.553375000000003 },
  { name: 'Johannesburg', lat: -26.209644045959973, lng: 28.133876611159252 },
  { name: 'Middelburg', lat: -25.78305600979654, lng: 29.4879521 },
  { name: 'Nelspruit', lat: -25.448327212748467, lng: 30.96099224189354 },
  { name: 'Polokwane', lat: -23.89287156802032, lng: 29.438605666932794 },
  { name: 'Richards', lat: -28.758259666768357, lng: 32.0430550688893 },
  { name: 'Vredenburg', lat: -32.92230627533647, lng: 17.98926699598437 },
  { name: 'George', lat: -33.9630083687365, lng: 22.47868023081507 },
];

const BranchTab = () => {
  const mapRef = React.useRef<any>(null);
  const [mapReady, setMapReady] = useState(false);
  useEffect(() => {
    const initMap = () => {
      const mapElement = document.getElementById('map');
      if (mapElement && window.google) {
        const map = new window.google.maps.Map(mapElement, {
          center: { lat: -29.0852, lng: 24.0113 },
          zoom: 6,
          styles: mapStyles,
        });

        mapRef.current = map;
        setMapReady(true);

        locations.forEach((location, index) => {
          const marker = new window.google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map,
            title: location.name,
            label: { text: (index + 1).toString(), color: 'white' },
          });

          marker.addListener('click', () => {
            window.open(
              `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`,
              '_blank',
            );
          });
        });
      }
    };

    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAsQIJ-HV_bWdfl7qIXe1hLKlmo1VOi0i8&callback=initMap';
    script.async = true;
    script.defer = true;
    window.initMap = initMap;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      delete window.initMap;
    };
  }, []);

  const handleLocationClick = useCallback((location) => {
    if (mapRef.current) {
      mapRef.current.setCenter({ lat: location.lat, lng: location.lng });
      mapRef.current.setZoom(14);
    }
  }, []);

  return (
    <div className={styles.branchTabCon}>
      {/* // TODO: google maps */}
      <div className={styles.branchMapCon} id="map" />
      <div className={styles.branchDetailsCon}>
        <div className={styles.branchDetailsHeader}>
          <Text className={styles.branchDetailsHeaderText}>
            Branches
          </Text>
          <Text className={styles.branchDetailsHeaderCount}>
            {branchesData.length || '--'}
          </Text>
        </div>

        <div className={styles.branchDetails}>
          {React.Children.toArray(
            branchesData.map((branch, idx) => (
              <div className={styles.branchDetailsItem}>
                <div className={styles.branchDetailsItemCon}>
                  <div>
                    <Text className={styles.branchDetailsCount}>
                      {idx + 1}
                    </Text>
                  </div>

                  <div className={styles.branchDetailsInfoCon}>
                    <Text className={styles.branchDetailsName}>
                      {branch.name}
                    </Text>

                    <div className={styles.branchDetailsInfo}>
                      <IoMailOutline className={styles.branchDetailsIcon} />
                      <Text className={styles.branchDetailsText}>{branch.emailAddress || 'N/A'}</Text>
                    </div>
                    <div className={styles.branchDetailsInfo}>
                      <IoCallOutline className={styles.branchDetailsIcon} />
                      <Text className={styles.branchDetailsText}>{branch.telNumber || 'N/A'}</Text>
                    </div>
                    <div className={styles.branchDetailsInfo}>
                      <Icon
                        className={[styles.branchDetailsIcon].join(' ')}
                        component={Images.FaxMachineIcon}
                      />
                      <Text className={styles.branchDetailsText}>{branch.faxNumber || 'N/A'}</Text>
                    </div>
                    <div
                      className={[
                        styles.branchDetailsInfo,
                      ].join(' ')}
                    >
                      <button
                        type="button"
                        className={styles.locationButton}
                        onClick={mapReady ? () => handleLocationClick(locations[idx]) : undefined}
                      >
                        <IoLocationOutline className={styles.branchDetailsIcon} />
                      </button>

                      <div>
                        {React.Children.toArray(
                          branch.address.map((addr) => (
                            <Text className={styles.branchDetailsAddrText}>
                              {addr}
                            </Text>
                          )),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )),
          )}
        </div>
      </div>
    </div>
  );
};

export default BranchTab;
