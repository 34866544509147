import APIClient, { IDelivery, IInventoryMaster } from '@bridgelabsdesign/gfox-api-client';
import { DeliveryTypes } from '@bridgelabsdesign/gfox-api-client/dist/utils/enums';

import {
  action, makeObservable, observable,
} from 'mobx';
import { isEmptyString } from '../utils/strings';

class DeliveryStore {
  selectedDelivery?: IDelivery | null = null;

  deliveryOptions: IDelivery[] = [];

  constructor() {
    makeObservable(this, {
      selectedDelivery: observable,
      deliveryOptions: observable,
      setSelectedDelivery: action,
      fetchDeliveryPricing: action,
      reset: action,
    });
  }

  setSelectedDelivery(opt?: IDelivery | null) {
    this.selectedDelivery = opt;
  }

  async fetchDeliveryPricing(invList: IInventoryMaster[], clientId: string, addressId: string, accountNumber:string) {
    if (invList.length === 0) {
      this.deliveryOptions = [];
      return;
    }

    try {
      const response = await APIClient.Delivery.getDeliveryPricing(invList, `clientId=${clientId}&addressId=${addressId}`);
      this.deliveryOptions = response.data.sort((b, a) => (a?.deliveryOrders[0]?.price ?? 0) - (b?.deliveryOrders[0]?.price ?? 0));

      if (!this.selectedDelivery) {
        let defaultDeliveryOption;
        if (!isEmptyString(accountNumber)) {
          defaultDeliveryOption = this.deliveryOptions.find((item) => item.refNo === DeliveryTypes.GFoxAccountHolder);
        } else {
          defaultDeliveryOption = this.deliveryOptions.find((item) => item.refNo === DeliveryTypes.Collect);
        }

        this.setSelectedDelivery(defaultDeliveryOption);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('deliveryStore fetchDeliveryPricing error:', error);
    }
  }

  reset() {
    this.selectedDelivery = null;
    this.deliveryOptions = [];
  }
}

export default DeliveryStore;
