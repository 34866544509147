import React, { useEffect, useState } from 'react';
import { IInventoryMaster, IOrder, IOrderStatusHistory } from '@bridgelabsdesign/gfox-api-client';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Typography from '../Typography';
import Buttons from '../Buttons';
import Images from '../../assets';
import styles from './previous-order.module.css';
import { formatPrice } from '../../utils/pricing';
import { orderHistorySteps, OrderHistoryType, OrderStatus } from '../../utils/order/status';
import { formatDate, isEmptyString } from '../../utils/strings';

interface IPreviousOrderCard {
  className?: string;
  invItem: IInventoryMaster[];
  order?: IOrder | null;
  onReorderClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const { Text } = Typography;

const PreviousOrderCard = ({
  className,
  invItem,
  order,
  onReorderClick,
}: IPreviousOrderCard) => {
  const [currentStep, setCurrentStep] = useState<OrderHistoryType>();
  const [deliveredHistory, seDeliveredHistory] = useState<IOrderStatusHistory>();
  const history = useHistory();

  const handleOnViewHistory = () => {
    history.push(`/account/orders/${order?.refNo}`);
  };

  const handleOrderChange = () => {
    if (!order) {
      return;
    }
    const step = orderHistorySteps.find((x) => x.status === order.statusCode && x.status !== OrderStatus.DELIVERED);
    setCurrentStep(step);
    seDeliveredHistory(order.orderStatusHistories.find((x) => x.statusCode === OrderStatus.DELIVERED));
  };

  useEffect(() => {
    handleOrderChange();
  }, [order, invItem]);

  return (
    <div className={[styles.con, className].join(' ')}>
      <div className={styles.headerCon}>
        <Text className={styles.headerText}>
          {`${isEmptyString(order?.refNo) ? 'Current' : 'Previous'} Order`}
        </Text>
        <Buttons.SecondaryBtn
          className={styles.historyBtn}
          type="ghost"
          onClick={handleOnViewHistory}
        >
          View History
        </Buttons.SecondaryBtn>
      </div>
      {order?.refNo && (
      <div className={styles.headerTextRefCon}>
        <Text className={styles.headerText}>
          {order.refNo}
        </Text>
      </div>
      )}

      <div className={styles.statusCon}>
        <div className={styles.statusIconCon}>
          <Icon
            className={styles.statusIcon}
            component={Images.FactoryIcon}
          />
        </div>
        <div className={styles.statusInfoCon}>
          {/* TODO: get correct order */}
          <Text className={styles.statusInfoHeader}>
            {currentStep?.readableText}
          </Text>
          <Text className={styles.statusInfoOrderDate}>
            {formatDate(order?.orderStatusHistories.find((x) => x.statusCode === currentStep?.status)?.createdAt as any)}
          </Text>
        </div>
      </div>

      <div className={[styles.statusCon, styles.statusConMiddle].join(' ')}>
        <div className={styles.statusIconCon}>
          <div className={styles.statusLine} />
        </div>
        <div className={styles.statusInfoCon}>
          <Text className={styles.statusInfoHeader}>
            Order details:
          </Text>
          {invItem.map((item) => (
            <Text
              key={item.sku}
              className={styles.statusInfoProduct}
            >
              {`${order?.orderInfo.find((x) => x.sku === item.sku)?.orderQty}x ${item?.sku}`}
            </Text>
          ))}
        </div>
        <div className={styles.statusPriceCon}>
          <Text className={styles.statusPrice}>
            {formatPrice(order?.grandTotal ?? 0)}
          </Text>
        </div>
      </div>

      <div className={styles.statusCon}>
        <div className={styles.statusIconCon}>
          <Icon
            className={styles.statusIcon}
            component={Images.HomeIcon}
          />
        </div>

        <div className={styles.statusInfoCon}>
          {/* TODO: get correct order */}
          <Text className={styles.statusInfoHeader}>
            Delivered
          </Text>
          <Text className={styles.statusInfoOrderDate}>
            {/* TODO: delivered date */}
            {deliveredHistory ? formatDate(deliveredHistory?.createdAt as any) : '---'}
          </Text>
        </div>
      </div>
      <Buttons.SecondaryBtn
        className={styles.reorderBtn}
        onClick={onReorderClick}
      >
        Re-Order
      </Buttons.SecondaryBtn>
    </div>
  );
};

PreviousOrderCard.defaultProps = {
  className: undefined,
  onReorderClick: undefined,
  order: undefined,
};

export default PreviousOrderCard;
