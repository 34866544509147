/* eslint-disable import/prefer-default-export */

export const specialsList = [
  {
    title: 'Complete Catalogue',
    image: 'https://gfox-cdn.bridgelabs.design/images/product-catalogue/GFox%20Catalogue%20Issue%205%20November%202020.png',
    subTitle: 'Issue 5 - 2020',
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/product-catalogue/GFox%20Catalogue%20Issue%205%20November%202020.pdf',
  },
  {
    title: 'Hand Protection Range Catalogue',
    // eslint-disable-next-line max-len
    image: 'https://gfox-cdn.bridgelabs.design/images/product-catalogue/G%20Fox%20Hand%20Protection%20Range%20(Update%20April%202022)%20Web.png',
    subTitle: 'Issue 3 - 2024',
    // eslint-disable-next-line max-len
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/product-catalogue/G%20Fox%20Hand%20Protection%20Range%20(Update%20March%2024)%20Web.pdf',
  },
  {
    title: 'HACCP Food Safe Catalogue',
    image: 'https://gfox-cdn.bridgelabs.design/images/product-catalogue/G%20Fox%20HACCP%20Range%20Detailer%20Update%20May%202019.png',
    subTitle: 'Issue 1 - 2019',
    // eslint-disable-next-line max-len
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/product-catalogue/G%20Fox%20HACCP%20Range%20Detailer%20Update%20May%202019.pdf',
  },
  {
    title: 'Security Range Catalogue',
    image: 'https://gfox-cdn.bridgelabs.design/images/product-catalogue/Security%20Range%20Detailer%20October%202019.png',
    subTitle: 'Issue 1 - 2019',
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/product-catalogue/Security%20Range%20Detailer%20October%202019.pdf',
  },
];
