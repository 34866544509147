import React, { useEffect, useState } from 'react';
import Buttons from '../Buttons';
import Typography from '../Typography';
import styles from './Order-card.module.css';
import { formatDate, isEmptyString } from '../../utils/strings';
import { orderHistorySteps, OrderHistoryType } from '../../utils/order/status';

export interface IOrdersProps{
  className?: string;
  orderID: string;
  orderDate: string;
  status:string;
  total: string;
  fileUrl?: string;
    // eslint-disable-next-line react/require-default-props
  onChatClick?: React.MouseEventHandler<HTMLButtonElement>;
  onReorderClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTrackOrderClick?: React.MouseEventHandler<HTMLButtonElement>;
  onViewClick: React.MouseEventHandler<HTMLButtonElement>;
}

const { Link, Text } = Typography;

const ViewMoreBtn = ({
  onClick,
  text = 'View items ordered',
  disabled,
}: {
  disabled: boolean,
    text: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) => (
  <Buttons.PrimaryBtn
    className={styles.viewMoreBtn}
    type="text"
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </Buttons.PrimaryBtn>
);

const OrderCard = ({
  className,
  orderID,
  orderDate,
  status,
  total,
  fileUrl,
  onReorderClick,
  onTrackOrderClick,
  onChatClick,
  onViewClick,
}: IOrdersProps) => {
  const [orderStatus, setOrderStatus] = useState<OrderHistoryType>();

  useEffect(() => {
    setOrderStatus(orderHistorySteps.find((x) => x.status === status));
  }, [status]);

  return (
    <>
      <div
        className={[styles.OrderMonitorView, className].join(' ')}
      >
        <div className={styles.infoContainer}>
          <div className={styles.infoProdNameCon}>
            {orderID && (
            <>
              <Text className={[styles.orderID].join(' ')}>
                {`Order ID: ${orderID}`}
              </Text>
              <Text className={[styles.dotIcon].join(' ')}>
                {'\u2022'}
              </Text>
            </>
            )}

            <Text
              className={[styles.status].join(' ')}
              style={{ color: orderStatus?.color }}
            >
              {orderStatus?.readableText2 ?? ''}
            </Text>

            <Text
              className={[styles.orderDate].join(' ')}
            >
              {`Date: ${formatDate(orderDate)}`}
            </Text>
            <ViewMoreBtn
              onClick={onViewClick}
              text="View"
              disabled={isEmptyString(orderID)}
            />
          </div>
          <div className={styles.totalCon}>
            <Text
              className={[
                styles.total,
              ].join(' ')}
            >
              {`Total ${total}`}
            </Text>
            <ViewMoreBtn
              onClick={onViewClick}
              text="View items ordered"
              disabled={isEmptyString(orderID)}
            />
          </div>

          {/* // TODO: cancel order */}
          <div className={styles.btnCon}>
            <Buttons.SecondaryBtn
              className={styles.cancelOrder}
              type="default"
              onClick={onReorderClick}
            >
              Re-order
            </Buttons.SecondaryBtn>
            {fileUrl && (
            <Buttons.SecondaryBtn className={styles.trackOrder} type="default">
              <Link href={fileUrl} external>
                View Order
              </Link>
            </Buttons.SecondaryBtn>
            )}

            <Buttons.SecondaryBtn
              className={styles.trackOrder}
              onClick={onTrackOrderClick}
              disabled={isEmptyString(orderID)}
            >
              Track Order
            </Buttons.SecondaryBtn>
            <Buttons.SecondaryBtn
              className={styles.trackOrder}
              onClick={onChatClick}
              disabled={isEmptyString(orderID)}
            >
              Chat
            </Buttons.SecondaryBtn>
          </div>
        </div>

      </div>

    </>

  );
};

OrderCard.defaultProps = {
  className: undefined,
  onReorderClick: undefined,
  onTrackOrderClick: undefined,
  fileUrl: '',
};
export default OrderCard;
