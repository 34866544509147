import React, { ReactNode } from 'react';
import Form from '../../components/Form';

export interface IAddressForm {
  name: string;
  label: String;
  whitespace: boolean,
  required: boolean;
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;
  disabled: boolean,
  hidden?: boolean,
}

export const addressForm: IAddressForm[] = [
  {
    name: 'address1',
    label: 'Address line 1',
    whitespace: true,
    required: true,
    formType: 'input',
    disabled: true,
    input: <Form.Input placeholder="Enter your address" />,

  },
  {
    name: 'address2',
    label: 'Address line 2',
    whitespace: true,
    required: false,
    formType: 'input',
    input: <Form.Input placeholder="Enter your address" />,
    disabled: true,
  },
  {
    name: 'address3',
    label: 'Suburb',
    whitespace: true,
    required: true,
    formType: 'input',
    input: <Form.Input placeholder="Enter your address" disabled />,
    disabled: true,
  },
  {
    name: 'city',
    label: 'City',
    whitespace: true,
    required: true,
    formType: 'input',
    input: <Form.Input placeholder="Enter your city name" disabled />,
    disabled: true,
  },
  {
    name: 'province',
    label: 'Select province',
    whitespace: true,
    required: false,
    formType: 'select',
    input: (
      <Form.Select
        options={[]}
        placeholder="Select the province"
        disabled
      />
    ),
    disabled: true,
  },
  {
    name: 'country',
    label: 'Select the country',
    whitespace: true,
    required: false,
    formType: 'select',
    input: (
      <Form.Select options={[]} placeholder="Select the Country" disabled />
    ),
    disabled: true,
  },
  {
    name: 'postalCode',
    label: 'Postal code',
    whitespace: true,
    required: true,
    formType: 'input',
    input: <Form.Input placeholder="Enter postal code" disabled />,
    disabled: false,
  },
  {
    name: 'hubCode',
    label: 'Hub Code',
    whitespace: true,
    required: true,
    formType: 'input',
    input: <Form.Input placeholder="Enter postal code" disabled />,
    disabled: false,
    hidden: true,
  },
];
