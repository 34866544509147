import { IOrder } from '@bridgelabsdesign/gfox-api-client';
import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import styles from './last-order.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { orderHistorySteps, OrderHistoryType, OrderStatus } from '../../utils/order/status';
import { formatDate } from '../../utils/strings';

const { Text } = Typography;

interface ILastOrderCardProps {
  estimateDelivery?: boolean;
  order?: IOrder | null;
}

const LastOrderCard = ({
  estimateDelivery,
  order,
}: ILastOrderCardProps) => {
  const [steps, setSteps] = useState<OrderHistoryType[]>([]);
  const [status, setStatus] = useState<OrderHistoryType>();
  const [currentStep, setCurrentStep] = useState(0);
  const { isMobileWindow, isTabletWindow } = useWindowSize();

  const handleOnOrderChange = () => {
    const info = orderHistorySteps.find((x) => x.status === order?.statusCode);
    setStatus(info);
  };

  const handleOnStatusChange = () => {
    if (!status) {
      return;
    }
    let tempSteps = orderHistorySteps.slice().reverse()
      .filter((x) => !x.hidden);
    if (!estimateDelivery) {
      tempSteps = tempSteps.filter((x) => x.status !== OrderStatus.ESTIMATED_DELIVERED);
    }
    tempSteps = tempSteps.map((x, idx) => {
      const isCurrentStep = (status as any) === x.status;
      if (isCurrentStep) setCurrentStep(idx);
      return ({ ...x, currentStatus: isCurrentStep });
    });
    setSteps(tempSteps);
  };

  const getStatusDate = (value?: OrderStatus) => {
    const statusHistory = order?.orderStatusHistories?.find((x) => x.statusCode === value);
    return formatDate(statusHistory?.createdAt as any, true);
  };

  useEffect(() => {
    handleOnOrderChange();
  }, [order]);

  useEffect(() => {
    handleOnStatusChange();
  }, [status]);

  if (order === null || order === undefined) {
    return null;
  }
  return (
    <div className={styles.lastOrderCon}>
      <div className={styles.lastOrderInfo}>
        <Text className={styles.lastOrderInfoHeader}>
          Latest Order
        </Text>
        <Text className={styles.lastOrderInfoOrderId}>
          Order ID:
          {' '}
          {order?.refNo}
        </Text>
        <Text
          className={styles.lastOrderInfoStatus}
          style={{ color: status?.color }}
        >
          {' \u2022 '}
          {/* TODO: retrieve status from helper */}
          {status?.readableText2}
        </Text>
      </div>

      <div className={styles.checkoutSteps}>
        <Steps
          size={(isMobileWindow || isTabletWindow) ? 'small' : 'default'}
          direction="horizontal"
          // {/* TODO: retrieve current from helper */
          current={currentStep}
          responsive={false}
          labelPlacement="vertical"
        >
          {steps.map((item) => (
            <Steps.Step
              key={item.status}
              subTitle={(
                <Text className={styles.checkoutStepsSubTitle}>
                  {getStatusDate(item.status)}
                </Text>
                )}
              title={(
                <Text className={styles.checkoutStepsTitle}>
                  {item.readableText}
                </Text>
                )}
            />
          ))}
        </Steps>
      </div>
      <div className={styles.lastOrderCurrent}>
        <Text className={styles.lastOrderCurrentHeader}>
          {status?.readableText}
        </Text>
        <Text className={styles.lastOrderCurrentDate}>
          {getStatusDate(status?.status)}
        </Text>
      </div>
    </div>
  );
};

LastOrderCard.defaultProps = {
  estimateDelivery: true,
  order: undefined,
};
export default LastOrderCard;
