import React, { ReactNode } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import styles from './styles.module.css';

export interface ILinkProps {
  children?: ReactNode;
  className?: string;
  external?: boolean
  href: string;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  style?: any;
}

const Link = ({
  children,
  className,
  external,
  href,
  onClick,
  ...rest
}: ILinkProps) => (
  <ReactRouterDomLink
    onClick={(e) => onClick && onClick(e)}
    target={external ? '_blank' : '_self'}
    to={external ? { pathname: href } : href}
    className={[styles.link, className].join(' ')}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </ReactRouterDomLink>
);

Link.defaultProps = {
  children: undefined,
  className: undefined,
  external: false,
  onClick: undefined,
  style: undefined,
};

export default Link;
