import CheckoutSummaryCard from './CheckoutSummaryCard';
import LastOrderCard from './LastOrderCard';
import LatestNewsCard from './LatestNewsCard';
import OrderSummaryCard from './OrderSummaryCard';
import PreviousOrderCard from './PreviousOrderCard';
import ProductCard from './ProductCard';
import DownloadOurApp from './DownloadOurApp';
import TeamCard from './TeamCard';
import OrderCard from './OrdersCard';
import CatalogueCard from './CatalogueCard';
import DeliveryCard from './DeliveryCard';

const Cards = {
  CatalogueCard,
  CheckoutSummaryCard,
  DeliveryCard,
  LastOrderCard,
  LatestNewsCard,
  OrderSummaryCard,
  PreviousOrderCard,
  ProductCard,
  DownloadOurApp,
  TeamCard,
  OrderCard,
};

export default Cards;
