import React from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import styles from './styles.module.css';

interface IInputProps extends AntdInputProps{
  className?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  rounded?: boolean;
}

const Input = ({
  className,
  name,
  placeholder,
  required,
  rounded,
  ...rest
}: IInputProps) => (
  <AntdInput
    className={[
      styles.input,
      className,
      rounded && styles.inputRounded,
    ].join(' ')}
    name={name}
    placeholder={placeholder}
    required={required}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

Input.defaultProps = {
  className: undefined,
  name: undefined,
  placeholder: undefined,
  required: false,
  rounded: false,
};

export default Input;
