import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Spin from '../../components/Spin/Spin';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import useQuery from '../../utils/hooks/useQuery';
import styles from './styles.module.css';

const ExitAfterMilliSeconds = 30_000;

const PaystackCallback = observer(() => {
  const { currentClient } = useAuthContext();
  const { cartStore, orderStore } = stores;

  const history = useHistory();
  const query = useQuery();

  const handleCallbackReference = async () => {
    if (!currentClient) {
      history.push('/');
      return;
    }

    const reference = query.get('reference');
    if (!reference) {
      history.push('/account/login)');
      return;
    }
    await orderStore.loadPreviousOrders(currentClient.id, true);
    const order = orderStore.previousOrders?.orders.find((x) => x.id === reference);
    if (!order) {
      console.error(`paystack callback error: could not confirm order ${reference}`);
    }

    // clear cart
    if (cartStore.cartItems.value.data.length > 0) {
      await cartStore.clearCart(currentClient.id);
    }

    history.push('/account/orders');
  };

  useEffect(() => {
    if (currentClient) {
      handleCallbackReference();
    }
  }, [currentClient, cartStore.cartItems.isLoading]);

  useEffect(() => {
    const exitTimeout = setTimeout(() => {
      history.push(currentClient ? '/account/orders' : '/account/login');
    }, ExitAfterMilliSeconds);
    return () => {
      clearTimeout(exitTimeout);
    };
  }, []);

  return (
    <main id="paystack-callback">
      <Helmet>
        <title>Payments - G. Fox</title>
      </Helmet>
      <Spin
        spinning
        tip="Processing order..."
      >
        <div className={styles.spinnerCon} />
      </Spin>
    </main>
  );
});

export default PaystackCallback;
