import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import styles from './styles.module.css';

interface TextProps {
  children?: ReactNode;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties
}

const Text = ({
  children,
  className,
  onClick,
  style,
}: TextProps) => (
  <Typography.Text
    className={[styles.text, className].join(' ')}
    onClick={onClick}
    style={style}
  >
    {children}
  </Typography.Text>
);

Text.defaultProps = {
  children: undefined,
  className: undefined,
  onClick: undefined,
  style: undefined,
};

export default Text;
