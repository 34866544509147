import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import styles from './styles.module.css';

interface IParagraphProps {
  children?: ReactNode;
  className?: string;
}

const Paragraph = ({ children, className }: IParagraphProps) => (
  <Typography.Paragraph
    className={[styles.paragraph, className].join(' ')}
  >
    {children}
  </Typography.Paragraph>
);

Paragraph.defaultProps = {
  children: undefined,
  className: undefined,
};

export default Paragraph;
