import React from 'react';
import Typography from '../../components/Typography';
import styles from './styles.module.css';

const { Paragraph, Text } = Typography;

// added "data" to store the html content

export interface IDescriptionTab{
  description?: string;
  items: {
      name: string;
      details: string[];
  }[];
  htmlContent: string | null | undefined;
}

const DescriptionTab = ({
  description,
  items,
  htmlContent,
}: IDescriptionTab) => (
  <div className={styles.detailsDescCon}>
    <Paragraph className={styles.detailsDescText}>
      {description}
    </Paragraph>

    {React.Children.toArray(
      items.map((sItem: any) => (
        <>
          <Text className={styles.detailsDescSubHeader}>
            {sItem.name}
          </Text>
          {sItem.details.map((detail: any) => (
            <Text
              key={detail}
              className={styles.detailsDescItem}
            >
              {detail}
            </Text>
          ))}
        </>
      )),
    )}

    {/* Used dangerouslyHtml to render the html content inside "data" */}
    {htmlContent && (
      <div className={styles.detailsDescTab}>
        <div
          className={[styles.detailsDescHtmlContent].join(' ')}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
    )}
  </div>
);

DescriptionTab.defaultProps = {
  description: undefined,
};

export default DescriptionTab;
