import APIClient, {
  ICategorySpecificInfo, IInventoryMaster, ISkuMaster, LoadManager,
} from '@bridgelabsdesign/gfox-api-client';

import {
  action, computed, flow, makeObservable, observable,
} from 'mobx';
import { colorSelection, findSizingIndex } from '../utils/productSelection';
import { compareStrings, isEmptyString } from '../utils/strings';

export type ProductSortByType =
  'default' |
  // TODO: to be re enabled
  // 'popularity' |
  // 'latest' |
  'price_low' |
  'price_high';

export type FilterColorType = {
  name: string;
  hex: string;
};

export type FilterByType = {
  header: string;
  values: string[];
};

export interface ILoadInventoryListParams {
  active?: boolean;
  parentSku?: string;
  skuList?: string;
  withPrice?: boolean;
}

class InventoryStore {
  categoryRefNo = ''

  filterByInv = new LoadManager<IInventoryMaster>({ data: [] }, APIClient.InventoryV2.getInventoryMasters);

  filterBy: FilterByType[] = [];

  filterBySelectedValues: ICategorySpecificInfo[] = [];

  selectedColors: string[] = [];

  searchQuery = '';

  searchId = 0;

  searchResult = new LoadManager<ISkuMaster>({ data: [] }, APIClient.SKUMasterV2.getSkuMasters);

  complementaryProducts = new LoadManager<ISkuMaster>({ data: [] }, APIClient.SKUMasterV2.getSkuMasters);

  sortBy: ProductSortByType = 'default';

  isLoadingProducts = false;

  defaultPageSize = 15;

  /**
  * Sets defaults select options (Colors, Size, etc.) where available
  */
  autoSelectFilters = false;

  constructor() {
    makeObservable(this, {
      categoryRefNo: observable,
      filterByInv: observable,
      searchResult: observable,
      complementaryProducts: observable,
      searchQuery: observable,
      filterBy: observable,
      filterByColors: computed,
      filterBySelectedValues: observable,
      selectedColors: observable,
      sortBy: observable,
      filteredSearchResults: computed,
      isLoading: computed,
      setFilterBySelectedValues: action,
      setSelectedColors: action,
      setCategoryRefNo: action,
      loadFilterBy: action,
      setSearchQuery: action,
      setSortBy: action,
      loadSearchResults: flow,
      loadFilterByInv: flow,
      loadSkuMasters: action,
      autoSelectFilters: observable,
      isLoadingProducts: observable,
      pagination: computed,
    });
  }

  get pagination() {
    return this.complementaryProducts.value.pagination ?? {
      PageNumber: 1,
      PageSize: this.defaultPageSize,
      TotalPages: 1,
      TotalRecords: 1,
    };
  }

loadSkuMasters = async (queryParameters?: string) => {
  this.isLoadingProducts = true;
  try {
    const response = await APIClient.SKUMasterV2.getSkuMasters(queryParameters);
    this.complementaryProducts.value.data = response.data;
    this.complementaryProducts.value = {
      data: response.data,
      pagination: response.pagination,
    };
  } catch (error: any) {
    this.complementaryProducts.error = error;
  }
  this.isLoadingProducts = false;
};

get filteredSearchResults(): ISkuMaster[] {
  // eslint-disable-next-line no-unused-vars
  const searchResultIds: { [T: string]: boolean } = {};
  const filterByInvValues = this.filterByInv.value.data.slice();

  for (let i = 0; i < filterByInvValues.length; i += 1) {
    const element = filterByInvValues[i];

    // filter by select color
    let colorSelectable = true;
    if (this.selectedColors.length > 0) {
      const colorInfo = element?.categorySpecificInfo?.find((x) => compareStrings(x.name, 'colour') || compareStrings(x.name, 'color'));
      colorSelectable = this.selectedColors.some((x) => x === colorInfo?.value) ?? false;
    }
    // filter by other selected values e.g. Size, Packaging, etc.
    let otherValuesSelectable = true;
    if (this.filterBySelectedValues.length > 0 && element?.categorySpecificInfo) {
      otherValuesSelectable = this.filterBySelectedValues?.some((x) => element?.categorySpecificInfo
        .some((c) => compareStrings(x.name, c.name) && compareStrings(x.value?.toString(), c.value?.toString())));
    }

    if (otherValuesSelectable && colorSelectable && !isEmptyString(element?.skuId)) {
      searchResultIds[element.skuId as string] = true;
    }
  }

  // sort items
  const results = this.searchResult.value.data.filter((x) => searchResultIds[x.id]);
  if (this.sortBy === 'price_low') {
    results.sort((a, b) => (b.masterStock?.listPriceWithDiscount ?? 0)
      - (a.masterStock?.listPriceWithDiscount ?? 0));
  } else if (this.sortBy === 'price_high') {
    results.sort((a, b) => (a.masterStock?.listPriceWithDiscount ?? 0)
      - (b.masterStock?.listPriceWithDiscount ?? 0));
  }

  return results;
}

get filterByColors() {
  const selectionValues = colorSelection(this.filterByInv.value.data);
  if (this.autoSelectFilters && selectionValues.length === 1) {
    this.selectedColors.push(selectionValues[0].name);
  }

  return selectionValues;
}

get isLoading(): boolean {
  return this.searchResult.isLoading;
}

loadFilterBy() {
  const ret: { header: string, values: string[] }[] = [];
  const filterInfo = this.filterByInv.value.data
    .reduce<ICategorySpecificInfo[]>((prev, curr) => prev.concat(curr.categorySpecificInfo), []);
  const cachedValues: { [T: string]: any } = {};

  for (let i = 0; i < filterInfo.length; i += 1) {
    const element = filterInfo[i];
    if (isEmptyString(element?.name) || compareStrings(element.name, 'colour') || compareStrings(element.name, 'color')) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (cachedValues[element.name] === undefined) {
      cachedValues[element.name] = ret.length;
      ret[ret.length] = {
        header: element.name.trim(),
        values: [element.value.toString()],
      };
    } else {
      const retIndex = cachedValues[element.name];
      const valueExists = ret[retIndex].values.some((x) => x === element.value);
      if (!valueExists) {
        ret[retIndex].values = [...ret[retIndex].values, element.value.toString()];
      }
    }
  }

  for (let i = 0; i < ret.length; i += 1) {
    // set default options where only one selection is available
    if (this.autoSelectFilters && ret[i].values.length === 1) {
      this.setFilterBySelectedValues({ name: ret[i].header, value: ret[i].values[0] });
    }

    // sort sizes, number, etc.
    if (compareStrings(ret[i].header, 'Size')) {
      ret[i].values = ret[i].values.sort((a, b) => findSizingIndex(a) - findSizingIndex(b));
    } else if (typeof ret[i].values[0] === 'number') {
      ret[i].values = ret[i].values.sort((a, b) => Number(a) - Number(b));
    } else {
      ret[i].values = ret[i].values.sort((a, b) => a.localeCompare(b));
    }
  }

  this.filterBy = ret;
}

setCategoryRefNo(refNo: string) {
  this.selectedColors = []; // reset selected values to non
  this.filterBySelectedValues = []; // reset selected values to non
  this.categoryRefNo = refNo;
}

setSearchQuery(s: string, refs:string) {
  this.searchQuery = s;
  this.searchId += 1;
  const id = this.searchId;
  setTimeout(() => {
    if (id === this.searchId) {
      this.loadSearchResults({
        search: s,
        withPrice: true,
        categoryRefNo: refs,
      });
    }
  }, 600);
}

setSortBy(value: ProductSortByType) {
  this.sortBy = value;
}

setFilterBySelectedValues(v: ICategorySpecificInfo) {
  const selectedValues = this.filterBySelectedValues.slice();
  const isSelected = selectedValues.some((x) => x.name === v.name && x.value === v.value);

  const ret = selectedValues.filter((x) => x.name !== v.name);
  if (!isSelected) {
    ret.push(v);
  }
  this.filterBySelectedValues = ret;
}

isFilterByValuesSelected(v: ICategorySpecificInfo): boolean {
  return this.filterBySelectedValues.some((x) => x.name === v.name && x.value === v.value);
}

setSelectedColors(values: string) {
  let ret = this.selectedColors.slice();
  const itemInList = ret.some((x) => x === values);
  if (!itemInList) {
    ret = [values];
  } else {
    ret = ret.filter((x) => x !== values);
  }
  this.selectedColors = ret;
}

isSelectedColour(value: string): boolean {
  return this.selectedColors.some((x) => x === value);
}

* loadSearchResults({
  active = true, withPrice = true, skuList, categoryRefNo = undefined,
}: any) {
  let queryParams = `search=${this.searchQuery}&`;
  queryParams += `active=${active ? 'true' : 'false'}&`;
  queryParams += `categoryRefNo=${categoryRefNo ?? this.categoryRefNo ?? ''}&`;
  queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
  queryParams += `skuList=${skuList ?? ''}`;
  yield this.searchResult.fetch(queryParams);
  if (!isEmptyString(this.searchQuery) && this.searchResult.value?.data?.length === 0) {
    this.searchResult.error = new Error('inventory search error: could not find searched item');
    return;
  }
  const parentSku = this.searchResult.value.data.reduce((prev, curr) => `${prev},${curr.sku}`, '');
  yield this.loadFilterByInv({ parentSku, withPrice });
  yield this.loadFilterBy();
}

* loadFilterByInv({
  active = true, parentSku, skuList, withPrice = true,
}: ILoadInventoryListParams) {
  let queryParams = '';
  queryParams += `active=${active ? 'true' : 'false'}&`;
  queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
  queryParams += parentSku ? `parentSku=${parentSku ?? ''}&` : '';
  queryParams += skuList ? `skuList=${skuList ?? ''}` : '';
  yield this.filterByInv.fetch(queryParams);
}
}

export default InventoryStore;
