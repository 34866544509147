/* eslint-disable import/prefer-default-export */

import { CaptureConsole, HttpClient } from '@sentry/integrations';
import * as Sentry from '@sentry/browser';

export const init = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const environment = process.env.REACT_APP_ENVIRONMENT ?? 'unknown';
  const tracesSampleRate = environment !== 'environment' ? 0.2 : 1.0;

  Sentry.init({
    dsn: 'https://4385d5ef1222c402fe563092f4f86fff@o1240590.ingest.sentry.io/4506035742375936',
    environment,
    integrations: [
      new CaptureConsole({
        levels: ['warn', 'error', 'debug', 'assert'],
      }),
      new HttpClient(),
      new Sentry.BrowserTracing({}),
      new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
