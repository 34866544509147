import APIClient, { ISurvey, LoadManager } from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import {
  action, makeObservable, observable,
} from 'mobx';

class SurveyStore {
  // eslint-disable-next-line max-len
  surveys = new LoadManager<ISurvey>({ data: [] }, APIClient.Survey.getSurvey, undefined, undefined, APIClient.Survey.deleteSurvey)

  currentSurvey: ISurvey | null = null;

  surveyLoaded:boolean = false;

  constructor() {
    makeObservable(this, {
      loadSurvey: action,
      surveys: observable,
      surveyLoaded: observable,
      currentSurvey: observable,
    });
  }

  // Load survey from api
  async loadSurvey() {
    await this.surveys.fetch();
    this.surveyLoaded = true; // and this line
  }

  // Load a specific survey by ID from API
  // eslint-disable-next-line class-methods-use-this
  async loadSurveyById(id: string) {
    try {
      const response = await APIClient.Survey.getSurveyById(id);
      return response;
    } catch (error) {
      message.error('Survey could not be fetched');
    }
    return undefined;
  }
}

export default SurveyStore;
