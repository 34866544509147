import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from 'antd';
import { IInventoryMaster, IOrder } from '@bridgelabsdesign/gfox-api-client';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Typography from '../../components/Typography';
import Images from '../../assets';
import styles from './styles.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';
import stores from '../../stores/stores';
import Cards from '../../components/Cards';
import Tables from '../../components/Tables';
import { useAuthContext } from '../../context/AuthContext';
import { copyObjects } from '../../utils/copyObjects';
import { CheckoutPath } from '../../utils/checkout';

const { Title, Text } = Typography;

const AccountDashboard = observer(() => {
  const [previousOrderInv, setPreviousOrderInv] = useState<IInventoryMaster[]>([]);

  const { currentClient } = useAuthContext();
  const { isMobileWindow, isTabletWindow } = useWindowSize();
  const { cartStore, quotesStore, orderStore } = stores;

  const lastOrder = orderStore.previousOrders?.orders[0];

  const history = useHistory();

  const tabInfo = [
    {
      name: 'Quotations',
      component: (
        <Tables.QuotesSummaryTable
          quotes={quotesStore.quotes.value.data.slice()}
        />
      ),
    },
    {
      name: 'Orders',
      component: (
        <Tables.OrdersSummaryTable
          orders={orderStore.previousOrders?.orders.slice() ?? []}
        />
      ),
    },
  ];

  const handleGetLastOrder = async () => {
    await orderStore.loadPreviousOrders(currentClient?.id, true);
    const order = orderStore.previousOrders?.orders[0];
    const inv = orderStore.previousOrders?.inventoryMasters.slice();

    const items = order?.orderInfo.map((x) => inv?.find((y) => x.sku === y.sku))
      .filter((x) => x !== undefined) as (IInventoryMaster[] | undefined);
    setPreviousOrderInv(items ?? []);
  };

  const handleOnReorderClick = async (order?: IOrder) => {
    if (!order) {
      return;
    }
    const orderInv = order.orderInfo.map<IInventoryMaster>((x) => {
      const inv = orderStore.previousOrders?.inventoryMasters?.find((i) => i.sku === x.sku);
      return copyObjects(inv, { orderQty: x?.orderQty ?? 1 });
    });
    const res = await cartStore.onReorder(currentClient?.id!, orderInv ?? []);
    if (res) {
      history.push(CheckoutPath.CART);
    }
  };

  useEffect(() => {
    if (currentClient?.id) {
      handleGetLastOrder();
      quotesStore.loadQuotes(currentClient?.id);
    }
  }, [currentClient?.id]);

  return (
    <main id="account-dashboard">
      <Helmet>
        <title>Dashboard - G. Fox</title>
      </Helmet>

      <Title className={styles.welcomeText} level={3}>
        {`Welcome Back ${currentClient?.userAuth?.firstName ?? ''} ${currentClient?.userAuth?.lastName}`}
      </Title>
      <Text className={styles.welcomeSummaryText}>
        Here’s a summary of what’s been happening
      </Text>

      <img
        className={styles.bannerItem}
        src={isMobileWindow ? Images.ComingSoonBanner : Images.ComingSoonBanner}
        alt="Product Banner"
      />

      <Cards.LastOrderCard
        order={lastOrder}
      />

      <div className={styles.dashboardContent}>
        <Cards.PreviousOrderCard
          className={styles.previousOrderCon}
          invItem={previousOrderInv.slice()}
          order={lastOrder}
          onReorderClick={() => handleOnReorderClick(lastOrder)}
        />

        {/* TODO: link to specials page */}
        <img
          className={styles.specialsImage}
          src={Images.BlackFridayCleaningMaterialsBanner}
          alt="Black Friday Special"
        />

        {/* TODO: fetch new obj */}
        <Cards.LatestNewsCard
          className={styles.previousOrderCon}
          headerTitle="Coronavirus Essential Equipment for Schools"
          // eslint-disable-next-line max-len
          headlineContent="The type of cleaning products and safety equipment that will be in high demand once schools reopen formally include infared thermometers, hand sanitiser, face masks, gloves as well as other various COVID related products."
          headlineDate="12 Aug 2022"
          writtenBy="by Nelly Mkhize"
          onViewMore={() => { }}
          type={isTabletWindow ? 'default' : 'flex'}
          imageSrc={Images.KidsSocialDistancingClassroom}
        />
      </div>

      <div className={styles.dashboardContent2}>
        <Cards.DownloadOurApp
          className={styles.previousOrderCon}
        />
        <Tabs
          className={styles.tabInfoCon}
        >
          {tabInfo.map((item) => (
            <Tabs.TabPane
              key={item.name}
              tab={(
                <Text className={styles.detailsHeaderText}>
                  {item.name}
                </Text>
              )}
            >
              {item.component}
            </Tabs.TabPane>
          ))}
        </Tabs>

        <div className={styles.bankingInfoCon}>
          <Text className={styles.bankingInfoHeader}>
            Banking Details
          </Text>
          <div>
            <Text className={styles.bankingInfoSubHeader}>
              G. Fox Branch:
              {' '}
            </Text>
            <Text className={styles.bankingInfoValue}>
              Johannesburg
            </Text>
          </div>
          <div>
            <Text className={styles.bankingInfoSubHeader}>
              Bank:
              {' '}
            </Text>
            <Text className={styles.bankingInfoValue}>
              Rand Merchant Bank
            </Text>
            <Text className={styles.bankingInfoValueExtra}>
              {`- ${currentClient?.branch?.branchName}` ?? ''}
            </Text>
          </div>
          <div>
            <Text className={styles.bankingInfoSubHeader}>
              Branch Code:
              {' '}
            </Text>
            <Text className={styles.bankingInfoValue}>
              255005
            </Text>
          </div>
          <div>
            <Text className={styles.bankingInfoSubHeader}>
              Account Number:
              {' '}
            </Text>
            <Text className={styles.bankingInfoValue}>
              62261931101
            </Text>
          </div>
          <div>
            <Text className={styles.bankingInfoSubHeader}>
              SWIFT Code:
              {' '}
            </Text>
            <Text className={styles.bankingInfoValue}>
              FIRNZAJJ
            </Text>
          </div>
        </div>

        <img
          className={styles.specialsImage}
          src={Images.BlackFridayCleaningMaterialsBanner}
          alt="Black Friday Special"
        />
      </div>
    </main>
  );
});

export default AccountDashboard;
