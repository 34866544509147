import React, { } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { BsCircleFill } from 'react-icons/bs';
import { ICategorySpecificInfo } from '@bridgelabsdesign/gfox-api-client';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import { FilterByType, FilterColorType } from '../../stores/inventory';

const { Text } = Typography;

interface IProductFilterBy {
  colors: FilterColorType[];
  filterBy: FilterByType[];
  // eslint-disable-next-line no-unused-vars
  onSelectColour(value: string): void;
  // eslint-disable-next-line no-unused-vars
  onSelectFilterBy(values: ICategorySpecificInfo): void;
  // eslint-disable-next-line no-unused-vars
  isSelectedColour(name: string): boolean;
  // eslint-disable-next-line no-unused-vars
  isFilterByValuesSelected(v: ICategorySpecificInfo): boolean;
}

const ProductFilterBy = observer(({
  colors,
  filterBy,
  onSelectColour,
  onSelectFilterBy,
  isSelectedColour,
  isFilterByValuesSelected,
  // eslint-disable-next-line arrow-body-style
}: IProductFilterBy) => {
  return (
    <>
      <div>
        {filterBy.length > 0 && (
        <Text
          className={[
            styles.filterMenuHeader,
            styles.filterMenuHeaderFilterBy,
          ].join(' ')}
        >
          Filter by
        </Text>
        )}
        {filterBy.map((opts) => (
          <>
            <Text className={styles.filterMenuFilterBySubHeader}>
              {opts.header}
            </Text>
            <div className={styles.filterMenuByItemsCon}>
              {React.Children.toArray(
                opts.values.map((item) => (
                  <Text
                    key={item}
                    className={[
                      styles.filterMenuFilterByItems,
                      isFilterByValuesSelected({ name: opts.header, value: item }) && styles.filterMenuFilterByItemsSelected,
                    ].join(' ')}
                    onClick={() => onSelectFilterBy({ name: opts.header, value: item })}
                  >
                    <span>{item}</span>
                    {/* TODO: calc length of fetched items not in view <span>{filterOptions.length}</span> */}
                  </Text>
                )),
              )}
            </div>
          </>
        ))}
      </div>

      {colors.length > 0 && (
      <Text className={styles.filterMenuFilterBySubHeader}>
        Colours
      </Text>
      )}
      <div className={styles.filterMenuColorCon}>
        {React.Children.toArray(
          colors.map((item) => (
            <Tooltip
              title={item.name}
            >
              <span
                className={[
                  styles.filterMenuColor,
                  isSelectedColour(item.name) && styles.filterMenuColorActive,
                ].join(' ')}
                onClick={() => onSelectColour(item.name)}
                aria-hidden
              >
                <BsCircleFill
                  className={styles.filterMenuColorIcon}
                  fill={item.hex}
                />
              </span>
            </Tooltip>
          )),
        )}
      </div>
    </>
  );
});

export default ProductFilterBy;
