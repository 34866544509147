import { observer } from 'mobx-react-lite';
import React from 'react';
import Icon from '@ant-design/icons';
import stores from '../../stores/stores';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Images from '../../assets';

const { Link } = Typography;

const TechnicalTab = observer(() => {
  const { cartStore } = stores;

  const technicalSpecFiles = cartStore.currentSkuItem?.technicalSpecificationFiles;

  return (
    <div className={styles.guidelinesTabCon}>
      <br />
      <div className={styles.guidelinesIconCon}>
        {technicalSpecFiles?.map((item) => (
          <>
            <Link key={item} external href={item}>
              <Icon
                className={styles.pdfIcon}
                component={Images.PdfIcon}
              />
            </Link>
          </>
        ))}
      </div>
    </div>
  );
});

export default TechnicalTab;
