import CheckoutStore from './checkout';
import InventoryStore from './inventory';
import OrderStore from './order';
import UserAuth from './userAuth';
import CategoryStore from './category';
import SearchStore from './search';
import CartStore from './cart';
import DeliveryStore from './delivery';
import AddressStore from './address';
import PayStore from './pay';
import QuoteStore from './quote';
import ClientStore from './client';
import BranchStore from './branch';
import EmailTokenStore from './email-token';
import AuthStore from './auth';
import LinkedAccountStore from './linked-account';
import RatingStore from './rating';
import VoucherStore from './voucher';
import WishStore from './wish';
import ChatStore from './chat';
import SpecialStore from './specials';
import SurveyStore from './survey';
import SurveyResponseStore from './survey-response';
import ContactStore from './contact';
import ContentSliderStore from './content-slider';
import { ImageMapStore } from './image-map';
import CustomPageStore from './custom-pages';
import FavoritesStore from './favorites';

export const stores = {
  addressStore: new AddressStore(),
  cartStore: new CartStore(),
  checkoutStore: new CheckoutStore(),
  clientStore: new ClientStore(),
  constactStore: new ContactStore(),
  deliveryStore: new DeliveryStore(),
  linkedAccountsStore: new LinkedAccountStore(),
  inventoryStore: new InventoryStore(),
  productSelectStore: new InventoryStore(),
  orderStore: new OrderStore(),
  quotesStore: new QuoteStore(),
  userAuthStore: new UserAuth(),
  categoryStore: new CategoryStore(),
  searchStore: new SearchStore(),
  specialsStore: new SpecialStore(),
  payStore: new PayStore(),
  branchesStore: new BranchStore(),
  emailTokenStore: new EmailTokenStore(),
  authStore: new AuthStore(),
  rating: new RatingStore(),
  surveyStore: new SurveyStore(),
  surveyResponseStore: new SurveyResponseStore(),
  vouchersStore: new VoucherStore(),
  wishStore: new WishStore(),
  contentSliderStore: new ContentSliderStore(),
  chatStore: new ChatStore(),
  ImageMapStore: new ImageMapStore(),
  CustomPageStore: new CustomPageStore(),
  favoriteStore: new FavoritesStore(),
};

export default stores;
