import React from 'react';
import ReactBarcode from 'react-barcode';

interface BarcodeProps {
  text: string;
}

const Barcode: React.FC<BarcodeProps> = ({ text }) => (
  <ReactBarcode
    value={text}
    width={1.5}
    height={50}
    format="CODE128"
    displayValue
    fontOptions=""
    font="monospace"
    textAlign="center"
    textPosition="bottom"
    textMargin={1}
    fontSize={10}
    background="#ffffff"
    lineColor="#000000"
    margin={4}
  />
);

export default Barcode;
