/* eslint-disable no-unused-vars */
enum MediaQuerySizes {
  MOBILE_EM = 43,
  MOBILE_PX = 688,
  TABLET_EM = 62,
  TABLET_PX = 992,
  LARGER_EM = 82,
  LARGER_PX = 1312,
}

export default MediaQuerySizes;
