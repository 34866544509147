import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { IRatingModel } from '@bridgelabsdesign/gfox-api-client';
// import { Modal } from 'antd';
import { Modal } from 'antd';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import AverageRatingCard from '../../components/Cards/RatingCard';
import ReviewCard from '../../components/Cards/ReviewCard';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import { formatDate } from '../../utils/strings';
import PrimaryBtn from '../../components/Buttons/PrimaryBtn';

const { Text } = Typography;

const RatingsTab = observer(() => {
  const { rating, cartStore } = stores;
  const [reviews, setReviews] = useState<IRatingModel[]>([]);
  const [visibleReviews, setVisibleReviews] = useState(3);
  const { currentClient } = useAuthContext();

  const loadMoreReviews = () => {
    setVisibleReviews(visibleReviews + 3);
  };

  useEffect(() => {
    const fetchRatings = async () => {
      if (currentClient?.id && cartStore.sku?.id) {
        try {
          await rating.fetchRatingsBySkuMaster(cartStore.sku?.id, currentClient?.id);
          if (rating.ratings && rating.ratings.length) {
            setReviews(rating.ratings);
          }
        } catch (error) {
          console.error('Error fetching ratings:', error);
        }
      }
    };
    fetchRatings();
  }, [cartStore.sku?.id, currentClient?.id]);

  const handleDeleteReview = async (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this review?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        const isSuccess = await rating.deleteRating(id);
        if (isSuccess) {
          const updatedReviews = reviews.filter((review) => review.id !== id);
          setReviews(updatedReviews);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div className={styles.guidelinesTabCon}>
      <Text className={styles.detailsHeaderText}>
        Ratings and Reviews
      </Text>
      <div className={styles.cardsContainer}>
        <AverageRatingCard />
        {reviews.slice(0, visibleReviews).map((review) => (
          <ReviewCard
            key={review.id}
            reviewerName={review.firstName}
            rating={review.value}
            comment={review.comment}
            date={formatDate(review.createdAt ? review.createdAt as any : '', true)}
            isClientReview={review.clientId === currentClient?.id}
            onDelete={() => handleDeleteReview(review.id)}
          />
        ))}
        {visibleReviews < reviews.length && (
        <PrimaryBtn onClick={loadMoreReviews} className={styles.viewMoreBtn}>
          View more
        </PrimaryBtn>
        )}

      </div>
    </div>
  );
});

export default RatingsTab;
