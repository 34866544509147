import React, { useMemo } from 'react';
import { calcProductPrice, formatPrice, IProductPricing } from '../../utils/pricing';
import styles from './pricing.module.css';
import Typography from '../Typography';

interface IPricingInformation {
  pricing?: IProductPricing | null;
  lineItems?: number;
  className?: string;
  textClassName?: string;
  text2ClassName?: string;
  label1ClassName?: string;
  showExclVAT?: boolean;
}

const { Text } = Typography;

const PricingInformation = ({
  pricing,
  lineItems,
  className,
  textClassName,
  text2ClassName,
  label1ClassName,
  showExclVAT = true, // Add this with a default value of true
} : IPricingInformation) => {
  const pricingInfo = useMemo(() => {
    if (pricing === undefined || pricing == null) {
      return undefined;
    }
    return calcProductPrice(pricing, lineItems);
  }, [pricing, lineItems]);

  return (
    <div className={[styles.con, className].join(' ')}>
      <Text className={[styles.pricingText, textClassName].join(' ')}>
        {pricingInfo?.excludingTax ? formatPrice(pricingInfo?.excludingTax) : ' '}
      </Text>
      <Text className={[styles.pricingTextOriginal, text2ClassName].join(' ')}>
        {pricingInfo?.crossOutPrice && formatPrice(pricingInfo?.crossOutPrice)}
      </Text>
      {pricingInfo && showExclVAT && (
        <Text className={[styles.pricingTextExcludingVAT, text2ClassName].join(' ')}>
          excl VAT
        </Text>
      )}
      {(pricing?.masterStock?.isAppPrice) && (
        <Text className={[styles.ecommerceText, label1ClassName].join(' ')}>
          E-commerce pricing
        </Text>
      )}
    </div>
  );
};

PricingInformation.defaultProps = {
  pricing: undefined,
  lineItems: 1,
  className: undefined,
  textClassName: undefined,
  text2ClassName: undefined,
  label1ClassName: undefined,
  showExclVAT: true,
};

export default PricingInformation;
