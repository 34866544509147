import APIClient, { IContact } from '@bridgelabsdesign/gfox-api-client';
import { action, makeObservable, observable } from 'mobx';
import { message } from 'antd';

class ContactStore {
     ContactResponses: IContact[] | undefined;

     constructor() {
       makeObservable(this, {
         ContactResponses: observable,
         postContactResponse: action,
       });
     }

     // eslint-disable-next-line class-methods-use-this
     async postContactResponse(contactResponseData:IContact, contactItems:IContact[]): Promise<boolean> {
       const hide = message.loading('Submitting your enquiry response...');
       let isSuccess = false;
       try {
         await APIClient.Contact.sendContactForm(contactResponseData, contactItems);
         isSuccess = true;
         message.success('Enquiry submitted successfully!');
       } catch (error) {
         console.error('Error submitting response:', error);
         message.error('Could not submit your enquiry. Please try again.');
       }
       hide();
       return isSuccess;
     }
}
export default ContactStore;
