import React, { useState } from 'react';
import {
  Form as AntdForm, FormInstance, message, Modal, Spin,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { FiSearch } from 'react-icons/fi';
import stores from '../../stores/stores';
import styles from './styles.module.css';
import Form from '../../components/Form';
import Typography from '../../components/Typography';
import { addressForm } from '../../utils/form-helpers/address-form';
import useWindowSize from '../../utils/hooks/useWindowSize';

const { Text } = Typography;

const AddressModal = observer(({
  isOpen,
  handleCancel,
  handleOk,
  form,
} : {
  isOpen: boolean,
  handleCancel(): void,
  handleOk(): void,
  form: FormInstance,
}) => {
  const [enteredAddress, setEnteredAddress] = useState(false);
  const { addressStore } = stores;
  const { isMobileWindow } = useWindowSize();

  const handleAddressSelect = (index: number) => {
    const filteredAddress = addressStore.searchResult[index];
    addressStore.setSelectedSearch(filteredAddress);
    form.setFieldsValue({
      address3: filteredAddress?.suburb,
      city: filteredAddress?.city,
      province: filteredAddress?.province,
      country: 'South Africa',
      postalCode: filteredAddress?.postalCode,
      hubCode: filteredAddress?.hubCode,
    });

    setEnteredAddress(true);
  };

  const handleOnOk = async () => {
    if (!enteredAddress) {
      handleOk();
      message.success('Delivery address added.');
    }
    try {
      await form.validateFields(['address1']);
      const errors = form.getFieldError('address1');
      if (errors.length > 0) {
        throw new Error('address1 field not valid ');
      }
      handleOk();
      message.success('Delivery address added.');
    } catch (err) {
      message.error('Please enter all required fields');
      console.error('address modal onOk err:', err);
    }
  };

  return (
    <Modal
      title="Add delivery address"
      visible={isOpen}
      onOk={handleOnOk}
      onCancel={handleCancel}
      width={!isMobileWindow ? '60%' : undefined}
    >
      <div>
        <Text className={styles.regsiterLabel}>
          Enter your postal code
        </Text>

        <Form.Select
          options={addressStore.searchResult.map((addr, idx) => ({
            value: idx,
            label: `${addr.suburb},  ${addr.city},  ${addr.province}.`,
          }))}
          onSelect={handleAddressSelect}
          // className={styles.select}
          showSearch
          loading={addressStore.isSearching}
          placeholder="Enter your postal code"
          onSearch={(v) => addressStore.setSearchQuery(v)}
          filterOption={false}
          showArrow={false}
          value={addressStore.searchQuery}
          notFoundContent={addressStore.isSearching ? <Spin size="small" /> : null}
          suffixIcon={<FiSearch />}
        />
      </div>

      {enteredAddress && (
        <div className={styles.inputcon}>
          {React.Children.toArray(
            addressForm.map((item) => (
              <div
                className={[
                  styles.registerFormCon,
                  item.formType === 'input' && styles.registerFormConInput,
                  item.formType === 'textarea' && styles.registerFormConTextArea,
                ].join(' ')}
              >
                {!item.hidden && (
                  <Text className={styles.registerLabel}>{item.label}</Text>
                )}
                <AntdForm.Item
                  name={item.name}
                  required={item.required}
                  rules={[{ required: item.required }, { whitespace: item.whitespace }]}
                  className={styles.regsiterFormItem}
                  hidden={item.hidden}
                >
                  {item.input}
                </AntdForm.Item>
              </div>
            )),
          )}
        </div>
      )}
    </Modal>
  );
});

export default AddressModal;
