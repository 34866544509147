import React, { useMemo } from 'react';
import {
  Empty, Image, Skeleton, Table,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { IWishList } from '@bridgelabsdesign/gfox-api-client';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Icon from '@ant-design/icons';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './checkout-item.module.css';
import { calcProductPrice, formatPrice } from '../../utils/pricing';
import stores from '../../stores/stores';
import Images from '../../assets';
import { useAuthContext } from '../../context/AuthContext';
import PricingInformation from '../Pricing/PricingInformation';
import { CheckoutPath } from '../../utils/checkout';
import { imgURLPrefix } from '../../config/image';

const { Link, Text } = Typography;

const ImageView = ({ isLoading, src }: {
  isLoading: boolean
  src: string,
}) => (
  <>
    {isLoading ? (
      <Skeleton
        active
        loading
        className={styles.carouselImgCon}
      />
    ) : (
      <Image
        src={`${imgURLPrefix}${src}`}
        alt="Product image"
        className={styles.cartItemImg}
        rootClassName={styles.cartItemImgCon}
        fallback={Images.FallbackImage}
      />
    )}
  </>
);

const CloseBtn = ({ wishId, edit }: { wishId:string, edit: boolean }) => {
  const { currentClient } = useAuthContext();
  const { wishStore } = stores;

  if (!edit) {
    return null;
  }
  return (
    <span className={styles.cartItemCloseIconCon}>
      <Buttons.PrimaryBtn
        type="text"
        icon={(
          <IoClose
            className={styles.cartItemCloseIcon}
          />
        )}
        onClick={() => wishStore.removeWishItem(currentClient?.id!, [wishId])}
      />
    </span>
  );
};

interface ICheckoutItemsTableProps {
  edit?: boolean,
  items?: IWishList[]
  loading?: boolean
}

const WishlistItemsTable = observer(({
  edit = true,
  items,
  loading = false,
}: ICheckoutItemsTableProps) => {
  const { currentClient } = useAuthContext();
  const { cartStore, wishStore } = stores;

  const history = useHistory();

  const dataSource = useMemo(() => {
    const src = items ?? wishStore.wishItems.value.data;
    return src.slice().sort((a, b) => b?.inventoryMasterId?.localeCompare(a?.inventoryMasterId));
  }, [items, wishStore.wishItems.value]);

  const isLoading = wishStore.wishItems.isLoading || dataSource.length === 0;

  const transferWishToCart = async (wishItems: IWishList[]) => {
    await wishStore.transferWishToCart(currentClient!, cartStore.cartItems.value.data, wishItems);
    await cartStore.loadCartItems(currentClient!.id);
    history.push(CheckoutPath.CART);
  };

  const tableColumns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'index',
      render: (text: string, record: IWishList, idx: number) => (idx + 1),
    },
    {
      title: '',
      dataIndex: 'imageSrc',
      key: 'imageSrc',
      width: '12%',
      render: (text: string, record: IWishList) => (
        <ImageView
          isLoading={loading ?? isLoading}
          src={record?.inventoryMaster?.productImages?.length > 0
            ? record?.inventoryMaster?.productImages[0]
            : Images.FallbackImage}
        />
      ),
    },
    {
      title: 'Product',
      dataIndex: 'sku',
      key: 'sku',
      render: (text: string, record: IWishList) => (
        isLoading || loading ? (
          <Skeleton
            active
            loading
            className={styles.cartTableProductInfo}
            paragraph={{ rows: 2 }}
          />
        ) : (
          <div className={styles.cartTableProductInfo}>
            <Text className={styles.cartItemName}>
              {record.inventoryMaster?.description}
            </Text>
            <div>
              {record.inventoryMaster?.categorySpecificInfo?.map((value) => (
                <span key={value.name} style={{ paddingRight: '1em' }}>
                  <Text className={styles.cartItemDetailsFieldName}>
                    {value.name}
                    { ': '}
                  </Text>
                  <Text className={styles.cartItemDetailsFieldValue}>
                    {value.value}
                  </Text>
                </span>
              ))}
            </div>
            <Text className={styles.cartItemDetailsSku}>
              {record.inventoryMaster?.sku}
            </Text>
          </div>
        )
      ),
    },
    {
      title: '',
      key: 'addToCart',
      render: (text: string, record: IWishList) => {
        const inv = wishStore.invList.value.data
          .find((x) => x.id === record.inventoryMasterId);
        if (!inv) {
          return null;
        }
        return (
          <>
            <PricingInformation
              className={styles.pricingInfo}
              pricing={{
                masterStock: inv.masterStock,
                accountPricing: inv.accountPricing,
              }}
            />
            <br />
          </>
        );
      },
    },
    {
      title: '',
      width: '8%',
      key: 'close',
      render: (a: string, record: IWishList) => (
        <div style={{ display: 'flex' }}>
          <span className={styles.cartItemCloseIconCon}>
            <Buttons.PrimaryBtn
              type="text"
              onClick={() => transferWishToCart([record])}
              icon={(
                <Icon
                  className={styles.cartItemCloseIcon}
                  component={Images.ShoppingCartIcon}
                  color="#d8232a"
                />
              )}
            />
          </span>
          <CloseBtn wishId={record.id} edit={edit} />
        </div>
      ),
    },
  ];

  const getInvItemPrice = (invSku: string, qty: number) => {
    const invItem = wishStore.invList.value.data.find((x) => x.sku === invSku);
    const price = calcProductPrice(invItem).excludingTax * qty;
    return price > 0 ? formatPrice(price) : '';
  };

  return (
    <>
      <div className={['checkout-items-table', styles.cartMobView].join(' ')}>
        {React.Children.toArray(
          dataSource.map((item) => (
            <div className={styles.cartItemCon}>
              <CloseBtn wishId={item.id} edit={edit} />
              <Text className={styles.cartItemName}>
                {item?.inventoryMaster?.description}
              </Text>
              <div className={styles.cartItemDetailsCon}>
                <ImageView
                  isLoading={isLoading || loading}
                  src={item?.inventoryMaster?.productImages?.length > 0
                    ? item?.inventoryMaster?.productImages[0]
                    : Images.FallbackImage}
                />
                <div className={styles.cartItemDetails}>
                  <Text className={styles.cartItemDetailsSku}>
                    {item.inventoryMaster?.sku}
                  </Text>
                  {item.inventoryMaster?.categorySpecificInfo?.map((value) => (
                    <div key={value.name}>
                      <Text className={styles.cartItemDetailsFieldName}>
                        {value.name}
                        {': '}
                      </Text>
                      <Text className={styles.cartItemDetailsFieldValue}>
                        {value.value}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.cartItemPriceCon}>
                <Buttons.CartCountBtn
                  quantity={item.quantity}
                  size="small"
                  showEditButton={edit}
                  onAddClick={() => wishStore.updateTowishItems(currentClient?.id!, item?.id, 1)}
                  onMinusClick={() => wishStore.updateTowishItems(currentClient?.id!, item?.id, -1)}
                />
                <Text className={styles.cartItemPrice}>
                  {getInvItemPrice(item.inventoryMaster?.sku ?? '', item.quantity)}
                </Text>
              </div>
            </div>
          )),
        )}
      </div>
      <div className={styles.cartTableView}>
        {/* TODO: format table header & add spacing between rows */}
        <Table
          dataSource={dataSource}
          columns={tableColumns}
          pagination={false}
          size="small"
          bordered={false}
          rowKey={(r) => r.id}
          rowClassName={styles.cartTableRow}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              wishStore.setSelectedItems(selectedRows);
            },
          }}
          locale={{
            emptyText: (
              <div className={styles.emptyListCon}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
                <Link
                  className={styles.emptyListShop}
                  href="/specials"
                >
                  Continue Shopping
                </Link>
              </div>
            ),
          }}
        />
      </div>

    </>
  );
});

export default WishlistItemsTable;
