import APIClient, { IAddress, IAddressHubCode, IClientAddress } from '@bridgelabsdesign/gfox-api-client';
import { getAddresses } from '@bridgelabsdesign/gfox-api-client/dist/client/address';
import { message } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { isEmptyString } from '../utils/strings';

export class AddressStore {
  selectedAddress?: IAddress | null;

  shippingAddress?: IAddress | null;

isSearching = false;

 addresses: IAddress[] = [];

searchId = 0;

searchQuery = '';

searchResult: IAddressHubCode[] = [];

selectedSearch?: IAddressHubCode | null = null;

constructor() {
  makeObservable(this, {
    addresses: observable,
    fetchAddresses: action,
    selectedAddress: observable,
    setSelectedAddress: action,
    setShippingAddress: action,
    setSelectedAddressFromClient: action,
    isSearching: observable,
    searchQuery: observable,
    searchResult: observable,
    selectedSearch: observable,
    setSearchQuery: action,
  });
}

setSelectedAddress(addr?: IAddress | null) {
  this.selectedAddress = addr;
}

setSelectedAddressFromClient(clientAddresses: IClientAddress[]) {
  let addr = clientAddresses.sort((a, b) => (
    moment(b.address?.createdAt).diff(moment(a.address?.createdAt))))
    .find((x) => x.isShippingAddres)?.address;

  if (!addr && clientAddresses.length > 0) {
    addr = clientAddresses[0]?.address;
  }
  this.selectedAddress = addr;
}

setSearchQuery(s: string) {
  this.searchQuery = s.trim();

  if (isEmptyString(this.searchQuery)) {
    this.isSearching = false;
    this.selectedSearch = null;
    this.selectedAddress = null;
    return;
  }
  if (this.selectedSearch) {
    this.selectedSearch = null;
    this.selectedAddress = null;
  }

  this.isSearching = true;
  setTimeout(() => {
    this.searchId += 1;
    const fecthId = this.searchId;
    const addr = { postalCode: this.searchQuery } as IAddress;
    APIClient.Address.searchAddress(addr)
      .then((res) => this.setSearchResults(fecthId, res.data));
  }, 800);
}

setSelectedSearch(s?: IAddressHubCode) {
  this.searchResult = [];
  this.isSearching = false;
  this.selectedSearch = s;
  this.setSelectedAddress(s ? {
    address3: this.selectedSearch?.suburb,
    city: this.selectedSearch?.city,
    province: this.selectedSearch?.province,
    country: 'South Africa',
    postalCode: this.selectedSearch?.postalCode,
    hubCode: this.selectedSearch?.hubCode,
  } as IAddress : null);
}

setShippingAddress(clientAddresses: IClientAddress[]) {
  console.log('here');
  const clientShippingAddress = clientAddresses.find((x) => x.isShippingAddres === true);
  this.shippingAddress = clientShippingAddress?.address ?? clientAddresses[0]?.address;
}

setSearchResults(fecthId: number, res: IAddressHubCode[]) {
  if (fecthId === this.searchId) {
    this.searchResult = res;
    this.isSearching = false;
  }
}

async fetchAddresses(clientId?: string) {
  try {
    const response = await getAddresses(`clientId=${clientId}`);
    this.addresses = response.data;
  } catch (error) {
    console.error(error);
  }
}

// eslint-disable-next-line
async addClientAddress(clientId: string, addr: IAddress): Promise<IClientAddress | undefined> {
  const body: IClientAddress = {
    clientId,
    address: {
      address1: addr.address1,
      address2: addr.address2,
      address3: addr.address3,
      hubCode: addr.hubCode,
      city: addr.city,
      country: addr.country,
      province: addr.province,
      postalCode: addr.postalCode,
    } as IAddress,
  } as IClientAddress;

  try {
    const updatedAddress = await APIClient.ClientAddress.addClientAddress(body, []);
    message.success('Your Address has been updated');
    return updatedAddress.data[0];
  } catch (error) {
    message.error('There was an error updating your Address');
  }
}
}

export default AddressStore;
