import React, { useState } from 'react';
import { Card, Rate, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Typography from '../Typography';
import styles from './ReviewCard.module.css';
import PrimaryBtn from '../Buttons/PrimaryBtn';

export interface IReviewCardProps {
  reviewerName: string;
  date: string;
  comment: string;
  rating: number;
  isClientReview: boolean;
  onDelete: () => void;
}

const { Text } = Typography;

const ReviewCard = ({
  reviewerName,
  date,
  comment,
  rating,
  isClientReview,
  onDelete,
}: IReviewCardProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const maxCharLimit = 100;

  const trimmedComment = comment.length > maxCharLimit
    ? `${comment.substring(0, maxCharLimit)}...`
    : comment;

  return (
    <div className={styles.container}>
      <Card title={reviewerName} className={styles.reviewCard}>
        <div className={styles.infoContainer}>
          <Rate allowHalf defaultValue={rating} disabled />
          <Text className={styles.comment}>
            {trimmedComment}
          </Text>
          <Text className={styles.date}>{date}</Text>
          {comment.length > maxCharLimit && (
            <PrimaryBtn onClick={showModal} className={styles.viewMoreBtn}>
              View more
            </PrimaryBtn>
          )}
          {isClientReview && (
            <PrimaryBtn onClick={onDelete} className={styles.reorderBtn}>
              {' '}
              Delete
              <DeleteOutlined />
            </PrimaryBtn>
          )}
        </div>
      </Card>

      <Modal
        title={reviewerName}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text>{comment}</Text>
      </Modal>
    </div>
  );
};

export default ReviewCard;
