import React, { useMemo } from 'react';
import { Avatar, message, Table } from 'antd';
import {
  AccountPermission, AccountPermissionString, ILinkedAccount, IUserAuth,
} from '@bridgelabsdesign/gfox-api-client';
import { CheckOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './quotes.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';

const { Text } = Typography;

interface IAccountMembersTableProps {
  accounts: ILinkedAccount[];
  // eslint-disable-next-line no-unused-vars
  onEdit(emailAddress?: string): void;
}

const AccountMembersTable = observer(({
  accounts,
  onEdit,
}: IAccountMembersTableProps) => {
  const { currentClient, getCurrentClient, linkedAccount } = useAuthContext();

  const { linkedAccountsStore } = stores;

  // eslint-disable-next-line arrow-body-style
  const getPermissionString = (v?: string[]): string => {
    return v?.reduce((p, c, i) => {
      if (i === 0) { return c; }
      return `${p}, ${c}`;
    }, '') ?? '';
  };

  const handleVerify = async (userAuth: IUserAuth) => {
    const emailAddress = userAuth?.emailAddress;
    if (!currentClient && !emailAddress) {
      message.error('Could not link account');
      return;
    }

    // TODO: relink account to not set permission to admin
    await linkedAccountsStore.requestAccountLink(currentClient?.accountNumber!, emailAddress, [AccountPermission.Admin]);
    await getCurrentClient!(false);
  };

  const isAdmin = useMemo(() => {
    const acc = linkedAccount?.find((x) => x.userAuthId === currentClient?.userAuthId);
    const hasAdminPermission = acc?.userPermissions.some((x) => x === AccountPermissionString[AccountPermission.Admin]);
    return hasAdminPermission ?? false;
  }, [linkedAccount, currentClient]);

  const tableColumns = [
    {
      title: 'Full Name',
      dataIndex: 'userAuthId',
      showMobile: true,
      key: 'quoteNo',
      render: (text: string, record: ILinkedAccount) => (
        <div className={styles.colMembersCon}>
          <Avatar className={styles.colMembersAvatar} />
          <div className={styles.colMembersNameCon}>
            <Text className={styles.colStatus}>
              {`${record?.userAuth?.firstName ?? ''}`}
              {`${record?.userAuth?.lastName ?? ''}`}
            </Text>
            <Text className={styles.colStatus}>
              {`${record?.userAuth?.emailAddress ?? ''}`}
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      showMobile: false,
      key: 'date',
      render: (text: string, record: ILinkedAccount) => (
        <Text className={styles.col}>
          {getPermissionString(record?.userPermissions)}
        </Text>
      ),
    },
    // TODO: all accounts currently share on region & regions
    // {
    //   title: 'Region',
    // },
    // TODO: branch details
    // TODO: total spent
    {
      title: 'Verified',
      dataIndex: 'verified',
      showMobile: false,
      key: 'date',
      width: 120,
      render: (text: boolean, record: ILinkedAccount) => (
        text ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <Buttons.SecondaryBtn
            type="text"
            className={[styles.colViewBtn].join(' ')}
            onClick={() => handleVerify(record?.userAuth)}
            disabled={!isAdmin}
          >
            Verify
          </Buttons.SecondaryBtn>
        )
      ),
    },
    {
      title: 'Action:',
      dataIndex: 'id',
      showMobile: true,
      fixed: 'right' as 'right',
      width: 120,
      key: 'action',
      render: (text: string, record: ILinkedAccount) => (
        <>
          <Buttons.SecondaryBtn
            className={[styles.colViewBtn].join(' ')}
            type="text"
            onClick={() => onEdit(record?.userAuth?.emailAddress)}
            disabled={!isAdmin}
          >
            Edit
          </Buttons.SecondaryBtn>
          <Buttons.SecondaryBtn
            className={[styles.colViewBtn].join(' ')}
            type="text"
            onClick={async () => {
              await linkedAccountsStore.removeLinkedAccount(record);
              await getCurrentClient!(false);
            }}
            disabled={!isAdmin}
          >
            Remove
          </Buttons.SecondaryBtn>
        </>
        // TODO: needs a delete option
      ),
    },
  ];

  const { isMobileWindow } = useWindowSize();

  // eslint-disable-next-line arrow-body-style
  const columns = useMemo(() => {
    return !isMobileWindow ? tableColumns : tableColumns.filter((x) => x.showMobile);
  }, [isMobileWindow, tableColumns]);

  return (
    <>
      <div className={styles.tableCon}>
        <Table<ILinkedAccount>
          className="account-table"
          dataSource={accounts}
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
          scroll={{ y: 500 }}
        />
      </div>
    </>
  );
});

export default AccountMembersTable;
