import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Radio } from 'antd';
import { Helmet } from 'react-helmet';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Images from '../../assets';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import Spin from '../../components/Spin/Spin';
import { formatPrice } from '../../utils/pricing';
import { isGFoxAccount } from '../../utils/account/account-holder';

const { Text } = Typography;

const gfoxAccountOption = 'G_FOX_ACCOUNT';
const addNewPaymentOption = 'ADD_NEW_PAYMENT';

const CheckoutPayment = observer(() => {
  const { currentClient } = useAuthContext();
  const { checkoutStore, payStore } = stores;

  const defautValue = isGFoxAccount(currentClient)
    ? gfoxAccountOption
    : (payStore.paystackCardTransactionData[0]?.id ?? addNewPaymentOption);

  const [value, setValue] = useState<any>(defautValue);

  useEffect(() => {
    if (currentClient?.id) {
      payStore.fetchPaystackPaymentInfo(currentClient.id);
    }
  }, [currentClient]);

  useEffect(() => {
    if (value === gfoxAccountOption) {
      checkoutStore.setSelectedAccountPaymentMethod(true);
      checkoutStore.setSelectedPaystackMethod(undefined);
    } else {
      const method = payStore.paystackCardTransactionData.find((x) => x.id === value as any);
      checkoutStore.setSelectedPaystackMethod(method);
      checkoutStore.setSelectedAccountPaymentMethod(false);
    }
  }, [value]);

  return (
    <main id="payment">
      <Helmet>
        <title>Order Payment - G. Fox</title>
      </Helmet>
      <Spin
        spinning={payStore.isLoadingPaystackTransAuth}
      >
        <div className={styles.detailsCon}>
          <Text className={styles.detailsText}>
            Select your payment method
          </Text>
          <Radio.Group
            className={styles.cardSelectorCon}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            {/* Conditionally render the G. Fox account option if it's a G. Fox account, outside the Radio.Group */}
            {isGFoxAccount(currentClient) && (
            <Radio
              className={styles.cardSelector}
              value={gfoxAccountOption}
            >
              {`The order will be added to your G. Fox Account: ${currentClient?.accountNumber ?? ''}`}
            </Radio>
            )}
            {
               payStore.paystackCardTransactionData.map((item) => (
                 <Radio
                   key={item.reference}
                   className={styles.cardSelector}
                   defaultChecked={false}
                   value={item?.id}
                 >
                   <Images.CardIcon className={styles.cardIcon} />
                   <Text className={styles.cardDigits}>
                     {` ${item?.authorization?.last4}`}
                   </Text>
                 </Radio>
               ))
            }
            {/* Always show the option to add a new payment method */}
            <Radio
              className={styles.cardSelector}
              value={addNewPaymentOption}
            >
              Add new Payment Method
            </Radio>
          </Radio.Group>

          <div className={styles.cardCon}>
            <Text className={styles.detailsText}>
              Payment Amount:
              {' '}
              {formatPrice(checkoutStore.totalPrice)}
            </Text>
            <br />
            <Text className={styles.detailsText} />
          </div>
        </div>
      </Spin>
    </main>
  );
});

export default CheckoutPayment;
