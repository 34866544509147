import React, { ReactNode, useEffect, useState } from 'react';
import {
  Carousel, Radio, Tabs, Form as AntdForm, message,
} from 'antd';
import { BsTelephone } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import { Helmet } from 'react-helmet';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Buttons from '../../components/Buttons';
import Form from '../../components/Form';
import BranchTab from './BranchTab';
import useQuery from '../../utils/hooks/useQuery';
import CTA from '../../components/CTA';
import { carouselData } from './data';
import stores from '../../stores/stores';
import { validateMessages } from '../../utils/form';

const { Text } = Typography;

export enum ContactPageTab {
  // eslint-disable-next-line no-unused-vars
  GENERAL_ENQUIRIES = 'general-enquiries',
  // eslint-disable-next-line no-unused-vars
  BRANCHES = 'branches',
}

const Contact = () => {
  const [activeTabKey, setActiveTabKey] = useState<ContactPageTab>(ContactPageTab.GENERAL_ENQUIRIES);
  const query = useQuery();
  const { branchesStore } = stores;
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [consent, setConsent] = useState<boolean>();
  const [branchId, setBranchID] = useState<string | null>(null);
  const { constactStore } = stores;
  // eslint-disable-next-line prefer-destructuring
  const branchList = branchesStore.branchList;
  useEffect(() => {
    branchesStore.loadBranchNames();
  }, [branchId]);

  const {
    userAuthStore, authStore,
  } = stores;
  const handleActiveTabQuery = () => {
    const tabQuery = query.get('tab');
    if (tabQuery !== null && Object.values(ContactPageTab).includes(tabQuery as ContactPageTab)) {
      setActiveTabKey(tabQuery as ContactPageTab);
    }
  };

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      // eslint-disable-next-line no-template-curly-in-string
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_REACT_CAPTCHA_CODE}`;
      script.async = true;
      document.body.appendChild(script);
    };

    loadRecaptchaScript();

    return () => {
    // Remove the script element when the component unmounts
      // eslint-disable-next-line max-len
      const script = document.querySelector(`script[src="https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_REACT_CAPTCHA_CODE}"]`);
      if (script) {
        script.remove();
      }
    };
  }, [recaptchaToken]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onFinish = async (values: any) => {
    if (!values.enquiryType) {
      message.error('Please select an enquiry type');
      return; // Stop the execution of the rest of the function
    }
    // Invoke reCAPTCHA challenge programmatically
    window.grecaptcha.ready(async () => {
      window.grecaptcha.execute(process.env.REACT_APP_REACT_CAPTCHA_CODE, { action: 'submit' })
        .then(async (token: any) => {
          setRecaptchaToken(token);

          // Verify reCAPTCHA token
          const recaptchaVerificationResult = await userAuthStore.handleRecaptchaVerification(token);
          if (!recaptchaVerificationResult) {
            message.error('reCAPTCHA verification failed. Please try again.');
            return;
          }
          // Proceed with your backend submission logic here
          await constactStore.postContactResponse(values, []);
          authStore.valuesForm(values, branchId as string, consent as boolean);
        });
    });
  };

  const onFinishFailed = () => {
    message.error('Could not submit');
  };

  useEffect(() => {
    handleActiveTabQuery();
  }, []);

  interface IContactFrom {
  name: string;
  label: string;
  pattern: RegExp | undefined;
  type: 'string' | 'email' | 'boolean';
  whitespace: boolean,
  required: boolean;
  formType: 'input' | 'select' | 'radio' | 'checkbox' | 'textarea';
  input: ReactNode;
}

  const contactForm: IContactFrom[] = [
    {
      name: 'firstName',
      label: 'First Name',
      required: true,
      pattern: undefined,
      whitespace: true,
      type: 'string',
      formType: 'input',
      input: <Form.Input placeholder="Enter your name" />,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      required: true,
      pattern: undefined,
      whitespace: true,
      type: 'string',
      formType: 'input',
      input: <Form.Input placeholder="Enter your surname" />,
    },
    {
      name: 'emailAddress',
      label: 'Email Address',
      whitespace: true,
      pattern: undefined,
      type: 'email',
      required: true,
      formType: 'input',
      input: <Form.Input placeholder="Enter your email address" />,
    },
    {
      name: 'companyName',
      label: 'Company Name',
      required: true,
      pattern: undefined,
      whitespace: true,
      type: 'string',
      formType: 'input',
      input: <Form.Input placeholder="Enter your company name" />,
    },
    {
      name: 'cellPhone',
      label: 'Cellphone',
      required: true,
      type: 'string',
      whitespace: true,
      formType: 'input',
      pattern: new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'),
      input: <Form.Input placeholder="Enter your phone number" />,
    },
    {
      name: 'landLine',
      label: 'Landline',
      required: false,
      whitespace: true,
      pattern: undefined,
      type: 'string',
      formType: 'input',
      input: <Form.Input placeholder="Enter your phone number" />,
    },
    {
      name: 'branchName',
      label: 'Branch',
      formType: 'select',
      whitespace: true,
      pattern: undefined,
      type: 'string',
      required: true,
      input: (
        <Form.Select
          onChange={(value) => setBranchID(value)}
          options={branchList.map((x) => ({ value: x.id, label: x.branchName }))}
          placeholder="Select your nearest branch"
        />
      ),
    },
    {
      name: 'contactMessage',
      label: 'Comment',
      type: 'string',
      whitespace: true,
      pattern: undefined,
      required: true,
      formType: 'textarea',
      input: <Form.TextArea placeholder="Enter your message" />,
    },
  ];

  return (
    <main id="contact">
      <Helmet>
        <title>Contact - G. Fox</title>
        <script src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_REACT_CAPTCHA_CODE}`} />
      </Helmet>
      <Carousel
        autoplay
        autoplaySpeed={7000}
        dots
        effect="fade"
      >
        {React.Children.toArray(
          carouselData.map((item) => (
            <CTA.TransparentBgImage
              useBackgroundDiv
              backgroundImage={item.backgroundImage}
              headerText={item.headerText}
              subHeaderText={item.subHeaderText}
              itemClassName={styles.carouselItem}
              overlay
              subText={(
                <>
                  {
                  item.subText.map((txt) => (
                    <Text
                      key={txt}
                      className={styles.carouselSubText}
                    >
                      {txt}
                    </Text>
                  ))
                }
                </>
            )}
            />
          )),
        )}
      </Carousel>
      <AntdForm
        name="contact"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <div
          className={[
            styles.tabCon,
            activeTabKey === ContactPageTab.BRANCHES && styles.tabConBranches,
          ].join(' ')}
        >
          <Tabs
            activeKey={activeTabKey}
            defaultActiveKey={activeTabKey}
            onChange={(_) => setActiveTabKey(_ as ContactPageTab)}
          >
            <Tabs.TabPane
              key={ContactPageTab.GENERAL_ENQUIRIES}
              tab={(
                <Text className={styles.detailsHeaderText}>
                  General Enquiries
                </Text>
            )}
            >
              <div className={styles.contactDetailsCon}>
                <Buttons.PrimaryBtn
                  className={styles.phoneBtn}
                  iconRight={(
                    <BsTelephone className={styles.phoneBtnIcon} />
                )}
                >
                  011 417 9300/9301
                </Buttons.PrimaryBtn>
                <hr />
                <Buttons.PrimaryBtn
                  className={styles.phoneBtn}
                  iconRight={(
                    <GoMail className={styles.phoneBtnIcon} />
                )}
                >
                  Sales@gfox.co.za
                </Buttons.PrimaryBtn>
              </div>

              <div className={styles.getInTouchCon}>
                <Text className={styles.getInTouchText}>
                  Get in touch
                </Text>

                <div className={styles.getInTouchRadioGrp}>
                  <AntdForm.Item
                    name="enquiryType"
                  >
                    <Radio.Group>
                      <Radio value="General enquiry">General Enquiry</Radio>
                      <Radio value="Embroidery">Embroidery</Radio>
                    </Radio.Group>
                  </AntdForm.Item>
                </div>

                {React.Children.toArray(
                  contactForm.map((item) => (
                    <div
                      className={[
                        styles.getInTouchFormCon,
                        item.formType === 'input' && styles.getInTouchFormConInput,
                        item.formType === 'textarea' && styles.getInTouchFormConTextArea,
                      ].join(' ')}
                    >
                      <Text className={styles.getInTouchLabel}>
                        {item.label}
                      </Text>
                      <AntdForm.Item
                        name={item.name}
                        rules={[
                          { required: item.required },
                          { type: item.type, message: 'Please enter a valid email' },
                          { pattern: item.pattern, message: 'invalid format' }, { whitespace: item.whitespace },
                        ]}

                      >

                        {item.input}

                      </AntdForm.Item>
                    </div>
                  )),
                )}

                <div className={styles.getInTouchCommsCon}>
                  <Radio checked={consent} onClick={() => setConsent(!consent)}>
                    <Text className={styles.getInTouchComms}>
                      Receive future communication and promotional special from G.Fox
                    </Text>
                  </Radio>
                </div>

                <Buttons.PrimaryBtn
                  // eslint-disable-next-line react/jsx-props-no-multi-spaces
                  htmlType="submit"
                >
                  Submit
                </Buttons.PrimaryBtn>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane
              key={ContactPageTab.BRANCHES}
              tab={(
                <Text className={styles.detailsHeaderText}>
                  Our Branches
                </Text>
            )}
            >
              <BranchTab />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </AntdForm>
    </main>
  );
};

export default Contact;
