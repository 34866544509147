import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { IInventoryMaster, IOrder } from '@bridgelabsdesign/gfox-api-client';
import { DatePicker, Empty, Input } from 'antd';
import { Helmet } from 'react-helmet';
import { RiSearchLine } from 'react-icons/ri';
import Cards from '../../components/Cards';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import Spin from '../../components/Spin/Spin';
import { formatPrice } from '../../utils/pricing';
import { copyObjects } from '../../utils/copyObjects';
import { CheckoutPath } from '../../utils/checkout';
import { isEmptyString } from '../../utils/strings';

const AccountOrders = observer(() => {
  const { currentClient } = useAuthContext();
  const { cartStore, orderStore } = stores;

  const history = useHistory();

  const handleOnOrderView = (orderRefNo: string) => {
    if (!isEmptyString(orderRefNo)) {
      history.push(`/account/orders/${orderRefNo}`);
    }
  };

  const handleOnReorderClick = async (order: IOrder) => {
    if (!order) {
      return;
    }
    const orderInv = order.orderInfo.map<IInventoryMaster>((x) => {
      const inv = orderStore.previousOrders?.inventoryMasters?.find((i) => i.sku === x.sku);
      return copyObjects(inv, { orderQty: x?.orderQty ?? 1 });
    });
    const res = await cartStore.onReorder(currentClient?.id!, orderInv ?? []);
    if (res) {
      history.push(CheckoutPath.CART);
    }
  };

  const handleOnTrackOrderClick = (orderRefNo?: string) => {
    if (!isEmptyString(orderRefNo)) {
      history.push(`/account/orders/${orderRefNo}/track`);
    }
  };

  const handleOnChatOrderClick = (orderRefNo?: string) => {
    if (!isEmptyString(orderRefNo)) {
      history.push(`/account/orders/${orderRefNo}/chat`);
    }
  };

  useEffect(() => {
    if (currentClient?.id) {
      orderStore.loadPreviousOrders(currentClient?.id, true);
    }
  }, [currentClient?.id]);

  // Second useEffect for updating search results
  useEffect(() => {
    orderStore.searchResults = orderStore.previousOrders;
  }, [orderStore.previousOrders]);
  return (
    <main id="account-orders">
      <Helmet>
        <title>Orders - G. Fox</title>
      </Helmet>
      <Spin
        spinning={orderStore.isLoadingHistory ?? false}
      >
        <div className={styles.searchDatePickerContainer}>
          <div className={styles.datePickerContainer}>
            <DatePicker.RangePicker
              className={styles.datePicker}
              onChange={(value) => orderStore.setDateRange(value)}
            />
          </div>
          <div className={styles.searchContainer}>
            <Input
              size="large"
              className={`${styles.pageHeaderOptionSearch} ${styles.searchInput}`}
              placeholder="Search quotes..."
              defaultValue={orderStore.query}
              prefix={<RiSearchLine color="#CBD5E0" />}
              // disabled={orderStore.isLoading}
              onChange={(_) => {
                if (currentClient?.id) {
                  orderStore.setQuery(_.target.value);
                }
              }}
            />
          </div>
        </div>
        <div>
          {React.Children.toArray(
            orderStore.searchResults?.orders?.map((item) => (
              <Cards.OrderCard
                className={styles.ordersCard}
                orderID={item.refNo}
                orderDate={item.dateOrdered as string}
                status={item.statusCode}
                total={formatPrice(item.grandTotal)}
                fileUrl={item.fileUrl}
                onViewClick={() => handleOnOrderView(item.refNo)}
                onReorderClick={() => handleOnReorderClick(item)}
                onTrackOrderClick={() => handleOnTrackOrderClick(item.refNo)}
                onChatClick={() => handleOnChatOrderClick(item.refNo)}
              />
            )),
          )}

          {orderStore.previousOrders?.orders
            && orderStore.searchResults?.orders.length === 0 && (
            <div className={styles.emptyListCon}>
              <Empty
                className={styles.emptyList}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          )}

          {/*
            //TODO: load more order using pagination
            <div className={styles.loadCon}>
              <Buttons.PrimaryBtn
                className={styles.loadMoreBtn}
                type="text"
              >
                Load more
              </Buttons.PrimaryBtn>
            </div>
          */}
        </div>
      </Spin>
    </main>
  );
});

export default AccountOrders;
