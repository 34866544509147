import React from 'react';
import Icon from '@ant-design/icons';
import { Carousel } from 'antd';
import { Helmet } from 'react-helmet';
import CTA from '../../components/CTA';
import Images from '../../assets';
import styles from './styles.module.css';
import { Documents, carouselData } from './data';
import Typography from '../../components/Typography';

import PopularProductCard from '../Home/PopularProductCard';
import { popularProducts } from '../Home/data';
import ShopAllHeader from '../Home/ShopAllHeader';

const {
  Link, Text, Title,
} = Typography;

const AboutBEE = () => (

  <main id="BEE">
    <Helmet>
      <title>About BBBEE - G. Fox</title>
    </Helmet>

    <Carousel
      autoplay
      autoplaySpeed={7000}
      dots
      effect="fade"
    >
      {React.Children.toArray(
        carouselData.map((item) => (
          <CTA.TransparentBgImage
            itemClassName={styles.carouselItem}
            backgroundImage={{ largeScreen: Images.GroupWorkers }}
            headerText={item.headerText}
            subHeaderText={item.subHeaderText}
            subText={(
              item.subText.map((txt) => (
                <Text
                  key={txt}
                  className={styles.carouselSubText}
                >
                  {txt}
                </Text>
              ))
            )}
          />
        )),
      )}
    </Carousel>
    <div className={styles.pdfShowcaseCon}>
      <Title
        className={styles.pdfHeader}
        level={2}
      >
        Documents
      </Title>
      <div className={styles.pdfCon}>
        {React.Children.toArray(
          Documents.map((item) => (
            <Link
              className={styles.pdItem}
              href={item.docUrl}
              external
            >
              <Icon
                className={styles.pdItemIcon}
                component={item.image}
              />
              <Text className={styles.pdItemName}>
                {item.name}
              </Text>
            </Link>
          )),
        )}
      </div>
    </div>
    <ShopAllHeader
      header="Popular products"
      btnTitle="View All"
    />

    <div className={styles.deliveriesCon}>
      <div className={styles.popularProdContainer}>
        {React.Children.toArray(
          popularProducts.slice(0, 2).map((item) => (
            <PopularProductCard item={item} />
          )),
        )}
      </div>
    </div>
  </main>
);
export default AboutBEE;
