import React from 'react';
import Typography from '../Typography';
import { IFooterDataRoute } from './data';
import styles from './styles.module.css';

interface IFooterItems {
  items: IFooterDataRoute[];
  visible: boolean;
}
const { Link } = Typography;

const FooterItems = ({ items, visible }: IFooterItems) => {
  if (!visible) { return <></>; }
  return (
    <>
      {items.map(({ externalLink, href, title }) => (
        <Link
          key={href}
          href={href ?? ''}
          external={externalLink}
          className={styles.footerItemLink}
        >
          {title}
        </Link>
      ))}
    </>
  );
};

export default FooterItems;
