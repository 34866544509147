import React, { useCallback, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.css';
import { MenuItemContainer } from '../Menu/styles';
import MenuList from '../../pages';
import Typography from '../Typography';
import stores from '../../stores/stores';

const { Link, Text } = Typography;

interface IMobileNavList {
  onNavItemClick?(): void;
  // eslint-disable-next-line no-unused-vars
  onProductCategoryClick(categoryRefNo: string): void;
}

const MobileNavList = observer(({
  onNavItemClick,
  onProductCategoryClick,
}: IMobileNavList) => {
  const [expandProductMenu, setExpandProductMenu] = useState(false);
  const isProductRoute = useCallback((path) => path === '/products', []);

  const handleMenuItemClick = (e: React.MouseEvent<Element, MouseEvent>, path?: string) => {
    if (!isProductRoute(path)) {
      onNavItemClick!();
      return;
    }
    e.preventDefault();
    setExpandProductMenu(!expandProductMenu);
  };
  const { categoryStore } = stores;

  const history = useHistory();

  const handleClick = () => {
    history.push('/products/favorites');
  };

  return (
    <>
      <div>
        {React.Children.toArray(
          MenuList.map((menuItem) => (
            <>
              <MenuItemContainer
                key={menuItem.title}
                className={[
                  styles.menuItem,
                  isProductRoute(menuItem.path) && styles.menuItemBorder,
                ].join(' ')}
              >
                <Link
                  href={menuItem.path}
                  className={styles.menuItemLink}
                  onClick={(e) => handleMenuItemClick(e, menuItem.path)}
                >
                  <Text
                    className={[
                      styles.menuItemText,
                    ].join(' ')}
                  >
                    {menuItem.title}
                  </Text>

                  {/* TODO: rotate arrow on expand */}
                  {isProductRoute(menuItem.path) && (
                    <FiChevronRight className={styles.menuItemRightArrow} />
                  )}
                </Link>
              </MenuItemContainer>
              {isProductRoute(menuItem.path) && expandProductMenu
                && (
                  <div className={styles.productCategoryCon}>
                    <div className={styles.favoritesCon}>
                      <Text
                        onClick={handleClick}
                        className={styles.favoriteProduct}
                      >
                        MY FAVORITES
                      </Text>
                    </div>
                    {categoryStore.categories.value.data.map((item) => (
                      <div
                        key={item.refNo}
                        className={styles.productCategoryItem}
                        onClick={() => onProductCategoryClick(item.refNo)}
                        aria-hidden
                      >
                        <Text
                          className={styles.productCategory}
                        >
                          {item.name.toUpperCase()}
                        </Text>
                        <FiChevronRight className={styles.menuItemRightArrow} />
                      </div>
                    ))}
                  </div>
                )}
            </>
          )),
        )}
      </div>
    </>
  );
});

export default MobileNavList;
