import React, { useMemo } from 'react';
import { IPaystackTransactionAuthorization, PaystackCardType } from '@bridgelabsdesign/gfox-api-client';
import { FaCcVisa, FaCcMastercard, FaCreditCard } from 'react-icons/fa';

interface ICardIcon {
  data?: IPaystackTransactionAuthorization | null;
  className?: string;
}

const CardIcon = ({
  data,
  className,
}:ICardIcon) => {
  const cardType = useMemo(() => {
    if (data?.card_type?.toLocaleLowerCase()?.includes('visa')) {
      return PaystackCardType.VISA;
    } if (data?.card_type?.toLocaleLowerCase()?.includes('mastercard')) {
      return PaystackCardType.MASTERCARD;
    }
    return PaystackCardType.OTHER;
  }, [data]);

  if (cardType === PaystackCardType.VISA) {
    return <FaCcVisa className={className} />;
  } if (cardType === PaystackCardType.MASTERCARD) {
    return <FaCcMastercard className={className} />;
  }
  return <FaCreditCard className={className} />;
};

CardIcon.defaultProps = {
  data: undefined,
  className: undefined,
};

export default CardIcon;
