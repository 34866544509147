import React, { } from 'react';
import {
  Empty, Image, Skeleton, Table,
  Badge,
  Tooltip,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { ICart } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './checkout-item.module.css';
import { calcProductPrice, formatPrice } from '../../utils/pricing';
import stores from '../../stores/stores';
import Images from '../../assets';
import { useAuthContext } from '../../context/AuthContext';
import { imgURLPrefix } from '../../config/image';

const { Link, Text } = Typography;

const ImageView = ({ isLoading, src }: {
  isLoading: boolean
  src: string,
}) => (
  <>
    {isLoading ? (
      <Skeleton
        active
        loading
        className={styles.carouselImgCon}
      />
    ) : (
      <Image
        src={`${imgURLPrefix}${src}`}
        alt="Product image"
        className={styles.cartItemImg}
        rootClassName={styles.cartItemImgCon}
        fallback={Images.FallbackImage}
      />
    )}
  </>
);

const CloseBtn = observer(({ cartId, edit }: { cartId:string, edit: boolean }) => {
  const { currentClient } = useAuthContext();
  const { cartStore } = stores;

  if (!edit) {
    return null;
  }
  return (
    <span className={styles.cartItemCloseIconCon}>
      <Tooltip title="Remove from cart">
        <Buttons.PrimaryBtn
          type="text"
          icon={(
            <IoClose
              className={styles.cartItemCloseIcon}
            />
        )}
          onClick={() => cartStore.removeCartItem(currentClient?.id!, [cartId])}
        />
      </Tooltip>
    </span>
  );
});

interface ICheckoutItemsTableProps {
  edit?: boolean,
  items?: ICart[]
  loading?: boolean
  // eslint-disable-next-line no-unused-vars
  isItemDeletedFromInventory?: (item: ICart) => boolean;
}

const CheckoutItemsTable = observer(({
  edit = true,
  items,
  loading = false,
  // eslint-disable-next-line no-unused-vars
  isItemDeletedFromInventory = () => false,
}: ICheckoutItemsTableProps) => {
  const { currentClient } = useAuthContext();
  const { cartStore } = stores;

  const cartItems = cartStore.cartItems.value.data.slice().sort((a, b) => b?.inventoryMasterId?.localeCompare(a?.inventoryMasterId));
  const isLoading = cartStore.cartItems.isLoading || cartItems.length === 0;

  // Add this function to check for duplicates
  const isDuplicate = (item: ICart) => cartItems.filter((cartItem) => cartItem.inventoryMasterId === item.inventoryMasterId).length > 1;

  const tableColumns = [
    {
      title: '',
      dataIndex: 'imageSrc',
      key: 'imageSrc',
      width: '12%',
      render: (text: string, record: ICart) => (
        <ImageView
          isLoading={loading ?? isLoading}
          src={record?.inventoryMaster?.productImages?.length > 0
            ? record?.inventoryMaster?.productImages[0]
            : Images.FallbackImage}
        />

      ),
    },
    {
      title: 'Product',
      dataIndex: 'sku',
      key: 'sku',
      width: '60%',
      render: (text: string, record: ICart) => {
        const isDeleted = isItemDeletedFromInventory(record);
        return isDeleted ? (
          <Text className={styles.deletedItem}>
            Item
            {' '}
            {record.inventoryMaster?.sku}
            {' '}
            not available
          </Text>
        ) : (
          <div className={styles.cartTableProductInfo}>
            <Text className={styles.cartItemName}>
              {record.inventoryMaster?.description}
              {isDuplicate(record) && (
                <Badge count="Duplicate" style={{ backgroundColor: '#ff4d4f', marginLeft: '8px' }} />
              )}
            </Text>
            <div>
              {record.inventoryMaster?.categorySpecificInfo?.map((value) => (
                <span key={value.name} style={{ paddingRight: '1em' }}>
                  <Text className={styles.cartItemDetailsFieldName}>
                    {value.name}
                    { ': '}
                  </Text>
                  <Text className={styles.cartItemDetailsFieldValue}>
                    {value.value}
                  </Text>
                </span>
              ))}
            </div>
            <Text className={styles.cartItemDetailsSku}>
              {record.inventoryMaster?.sku}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      width: '20%',
      render: (text: number, record: ICart) => (
        <Buttons.CartCountBtn
          quantity={record?.quantity}
          showEditButton={edit}
          size="small"
          onAddClick={() => cartStore.updateToCartItems(currentClient?.id!, record?.id, 1)}
          onMinusClick={() => cartStore.updateToCartItems(currentClient?.id!, record?.id, -1)}
          handleQuantityChange={(e) => {
            const value = e.target.value === '' ? 0 : Number(e.target.value);
            cartStore.updateToCartItems(currentClient?.id!, record?.id, Number(value), true);
          }}
        />
      ),
    },
    {
      title: '',
      width: '8%',
      dataIndex: 'close',
      key: 'close',
      render: (a: string, record: ICart) => <CloseBtn cartId={record.id} edit={edit} />,
    },
  ];

  const getInvItemPrice = (invSku: string, qty: number) => {
    const invItem = cartStore.invList.value.data.find((x) => x.sku === invSku);
    const price = calcProductPrice(invItem).excludingTax * qty;
    return price > 0 ? formatPrice(price) : '';
  };

  return (
    <>
      <div className={['checkout-items-table', styles.cartMobView].join(' ')}>
        {React.Children.toArray(
          (items ?? cartItems).map((item) => (
            <div className={styles.cartItemCon}>
              <CloseBtn cartId={item.id} edit={edit} />
              <Text className={styles.cartItemName}>
                {item?.inventoryMaster?.description}
                {isDuplicate(item) && (
                  <Badge count="Duplicate" style={{ backgroundColor: '#ff4d4f', marginLeft: '8px' }} />
                )}
              </Text>
              <div className={styles.cartItemDetailsCon}>
                <ImageView
                  isLoading={isLoading || loading}
                  src={item?.inventoryMaster?.productImages?.length > 0
                    ? item?.inventoryMaster?.productImages[0]
                    : Images.FallbackImage}
                />
                <div className={styles.cartItemDetails}>
                  <Text className={styles.cartItemDetailsSku}>
                    {item.inventoryMaster?.sku}
                  </Text>
                  {item.inventoryMaster?.categorySpecificInfo?.map((value) => (
                    <div key={value.name}>
                      <Text className={styles.cartItemDetailsFieldName}>
                        {value.name}
                        {': '}
                      </Text>
                      <Text className={styles.cartItemDetailsFieldValue}>
                        {value.value}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.cartItemPriceCon}>
                <Buttons.CartCountBtn
                  quantity={item.quantity}
                  size="small"
                  showEditButton={edit}
                  onAddClick={() => cartStore.updateToCartItems(currentClient?.id!, item?.id, 1)}
                  onMinusClick={() => cartStore.updateToCartItems(currentClient?.id!, item?.id, -1)}
                  handleQuantityChange={(e) => {
                    const value = e.target.value === '' ? 0 : Number(e.target.value);
                    cartStore.updateToCartItems(currentClient?.id!, item?.id, Number(value), true);
                  }}
                />
                <Text className={styles.cartItemPrice}>
                  {getInvItemPrice(item.inventoryMaster?.sku ?? '', item.quantity)}
                </Text>
              </div>
            </div>
          )),
        )}
      </div>
      <div className={styles.cartTableView}>
        {/* TODO: format table header & add spacing between rows */}
        <Table
          dataSource={items ?? cartItems}
          columns={tableColumns}
          pagination={false}
          size="small"
          bordered={false}
          rowClassName={styles.cartTableRow}
          locale={{
            emptyText: (
              <div className={styles.emptyListCon}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
                <Link
                  className={styles.emptyListShop}
                  href="/specials"
                >
                  Continue Shopping
                </Link>
              </div>
            ),
          }}
        />
      </div>

    </>
  );
});

export default CheckoutItemsTable;
