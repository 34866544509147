import APIClient, {
  ICategory,
  ISkuMaster,
  LoadManager,
} from '@bridgelabsdesign/gfox-api-client';
import {
  computed, action, observable, makeObservable,
} from 'mobx';

export type CategoryFilterItem = ICategory & {
  checked: boolean,
}

export class CategoryStore {
  categories = new LoadManager<ICategory>({ data: [] }, APIClient.Category.getCategories);

  nestedCategories = new LoadManager<ICategory>({ data: [] }, APIClient.Category.getCategories);

  breadCrumb: {refNo: string, title: string }[] = [];

  pathBreadCrumbs: {refNo:string, name: string }[] = [];

  currentRef = '';

  currentSubCategoryRef = '';

  // refNo's of products currently displayed along with categories
  productRefNos = new Set<string>();

  filteredNestedCategories: ICategory[] = [];

  constructor() {
    makeObservable(this, {
      categories: observable,
      nestedCategories: observable,
      breadCrumb: observable,
      pathBreadCrumbs: observable,
      loadNestedCategories: action,
      currentRef: observable,
      currentSubCategoryRef: observable,
      productRefNos: observable,
      setProductRefNos: action,
      filteredNestedCategories: observable,
      setFilteredNestedCategories: action,
      currentCategory: computed,
      currentSubCategory: computed,
      setCurrentRef: action,
      setCurrentSubCategoryRef: action,
      // setProductSelectPath: action,
    });
  }

  get currentCategory(): ICategory | undefined {
    return this.categories.value.data.find((x) => x.refNo === this.currentRef) as any;
  }

  get currentSubCategory(): ICategory {
    return this.currentCategory?.inverseParentCategory?.find((x) => x.refNo === this.currentSubCategoryRef) as any;
  }

  /* eslint-disable class-methods-use-this */
  async getSubCategoryPath(Id: string): Promise<void> {
    try {
      const response = await APIClient.Category.getCategoryPath(Id);
      this.pathBreadCrumbs = response.data.map((category: Pick<ICategory, 'id' | 'refNo' | 'name' | 'parentCategoryId'>) => ({
        refNo: category.refNo,
        name: category.name,
      }));
    } catch (error) {
      console.error('Error fetching category path:', error);
    }
  }

  setProductRefNos(s: Set<string>) {
    this.productRefNos = s;
  }

  setFilteredNestedCategories(skuList: ISkuMaster[]) {
    if (this.nestedCategories.value.data.length === 0) {
      this.filteredNestedCategories = [];
      return;
    }

    this.filteredNestedCategories = this.nestedCategories.value.data[0]?.inverseParentCategory?.filter((item) => {
      const idx = skuList.findIndex((x) => x.categoryId === item.id);
      return !(this.productRefNos.has(item.refNo) && idx >= 0);
    }) ?? [];
  }

  setCurrentRef(refNo: string) {
    this.currentRef = refNo;
  }

  setCurrentSubCategoryRef(refNo: string) {
    this.currentSubCategoryRef = refNo;
  }

  async loadCategory() {
    if (this.categories.value.data.length === 0) {
      await this.categories.fetch();
    }
  }

  async loadNestedCategories(refs: string[]) {
    this.breadCrumb = [];

    if (refs.length === 0) {
      this.nestedCategories.value.data = [];
      return;
    }

    // load main category levels
    let currentCategory = this.categories.value.data;
    for (let i = 0; i < refs.length; i += 1) {
      const idx = currentCategory?.findIndex((x) => x.refNo === refs[i]
        || x.refNo === this.currentRef || x.refNo === this.currentSubCategoryRef);
      if (idx < 0) {
        break;
      }
      this.breadCrumb.push({ refNo: currentCategory[idx].refNo, title: currentCategory[idx].name });
      currentCategory = currentCategory[idx].inverseParentCategory;
    }

    // load needed sub category
    await this.nestedCategories.fetch(`refNo=${refs[refs.length - 1]}`);
    if (this.nestedCategories.error) {
      console.error(`prod selection nav error: ${this.nestedCategories.error}`);
      return;
    }

    // remove items if upper category clicked
    const searchPathIndex = this.breadCrumb.findIndex((x) => x.refNo === refs[refs.length - 1]);
    if (searchPathIndex >= 0) {
      this.breadCrumb.splice(searchPathIndex);
    }

    // search for ref
    let tempSearch: ICategory | undefined;
    for (let i = 0; i < refs.length; i += 1) {
      tempSearch = this.nestedCategories.value.data.find((x) => x.refNo === refs[i]);
      if (tempSearch !== undefined) {
        break;
      }
    }

    if (tempSearch !== undefined) {
      this.breadCrumb.push({ refNo: tempSearch.refNo, title: tempSearch.name });
      for (let i = 1; i < refs.length; i += 1) {
        tempSearch = tempSearch?.inverseParentCategory.find((x) => x.refNo === refs[i]);
        if (tempSearch === undefined) {
          break;
        }
        this.breadCrumb.push({ refNo: tempSearch.refNo, title: tempSearch.name });
      }
    }
  }
}
export default CategoryStore;
