import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import stores from '../../stores/stores';
import Tables from '../../components/Tables';
import styles from './styles.module.css';

const Wishlist = observer(() => {
  const { wishStore } = stores;

  useEffect(() => {
    wishStore.setSelectedItems([]);
  }, []);

  return (
    <main id="checkout-cart" className={styles.cartCon}>
      <Helmet>
        <title>Wishlist - G. Fox</title>
      </Helmet>
      <Tables.WishlistItemsTable />
    </main>
  );
});

export default Wishlist;
