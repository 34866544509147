import APIClient, {
  LoadManager, IWishList, IInventoryMaster, ISkuMaster, IClient, ICategory, ICategorySpecificInfo, ICart,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import { MessageType } from 'antd/lib/message';
import {
  action,
  computed, flow, makeObservable, observable,
} from 'mobx';
import { calcProductPrice } from '../utils/pricing';
import QuoteReorder from '../utils/quotes/quote-reorder';
import { ILoadInventoryListParams } from './inventory';
import * as wishlistStorage from '../utils/wishlistStorage';
import * as cartStorage from '../utils/cartStorage';
import { selectedInvItem } from '../utils/productSelection';

type IProductSelectionQuery = {
  colors: string[];
  filterBy: ICategorySpecificInfo[],
}

class WishStore {
  private localWishItems: LoadManager<IWishList> = new LoadManager<IWishList>({ data: [] },
    wishlistStorage.getWishes,
    wishlistStorage.addWish,
    wishlistStorage.updateWish,
    wishlistStorage.deleteWish);

  wishItems: LoadManager<IWishList> = this.localWishItems;

  skuList = new LoadManager<ISkuMaster>({ data: [] }, APIClient.SKUMasterV2.getSkuMasters);

  invList = new LoadManager<IInventoryMaster>({ data: [] }, APIClient.InventoryV2.getInventoryMasters);

  sku: ISkuMaster | undefined = undefined;

  activeImageIdx = 0;

  lineItemCount = 1;

  productSelectionQuery: IProductSelectionQuery = { colors: [], filterBy: [] };

  productQueryFilterBy: ICategory[] = [];

  pageInventoryMasterSku?: string | null;

  /*
   * Currently selected wish list items
   */
  selectedItems: IWishList[] = [];

  constructor() {
    makeObservable(this, {
      wishItems: observable,
      // getSkuById: action,
      sku: observable,
      skuList: observable,
      setSku: action,
      invList: observable,
      activeImageIdx: observable,
      lineItemCount: observable,
      productSelectionQuery: observable,
      wishItemsInvPricing: computed,
      pageInventoryMasterSku: observable,
      selectedItems: observable,
      isLoadingwishItems: computed,
      wishCount: computed,
      currentSkuItem: computed,
      currentInvItem: computed,
      skuDescription: computed,
      setSelectedItems: action,
      setProductSelectionQuery: action,
      setLineItemCount: action,
      updateTowishItems: action,
      removeWishItem: action,
      clearWish: action,
      addTowish: action,
      setActiveImageIdx: action,
      transferWishToCart: action,
      addLineItemTowish: action,
      loadwishItems: flow,
      loadWishInfo: flow,
      loadSkuList: flow,
      loadInventoryList: flow,
    });
  }

  setSku = action((sku: ISkuMaster | undefined) => {
    this.sku = sku;
  });

  get wishItemsInvPricing() {
    const cartItems = this.wishItems.value.data.slice();
    const invList = this.invList.value.data.slice();

    const pricing = {
      vatTotal: 0,
      totalWithVat: 0,
    };

    if (invList.length > 0 && cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i += 1) {
        const invItem = invList.find((inv) => inv?.id === cartItems[i].inventoryMasterId);
        const productPrice = calcProductPrice(invItem);
        pricing.totalWithVat += (productPrice.withTax * cartItems[i].quantity);
        if (productPrice.withTax !== productPrice.excludingTax) {
          const vat = (productPrice.withTax - productPrice.excludingTax);
          pricing.vatTotal += (vat * cartItems[i].quantity);
        }
      }
    }

    return pricing;
  }

  get isLoadingwishItems(): boolean {
    return this.wishItems.isLoading || this.skuList.isLoading || this.invList.isLoading
      || this.invList.value.data.length === 0;
  }

  get wishCount(): number {
    if (this.wishItems.value.data.length === 0) {
      return 0;
    }
    return this.wishItems.value.data.reduce((p, c) => p + c.quantity, 0);
  }

  get currentSkuItem(): ISkuMaster | undefined {
    if (this.skuList?.value.data.length > 0) {
      return this.skuList?.value.data[0];
    }
    return undefined;
  }

  get currentInvItem(): IInventoryMaster | undefined {
    if (this.pageInventoryMasterSku) {
      const invItem = this.invList.value.data.find((x) => x.sku === this.pageInventoryMasterSku);
      if (invItem) {
        return invItem;
      }
    }

    const data = selectedInvItem(this.invList.value.data, this.productSelectionQuery);
    return data[0] ?? this.invList.value.data[0];
  }

  get skuDescription(): string[] {
    const item = this.currentSkuItem;
    if (!item?.fullDescription) {
      return [];
    }
    return item?.fullDescription?.split('|') ?? [];
  }

  setSelectedItems(values: IWishList[]) {
    this.selectedItems = values;
  }

  setProductSelectionQuery(values: IProductSelectionQuery) {
    this.productSelectionQuery = values;
  }

  setLineItemCount(count?: number) {
    let updatedCount = this.lineItemCount;
    if (count === undefined) {
      const invItemId = this.currentInvItem?.id;
      const itemQty = this.wishItems.value.data.find((x) => x?.inventoryMasterId === invItemId!)?.quantity;
      if (invItemId === undefined || itemQty === undefined) {
        updatedCount = 1;
      } else {
        updatedCount = itemQty ?? 1;
      }
    } else {
      updatedCount += count;
    }

    if (updatedCount > 0) {
      this.lineItemCount = updatedCount;
    }
  }

  setActiveImageIdx(idx: number) {
    this.activeImageIdx = idx;
  }

  async transferWishToCart(currentClient: IClient, cartList: ICart[], wishList: IWishList[]) {
    if (wishList.length === 0 || !currentClient?.id) {
      return;
    }

    const hide = message.loading('Adding items to cart...', 0);

    let cartContent = wishList.map((item) => ({
      clientId: currentClient.id,
      inventoryMasterId: item.inventoryMaster.id,
      quantity: item.inventoryMaster.orderQty,
      createdAt: item.createdAt,
    }as ICart));

    // Filter cartContent for items that aren't in cartList.
    cartContent = cartContent.filter((item) =>
      // Assuming the unique identifier of each item is 'inventoryMasterId'.
      // eslint-disable-next-line implicit-arrow-linebreak
      !cartList.some((cartItem) => cartItem.inventoryMasterId === item.inventoryMasterId));

    try {
      if (cartContent.length > 0) {
        await cartStorage.addRange(cartContent, 'api');
      }

      const wishRemoveIds = wishList.map((x) => x.id);
      await this.removeWishItem(currentClient.id, wishRemoveIds, false);
    } catch (error) {
      message.error('Could not add items to cart');
      console.error(error);
    } finally {
      hide();
    }
  }

  async addLineItemTowish(client: IClient | null | undefined, onAdd: () => void) {
    const invItem = this.invList.value.data.find((x) => x.sku === this.currentInvItem?.sku!);
    if (!invItem) {
      message.error('Could not add items to wish List.');
      return;
    }

    const body = this.wishItems.value.data.slice();
    let itemIdx = body.findIndex((x) => x.inventoryMasterId === invItem.id);
    if (itemIdx < 0) {
      itemIdx = body.length;
      body[itemIdx] = {
        clientId: client?.id,
        inventoryMasterId: invItem.id,
        quantity: this.lineItemCount,
      } as IWishList;
    } else {
      body[itemIdx].quantity = this.lineItemCount;
    }

    body[itemIdx].inventoryMaster = invItem;

    try {
      await wishlistStorage.addRange(body);
      await this.loadWishInfo(this.currentSkuItem?.sku!, client?.id);
      message.success('Added items to wish.');
    } catch (error) {
      console.log('error', error);
      message.error('Could not add item to wishhh.');
      // eslint-disable-next-line no-console
      console.error('wishStore addLineItemTowish err:', error);
    }

    onAdd();
  }

  updateTowishItemsDebounceId = 0; // debounce add to wish requests

  async updateTowishItems(clientId: string | null, wishId: string, quantity = 1) {
    if (!this.wishItems) {
      return;
    }

    const data = this.wishItems.value.data.map((x) => Object.assign(x, {
      client: null,
      inventoryMaster: clientId ? null : x.inventoryMaster, // needed for local wish storage
    }) as IWishList);

    const idx = data.findIndex((x) => x.id === wishId);
    if (idx < 0) {
      message.error('Could not add items to wish.');
      return;
    }

    data[idx].quantity += quantity;
    if (data[idx].quantity <= 0) {
      await this.loadwishItems(clientId);
      return;
    }

    this.updateTowishItemsDebounceId += 1;
    const id = this.updateTowishItemsDebounceId;
    setTimeout(async () => {
      if (id === this.updateTowishItemsDebounceId) {
        try {
          if (this.wishItems) {
            this.wishItems.isLoading = true;
            await wishlistStorage.addRange(data);
          }
        } catch (error) {
          message.error('Could not add items to wish.');
          // eslint-disable-next-line no-console
          console.error('wishStore updateTowishItems error:', error);
        }
      }
      await this.loadwishItems(clientId);
    }, clientId ? 800 : 0); // on local storage wish no debounce applied
  }

  async removeWishItem(clientId: string, wishIds: string[], showMessage = true) {
    const data = this.wishItems.value.data.filter((x) => wishIds.some((c) => c === x.id));

    let hide: MessageType | undefined;
    if (showMessage) {
      hide = message.loading('Removing item from wish...', 0);
    }
    try {
      const items = data.map((x) => x.id);
      await wishlistStorage.deleteRange(items);
      await this.loadwishItems(clientId);
    } catch (error) {
      message.error('Could not remove item');
      // eslint-disable-next-line no-console
      console.error('wishStore removewishItem error', error);
    } finally {
      if (hide) hide();
    }
  }

  async clearWish(clientId: string, showMessage = true) {
    const ids = this.wishItems.value.data.map((x) => x.id);
    await this.removeWishItem(clientId, ids, showMessage);
    this.invList.value.data = [];
  }

  async addTowish(clientId: string, invList: IInventoryMaster[]) {
    // const data = this.wishItems.value.data.slice();
    const body: Partial<IWishList>[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < invList.length; i++) {
      if (invList[i].orderQty <= 0) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const quantity = invList[i].orderQty;
      const inventoryMasterId = invList[i].id;
      body[body.length] = { clientId, inventoryMasterId, quantity } as IWishList;
    }

    await wishlistStorage.addRange(body as IWishList[]);
    await this.loadWishInfo(clientId);
  }

  async onReorder(clientId: string, invList: IInventoryMaster[], quoteRefNo?: string) {
    const hide = message.loading('Adding items to cart...', 0);
    try {
      await this.clearWish(clientId, false);
      await this.addTowish(clientId, invList);
      await this.loadwishItems(clientId);
      hide();

      if (quoteRefNo) {
        await QuoteReorder.saveRefNo(quoteRefNo);
      }
      return true;
    } catch (error) {
      hide();
      message.error('Could not reorder items.');
      // eslint-disable-next-line no-console
      console.error('wishStore onReorder err: ', error);
      return false;
    }
  }

  * loadWishInfo(sku: string, clientId?: string): {} {
    if (!this.wishItems) {
      return;
    }
    yield this.loadwishItems(clientId);

    yield this.loadSkuList({ skuList: sku, withPrice: true });
    yield this.loadInventoryList({ parentSku: sku, withPrice: true });
    this.sku = this.skuList.value.data.find((x) => x.sku === sku);
    this.setLineItemCount();
    if (this.skuList.error || this.invList.error) {
      // TODO: Toast.show({ type: 'error', text1: 'Could not load wish.' });
      const error = this.skuList.error ?? this.invList;
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  private loadwishItemsClientId?: string;

  async* loadwishItems(clientId?: string | null) {
    if (clientId) {
      this.loadwishItemsClientId = clientId;
    }

    const queryParams = `clientId=${this.loadwishItemsClientId ?? ''}`;

    if (!this.loadwishItemsClientId) {
      this.wishItems = this.localWishItems;
    }

    yield this.wishItems.fetch(queryParams);
    if (this.wishItems.error) {
      // eslint-disable-next-line no-console
      console.warn(this.wishItems.error);
    }
  }

  * loadSkuList({
    active = true, categoryRefNo, withPrice = true, skuList,
  }: any) {
    let queryParams = '';
    queryParams += `active=${active ? 'true' : 'false'}&`;
    queryParams += `categoryRefNo=${categoryRefNo ?? ''}&`;
    queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
    queryParams += `skuList=${skuList ?? ''}`;
    yield this.skuList.fetch(queryParams);
  }

  * loadInventoryList({
    active = true, parentSku, skuList, withPrice = true,
  }: ILoadInventoryListParams) {
    let queryParams = '';
    queryParams += `active=${active ? 'true' : 'false'}&`;
    queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
    queryParams += parentSku ? `parentSku=${parentSku ?? ''}&` : '';
    queryParams += skuList ? `skuList=${skuList ?? ''}` : '';
    yield this.invList.fetch(queryParams);
  }
}

export default WishStore;
