import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Input, DatePicker } from 'antd';
import { RiSearchLine } from 'react-icons/ri';
import Tables from '../../components/Tables';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import Spin from '../../components/Spin/Spin';

const { Title } = Typography;

const AccountQuotes = observer(() => {
  const { currentClient } = useAuthContext();
  const { quotesStore } = stores;

  useEffect(() => {
    if (currentClient?.id) {
      quotesStore.loadQuotes(currentClient?.id);
    }
  }, [currentClient?.id]);

  useEffect(() => {
    quotesStore.searchResults = quotesStore.quotes.value.data;
  }, [quotesStore.quotes.value.data]);

  return (
    <main id="account-quotes">
      <Spin
        spinning={quotesStore.quotes.isLoading ?? false}
      >
        <Title
          className={styles.headerText}
          level={2}
        >
          Quotes
        </Title>
        <div className={styles.searchDatePickerContainer}>
          <div className={styles.datePickerContainer}>
            <DatePicker.RangePicker
              className={styles.datePicker}
              disabled={quotesStore.quotes.isLoading}
              onChange={(value) => quotesStore.setDateRange(value)}
            />
          </div>
          <div className={styles.searchContainer}>
            <Input
              size="large"
              className={`${styles.pageHeaderOptionSearch} ${styles.searchInput}`}
              placeholder="Search quotes..."
              defaultValue={quotesStore.query}
              prefix={<RiSearchLine color="#CBD5E0" />}
              disabled={quotesStore.quotes.isLoading}
              onChange={(_) => {
                quotesStore.setQuery(_.target.value);
              }}
            />
          </div>
        </div>

        <div className={styles.tableCon}>
          <Tables.QuotesTable
            quotes={quotesStore.searchResults.slice()}
          />

        </div>
      </Spin>
    </main>
  );
});

export default AccountQuotes;
