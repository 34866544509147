/* eslint-disable no-unused-vars */
import React, { ReactNode } from 'react';
import { ImHome } from 'react-icons/im';
import Icon from '@ant-design/icons';
import { AccountPermission } from '@bridgelabsdesign/gfox-api-client';
import { TbShoppingCartDiscount } from 'react-icons/tb';
import Home from './Home/Home';
import About from './About/About';
import Products from './Products/Products';
import Specials from './Specials/Specials';
import Contact from './Contact/Contact';
import AccountDashboard from './AccountDashboard/AccountDashboard';
import Images from '../assets';
import styles from './styles.module.css';
import AccountDiscounts from './AccountDiscounts/AccountDiscounts';
import AccountOrders from './AccountOrders/AccountOrders';
import AccountQuotes from './AccountQuotes/AccountQuotes';
import AccountInvoices from './AccountInvoices/AccountInvoices';
import AccountCreditNotes from './AccountCreditNotes/AccountCreditNotes';
import AccountMembers from './AccountMembers/AccountMembers';
import AccountProfile from './AccountProfile/AccountProfile';
import AccountOrdersInfo from './AccountOrdersInfo/AccountOrdersInfo';
import AccountOrdersTrack from './AccountOrdersTrack/AccountOrdersTrack';
import AccountOrdersChat from './AccountOrderChat/AccountOrdersChat';
import AddNewAddress from './AccountProfile/AddNewAddress';
import AccountLoyaltyCard from './AccountLoyaltyCard/AccountLoyaltyCard';

export enum Layout {
  Plain = 'plain',
  Header = 'header',
  SideBarWithHeader = 'sidebar_with_header',
  SideBar = 'sidebar',
}
export interface IRouteProps {
  component: React.FunctionComponent<any>,
}

export interface IHeaderLayoutProps extends IRouteProps {
  transparent?: boolean,
  headerContent: JSX.Element,
}
export interface ISideBarLayoutProps extends IRouteProps {
  sideBarContent: JSX.Element,
}
export interface IHeaderAndSideBarLayoutProps extends IRouteProps {
  headerContent: JSX.Element,
  sideBarContent: JSX.Element,
}
export interface IMenuList extends IRouteProps {
  path: string,
  exact: boolean,
  title: string,
  icon: ReactNode,
  layout: string,
  platformRights?: AccountPermission[];
}

export const AccountMenuList: IMenuList[] = [
  {
    title: 'Dashboard',
    path: '/account/dashboard',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.HeaderMenuIcon} />,
    component: () => <AccountDashboard />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.View, AccountPermission.Orders, AccountPermission.AccountAndPayments],
  },
  {
    title: 'Orders',
    path: '/account/orders',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.ShoppingListIcon} />,
    component: () => <AccountOrders />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.Orders],
  },
  {
    title: 'Order Information',
    path: '/account/orders/:refNo',
    exact: true,
    icon: undefined,
    component: () => <AccountOrdersInfo />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.Orders],
  },
  {
    title: 'Track Order',
    path: '/account/orders/:refNo/track',
    exact: true,
    icon: undefined,
    component: () => <AccountOrdersTrack />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.Orders],
  },

  {
    title: 'Quotes',
    path: '/account/quotes',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.FileIcon} />,
    component: () => <AccountQuotes />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.AccountAndPayments],
  },
  {
    title: 'Invoices',
    path: '/account/invoices',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.InvoiceIcon} />,
    component: () => <AccountInvoices />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.AccountAndPayments],
  },
  {
    title: 'Credit Notes',
    path: '/account/credit-notes',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.PaperNoteIcon} />,
    component: () => <AccountCreditNotes />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.AccountAndPayments],
  },
  {
    title: 'Members',
    path: '/account/members',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.AddUserIcon} />,
    component: () => <AccountMembers />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin],
  },
  {
    title: 'Discounts',
    path: '/account/discounts',
    exact: true,
    icon: <TbShoppingCartDiscount className={styles.accountDashboardIcon} />,
    component: () => <AccountDiscounts />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.Orders],
  },
  {
    title: 'My G. Fox Card',
    path: '/account/loyalty-card',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.CardIcon} />,
    component: () => <AccountLoyaltyCard />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.View],
  },
  {
    title: 'My Profile',
    path: '/account/profile',
    exact: true,
    icon: <Icon className={styles.accountDashboardIcon} component={Images.UserIcon} />,
    component: () => <AccountProfile />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.View, AccountPermission.Orders, AccountPermission.AccountAndPayments],
  },
  {
    title: 'My Address',
    path: '/account/profile/address',
    exact: true,
    icon: undefined,
    component: () => <AddNewAddress />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.View, AccountPermission.Orders, AccountPermission.AccountAndPayments],
  },
  {
    title: 'Chats',
    path: '/account/orders/:refNo/chat',
    exact: true,
    icon: undefined,
    component: () => <AccountOrdersChat />,
    layout: Layout.Header,
    platformRights: [AccountPermission.Admin, AccountPermission.View, AccountPermission.Orders, AccountPermission.AccountAndPayments],
  },

];

const MenuList: IMenuList[] = [
  {
    title: 'About',
    path: '/about',
    exact: true,
    icon: null,
    component: () => <About />,
    layout: Layout.Header,

  },
  {
    title: 'Products',
    path: '/products',
    exact: true,
    icon: null,
    component: () => <Products />,
    layout: Layout.Header,

  },
  {
    title: 'Specials',
    path: '/specials',
    exact: true,
    icon: null,
    component: () => <Specials />,
    layout: Layout.Header,

  },
  {
    title: 'Contact',
    path: '/contact',
    exact: true,
    icon: null,
    component: () => <Contact />,
    layout: Layout.Header,

  },
];
export default MenuList;
