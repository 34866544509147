import React from 'react';
import Icon from '@ant-design/icons';
import Typography from '../Typography';
import styles from './styles.module.css';
import Images from '../../assets';

const { Link } = Typography;

interface IFacebookIconLinkProp {
  className?: string;
}

const FacebookIconLink = ({ className }: IFacebookIconLinkProp) => (
  <Link
    external
    href="https://www.facebook.com/GFoxZAofficial/"
  >
    <Icon
      className={[styles.facebookIcon, className].join(' ')}
      component={Images.FacebookIcon}
    />
  </Link>
);

FacebookIconLink.defaultProps = {
  className: undefined,
};

export default FacebookIconLink;
