import APIClient, {
  IClient, IUserAuth, IAddress, ILoginRequest,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import { copyObjects } from '../utils/copyObjects';
import { isEmptyString } from '../utils/strings';

export enum LoginType {
  // eslint-disable-next-line no-unused-vars
  APPLE = 'apple',
  // eslint-disable-next-line no-unused-vars
  EMAIL_PASSWORD = 'email_password',
  // eslint-disable-next-line no-unused-vars
  GOOGLE = 'google',
}
class AuthStore {
  isAccountMigration = false;

  registeredUserAuth: IUserAuth | null = null;

  registeredClient: IClient | null = null;

  registerType: LoginType = LoginType.EMAIL_PASSWORD;

  registerPostalAddress: IAddress | null = null; // TODO: remove thie reference

  registerAddress: IAddress | null = null;

  constructor() {
    makeObservable(this, {
      isAccountMigration: observable,
      registeredUserAuth: observable,
      registeredClient: observable,
      // registerPostalAddress: observable,
      valuesForm: action,
      requestPasswordReset: action,
      setIsAccountMigration: action,
    });
  }

  valuesForm(value: any, branchId: string, communicationConsent: boolean) {
    this.registeredUserAuth = {
      firstName: value.firstName,
      lastName: value.lastName,
      emailAddress: value.emailAddress,
      password: value.password,
      authType: this.registerType,
      verified: value.verified,
    } as IUserAuth;

    this.registeredClient = {
      billingPhone: value.billingPhone,
      accountNumber: value.accountNumber,
      branchId,
      billingCompany: value.billingCompany,
      communicationConsent,
    } as IClient;

    this.registerPostalAddress = {
      address1: value.address1,
      address2: value.address2,
      address3: value.address3,
      city: value.city,
      province: value.province,
      postalCode: value.postalCode,
      country: value.country,
    } as IAddress;

    this.registerAddress = {
      address1: value.address1,
      address2: value.address2,
      address3: value.address3,
      city: value.city,
      province: value.province,
      postalCode: value.postalCode,
      country: value.country,
      hubCode: value.hubCode,
    } as IAddress;
  }

  get clientFromFormValues() {
    let {
      registeredClient,
      // eslint-disable-next-line prefer-const
      registeredUserAuth,
    } = this;

    const clientValues: Partial<IClient> = {

      branchId: registeredClient?.branchId,
      communicationConsent: registeredClient?.communicationConsent,
      billingPhone: registeredClient?.billingPhone,
      billingCompany: registeredClient?.billingCompany,
    };

    registeredClient = copyObjects(registeredClient, clientValues);
    registeredUserAuth!.clients = [];
    registeredClient.userAuth = registeredUserAuth!;
    return registeredClient;
  }

  async createProfile(hashPassword = false): Promise<IClient | null> {
    // this.communicationConsent = !!communicationConsent;
    const queryParams = `hashPassword=${hashPassword}`;
    try {
      message.success('Registering account...');
      const body: { client: IClient; address?: IAddress } = { client: this.clientFromFormValues };
      if (this.registerAddress != null && this.registerAddress.postalCode != null) {
        body.address = this.registerAddress;
      }
      const currentClient = await APIClient.Client.signUpClient(body, queryParams);
      if (!this.isAccountMigration && !isEmptyString(currentClient.accountNumber)) {
        await APIClient.Client.linkAccountNumber(currentClient.accountNumber, currentClient.userAuth);
      }
      message.destroy();
      return currentClient;
    } catch (error) {
      console.error(error);
      message.error('Could not register account.');
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async requestPasswordReset(body: ILoginRequest) {
    const hide = message.loading('Sending request to email...', 0);
    const requestBodyWithSource = {
      ...body,
      source: 'website',
    };
    try {
      await APIClient.UserAuth.forgotPassword(requestBodyWithSource);
      message.success('Request sent to email address.');
    } catch (error) {
      message.error('Please request to link account number.');
      // eslint-disable-next-line no-console
      console.error('authStore reset password error:', error);
    }
    hide();
  }

  setIsAccountMigration(value: boolean) {
    this.isAccountMigration = value;
  }
}

export default AuthStore;
