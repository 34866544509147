import Icon from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import React, { } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import Buttons from '.';
import stores from '../../stores/stores';
import Typography from '../Typography';
import styles from './styles.module.css';

interface IShopMoreBtnProps {
  shopCategories: {
    title: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    refNo: string;
  }[]
}

const { Link } = Typography;

const ShopMoreBtn = observer(({
  shopCategories,
}: IShopMoreBtnProps) => {
  const { categoryStore } = stores;
  const categories = categoryStore.categories.value.data;

  const generateLink = (refNo?: string): string => {
    const idx = categories.findIndex((x) => x.refNo === refNo);
    return idx >= 0 ? `/products/${refNo}` : '/specials';
  };

  return (
    <div className={styles.carouselOptionsContainer}>
      {/* TODO: link to products page */}
      {React.Children.toArray(
        shopCategories.map((sItem) => (
          <Link
            href={generateLink(sItem?.refNo)}
            className={styles.carouselCategory}
          >
            <Icon
              className={styles.carouselCategoryIcon}
              component={sItem.icon}
            />
            {sItem.title}
          </Link>
        )),
      )}
      <Buttons.LinkBtn
        containerClassName={styles.carouselCtaBtnLink}
        className={styles.carouselCtaBtn}
        link={{ href: '/specials' }}
        iconRight={(
          <FiChevronRight
            className={styles.carouselMoreIcon}
          />
        )}
      >
        Shop More
      </Buttons.LinkBtn>
    </div>
  );
});

export default ShopMoreBtn;
