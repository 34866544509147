/* eslint-disable */
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import Form from '../../components/Form';
import styles from './styles.module.css';
import Link from '../../components/Typography/Link';
import stores from '../../stores/stores';

export interface IFormHelper {
  header: string;
  headerJSX?: JSX.Element;
  section?: 'address' | undefined,
  forms: {
    name: string;
    label: string;
    required: boolean;
    whitespace: boolean | undefined;
    pattern: RegExp | undefined;
    type: 'string' | 'email' | 'boolean';
    formType: string;
    input: JSX.Element;
    note?: string;
    responsive: boolean,
  }[]
}

const accountProfileForm: IFormHelper[] = [
  {
    header: 'Your Details',
    forms: [
      {
        name: 'firstName',
        label: 'First Name',
        whitespace: true,
        required: true,
        pattern: undefined,
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your name" rounded />,
        responsive: true,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        required: true,
        pattern: undefined,
        type: 'string',
        whitespace: true,
        formType: 'input',
        input: <Form.Input placeholder="Enter your surname" rounded />,
        responsive: true,
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        required: true,
        pattern: undefined,
        type: 'email',
        formType: 'input',
        input: <Form.Input placeholder="Enter your email address" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'billingCompany',
        label: 'Company Name',
        required: false,
        pattern: undefined,
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your company name" rounded />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'billingPhone',
        label: 'Cellphone',
        required: true,
        pattern: new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'),
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your phone number" rounded />,
        whitespace: true,
        responsive: true,
      },
    ],
  },
  {
    header: 'Select your nearest branch:',
    forms: [
      {
        name: 'branchName',
        label: 'This is the branch through which your orders will be processed.',
        required: true,
        pattern: undefined,
        type: 'string',
        formType: 'select',
        input: <Form.Select options={[]} placeholder="Select the branch" rounded />,
        whitespace: true,
        responsive: false,
      },
      {
        name: 'accountNumber',
        label: 'If you have an account with G.Fox enter your account number below',
        note: 'Note: Your account will not be active until your account number has been verified',
        required: false,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your account number" rounded />,
        whitespace: true,
        responsive: false,
      },
    ],
  },

  {

    header: 'Delivery Address:',
    headerJSX: (
      <Link
        className={styles.circularButton}
        href="/account/profile/address"
      >
        <PlusOutlined />
      </Link>
    ),
    section: 'address',
    forms: [
      {
        name: 'address1',
        label: 'Address line 1',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your address" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'address2',
        label: 'Address line 2',
        required: false,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your address" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'city',
        label: 'City',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your city name" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'province',
        label: 'Select province',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Select your Province" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'country',
        label: 'Country',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your Country" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'postalCode',
        label: 'Postal code',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter postal code" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
    ],
  },
  // {
  //   header: 'Postal Address:',
  //   forms: [
  //     {
  //       name: '',
  //       label: '',
  //       required: false,
  //       formType: 'radio',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Radio value={3}>Same address as above</Radio>,
  //       whitespace: true,
  //       responsive: false,
  //     },
  //     {
  //       name: '',
  //       label: '',
  //       required: false,
  //       formType: 'radio',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <></>,
  //       whitespace: true,
  //       responsive: false,
  //     },
  //     {
  //       name: 'address1',
  //       label: 'Address line 1',
  //       required: true,
  //       formType: 'input',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Form.Input placeholder="Enter your name" rounded />,
  //       whitespace: true,
  //       responsive: true,
  //     },
  //     {
  //       name: 'address2',
  //       label: 'Address line 2',
  //       required: true,
  //       formType: 'input',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Form.Input placeholder="Enter your surname" rounded />,
  //       whitespace: true,
  //       responsive: true,
  //     },
  //     {
  //       name: 'city',
  //       label: 'City',
  //       required: true,
  //       formType: 'input',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Form.Input placeholder="Enter your email address" rounded />,
  //       whitespace: true,
  //       responsive: true,
  //     },
  //     {
  //       name: 'province',
  //       label: 'Select province',
  //       required: true,
  //       formType: 'input',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Form.Input placeholder="Enter your company name" rounded />,
  //       whitespace: true,
  //       responsive: true,
  //     },
  //     {
  //       name: 'country',
  //       label: 'Country',
  //       required: true,
  //       formType: 'input',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Form.Input placeholder="Enter your phone number" rounded />,
  //       whitespace: true,
  //       responsive: true,
  //     },
  //     {
  //       name: 'postalCode',
  //       label: 'Postal code',
  //       required: true,
  //       formType: 'input',
  //       pattern: undefined,
  //       type: 'string',
  //       input: <Form.Input placeholder="Enter your phone number" rounded />,
  //       whitespace: true,
  //       responsive: true,
  //     },
  //   ],
  // },
];

export default accountProfileForm;
