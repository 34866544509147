import React from 'react';
import { Input as AntdInput, Form as AntdForm } from 'antd';
import { FiChevronRight } from 'react-icons/fi';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react-lite';
import Input from './Input';
import styles from './styles.module.css';
import Buttons from '../Buttons';
import stores from '../../stores/stores';
import { validateMessages } from '../../utils/form';

interface ISubscribeNewsletterProps {
  containerClass?: string;
  footer?: boolean;
  placeholder?: string | undefined;
}

interface IFormType {
  emailAddress: string;
}

const SubscribeNewsletter = observer(({
  containerClass = undefined,
  footer = false,
  placeholder = 'Your email address',
}: ISubscribeNewsletterProps) => {
  const [form] = useForm();
  const { userAuthStore } = stores;

  const handleOnSubmit = async (values: IFormType) => {
    await userAuthStore.subscribeNewsletter(values);
  };

  return (
    <AntdForm<IFormType>
      form={form}
      name="mailing-list"
      requiredMark
      validateMessages={validateMessages}
      onFinish={handleOnSubmit}
    >
      <AntdForm.Item
        name="emailAddress"
        required
        rules={[{ required: true }]}
      >
        <AntdInput.Group
          className={[
            styles.subscribeNewsletterCon,
            footer && styles.subscribeNewsletterFooterCon,
            containerClass,
          ].join(' ')}
          compact
        >
          <Input
            type="email"
            className={[
              styles.subscribeNewsletterInput,
              footer && styles.newsletterFooterInput,
            ].join(' ')}
            placeholder={placeholder}
            onChange={(x) => form.setFieldValue('emailAddress', x.target.value)}
          />

          <Buttons.PrimaryBtn
            htmlType="submit"
            className={styles.subscribeNewsletterBtn}
            icon={!footer && (
              <FiChevronRight
                className={styles.subscribeNewsletterBtnIcon}
              />
            )}
          >
            { footer && 'SEND' }
          </Buttons.PrimaryBtn>

        </AntdInput.Group>
      </AntdForm.Item>
    </AntdForm>
  );
});

export default SubscribeNewsletter;
