import React from 'react';
import { Table } from 'antd';
import { IQuote } from '@bridgelabsdesign/gfox-api-client';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './quotes-summary.module.css';
import { isEmptyString } from '../../utils/strings';

const { Link, Text } = Typography;

interface IQuotesSummaryTableProps {
  quotes: IQuote[]
}

const QuotesSummaryTable = ({
  quotes,
}: IQuotesSummaryTableProps) => {
  const tableColumns = [
    {
      title: 'Quote No:',
      dataIndex: 'refNo',
      key: 'refNo',
      render: (text: string) => (
        <Text className={styles.colRefNo}>
          {text}
        </Text>
      ),
    },
    {
      title: 'Status:',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: IQuote) => (
        <Text className={styles.colStatus}>
          {`${!isEmptyString(record.fileUrl) ? 'Ready' : 'Processing'}`}
        </Text>
      ),
    },
    {
      title: 'Action:',
      dataIndex: 'id',
      key: 'action',
      render: (text: string, record: IQuote) => (
        <Buttons.SecondaryBtn
          className={styles.colViewBtn}
          type="text"
          disabled={isEmptyString(record.fileUrl)}
        >
          <Link
            href={record.fileUrl}
            external
          >
            View
          </Link>
        </Buttons.SecondaryBtn>
      ),
    },
  ];

  return (
    <>
      <div className={styles.tableCon}>
        {/* TODO: format table header & add spacing between rows */}
        <Table
          className="account-table"
          dataSource={quotes}
          columns={tableColumns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
        />
      </div>
    </>
  );
};

export default QuotesSummaryTable;
