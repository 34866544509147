import { Radio, Typography } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import Tables from '../../components/Tables';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import { LegalDocuments } from '../../utils/legal';
import { useAuthContext } from '../../context/AuthContext';
import Link from '../../components/Typography/Link';

const { Text } = Typography;

const CheckoutSummary = observer(() => {
  const { checkoutStore } = stores;
  const { currentClient } = useAuthContext();

  return (
    <main id="checkout-summary" className={styles.checkoutCon}>
      <Helmet>
        <title>Order Summary - G. Fox</title>
      </Helmet>
      <Radio
        className={styles.checkoutTermsRadio}
        checked={checkoutStore.acceptTsAndCs}
        onClick={() => checkoutStore.setAcceptTsAndCs(!checkoutStore.acceptTsAndCs)}
      >
        <Text>
          I have read and accept the T&C’s, data protection declaration and Cancellation policy of the company G.Fox South Africa.
        </Text>
      </Radio>
      <div className={styles.checkoutItemsCon}>
        <Tables.CheckoutItemsTable edit={false} />
      </div>
      <Radio
        className={styles.checkoutTermsBottomRadio}
        checked={checkoutStore.acceptTsAndCs}
        onClick={() => checkoutStore.setAcceptTsAndCs(!checkoutStore.acceptTsAndCs)}
      >
        <Text>
          I have read and accept the T&C’s, data protection declaration and Cancellation policy of the company G.Fox South Africa.
        </Text>
      </Radio>

      {/* TODO: should sub or unsub to mailing list */}
      {!currentClient?.communicationConsent && (
        <>
          <Text className={styles.checkoutNewsletterHeader}>
            Subscribe to our Newsletter
          </Text>
          <Text className={styles.checkoutNewsletterText}>
            Subscribe to receive our specials & promotions newsletter
          </Text>
          <Radio
            className={styles.checkoutNewsletterRadio}
            checked={checkoutStore.subNewsletter}
            onClick={() => checkoutStore.setSubNewsletter(!checkoutStore.subNewsletter)}
          >
            <Text>
              {/* eslint-disable-next-line max-len */}
              I hereby allow G.Fox to send me regular product updates by email. This includes information about shoes, clothing, health & safety, company supplies and office items, and industry-specific events. I can withdraw my consent to G.Fox at any time. For more information, see the
              {' '}
              <Link style={{ display: 'inline-block' }} href={LegalDocuments.TRADING_TERMS} external>
                {' '}
                <Text className={styles.registerLabelredBold}>Privacy Policy</Text>
              </Link>
            </Text>
          </Radio>
        </>
      )}

    </main>
  );
});

export default CheckoutSummary;
