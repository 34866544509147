import React, { useMemo } from 'react';
import { Table } from 'antd';
import { IClientGetInvoicesResponse, ICustomerInvoice } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './quotes-summary.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';

const { Text } = Typography;
interface IInvoicesTableProps {
  invoices: IClientGetInvoicesResponse[];
  isCreditNote?: boolean
}

const InvoicesTable = observer(({
  invoices,
  isCreditNote = false,
}: IInvoicesTableProps) => {
  const { isMobileWindow } = useWindowSize();
  const { clientStore } = stores;
  const { currentClient } = useAuthContext();

  const handleViewInvoice = async (invoice: ICustomerInvoice) => {
    if (currentClient) {
      await clientStore.generateInvoicePDF(currentClient, invoice, isCreditNote);
    }
  };

  const tableColumns = [
    {
      title: 'Purchase Order No.:',
      key: 'customerPoNumber',
      showMobile: true,
      render: (text: string, record: IClientGetInvoicesResponse) => (
        <Text className={styles.colRefNo}>
          {record?.invoice?.customerPoNumber ?? ''}
        </Text>
      ),
    },
    {
      title: isCreditNote ? 'Credit Note ID:' : 'Invoice ID:',
      dataIndex: 'invoiceNumber',
      showMobile: true,
      render: (text: string, record: IClientGetInvoicesResponse) => (
        <Text className={styles.colRefNo}>
          {record?.invoice?.invoiceNumber ?? ''}
        </Text>
      ),
    },
    {
      title: 'Sales Order',
      key: 'salesOrder',
      showMobile: true,
      render: (text: string, record: IClientGetInvoicesResponse) => (
        <Text className={styles.colRefNo}>
          {record?.invoice?.salesOrder ?? ''}
        </Text>
      ),
    },
    {
      title: 'Date:',
      dataIndex: 'invoiceDate',
      showMobile: false,
      render: (text: string, record: IClientGetInvoicesResponse) => (
        <Text className={styles.colRefNo}>
          {/* TODO: format date */}
          {record?.invoice?.invoiceDate ?? ''}
        </Text>
      ),
    },
    {
      title: 'Action:',
      dataIndex: 'id',
      showMobile: true,
      key: 'action',
      render: (text: string, record: IClientGetInvoicesResponse) => (
        <Buttons.SecondaryBtn
          className={styles.colViewBtn}
          type="text"
          onClick={() => handleViewInvoice(record.invoice)}
        >
          View
        </Buttons.SecondaryBtn>
      ),
    },
  ];

  // eslint-disable-next-line arrow-body-style
  const columns = useMemo(() => {
    return !isMobileWindow ? tableColumns : tableColumns.filter((x) => x.showMobile);
  }, [isMobileWindow, tableColumns]);

  return (
    <>
      <div className={styles.tableCon}>
        {/* TODO: center no data icon indicator */}
        <Table
          className="account-table"
          dataSource={invoices}
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
        />
      </div>
    </>
  );
});

export default InvoicesTable;
