import { Empty } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import Buttons from '../../components/Buttons';
import Typography from '../../components/Typography';
import styles from './styles.module.css';

const { Title } = Typography;

const Lost = () => (
  <main id="lost">
    <Helmet>
      <title>Lost - G. Fox</title>
    </Helmet>
    <div className={styles.lostCon}>
      <Title
        className={styles.lostHeader}
        level={2}
      >
        404
      </Title>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
      <div>
        <Buttons.LinkBtn
          link={{ href: '/specials' }}
          type="default"
        >
          Explore Products
        </Buttons.LinkBtn>
      </div>
    </div>
  </main>
);

export default Lost;
