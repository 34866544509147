// /src/stores/CustomPageStore.js
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import APIClient, { ICustomPage, LoadManager } from '@bridgelabsdesign/gfox-api-client';

export class CustomPageStore {
        pages = new LoadManager<ICustomPage>({ data: [] }, APIClient.CustomPage.getCustomPages);

        isLoading = false;

        singleCustomPage?: ICustomPage;

        constructor() {
          makeObservable(this, {
            pages: observable,
            isLoading: observable,
            singleCustomPage: observable,
            loadCustomPages: action,
            loadCustomPageById: action,
            loadCustomPageByName: action,
            customPagesList: computed,
          });
        }

        get customPagesList(): ICustomPage[] {
          return this.pages.value.data.slice();
        }

        loadCustomPages = async () => {
          if (this.isLoading) {
            return;
          }

          try {
            this.isLoading = true;
            await this.pages.fetch();
          } catch (error) {
            console.error('Error loading custom pages:', error);
          } finally {
            this.isLoading = false;
          }
        };

        loadCustomPageById = async (id: string) => {
          if (this.isLoading) {
            return;
          }

          try {
            this.isLoading = true;
            this.singleCustomPage = await APIClient.CustomPage.getCustomPageById(id);
          } catch (error) {
            console.error('Error loading custom page by ID:', error);
          } finally {
            this.isLoading = false;
          }
        };

        loadCustomPageByName = async (name: string) => {
          if (this.isLoading) {
            return;
          }

          try {
            this.isLoading = true;
            this.singleCustomPage = await APIClient.CustomPage.getCustomPageByName(name);
          } catch (error) {
            console.error('Error loading custom page by name:', error);
          } finally {
            this.isLoading = false;
          }
        };
}

export default CustomPageStore;
