import { AccountPermission } from '@bridgelabsdesign/gfox-api-client';
import { Form as AntdForm, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoClose } from 'react-icons/io5';
import Buttons from '../../components/Buttons';
import Form from '../../components/Form';
import { ISelectOptions } from '../../components/Form/Select';
import Spin from '../../components/Spin/Spin';
import Tables from '../../components/Tables';
// import Typography from '../../components/Typography';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import { validateMessages } from '../../utils/form';
import { isEmptyString } from '../../utils/strings';
import styles from './styles.module.css';

// const { Text } = Typography;
export const permissionOptions: ISelectOptions[] = [
  { label: 'Admin', value: AccountPermission.Admin },
  { label: 'Accounts and Payments', value: AccountPermission.AccountAndPayments },
  { label: 'Orders', value: AccountPermission.Orders },
  { label: 'View Only', value: AccountPermission.View },
];

const AccountMembers = observer(() => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [form] = useForm();
  const { currentClient, getCurrentClient } = useAuthContext();
  const { linkedAccountsStore } = stores;

  // eslint-disable-next-line no-unused-vars
  const handleOnInvite = () => {
    setIsFormModalOpen(true);
  };

  const handleFormSubmit = async (values: any) => {
    if (!currentClient) {
      message.error('Could not invite team member');
      return;
    }
    const { emailAddress, permissions } = values;
    const res = await linkedAccountsStore.requestAccountLink(currentClient?.accountNumber, emailAddress, permissions);
    if (res) {
      setIsFormModalOpen(false);
    }
  };

  const handleOnFormFail = () => {
    message.error('Could not invite team member');
  };

  const handleOnEdit = (emailAddress?: string) => {
    setIsFormModalOpen(true);
    form.setFieldValue('emailAddress', emailAddress);
  };

  useEffect(() => {
    if (currentClient) {
      const userAuthId = currentClient?.userAuthId;
      const clientId = currentClient?.id;
      linkedAccountsStore.fetchLinkedAccounts({ clientId, userAuthId });
    }
  }, [currentClient]);

  return (
    <main id="account-members">
      <Helmet>
        <title>Members - G. Fox</title>
      </Helmet>
      <Spin
        tip=""
        spinning={linkedAccountsStore.linkedAccounts.isLoading}
      >
        <Buttons.SecondaryBtn
          onClick={handleOnInvite}
          className={styles.inviteBtn}
          disabled={isEmptyString(currentClient?.accountNumber)}
          style={{ marginBottom: '1em' }}
        >
          Invite member
        </Buttons.SecondaryBtn>

        <Tables.AccountMembersTable
          accounts={linkedAccountsStore.linkedAccounts.value.data.slice()}
          onEdit={handleOnEdit}
        />

        <Modal
          title="Invite member"
          className={styles.checkoutModal}
          visible={isFormModalOpen}
          onOk={async () => {
            form.submit();
            await getCurrentClient!(false);
          }}
          onCancel={() => setIsFormModalOpen(false)}
          okText="Continue"
          cancelText="Cancel"
          okButtonProps={{ style: { height: '3em' } }}
          cancelButtonProps={{ style: { height: '3em' } }}
          closeIcon={(<IoClose />)}
        >
          <div className={styles.formModalBody}>
            <AntdForm
              form={form}
              name="account-members"
              validateMessages={validateMessages}
              className="custom-form"
              onFinish={handleFormSubmit}
              onFinishFailed={handleOnFormFail}
              layout="vertical"
              requiredMark={false}
              style={{ width: '100%' }}
            >
              <AntdForm.Item
                name="emailAddress"
                rules={[
                  { required: true },
                  { type: 'email' },
                ]}
              >
                <Form.Input placeholder="Email address" rounded />
              </AntdForm.Item>
              <AntdForm.Item
                name="permissions"
                rules={[{ required: true }]}
              >
                <Form.Select
                  placeholder="Permissions"
                  mode="tags"
                  options={permissionOptions}
                  rounded
                />
              </AntdForm.Item>
            </AntdForm>
          </div>
        </Modal>
      </Spin>
    </main>
  );
});

export default AccountMembers;
