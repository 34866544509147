import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';
import styles from './styles.module.css';

export interface IPrimaryBtn extends Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'type'
> {
  htmlType?: ButtonHTMLType;
  type?: ButtonType;
  icon?: ReactNode; // TODO: Make options of either icon or iconRight (not both)
  iconRightClassName?: string;
  iconRight?: ReactNode;
}

const PrimaryBtn = ({
  children,
  className,
  htmlType,
  icon,
  iconRightClassName,
  iconRight,
  type,
  ...props
}: IPrimaryBtn) => (
  <Button
    className={[
      styles.primaryBtn,
      !!icon && !children ? styles.primaryBtnIconOnly : '',
      className,
    ].join(' ')}
    type={type}
    htmlType={htmlType}
    icon={icon}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
    {iconRight && (
      <span
        className={[styles.primaryBtnIconRight, iconRightClassName].join(' ')}
      >
        {iconRight}
      </span>
    )}
  </Button>
);

PrimaryBtn.defaultProps = {
  htmlType: 'button',
  icon: undefined,
  iconRightClassName: undefined,
  iconRight: undefined,
  type: 'primary',
};

export default PrimaryBtn;
