import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.css';
import Images from '../../assets';
import Typography from '../Typography';
import data from './data';
import FooterItems from './FooterItems';
import Form from '../Form';
import FacebookIconLink from './FacebookIconLink';
import LinkedInIconLink from './LinkedinLink';
import stores from '../../stores/stores';

const { Text } = Typography;

const Footer = observer(() => {
  const [expandedHeader, setExpandedHeader] = useState('');
  const [footerItems, setFooterItems] = useState(data);

  const { categoryStore } = stores;
  const categories = categoryStore.categories.value.data;

  const handleOnClickHeader = (header: string) => {
    if (header !== expandedHeader) {
      setExpandedHeader(header);
      return;
    }
    setExpandedHeader('');
  };

  const handleSetFooterItems = () => {
    const idx = footerItems?.findIndex((x) => x.header === 'Products');
    if (idx < 0) {
      return;
    }
    const items = footerItems.slice();
    items[idx].routes = categories.map((x) => ({ title: x.name, href: `/products/${x?.refNo}` }));
    items[idx].routes.push({ title: 'New Products', href: '/products/new' });
    setFooterItems(items);
  };

  useEffect(() => {
    handleSetFooterItems();
  }, [categories]);

  return (
    <footer className={styles.container}>
      <div className={styles.mainHeader}>
        <FacebookIconLink
          className={styles.facebookIconMobile}
        />
      </div>
      <div className={styles.mainHHeader}>
        <LinkedInIconLink className={styles.facebookIconMobile} />
      </div>
      <div className={styles.itemListContainer}>
        {data.map((item) => (
          <div
            key={item.header}
            className={styles.itemContainer}
            onClick={() => handleOnClickHeader(item.header)}
            role="button"
            aria-hidden="true"
          >
            <div className={styles.itemContent}>
              <div key={item.header} className={styles.itemHeader}>
                <Text className={styles.itemHeaderText}>
                  {item.header}
                </Text>
                <Icon
                  className={styles.expandIcon}
                  component={Images.ArrowDownIcon}
                />
              </div>

              <div className={styles.footerItemMobile}>
                <FooterItems
                  items={item.routes}
                  visible={item.header === expandedHeader}
                />
              </div>
              <div className={styles.footerItemLaptop}>
                <FooterItems items={item.routes} visible />
              </div>
              <div className={styles.socialMediaCon}>
                <FacebookIconLink
                  className={styles.facebookDesktopIcon}
                />

                <LinkedInIconLink
                  className={styles.facebookDesktopIcon}
                />
              </div>
            </div>
          </div>
        ))}

        <div className={styles.newsletterContainer}>
          <Text className={styles.itemHeaderText}>
            Subscribe
          </Text>
          <Text className={styles.newsletterText}>
            Subscribe to receive our specials & promotions newsletter
          </Text>
          <div className={styles.newsletterInput}>
            <Form.SubscribeNewsletter footer />
          </div>
        </div>

      </div>
      <div className={styles.copyrightContainer}>
        <Text className={styles.copyrightText}>
          Copyright © 2024 G. Fox
        </Text>
      </div>
    </footer>
  );
});

export default Footer;
