// /src/pages/CustomPage/CustomPage.js
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spin from '../../components/Spin/Spin';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import { capitalizeFirstLetter } from '../../utils/strings';

const CustomPage = observer(() => {
  const { CustomPageStore } = stores;
  const { pageName } = useParams<{ pageName: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadCustomPage = async () => {
      setIsLoading(true);
      try {
        await CustomPageStore.loadCustomPageByName(pageName);
        if (!CustomPageStore.singleCustomPage || !CustomPageStore.singleCustomPage.htmlContent) {
          history.push('/lost');
        }
      } catch (error) {
        console.error('Error loading custom page:', error);
        history.push('/lost');
      } finally {
        setIsLoading(false);
      }
    };

    loadCustomPage();
  }, [pageName, CustomPageStore, history]);

  if (isLoading) {
    return (
      <div className={styles.spinContainer}>
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (!CustomPageStore.singleCustomPage || !CustomPageStore.singleCustomPage.htmlContent) {
    history.push('/lost');
    return null;
  }

  const { htmlContent } = CustomPageStore.singleCustomPage;
  const capitalizedPageName = capitalizeFirstLetter(pageName);

  return (
    <main className={styles.customPageContainer}>
      <Helmet>
        <title>{`${capitalizedPageName} - G. Fox`}</title>
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </main>
  );
});

export default CustomPage;
