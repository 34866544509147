import React from 'react';
import { Input as AntdInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import styles from './styles.module.css';

interface IInputProps extends TextAreaProps {
  className?: string;
  placeholder?: string;
}

const TextArea = ({ className, placeholder, ...rest }: IInputProps) => (
  <AntdInput.TextArea
    className={[styles.input, styles.inputTextArea, className].join(' ')}
    placeholder={placeholder}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

TextArea.defaultProps = {
  className: undefined,
  placeholder: undefined,
};

export default TextArea;
