import APIClient, {
  LoadManager, ICart, IInventoryMaster, ISkuMaster, IClient, ICategory, ICategorySpecificInfo, IStockQuantityResponse, IStockQuantityRequest,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import {
  action,
  computed, flow, makeObservable, observable,
} from 'mobx';
import { calcProductPrice } from '../utils/pricing';
import QuoteReorder from '../utils/quotes/quote-reorder';
import { ILoadInventoryListParams } from './inventory';
import * as cartStorage from '../utils/cartStorage';
import { findSizingIndex, selectedInvItem } from '../utils/productSelection';
import { getStockMessage } from '../utils/order/stockMessage';
import { compareStrings } from '../utils/strings';

export type IProductSelectionQuery = {
  colors: string[];
  filterBy: ICategorySpecificInfo[],
}

class CartStore {
  private apiCartItems: LoadManager<ICart>= new LoadManager<ICart>({ data: [] },
    APIClient.Cart.getCarts,
    APIClient.Cart.addCart,
    APIClient.Cart.updateCart,
    APIClient.Cart.deleteCart);

  private localCartItems: LoadManager<ICart> = new LoadManager<ICart>({ data: [] },
    cartStorage.getCarts,
    cartStorage.addCart,
    cartStorage.updateCart,
    cartStorage.deleteCart);

  cartItems: LoadManager<ICart> = this.localCartItems;

  skuList = new LoadManager<ISkuMaster>({ data: [] }, APIClient.SKUMasterV2.getSkuMasters);

  invList = new LoadManager<IInventoryMaster>({ data: [] }, APIClient.InventoryV2.getInventoryMasters);

  sku: ISkuMaster | undefined = undefined;

  activeImageIdx = 0;

  lineItemCount = 1;

  selectedProductItems: ICart[] = []

  realTimeStockQuantity: IStockQuantityResponse[] = [];

  productSelectionQuery: IProductSelectionQuery = { colors: [], filterBy: [] };

  productQueryFilterBy: ICategory[] = [];

  voucherClaim?: { refNo: string, claimId: string } | undefined;

  pageInventoryMasterSku?: string | null;

  constructor() {
    makeObservable(this, {
      cartItems: observable,
      // getSkuById: action,
      sku: observable,
      setVoucherClaim: action,
      invList: observable,
      activeImageIdx: observable,
      realTimeStockQuantity: observable,
      fetchRealTimeStockQuantity: action,
      clearSelectedProductItems: action,
      cartMatchedProductSelection: action,
      lineItemCount: observable,
      productSelectionQuery: observable,
      setSelectedProductItems: action,
      updateQuantity: action,
      voucherClaim: observable,
      pageInventoryMasterSku: observable,
      cartItemsInvPricing: computed,
      isLoadingCartItems: computed,
      selectedProductItems: observable,
      removeProduct: action,
      cartCount: computed,
      currentSkuItem: computed,
      currentInvItem: computed,
      skuDescription: computed,
      setProductSelectionQuery: action,
      setLineItemCount: action,
      updateToCartItems: action,
      removeCartItem: action,
      clearCart: action,
      addToCart: action,
      setActiveImageIdx: action,
      addLineItemToCart: action,
      loadCartItems: flow,
      loadCartInfo: flow,
      loadSkuList: flow,
      loadInventoryList: flow,
    });
  }

  setSku = action((sku: ISkuMaster | undefined) => {
    this.sku = sku;
  });

  setVoucherClaim(value: { refNo: string, claimId: string } | undefined) {
    this.voucherClaim = value;
  }

  get cartItemsInvPricing() {
    const cartItems = this.cartItems.value.data.slice();
    const invList = this.invList.value.data.slice();

    const pricing = {
      vatTotal: 0,
      totalWithVat: 0,
    };

    if (invList.length > 0 && cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i += 1) {
        const invItem = invList.find((inv) => inv?.id === cartItems[i].inventoryMasterId);
        const productPrice = calcProductPrice(invItem);
        pricing.totalWithVat += (productPrice.withTax * cartItems[i].quantity);
        if (productPrice.withTax !== productPrice.excludingTax) {
          const vat = (productPrice.withTax - productPrice.excludingTax);
          pricing.vatTotal += (vat * cartItems[i].quantity);
        }
      }
    }

    return pricing;
  }

  get isLoadingCartItems(): boolean {
    return this.cartItems.isLoading || this.skuList.isLoading || this.invList.isLoading;
  }

  get cartCount(): number {
    if (this.cartItems.value.data.length === 0) {
      return 0;
    }
    return this.cartItems.value.data.reduce((p, c) => p + c.quantity, 0);
  }

  get currentSkuItem(): ISkuMaster | undefined {
    if (this.skuList?.value.data.length > 0) {
      return this.skuList?.value.data[0];
    }
    return undefined;
  }

  get currentInvItem(): IInventoryMaster | undefined {
    if (this.pageInventoryMasterSku) {
      const invItem = this.invList.value.data.find((x) => x.sku === this.pageInventoryMasterSku);
      if (invItem) {
        return invItem;
      }
    }

    const data = selectedInvItem(this.invList.value.data, this.productSelectionQuery);

    if (data.length > 0) {
      return data[0];
    }

    let smallestItem = data[0];
    let smallestValue = Infinity;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.invList.value.data) {
      const sizes = item.categorySpecificInfo
        ?.filter((info) => compareStrings(info.name, 'Size'))
        .map((info) => findSizingIndex(info.value.toString()));

      if (sizes && sizes.length > 0) {
        const minValue = Math.min(...sizes);
        if (minValue < smallestValue) {
          smallestValue = minValue;
          smallestItem = item;
        }
      }
    }

    return smallestItem ?? this.invList.value.data[0];
  }

  get skuDescription(): string[] {
    const item = this.currentSkuItem;
    if (!item?.fullDescription) {
      return [];
    }
    return item?.fullDescription?.split('|') ?? [];
  }

  setProductSelectionQuery(values: IProductSelectionQuery) {
    this.productSelectionQuery = values;
  }

  setLineItemCount(count?: number, isDirectInput: boolean = false, invItemId:string | undefined = undefined) {
    if (isDirectInput) {
      if (count !== undefined) {
      // Directly set the count for input changes
        this.lineItemCount = count;
      }
    } else {
      let updatedCount = this.lineItemCount + (count ?? 0);
      if ((updatedCount === this.lineItemCount)) {
        if (invItemId === undefined) {
        // eslint-disable-next-line no-param-reassign
          invItemId = this.currentInvItem?.id;
        }
        const itemQty = this.cartItems.value.data.find((x) => x?.inventoryMasterId === invItemId)?.quantity;
        if (invItemId !== undefined && itemQty !== undefined) {
          updatedCount = itemQty;
        }
      }
      // Ensure the updated count is positive
      if (updatedCount > 0) {
        this.lineItemCount = updatedCount;
      }
    }
  }

  setActiveImageIdx(idx: number) {
    this.activeImageIdx = idx;
  }

updateQuantity = (localItems: ICart[], isDirectInput: boolean = false, inventoryMasterId:string | undefined, change:number) => {
  const updatedItems = localItems?.map((item) => {
    if (item.inventoryMasterId === inventoryMasterId) {
      if (isDirectInput) {
        if (change !== undefined) {
          // eslint-disable-next-line no-param-reassign
          item.quantity = change;
          return item;
        }
      }
      // return { ...item, quantity: Math.max(item.quantity + change, 1) };
      // eslint-disable-next-line no-param-reassign
      item.quantity = Math.max(item.quantity + change, 1); // Prevents quantity from going negative
    }
    return item;
  });
  this.selectedProductItems = updatedItems;
};

  setSelectedProductItems = (localItems: ICart[]) => {
    localItems.forEach((localItem) => {
      const existingItem = this.selectedProductItems.find((item) => item.inventoryMasterId === localItem.inventoryMasterId);
      if (!existingItem) {
        this.selectedProductItems.push(localItem);
      }
    });
  }

  clearSelectedProductItems=() => {
    this.selectedProductItems = [];
  }

  removeProduct = (productId:string) => {
    this.selectedProductItems = this.selectedProductItems.filter(
      (product) => product.inventoryMasterId !== productId,
    );
  }

  async addLineItemToCart(client: IClient | null | undefined, onAdd: () => void) {
    const invItem = this.invList.value.data.find((x) => x.sku === this.currentInvItem?.sku!);
    if (!invItem) {
      message.error('Could not add items to cart.');
      return;
    }

    // eslint-disable-next-line max-len
    const stockMessage = getStockMessage({ lineItemCount: this.lineItemCount }, this.currentInvItem?.masterStock, client);
    // const outOfStock = itemOutOfStock(this.lineItemCount, this.currentInvItem?.masterStock);
    if (stockMessage.isCOD && (stockMessage.isOutOfStock || stockMessage.isAppPrice)) {
      message.error(stockMessage.message);
      return;
    }

    const body = this.cartItems.value.data.slice();
    let itemIdx = body.findIndex((x) => x.inventoryMasterId === invItem.id);
    if (itemIdx < 0) {
      itemIdx = body.length;
      body[itemIdx] = {
        clientId: client?.id,
        inventoryMasterId: invItem.id,
        quantity: this.lineItemCount,
      } as ICart;
    } else {
      if (this.lineItemCount === body[itemIdx].quantity) {
        return;
      }
      body[itemIdx].quantity = this.lineItemCount;
    }

    // needed for local cart details
    if (!client) {
      body[itemIdx].inventoryMaster = invItem;
    }

    try {
      await cartStorage.addRange(body, client ? 'api' : 'local');
      await this.loadCartInfo(this.currentSkuItem?.sku!, client?.id);
      message.success('Added items to cart.');
    } catch (error) {
      message.error('Could not add item to cart.');
      // eslint-disable-next-line no-console
      console.error('cartStore addLineItemToCart err:', error);
      return;
    }

    onAdd();
  }

  async addMultipleLineItems(client: IClient | null | undefined, invItems: IInventoryMaster[], onAdd: () => void) {
    const body = this.cartItems.value.data.slice();

    // eslint-disable-next-line no-restricted-syntax
    for (const invItem of invItems) {
      const stockMessage = getStockMessage({ lineItemCount: invItem.orderQty }, invItem.masterStock, client);
      if (stockMessage.isCOD && (stockMessage.isOutOfStock || stockMessage.isAppPrice)) {
        message.error(stockMessage.message);
        return;
      }

      const itemIdx = body.findIndex((x) => x.inventoryMasterId === invItem.id);
      if (itemIdx < 0) {
        body.push({
          clientId: client?.id,
          inventoryMasterId: invItem.id,
          quantity: invItem.orderQty,
          inventoryMaster: !client ? invItem : undefined,
        } as ICart);
      } else {
        body[itemIdx].quantity = invItem.orderQty;
      }
    }

    try {
      await cartStorage.addRange(body, client ? 'api' : 'local');
      await this.loadCartInfo(this.currentSkuItem?.sku!, client?.id);
      message.success('Added items to cart.');
    } catch (error) {
      message.error('Could not add item to cart.');
      console.error('cartStore addLineItemsToCart err:', error);
      return;
    }

    onAdd();
  }

  updateToCartItemsDebounceId = 0; // debounce add to cart requests

  async updateToCartItems(clientId: string | null, cartId: string, quantity = 1, isDirectInput: boolean = false) {
    if (!this.cartItems) {
      return;
    }
    // Map the data to update the quantities
    const data = this.cartItems.value.data.map((x) => ({
      ...x,
      client: null,
      inventoryMaster: clientId ? null : x.inventoryMaster, // needed for local cart storage
    }));

    // Find the index of the item in the cart
    const idx = data.findIndex((x) => x.id === cartId);
    if (idx < 0) {
      message.error('Could not add items to cart.');
      return;
    }

    // Set or update the quantity based on isDirectInput flag
    if (isDirectInput) {
      data[idx].quantity = quantity;
    } else {
      data[idx].quantity += quantity;
    }

    // Handle cases where quantity becomes zero or negative
    if (data[idx].quantity <= 0) {
      await this.loadCartItems(clientId);
      return;
    }

    // Debounce logic for cart update
    this.updateToCartItemsDebounceId += 1;
    const id = this.updateToCartItemsDebounceId;
    setTimeout(async () => {
      if (id === this.updateToCartItemsDebounceId) {
        try {
          if (this.cartItems) {
            this.cartItems.isLoading = true;
            await cartStorage.addRange(data as ICart[], clientId ? 'api' : 'local');
          }
        } catch (error) {
          message.error('Could not add items to cart.');
          console.error('cartStore updateToCartItems error:', error);
        }
        await this.loadCartItems(clientId);
      }
    }, clientId ? 800 : 0); // Apply debounce for API calls, immediate for local storage
  }

  async removeCartItem(clientId: string, cartIds: string[], showMessage = true) {
    const data = this.cartItems.value.data.filter((x) => cartIds.some((c) => c === x.id));

    let hide: any | undefined;
    if (showMessage) {
      hide = message.loading('Removing item from cart...', 0);
    }
    try {
      const items = data.map((x) => x.id);
      await cartStorage.deleteRange(items, clientId ? 'api' : 'local');
      await this.loadCartItems(clientId);
    } catch (error) {
      message.error('Could not remove item');
      // eslint-disable-next-line no-console
      console.error('cartStore removeCartItem error', error);
    } finally {
      if (hide) hide();
    }
  }

  async clearCart(clientId: string, showMessage = true) {
    const ids = this.cartItems.value.data.map((x) => x.id);
    await this.removeCartItem(clientId, ids, showMessage);
  }

  async addToCart(clientId: string, invList: IInventoryMaster[]) {
    // const data = this.cartItems.value.data.slice();
    const body: Partial<ICart>[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < invList.length; i++) {
      if (invList[i].orderQty <= 0) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const quantity = invList[i].orderQty;
      const inventoryMasterId = invList[i].id;
      body[body.length] = { clientId, inventoryMasterId, quantity } as ICart;
    }

    await cartStorage.addRange(body as ICart[], clientId ? 'api' : 'local');
    await this.loadCartInfo(clientId);
  }

  async onReorder(clientId: string, invList: IInventoryMaster[], quoteRefNo?: string) {
    const hide = message.loading('Adding items to cart...', 0);
    try {
      await this.clearCart(clientId, false);
      await this.addToCart(clientId, invList);
      await this.loadCartItems(clientId);
      hide();

      if (quoteRefNo) {
        await QuoteReorder.saveRefNo(quoteRefNo);
      }
      return true;
    } catch (error) {
      hide();
      message.error('Could not reorder items.');
      // eslint-disable-next-line no-console
      console.error('cartStore onReorder err: ', error);
      return false;
    }
  }

  async fetchRealTimeStockQuantity(clientId: string, stockQuantityRequests: IStockQuantityRequest[]) {
    try {
      const response = await APIClient.Cart.getRealTimeStockQuantity(clientId, stockQuantityRequests);
      this.realTimeStockQuantity = response.data;
    } catch (error) {
      console.error('Could not fetch real-time stock quantity:', error);
    }
  }

  cartMatchedProductSelection() {
    const filter: ICart[] = [];
    this.cartItems.value.data.forEach((item) => {
      const inv = this.invList.value.data.find((invItem) => invItem.id === item.inventoryMasterId);
      if (inv) {
        filter.push({ ...item, inventoryMaster: inv });
      }
    });
    return this.setSelectedProductItems(filter);
  }

  * loadCartInfo(sku: string, clientId?: string): {} {
    if (!this.cartItems) {
      return;
    }
    yield this.loadCartItems(clientId);
    yield this.loadSkuList({ skuList: sku, withPrice: true });
    yield this.loadInventoryList({ parentSku: sku, withPrice: true });
    this.cartMatchedProductSelection();
    // new method, load selelction table items
    this.sku = this.skuList.value.data.find((x) => x.sku === sku);
    this.setLineItemCount();
    if (this.skuList.error || this.invList.error) {
      // TODO: Toast.show({ type: 'error', text1: 'Could not load cart.' });
      const error = this.skuList.error ?? this.invList;
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  private loadCartItemsClientId?: string;

  async* loadCartItems(clientId?: string | null) {
    if (clientId) {
      this.loadCartItemsClientId = clientId;
    }

    const queryParams = `clientId=${this.loadCartItemsClientId ?? ''}`;

    if (!this.loadCartItemsClientId) {
      this.cartItems = this.localCartItems;
    } else {
      this.cartItems = this.apiCartItems;

      const localCartHasItems = await cartStorage.hasItems();
      if (localCartHasItems) {
        try {
          const items = await cartStorage.getCarts();
          this.addToCart(this.loadCartItemsClientId, items.data.map((x) => x.inventoryMaster));
          await cartStorage.clearItems();
        } catch (error) {
          message.error('Could not load cart details.');
          // eslint-disable-next-line no-console
        }
      }
    }

    yield this.cartItems.fetch(queryParams);
    if (this.cartItems.error) {
      // eslint-disable-next-line no-console
      console.warn(this.cartItems.error);
    }
  }

  * loadSkuList({
    active = true, categoryRefNo, withPrice = true, skuList,
  }: any) {
    let queryParams = '';
    queryParams += `active=${active ? 'true' : 'false'}&`;
    queryParams += `categoryRefNo=${categoryRefNo ?? ''}&`;
    queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
    queryParams += `skuList=${skuList ?? ''}`;
    yield this.skuList.fetch(queryParams);
  }

  * loadInventoryList({
    active = true, parentSku, skuList, withPrice = true,
  }: ILoadInventoryListParams) {
    let queryParams = '';
    queryParams += `active=${active ? 'true' : 'false'}&`;
    queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
    queryParams += parentSku ? `parentSku=${parentSku ?? ''}&` : '';
    queryParams += skuList ? `skuList=${skuList ?? ''}&` : '';
    queryParams += `voucherRefNo=${this.voucherClaim?.refNo ?? ''}`;
    yield this.invList.fetch(queryParams);
  }

  RealTimeStockQuantityLoader = async (cartItems: ICart[], currentClientId: string | undefined) => {
    const stockQuantityRequests = cartItems
      .map((item) => {
        const masterStock = this.invList.value.data.find((x) => x.id === item.inventoryMasterId)?.masterStock;
        return {
          sku: masterStock?.stockCode!,
          qty: masterStock?.qtyAvailable || 0,
        };
      });

    if (currentClientId) {
      await this.fetchRealTimeStockQuantity(currentClientId, stockQuantityRequests);
    }
  };
}

export default CartStore;
