import React from 'react';
import PrimaryBtn, { IPrimaryBtn } from './PrimaryBtn';
import styles from './styles.module.css';

export interface ISecondaryBtn extends IPrimaryBtn {
}

const SecondaryBtn = ({
  className,
  ...props
}: ISecondaryBtn) => (
  <PrimaryBtn
    className={[styles.secondaryBtn, className].join(' ')}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

SecondaryBtn.defaultProps = {};

export default SecondaryBtn;
