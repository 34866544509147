import React from 'react';
import { Menu as AntdMenu } from 'antd';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import Typography from '../components/Typography';
import Header from './Header';
import styles from './account.module.css';
import Menu from '../components/Menu/Menu';
import { AccountMenuList } from '../pages';
import useWindowSize from '../utils/hooks/useWindowSize';

interface ICheckoutLayout {
  component: JSX.Element,
}

const { Title } = Typography;

const CheckoutLayout = ({
  component: Component,
}: ICheckoutLayout) => {
  const [activePath, setActivePath] = React.useState('');

  const location = useLocation();
  const history = useHistory();

  const { isMobileWindow, isTabletWindow } = useWindowSize();

  const generateMenuItems = (defaultRoutes: any[]) => defaultRoutes.map((prop) => (
    prop.icon !== undefined && (
      <AntdMenu.Item
        key={prop.path}
        icon={prop.icon}
        onClick={() => {
          // force ant nav not navigating on in mobile view
          if (isMobileWindow || isTabletWindow) {
            history.push(prop.path);
          }
        }}
      >
        {!(isMobileWindow || isTabletWindow) && (
          <NavLink
            to={prop.path}
          >
            {!(isMobileWindow || isTabletWindow) && prop.title}
          </NavLink>
        )}
      </AntdMenu.Item>
    )
  ));

  React.useEffect(() => {
    const currentRoute = AccountMenuList.find((prop: any) => prop.path === location.pathname);
    if (currentRoute === undefined) { return; }
    setActivePath(currentRoute.path);
  }, [AccountMenuList, location]);

  return (
    <Header
      headerContent={<Menu direction="row" />}
      component={() => (
        <div>
          <div className={styles.accountCon}>
            <div className={styles.accountMenuCon}>
              <Title
                className={styles.accountMenuHeader}
                level={2}
              >
                My Account
              </Title>
              <div>
                {/* TODO: Menu styling */}
                <AntdMenu
                  className={styles.accountMenu}
                  inlineCollapsed={(isMobileWindow || isTabletWindow)}
                  mode={(isMobileWindow || isTabletWindow) ? 'horizontal' : 'inline'}
                  theme="light"
                  defaultSelectedKeys={[activePath]}
                  triggerSubMenuAction="click"
                  overflowedIndicator={(
                    <FiChevronRight />
                  )}
                >
                  {generateMenuItems(AccountMenuList)}
                </AntdMenu>
              </div>
            </div>
            <div className={styles.accountContentCon}>
              {Component}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default CheckoutLayout;
