import CartCountBtn from './CartCountBtn';
import LinkBtn from './LinkBtn';
import PrimaryBtn from './PrimaryBtn';
import SecondaryBtn from './SecondaryBtn';
import ShopMoreBtn from './ShopMoreBtn';

const Buttons = {
  CartCountBtn,
  LinkBtn,
  PrimaryBtn,
  SecondaryBtn,
  ShopMoreBtn,
};

export default Buttons;
