import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { RiSearchLine } from 'react-icons/ri';
import { DatePicker, Input } from 'antd';
import Tables from '../../components/Tables';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import Spin from '../../components/Spin/Spin';

const { Title } = Typography;

const AccountCreditNotes = observer(() => {
  const { clientStore } = stores;
  const { currentClient } = useAuthContext();

  useEffect(() => {
    if (currentClient) {
      clientStore.fetchCreditNotes(currentClient);
    }
  }, [currentClient]);

  // Second useEffect for updating search results
  useEffect(() => {
    clientStore.searchResults = clientStore.invoices;
  }, [clientStore.invoices]);

  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      clientStore.invoices = [];
    };
  }, []);

  return (
    <main id="account-credit-notes">
      <Helmet>
        <title>Credit Notes - G. Fox</title>
      </Helmet>
      <Spin
        spinning={clientStore.isLoadingInvoices}
      >
        <Title
          className={styles.headerText}
          level={2}
        >
          Credit Notes
        </Title>
        <div className={styles.searchDatePickerContainer}>

          <div className={styles.datePickerContainer}>
            <DatePicker.RangePicker
              className={styles.datePicker}
              disabled={clientStore.isLoadingInvoices}
              onChange={(value) => clientStore.setDateRange(value)}
            />
          </div>

          <div className={styles.searchContainer}>
            <Input
              size="large"
              className={`${styles.pageHeaderOptionSearch} ${styles.searchInput}`}
              placeholder="Search credit notes..."
              defaultValue={clientStore.query}
              prefix={<RiSearchLine color="#CBD5E0" />}
              disabled={clientStore.isLoadingInvoices}
              onChange={(_) => {
                clientStore.setQuery(_.target.value);
              }}
            />
          </div>
        </div>

        <div className={styles.tableCon}>
          <Tables.InvoicesTable isCreditNote invoices={clientStore.searchResults.slice().reverse()} />
        </div>
      </Spin>
    </main>
  );
});

export default AccountCreditNotes;
