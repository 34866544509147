import React from 'react';
import { Card } from 'antd';
import styles from './loyalty-card-front.module.css';
import Images from '../../assets';

const GFoxCard = () => (
  <div className={styles.container}>
    <Card className={styles.cardContainer}>
      <img src={Images.GFoxLogo} alt="G. Fox Logo" className={styles.foxLogo} />
      <div className={styles.cardTopText}>
        <h2>G.Fox </h2>
      </div>
      <div className={styles.cardText}>
        <h1>G. Fox Card</h1>
      </div>
    </Card>
  </div>

);

export default GFoxCard;
