/* eslint-disable import/prefer-default-export */

export const certificatesDocs = [
  {
    title: 'G. Fox Factory SABS Certificate',
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/certificates/SKM_C224e19080812120.pdf',
  },
  // {
  //   title: 'Bidvest Group Limited BBBEE Certificate',
  //   documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/certificates/The-Bidvest-Group-Certificate-Jan-2021.pdf',
  // },
  {
    title: 'V6 Safety Boot Certificate',
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/certificates/en-certificate-v6-v4-boot-1.pdf',
  },
  {
    title: 'Lebombo Boot Certificate',
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/certificates/en-certificate-lebombo-boot-1.pdf',
  },
  {
    title: 'Eagle Boot Certificate',
    documentUrl: 'https://gfox-cdn.bridgelabs.design/documents/certificates/en-certificate-eagle-boot-1.pdf',
  },
];
