import React from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import Typography from '../Typography';
import styles from './download-app.module.css';
import Buttons from '../Buttons';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDownloadOurApp {
  className?: string,
}
const androidLink = 'https://play.google.com/store/apps/details?id=za.co.gfox.app';
const iOSLink = 'https://apps.apple.com/us/app/g-fox/id1633431247';

const handleAndroidClick = () => {
  window.open(androidLink, '_blank');
};

const handleiOSClick = () => {
  window.open(iOSLink, '_blank');
};
const { Text } = Typography;

const DownloadOurApp = ({
  className,
}: IDownloadOurApp) => (
  <div className={[styles.con, className].join(' ')}>
    {' '}
    <Text className={styles.headerText}>
      Download our G.Fox app
    </Text>
    <div className={styles.playBtnCon}>
      <div className={styles.iconContainer}>
        <Buttons.PrimaryBtn
          icon={(
            <AppleOutlined style={{ color: 'black' }} />

                )}
          className={styles.centerBtn}
          onClick={handleiOSClick}
        />
        <Buttons.PrimaryBtn
          icon={(
            <AndroidOutlined style={{ color: 'black' }} />
                )}
          className={styles.centerBtn}
          onClick={handleAndroidClick}
        />
      </div>
    </div>

  </div>
);

DownloadOurApp.defaultProps = {
  className: undefined,
};

export default DownloadOurApp;
