import React, { ReactNode } from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import Icon from '@ant-design/icons';
import Images from '../../assets';
import './styles.css';

interface AvatarProps {
  className?: string;
  icon?: ReactNode;
  onClick?():void;
  size?: AvatarSize;
}

const Avatar = ({
  className, icon, onClick, size,
}: AvatarProps) => (
  <AntdAvatar
    className={['account-avatar-container', className].join(' ')}
    icon={icon}
    size={size}
    onClick={() => onClick && onClick()}
  />
);

Avatar.defaultProps = {
  className: undefined,
  icon: (
    <Icon
      className="navbar-icon avatar-default-user-icon"
      component={Images.UserIcon}
    />
  ),
  size: 'small',
  onClick: undefined,
};

export default Avatar;
