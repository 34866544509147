/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../Lost/styles.module.css';
import Images from '../../assets';

const Notification = () => (
  <main id="lost">
    <Helmet>
      <title>Lost - G. Fox</title>
    </Helmet>
    <div className={styles.lostCon}>
      <img
        src={Images.Notification}
      />
    </div>
  </main>
);

export default Notification;
