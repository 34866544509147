import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import { specialsList } from './data';
import Cards from '../../components/Cards';
import { imgURLPrefix } from '../../config/image';

const { Title } = Typography;

const ProductCatalogue = () => (
  <main id="product-catalogue">
    <Helmet>
      <title>Product Catalogue - G. Fox</title>
    </Helmet>
    <div className={styles.filtersCon}>
      <Title
        className={styles.filtersHeader}
        level={2}
      >
        Product Catalogue
      </Title>
    </div>

    <div className={styles.specialsListCon}>
      {React.Children.toArray(
        specialsList.map((item) => (
          <Cards.CatalogueCard
            imageSrc={`${imgURLPrefix}${item.image}`}
            onClick={() => window.open(item?.documentUrl, '_blank')}
            title={item.title}
            subTitle={item.subTitle}
            href={undefined}
          />
        )),
      )}
    </div>
    {/* TODO: sub to newsletter */}
  </main>
);

export default ProductCatalogue;
