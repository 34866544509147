import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import Buttons from '../../components/Buttons';
import styles from './styles.module.css';
import stores from '../../stores/stores';

const { Title } = Typography;

interface IShopAllHeaderProps {
  header: string,
  btnTitle?: ReactNode,
  refNo?: string,
}

const ShopAllHeader = observer(({
  header,
  btnTitle,
  refNo = 'work-wear',
}: IShopAllHeaderProps) => {
  const { categoryStore } = stores;
  const categories = categoryStore.categories.value.data;

  const generateLink = (): string => {
    const c = categories.find((x) => x.refNo === refNo)?.inverseParentCategory[0]?.refNo;
    return c ? `/products/${refNo}/${c}` : '/specials';
  };

  return (
    <div className={styles.shopHeaderContainer}>
      <Title
        level={2}
        className={styles.shopHeader}
      >
        {header}
      </Title>

      {btnTitle && (
        <Buttons.LinkBtn
          link={{ href: generateLink() }}
          type="ghost"
          className={styles.shopHeaderBtn}
        >
          {btnTitle}
        </Buttons.LinkBtn>
      )}
    </div>
  );
});

export default ShopAllHeader;
