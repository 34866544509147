import APIClient, {
  IInventoryMaster, IOrder, IOrderedInventory, ISavedOrderInfo,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import moment, { Moment } from 'moment';
import { calcProductPrice } from '../utils/pricing';

type PickedDateRange = [Moment | null, Moment | null] | null;
export class OrderStore {
  previousOrders: IOrderedInventory | null = null;

  isLoadingHistory: boolean | null = null;

  orderComplete = false;

  checkoutUrl = '';

   query: string = '';

  searchId = 0;

  searchResults: IOrderedInventory | null = null;

  dateRange: PickedDateRange = [moment().add(-31, 'days'), moment()];

  constructor() {
    makeObservable(this, {
      isLoadingHistory: observable,
      orderComplete: observable,
      checkoutUrl: observable,
      setLoadingPreviousOrders: action,
      setPreviousOrders: action,
      setOrderComplete: action,
      loadPreviousOrders: action,
      refreshTrackOrder: action,
      setCheckoutUrl: action,
      loadingCheckoutStore: action,
      accountHolderCheckout: action,
      reset: action,
      query: observable,
      setQuery: action,
      searchResults: observable,
      dateRange: observable,
      setDateRange: action,
    });
  }

  setDateRange(range: PickedDateRange) {
    if (range && range[0] && range[1]) {
      this.dateRange = range;
      const filteredOrders = this.previousOrders?.orders.filter((order) => {
        const createdAtMoment = moment(order.dateOrdered);
        return createdAtMoment.isBetween(range[0], range[1]);
      });

      this.searchResults = {
        orders: filteredOrders ?? [],
        inventoryMasters: this.previousOrders?.inventoryMasters ?? [],
      };
    } else {
      message.error('Select date range.');
    }
  }

  setQuery(s: string) {
    this.query = s.trim();

    if (this.query.length === 0) {
      this.searchResults = this.previousOrders;
      return;
    }

    setTimeout(() => {
      const filteredData = (this.previousOrders?.orders || []).filter((order) => {
        const orderIdMatch = order.refNo && order.refNo.toLowerCase().includes(this.query.toLowerCase());
        return orderIdMatch;
      });

      runInAction(() => {
        if (this.searchResults) {
          this.searchResults.orders = filteredData;
        }
      });
    }, 800);
  }

  setLoadingPreviousOrders(value: boolean) {
    this.isLoadingHistory = value;
  }

  setPreviousOrders(value: IOrderedInventory) {
    this.previousOrders = value;
  }

  setOrderComplete(value: boolean) {
    this.orderComplete = value;
  }

  async refreshTrackOrder(orderId: string) {
    const hide = message.loading('Getting order status...', 0);
    const prevOrderIndex = this.previousOrders?.orders.findIndex((x) => x.id === orderId) ?? -1;
    try {
      const updatedOrder = await APIClient.Order.getOrder(orderId);
      if (this.previousOrders && prevOrderIndex >= 0) {
        this.previousOrders.orders[prevOrderIndex].statusCode = updatedOrder.statusCode;
        this.previousOrders.orders[prevOrderIndex].orderStatusHistories = updatedOrder.orderStatusHistories;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('orderStore refreshTrackOrder error: ', error);
    }
    hide();
    return this.previousOrders?.orders[prevOrderIndex];
  }

  async loadPreviousOrders(clientId?: string, reload = false) {
    if (!clientId) {
      this.setLoadingPreviousOrders(false);
      return;
    }

    if (this.isLoadingHistory || (this.previousOrders !== null && reload === false)) {
      return;
    }

    try {
      this.setLoadingPreviousOrders(true);
      const res = await APIClient.Order.getPreviousOrders(clientId);
      this.setPreviousOrders(res);
    } catch (error) {
      // message.error('Could not get previous orders.');
      // eslint-disable-next-line no-console
      console.error('orderStore loadPreviousOrder error: ', error);
    }

    // TODO: prevent flickering without timeout
    setTimeout(() => runInAction(() => this.setLoadingPreviousOrders(false)), 800);
  }

  setCheckoutUrl(s: string) {
    this.setCheckoutUrl(s);
  }

  // eslint-disable-next-line class-methods-use-this
  async loadingCheckoutStore() {
    const body: IOrder = {} as any;
    await APIClient.Pay.postPaystackPayment(body);
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  getOrderInfo(invList: IInventoryMaster[]): ISavedOrderInfo[] {
    return invList.map<ISavedOrderInfo>((x) => ({
      orderQty: x.orderQty,
      price: calcProductPrice(x).withTax,
      sku: x.sku,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  async accountHolderCheckout(order: IOrder) {
    const hide = message.loading('Generating order...', 0);

    try {
      await APIClient.Order.addOrder(order, []);
      hide();
      return true;
    } catch (error) {
      hide();
      message.error('Could not generate order');
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async checkout(order: IOrder, authorizationCode?: string | null) {
    const hide = message.loading('Generating order...', 0);

    let queryParams = '';
    queryParams += `chargeAuthorizationCode=${authorizationCode ?? ''}&`;
    queryParams += `callbackUrl=${window?.location?.origin ?? ''}/account/orders?clearCart=true}&`;

    try {
      const result = await APIClient.Pay.postPaystackPayment(order, queryParams);
      hide();
      return result;
    } catch (error) {
      hide();
      message.error('Could not generate order.');
      return null;
    }
  }

  reset() {
    this.previousOrders = null;
    this.isLoadingHistory = null;
    this.orderComplete = false;
    this.checkoutUrl = '';
  }
}

export default OrderStore;
