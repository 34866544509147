import React from 'react';
import { IHeaderAndSideBarLayoutProps } from '../pages';
import Body from './styles';

const SideBarAndHeaderLayout = ({ component: Component, headerContent, sideBarContent }: IHeaderAndSideBarLayoutProps) => (
  <Body>
    <div id="left" className="column">
      <div className="top-left">LOGO/BRAND</div>
      <div className="bottom">
        { sideBarContent }
      </div>
    </div>
    <div id="right" className="column">
      <div style={{ background: 'white' }} className="top-right">
        { headerContent }
      </div>
      <div className="bottom">
        <Component />
      </div>
    </div>
  </Body>
);

export default SideBarAndHeaderLayout;
