import APIClient, { ISearchResult } from '@bridgelabsdesign/gfox-api-client';
import mixpanel from 'mixpanel-browser';
import {
  action, computed, flow, makeObservable, observable,
} from 'mobx';
import { isEmptyString } from '../utils/strings';

class SearchStore {
  searchQuery = '';

  searchId = 0;

  searchResult: ISearchResult = { skuMaster: [], category: [] };

  isLoading = false;

  error: Error | null = null;

  showSearchPanel = false;

  constructor() {
    makeObservable(this, {
      searchQuery: observable,
      searchResult: observable,
      isLoading: observable,
      error: observable,
      showSearchPanel: observable,
      showErrorPanel: computed,
      showResultsPanel: computed,
      setSearchQuery: action,
      toggleShowSearchPanel: action,
      loadSearchResults: flow,
      resetSearch: action,
    });
  }

  get showErrorPanel(): boolean {
    return this.error !== null && !this.isLoading;
  }

  get showResultsPanel(): boolean {
    return !this.isLoading && this.error === null;
  }

  resetSearch() {
    this.searchQuery = '';
    this.searchResult = { skuMaster: [], category: [] };
    this.error = null;
  }

  setSearchQuery(s: string) {
    this.searchQuery = s;
    this.searchId += 1;
    const id = this.searchId;
    if (isEmptyString(s)) {
      this.isLoading = false;
      this.searchResult = { skuMaster: [], category: [] };
      return;
    }
    setTimeout(() => {
      if (id === this.searchId) {
        this.loadSearchResults(s);
      }
    }, 600);
  }

  toggleShowSearchPanel(value?: boolean): void {
    if (value !== undefined) {
      this.showSearchPanel = value;
      return;
    }
    this.showSearchPanel = !this.showSearchPanel;
  }

  * loadSearchResults(search: string) {
    this.isLoading = true;
    this.error = null;
    try {
      this.searchResult = yield APIClient.SKUMasterV2.searchSkuMasters(search);
      if (!isEmptyString(search) && this.searchResult.skuMaster.length === 0) {
        this.error = new Error('inventory search error: could not find searched item');
      }
      mixpanel.track('Product Search', {
        query: search.trim(),
      });
    } catch (err) {
      console.error(err);
      this.error = err as Error;
    } finally {
      this.isLoading = false;
    }
  }

  useComplimentaryProducts = (cartStore: any, inventoryStore: any, loadSkuMasters: any) => () => {
    if (cartStore.skuList?.value.data?.length) {
      const skuNumbers = cartStore.skuList.value.data.flatMap(
        (skuMaster: any) => (skuMaster.complementaryProducts
          ? skuMaster.complementaryProducts.map(
            (complimentaryProduct: any) => complimentaryProduct.split('_')[0],
          )
          : []),
      );

      if (skuNumbers.length) {
        const skuNumbersParam = skuNumbers.join(',').replace(/\|/g, ',');
        loadSkuMasters(`withPrice=true&skuList=${skuNumbersParam}`);
      } else {
        // eslint-disable-next-line no-param-reassign
        inventoryStore.complementaryProducts.value.data = [];
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      inventoryStore.complementaryProducts.value.data = [];
    }
  };
}

export default SearchStore;
