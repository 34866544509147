import { IInventoryMaster } from '@bridgelabsdesign/gfox-api-client';
import { message, Modal } from 'antd';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import ProductFilterBy from '../../pages/Products/ProductFilterBy';
import stores from '../../stores/stores';
import { selectedInvItem } from '../../utils/productSelection';

const ProductSelectionModal = observer(({
  isVisible,
  setIsVisible,
  onSelectColor,
}: {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsVisible: (v: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  onSelectColor:(invItems: IInventoryMaster[]) => void;
}) => {
  const {
    productSelectStore,
  } = stores;

  const handleOnViewModalSelection = () => {
    const selectedFilterHeaders = new Set<string>();
    productSelectStore.filterBySelectedValues.forEach((item) => selectedFilterHeaders.add(item.name));

    const checkColors = productSelectStore.filterByColors.length > 0;
    if (checkColors && productSelectStore.selectedColors.length === 0) {
      message.error('Select a product color');
      return;
    }

    if (productSelectStore.filterBy.length !== selectedFilterHeaders.size) {
      const missingFilterBy = productSelectStore.filterBy.find((x) => !selectedFilterHeaders.has(x.header));
      message.error(`Select filter options ${missingFilterBy?.header ?? ''}`);
      return;
    }

    const tempInvItems = selectedInvItem(productSelectStore.filterByInv.value.data, {
      colors: productSelectStore.selectedColors,
      filterBy: productSelectStore.filterBySelectedValues,
    });

    if (tempInvItems.length === 0) {
      // TODO: show what exactly is not available. e.g. is it a color option, a sizing options, or ...
      message.error('Product not available. Please select different options');
      return;
    }

    onSelectColor(tempInvItems);
  };

  useEffect(() => {
    runInAction(() => { productSelectStore.autoSelectFilters = true; }); // TODO: remove run inaction
  }, []);

  return (
    <Modal
      title="Product Options"
      visible={isVisible}
      width={570}
      onOk={handleOnViewModalSelection}
      onCancel={() => {
        setIsVisible(false);
      }}
      okText="Continue"
      cancelText="Cancel"
      okButtonProps={{ style: { height: '3em' } }}
      cancelButtonProps={{ style: { height: '3em' } }}
      closeIcon={(<IoClose />)}
    >
      <ProductFilterBy
        colors={productSelectStore.filterByColors}
        filterBy={productSelectStore.filterBy}
        onSelectColour={(v) => productSelectStore.setSelectedColors(v)}
        onSelectFilterBy={(v) => productSelectStore.setFilterBySelectedValues(v)}
        isSelectedColour={(n) => productSelectStore.isSelectedColour(n)}
        isFilterByValuesSelected={(v) => productSelectStore.isFilterByValuesSelected(v)}
      />
    </Modal>
  );
});

export default ProductSelectionModal;
