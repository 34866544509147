import React from 'react';
import { LinkedinOutlined } from '@ant-design/icons';
import Typography from '../Typography';
import styles from './styles.module.css';

const { Link } = Typography;

interface ILinkedInIconLinkProp {
  className?: string;
}

const LinkedInIconLink = ({ className }: ILinkedInIconLinkProp) => (
  <Link
    external
    href=" https://www.linkedin.com/company/g-fox/"
  >
    <LinkedinOutlined className={[styles.facebookIcon, className].join(' ')} />
  </Link>
);

LinkedInIconLink.defaultProps = {
  className: undefined,
};

export default LinkedInIconLink;
