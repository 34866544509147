import AccountMembersTable from './AccountMembersTable';
import CheckoutItemsTable from './CheckoutItemsTable';
import CreditTable from './CreditTable';
import InvoicesTable from './InvoicesTable';
import QuotesSummaryTable from './QuotesSummaryTable';
import OrdersSummaryTable from './OrdersSummaryTable';
import QuotesTable from './QuotesTable';
import VouchersTable from './VouchersTable';
import WishlistItemsTable from './WishlistTable';
import FavoritesTable from './FavoritesTable';

const Tables = {
  WishlistItemsTable,
  FavoritesTable,
  AccountMembersTable,
  CheckoutItemsTable,
  InvoicesTable,
  CreditTable,
  QuotesSummaryTable,
  OrdersSummaryTable,
  QuotesTable,
  VouchersTable,
};

export default Tables;
