import React from 'react';
import { DatePicker } from 'antd';
import Form from '../../components/Form';
import styles from './styles.module.css';

export interface IFormHelper {
  header: string;
  headerJSX?: JSX.Element;
  section?: 'address' | undefined,
  forms: {
    name: string;
    label: string;
    required: boolean;
    whitespace: boolean | undefined;
    pattern?: RegExp | undefined;
    type?: 'string' | 'email' | 'boolean';
    formType: string;
    input: JSX.Element;
    note?: string;
    responsive: boolean,
  }[]
}

const allocateLoyaltyCardForm: IFormHelper[] = [
  {
    header: 'Fill missing Details',
    forms: [
      {
        name: 'firstName',
        label: 'First Name',
        whitespace: true,
        required: true,
        pattern: undefined,
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your name" disabled className={styles.inputDisabled} />,
        responsive: true,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        required: true,
        pattern: undefined,
        type: 'string',
        whitespace: true,
        formType: 'input',
        input: <Form.Input placeholder="Enter your surname" disabled className={styles.inputDisabled} />,
        responsive: true,
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        required: true,
        pattern: undefined,
        type: 'email',
        formType: 'input',
        input: <Form.Input placeholder="Enter your email address" disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'billingCompany',
        label: 'Company Name',
        required: false,
        pattern: undefined,
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your company name" disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'industry',
        label: 'Industry Name',
        required: true,
        pattern: undefined,
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your industry name" />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'billingPhone',
        label: 'Cellphone',
        required: true,
        pattern: new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'),
        type: 'string',
        formType: 'input',
        input: <Form.Input placeholder="Enter your phone number" disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'dateOfBirth',
        label: 'Date Of Birth',
        required: true,
        formType: 'datePicker',
        input: <DatePicker placeholder="Enter your date of birth" className={styles.datePicker} format="YYYY-MM-DD" />,
        whitespace: true,
        responsive: true,
      },
    ],
  },
];

export default allocateLoyaltyCardForm;
