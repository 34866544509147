import React, { useEffect } from 'react';
import {
  Form as AntdForm, message, Select, Spin,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import APIClient, { IAddress, IClientAddress } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import Avatar from '../../components/Avatar/Avatar';
import Buttons from '../../components/Buttons';
import { validateMessages } from '../../utils/form';
import Form from '../../components/Form';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';

const { Text, Title } = Typography;

export interface IFormHelper {
  header: string;
  headerJSX?: JSX.Element;
  forms: {
    name: string;
    label: string;
    required: boolean;
    whitespace: boolean|undefined;
    pattern: RegExp|undefined;
    type:'string' | 'email' | 'boolean' ;
    formType: string;
    input: JSX.Element;
    note?: string;
    responsive: boolean,
    hidden?: boolean,
  }[]
}
const accountProfileForm: IFormHelper[] = [
  {

    header: 'Delivery Address:',
    forms: [
      {
        name: 'address1',
        label: 'Address line 1',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your address" rounded />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'address2',
        label: 'Address line 2',
        required: false,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your address" rounded />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'address3',
        label: 'Suburb',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your address" rounded disabled />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'city',
        label: 'City',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your city name" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'province',
        label: 'Select province',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Select your Province" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'country',
        label: 'Country',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter your Country" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'postalCode',
        label: 'Postal code',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter postal code" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
      },
      {
        name: 'hubCode',
        label: 'Hub Code',
        required: true,
        formType: 'input',
        pattern: undefined,
        type: 'string',
        input: <Form.Input placeholder="Enter postal code" rounded disabled className={styles.inputDisabled} />,
        whitespace: true,
        responsive: true,
        hidden: true,
      },
    ],
  },

];

const AddNewAddress = observer(() => {
  const [form] = useForm();
  const { addressStore } = stores;
  const { currentClient, setCurrentClient } = useAuthContext();
  useEffect(() => () => {
    addressStore.setSearchQuery('');
  }, []);
  const handleAddressSelect = (index: number) => {
    const filteredAddress = addressStore.searchResult[index];
    addressStore.setSelectedSearch(filteredAddress);
    form.setFieldsValue({
      address3: filteredAddress?.suburb,
      city: filteredAddress?.city,
      province: filteredAddress?.province,
      country: 'South Africa',
      postalCode: filteredAddress?.postalCode,
      hubCode: filteredAddress?.hubCode,
    });
  };

  const handleSearch = async (value: string) => {
    addressStore.setSearchQuery(value);
  };

  const history = useHistory();
  const onFinish = async (values: any) => {
    const body: IClientAddress = {
      clientId: currentClient?.id,
      address: {
        address1: values.address1,
        address2: values.address2,
        address3: values.address3,
        city: values.city,
        country: values.country,
        province: values.province,
        postalCode: values.postalCode,
        hubCode: values.hubCode,
      }as IAddress,
    } as IClientAddress;
    try {
      // Make an API call to update the user's profile information
      const updatedAddress = await APIClient.ClientAddress.addClientAddress(body, []);
      message.success('Your Address has been updated');
      const updatedClient = { ...currentClient };
      updatedClient.clientAddresses?.push(updatedAddress.data[0]);
      setCurrentClient(updatedClient);
      setTimeout(() => {
        history.goBack();
      }, 2000);
    } catch (error) {
      message.error('There was an error updating your Address');
    }
  };
  return (
    <main id="account-profile">

      <AntdForm
        name="update"
        requiredMark={false}
        validateMessages={validateMessages}
        onFinish={onFinish}
        form={form}
      >
        <Title
          className={styles.headerText}
          level={2}
        >
          Invoices
        </Title>

        <div className={styles.contentCon}>
          <div className={styles.userInfoCon}>
            <Avatar
              className={styles.userAvatar}
              size="large"
            />
            <div className={styles.userInfo}>
              <Text className={styles.userName} />
              <Text className={styles.userEmail} />
              <Text className={styles.userPhone} />
              <Text className={styles.userAddress} />
            </div>
            <div className={styles.addAddressCon} />
          </div>
          <Select
            onSelect={handleAddressSelect}
            className={styles.select}
            showSearch
            placeholder="Search for postal code"
            onSearch={handleSearch}
            filterOption={false}
            showArrow={false}
          // value={searchValue}
            notFoundContent={addressStore.isSearching ? <Spin size="small" /> : null}
          >
            {React.Children.toArray(addressStore.searchResult.map((address, index) => (
              <Select.Option value={index}>
                {address.suburb}
                ,
                {' '}
                {address.city}
                ,
                {' '}
                {address.province}
              </Select.Option>
            )))}
          </Select>
          <div className={styles.getInTouchCon}>
            {React.Children.toArray(
              accountProfileForm.map((item) => (
                <div className={styles.formCon}>
                  <Text className={styles.getInTouchText}>
                    {item.header}
                  </Text>
                  {React.Children.toArray(
                    item.forms.map((formItem) => (
                      <div
                        className={[
                          styles.getInTouchFormCon,
                          formItem.responsive && styles.getInTouchFormConResponsive,
                          formItem.formType === 'input' && styles.getInTouchFormConInput,
                          formItem.formType === 'textarea' && styles.getInTouchFormConTextArea,
                        ].join(' ')}
                      >
                        {!formItem.hidden && (
                          <div>
                            <Text className={styles.getInTouchLabel}>
                              {formItem.label}
                            </Text>
                          </div>
                        )}
                        {formItem?.note && (
                          <Text className={styles.getInTouchNote}>
                            {formItem.note}
                          </Text>
                        )}
                        <AntdForm.Item
                          name={formItem.name}
                          required={formItem.required}
                          rules={[{ required: formItem.required }, { whitespace: formItem.whitespace },
                            { pattern: formItem.pattern, message: 'invalid format' }, { type: formItem.type, message: 'Please enter a valid email' }]}
                          className={styles.getInTouchFormItem}
                          hidden={formItem.hidden}
                        >

                          {formItem.input}

                        </AntdForm.Item>
                      </div>
                    )),
                  )}
                </div>
              )),
            )}

          </div>
          <Buttons.PrimaryBtn
            htmlType="submit"
          >
            Save changes
          </Buttons.PrimaryBtn>
        </div>
      </AntdForm>
    </main>
  );
});
export default AddNewAddress;
