import React from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import styles from './styles.module.css';

interface IInputProps extends AntdInputProps{
  className?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
}

const InputPassword = ({
  className,
  name,
  placeholder,
  required,
  ...rest
}: IInputProps) => (
  <AntdInput.Password
    className={[styles.input, className].join(' ')}
    name={name}
    placeholder={placeholder}
    required={required}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

InputPassword.defaultProps = {
  className: undefined,
  name: undefined,
  placeholder: undefined,
  required: false,
};

export default InputPassword;
