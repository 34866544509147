/* eslint-disable no-unused-vars */
import React from 'react';
import { BsCircleFill } from 'react-icons/bs'; // Importing the icon
import Typography from '../Typography';
import styles from './checkout-delivery.module.css';
import PrimaryBtn from '../Buttons/PrimaryBtn';

export interface IDeliveryOptionProps{
  className?: string;
  deliveryType: string;
  price: string | null;
  value: string;
  onSelect: (value: number) => void;
  isSelected: boolean; // New prop to indicate if the option is selected
}

const { Text } = Typography;

const DeliveryOptionCard = ({
  className,
  deliveryType,
  price,
  value,
  onSelect,
  isSelected, // Add isSelected to the destructuring
}: IDeliveryOptionProps) => (
  <div className={[styles.OrderMonitorView, className, isSelected ? styles.selected : ''].join(' ')}>
    <div className={styles.infoContainer}>
      <div className={styles.infoProdNameCon}>
        <Text className={styles.orderID}>
          {deliveryType}
        </Text>
      </div>
      <div className={styles.totalCon}>
        <Text className={styles.total}>
          {`Price: ${price ?? '---'}`}
        </Text>
      </div>
      <div className={styles.btnCon}>
        {/* Button for selection */}
        <PrimaryBtn type="ghost" className={styles.trackOrder} onClick={() => onSelect(Number(value))}>
          {/* BsCircleFill Icon for visual indication */}
          <BsCircleFill className={isSelected ? styles.selectedIcon : styles.deliveryOptsIcon} />
        </PrimaryBtn>
      </div>
    </div>
  </div>
);

DeliveryOptionCard.defaultProps = {
  className: undefined,
};

export default DeliveryOptionCard;
