import React, { ReactNode } from 'react';
import { DatePicker } from 'antd';
import Form from '../../components/Form';
import styles from './styles.module.css';

export interface IDetails{
  key: string
  Header:string
  items: {
    name?: string
    label:string,
    required:boolean
    formType:string
    input:ReactNode
  }[]
}

export const Details:IDetails [] = [
  {
    key: 'account',
    Header: '',
    items: [
      {
        name: 'customerOrderNo',
        label: 'Purchase Order Number',
        required: true,
        formType: 'input',
        input: <Form.Input placeholder="Enter Purchase Order Number" />,
      },
      {
        name: 'requestedShipDate',
        label: 'Requested Ship Date',
        required: false,
        formType: 'datePicker',
        input: <DatePicker placeholder="Ship Date" className={styles.datePicker} />,
      },
      {
        name: 'accountNumber',
        label: 'G.FOX Account number',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your name" />,
      },
      {
        name: 'billingCompany',
        label: 'Company name',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your company name" />,
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your email address" />,
      },
      {
        name: 'billingPhone',
        label: 'Phone Number',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your phone number" />,
      },
      // TODO: add industry sector field
      // {
      //   label: 'Industry Sector',
      //   required: false,
      //   formType: 'input',
      //   input: <Form.Input disabled placeholder="Enter your industry sector " />,
      // },
    ],
  },
  {
    key: 'branch',
    Header: 'Your nearest branch',
    items: [
      {
        name: 'branchName',
        label: 'This the branch through which your orders will be processed',
        required: false,
        formType: 'input',
        input: (
          <Form.Select disabled options={[]} placeholder="Select your nearest branch" />
        ),
      },

    ],
  },
  {
    key: 'deliveryAddress',
    Header: 'Delivery Address:',
    items: [

      {
        name: 'address1',
        label: 'Address Line 1',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your address" />,
      },
      {
        name: 'address2',
        label: 'Address Line 2',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your address" />,
      },
      {
        name: 'address3',
        label: 'Suburb',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your address" />,
      },
      {
        name: 'city',
        label: 'City',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter your city name" />,
      },
      {
        name: 'province',
        label: 'Select province',
        required: false,
        formType: 'input',
        input: (
          <Form.Select disabled options={[]} placeholder="Select the province" />
        ),
      },
      {
        name: 'country',
        label: 'Select the country',
        required: false,
        formType: 'input',
        input: (
          <Form.Select disabled options={[]} placeholder="Select the Country" />
        ),
      },
      {
        name: 'postalCode',
        label: 'Postal code',
        required: false,
        formType: 'input',
        input: <Form.Input disabled placeholder="Enter postal code" />,
      },
      {
        name: 'comments',
        label: 'Comments/Notes',
        required: false,
        formType: 'textarea',
        input: <Form.TextArea placeholder="Enter Comments" />,
      },

    ],
  },

];
