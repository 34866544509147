import React, { useState, useRef, useEffect } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { Input, InputRef, message } from 'antd';
import styles from './styles.module.css';
import Buttons from '.';
import Typography from '../Typography';

const { Text } = Typography;

interface ICartCountBtn {
  className?: string;
  onAddClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMinusClick?: React.MouseEventHandler<HTMLButtonElement>;
  // eslint-disable-next-line react/require-default-props
  handleQuantityChange?: React.ChangeEventHandler<HTMLInputElement>;
  quantity: number;
  showEditButton?: boolean;
  size?: 'default' | 'small';
}

const CartCountBtn = ({
  className,
  onAddClick,
  onMinusClick,
  handleQuantityChange,
  quantity,
  showEditButton,
  size,
}: ICartCountBtn) => {
  const [inputValue, setInputValue] = useState(quantity);
  const [error, setError] = useState('');
  const quantityInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (quantityInputRef.current && quantityInputRef.current.input) {
      // quantityInputRef.current.input.value = quantity.toString();
    }
  }, [quantity]);
  useEffect(() => {
    setInputValue(quantity);
  }, [quantity]);

  const handleChange = (e:any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const inputValue = e.target.value;
    const numericValue = Number(inputValue);

    setInputValue(numericValue); // update the state here

    if (inputValue === '' || numericValue < 1) {
      setError('Quantity empty or less than 1.');
      message.error('Quantity cannot be less than 1 or empty.');
    } else {
      setError('');
      if (handleQuantityChange) {
        handleQuantityChange(e);
      }
    }
  };

  return (
    <div
      className={[
        styles.cartCountCon,
        size === 'small' && styles.cartCountSmall,
        className,
      ].join(' ')}
    >
      {showEditButton && (
        <Buttons.PrimaryBtn
          className={styles.cartCountBtn}
          type="default"
          icon={<FiMinus className={styles.cartCountIcon} />}
          onClick={onMinusClick}
        />
      )}
      {showEditButton ? (
        <>
          <div className={styles.inputWithError}>
            <Input
              ref={quantityInputRef}
              className={[
                styles.cartCountInput,
                styles.cartCountTextHidden,
              ].join(' ')}
              value={inputValue}
              onChange={handleChange}
              type="number"
              min={1}
            />
            {error && <div className={styles.errorText}>{error}</div>}
          </div>
        </>
      ) : (
        <Text
          className={[
            styles.cartCountText,
            !showEditButton && styles.cartCountTextHidden,
          ].join(' ')}
        >
          {quantity}
        </Text>
      )}
      {showEditButton && (
        <Buttons.PrimaryBtn
          className={styles.cartCountBtn}
          type="default"
          icon={<FiPlus className={styles.cartCountIcon} />}
          onClick={onAddClick}
        />
      )}
    </div>
  );
};

CartCountBtn.defaultProps = {
  className: undefined,
  onAddClick: undefined,
  onMinusClick: undefined,
  showEditButton: true,
  size: 'default',
};

export default CartCountBtn;
