import styled from 'styled-components';

export const MenuContainer = styled.div<{flexDirection: 'row'| 'column'}>`
    display:flex;
    flex-direction:${(props) => props.flexDirection};
    position:relative;
    flex: 1;
    top:0px;
    width : 100%;
    
    a {
        text-decoration : none;
        width: 100%;
        height:100%;
    }
`;

export const MenuItemContainer = styled.div`
    display:flex;

    label {
        white-space: nowrap;
        font-size: 14px;
    }
`;
