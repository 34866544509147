import APIClient, { IPaystackTransactionData, PaystackChannelEnum } from '@bridgelabsdesign/gfox-api-client';
import { action, makeObservable, observable } from 'mobx';

class PayStore {
  isLoadingPaystackTransAuth = false;

  paystackCardTransactionData: IPaystackTransactionData[] = [];

  paystackOtherTransactionData: IPaystackTransactionData[] = [];

  constructor() {
    makeObservable(this, {
      isLoadingPaystackTransAuth: observable,
      setIsLoadingPaystackTransAuth: action,
      paystackCardTransactionData: observable,
      setPaystackCardTransactionData: action,
      paystackOtherTransactionData: observable,
      setPaystackOtherTransactionData: action,
      fetchPaystackPaymentInfo: action,
    });
  }

  setIsLoadingPaystackTransAuth(value: boolean) {
    this.isLoadingPaystackTransAuth = value;
  }

  setPaystackCardTransactionData(value: IPaystackTransactionData[]) {
    this.paystackCardTransactionData = value;
  }

  setPaystackOtherTransactionData(value: IPaystackTransactionData[]) {
    this.paystackOtherTransactionData = value;
  }

  async fetchPaystackPaymentInfo(clientId: string, reusable = true, distinctSignature = true) {
    const queryParams = `reusable=${reusable}&distinctSignature=${distinctSignature}&`;
    try {
      this.setIsLoadingPaystackTransAuth(true);
      const results = (await APIClient.Pay.getPaystackTransactionByClient(clientId, queryParams));
      const cardDetails = results.filter((x) => x.channel === PaystackChannelEnum.CARD);
      const otherDetails = results.filter((x) => x.channel !== PaystackChannelEnum.CARD);
      this.setPaystackCardTransactionData(cardDetails);
      this.setPaystackOtherTransactionData(otherDetails);
      this.setIsLoadingPaystackTransAuth(false);
    } catch (error) {
      this.setIsLoadingPaystackTransAuth(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export default PayStore;
