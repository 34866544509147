import React, { useMemo } from 'react';
import { Divider, Table } from 'antd';
import { IVoucherClaim } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography';
import styles from './voucher.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { formatDate } from '../../utils/strings';

const { Text } = Typography;

interface IVouchersTableProps {
  voucherClaims: IVoucherClaim[]
}

const VouchersTable = observer(({
  voucherClaims,
}: IVouchersTableProps) => {
  const { isMobileWindow } = useWindowSize();

  const tableColumns = [
    {
      title: '#',
      dataIndex: 'refNo',
      showMobile: true,
      key: 'quoteNo',
      width: 50,
      render: (text: string, record: IVoucherClaim, idx: number) => (
        <Text className={styles.colRefNo}>
          {`${idx + 1}`}
        </Text>
      ),
    },
    {
      title: 'Code',
      showMobile: false,
      key: 'refNo',
      width: 125,
      render: (text: string, record: IVoucherClaim) => (
        <Text className={styles.colStatus}>
          {`${record.voucher?.refNo ?? ''}`}
        </Text>
      ),
    },
    {
      title: 'Title',
      showMobile: true,
      key: 'title',
      render: (text: string, record: IVoucherClaim) => (
        <>
          <Text className={styles.colStatus}>
            {`${record.voucher?.title ?? ''}`}
          </Text>
          {isMobileWindow && (
            <>
              <Divider />
              <Text className={styles.colStatus}>
                {`${record.voucher?.description ?? ''}`}
              </Text>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Description',
      showMobile: false,
      key: 'description',
      render: (text: string, record: IVoucherClaim) => (
        <Text className={styles.colStatus}>
          {`${record.voucher?.description ?? ''}`}
        </Text>
      ),
    },
    {
      title: 'Valid Till',
      dataIndex: 'toDate',
      showMobile: false,
      key: 'date',
      render: (text: string, record: IVoucherClaim) => (
        <Text className={styles.colStatus}>
          {record?.voucher?.fromDate && formatDate(record.voucher.fromDate as string)}
          {' - '}
          {record?.voucher?.toDate && formatDate(record.voucher.toDate as string)}
        </Text>
      ),
    },
  ];

  // eslint-disable-next-line arrow-body-style
  const columns = useMemo(() => {
    return !isMobileWindow ? tableColumns : tableColumns.filter((x) => x.showMobile);
  }, [isMobileWindow, tableColumns]);

  return (
    <>
      <div className={styles.tableCon}>
        <Table<IVoucherClaim>
          style={{ width: '100%' }}
          className="account-table"
          dataSource={voucherClaims}
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
          scroll={{ y: 1500 }}
        />
      </div>
    </>
  );
});

export default VouchersTable;
