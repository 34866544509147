import React from 'react';
import { ISideBarLayoutProps } from '../pages';
import Body from './styles';

const SideBarLayout = ({ component: Component, sideBarContent }: ISideBarLayoutProps) => (
  <Body>
    <div id="left" className="column">
      <div className="top-left">LOGO/BRAND</div>
      <div className="bottom">
        { sideBarContent }
      </div>
    </div>
    <div id="right" className="column">
      <div className="right-full">
        <Component />
      </div>
    </div>
  </Body>
);

export default SideBarLayout;
