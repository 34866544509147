/* eslint-disable no-unused-vars */
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import Typography from '../Typography';
import styles from './order-summary.module.css';
import stores from '../../stores/stores';
import { useAuthContext } from '../../context/AuthContext';
import Images from '../../assets';

interface ICheckoutPaymentDetails {
  cardIcon?: ReactNode;
  cardName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  cardNumber?: string | null;
  expiryDate?: string | null;
  cardCvv?: string | null;
}
// eslint-disable-next-line no-unused-vars
interface IOrderSummaryCardProps {}

const { Text } = Typography;
export interface IAddressInfo {
  firstName?: string;
  lastName?: string;
  title?: string;
  accountNumber?: string;
  companyName?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  branch?: string;
  phoneNumber?: string;
  emailAddress?: string;
}

const AddressInfo = ({ address, header }: {
  address: IAddressInfo,
  header: string,
}) => (
  <div>
    <Text className={styles.addrHeader}>
      {header ?? ''}
    </Text>

    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Name
      </Text>
      <Text className={styles.addrValue}>
        {`${address.firstName ?? ''} ${address.lastName ?? ''}`}
      </Text>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Account number
      </Text>
      <Text className={styles.addrValue}>
        {address.accountNumber ?? ''}
      </Text>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Company name
      </Text>
      <Text className={styles.addrValue}>
        {address.companyName ?? ''}
      </Text>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Street
      </Text>
      <span className={styles.addrValue}>
        <Text className={styles.addrValue}>
          {address.address1 ?? ''}
        </Text>
        <br />
        <Text className={styles.addrValue}>
          {address.address2 ?? ''}
        </Text>
      </span>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Postal code
      </Text>
      <Text className={styles.addrValue}>
        {address.postalCode ?? ''}
      </Text>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Branch
      </Text>
      <Text className={styles.addrValue}>
        {address.branch ?? ''}
      </Text>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Phone
      </Text>
      <Text className={styles.addrValue}>
        {address.phoneNumber ?? ''}
      </Text>
    </div>
    <div className={styles.addrValueCon}>
      <Text className={styles.addrTitle}>
        Email
      </Text>
      <Text className={styles.addrValue}>
        {address.emailAddress ?? ''}
      </Text>
    </div>
  </div>
);

const PaymentInfo = ({ payment }: {
  payment: ICheckoutPaymentDetails,
}) => (
  <div className={styles.paymentCon}>
    <Text className={styles.paymentHeader}>
      Payment method
    </Text>
    <div className={[styles.paymentValueCon, styles.paymentCardIconCon].join(' ')}>
      <span className={styles.paymentCardIcon}>
        {payment.cardIcon}
      </span>
      <Text className={styles.paymentProvider}>
        {payment?.cardName ?? '***'}
      </Text>
    </div>
    <div className={styles.paymentValueCon}>
      <Text className={styles.paymentTitle}>
        Cardholder name
      </Text>
      <Text className={styles.paymentValue}>
        {`${payment.firstName ?? ''} ${payment.lastName ?? ''}`}
      </Text>
    </div>
    <div className={styles.paymentValueCon}>
      <Text className={styles.paymentTitle}>
        Card number
      </Text>
      <Text className={styles.paymentValue}>
        {payment?.cardNumber ?? ''}
      </Text>
    </div>
    <div className={styles.paymentValueCon}>
      <Text className={styles.paymentTitle}>
        Expiry date
      </Text>
      <Text className={styles.paymentValue}>
        {payment?.expiryDate ?? ''}
      </Text>
    </div>
    <div className={styles.paymentValueCon}>
      <Text className={styles.paymentTitle}>
        CVV
      </Text>
      <Text className={styles.paymentValue}>
        {payment?.cardCvv ?? ''}
      </Text>
    </div>
  </div>
);

const OrderSummaryCard = observer(() => {
  const { currentClient } = useAuthContext();
  const { addressStore, checkoutStore } = stores;

  const authorization = checkoutStore.selectedPaystackMethod?.authorization;

  return (
    <div className={styles.orderCon}>
      {/* {addressStore.selectedAddress && ( */}
      <div className={styles.orderInfoCon}>
        <AddressInfo
          address={{
            firstName: currentClient?.userAuth?.firstName,
            lastName: currentClient?.userAuth?.lastName,
            // title: addressStore.address1; // TODO: add title to user info
            accountNumber: currentClient?.accountNumber,
            companyName: currentClient?.billingCompany,
            address1: addressStore.selectedAddress?.address1,
            address2: addressStore.selectedAddress?.address2,
            postalCode: addressStore.selectedAddress?.postalCode,
            branch: currentClient?.branch?.branchName,
            phoneNumber: currentClient?.billingPhone,
            emailAddress: currentClient?.userAuth?.emailAddress,
          }}
          header="Billing Address"
        />
      </div>
      {/* )} */}

      {/* {checkoutStore.postalAddress && ( */}
      {/* <div className={styles.orderInfoCon}>
        <AddressInfo
          address={{
            firstName: currentClient?.userAuth?.firstName,
            lastName: currentClient?.userAuth?.lastName,
            // title: addressStore.address1; // TODO: title
            accountNumber: currentClient?.accountNumber,
            companyName: currentClient?.billingCompany,
            address1: addressStore.selectedAddress?.address1,
            address2: addressStore.selectedAddress?.address2,
            postalCode: addressStore.selectedAddress?.postalCode,
            branch: currentClient?.branch?.branchName,
            phoneNumber: currentClient?.billingPhone,
            emailAddress: currentClient?.userAuth?.emailAddress,
          }}
          header="Postal Address"
        />
      </div> */}
      {/* )} */}

      {authorization && (
        <div className={styles.orderInfoCon}>
          <PaymentInfo
            payment={{
              cardIcon: <Images.CardIcon className={styles.cardIcon} data={authorization} />,
              cardName: authorization?.card_type,
              firstName: authorization?.account_name,
              lastName: '--',
              cardNumber: `**** **** **** ${authorization?.last4 ?? ''}`,
              expiryDate: `${authorization?.exp_month} - ${authorization?.exp_year}`,
              cardCvv: '--',
            }}
          />
        </div>
      )}
    </div>
  );
});

export default OrderSummaryCard;
