import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Card, Rate, Modal, Input,
} from 'antd';
import { IRatingModel } from '@bridgelabsdesign/gfox-api-client';
import styles from './average-rating.module.css';
import Buttons from '../Buttons';
import stores from '../../stores/stores';
import { IAuthContext, useAuthContext } from '../../context/AuthContext';

const { TextArea } = Input;
// interface AverageRatingCardProps {
//   reviews: IRatingModel[];
// }
const AverageRatingCard = observer(() => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reviewRating, setReviewRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const { rating, cartStore } = stores;
  const { currentClient } = useAuthContext() as IAuthContext;
  const [averageRating, setAverageRating] = useState<number | null>(null);
  const [ratingModel, setRatingModel] = useState<IRatingModel>({
    clientId: null,
    skuMasterId: null,
    deletedByUserId: null,
    value: 0,
    comment: '',
    deletedAt: null,
  } as IRatingModel);

  useEffect(() => {
    setRatingModel({
      clientId: currentClient?.id ?? null,
      skuMasterId: cartStore.sku?.id ?? null,
      deletedByUserId: null,
      value: reviewRating,
      comment: reviewText,
      deletedAt: null,
    } as IRatingModel);
  }, [currentClient?.id, cartStore.sku?.id, reviewRating, reviewText]);

  useEffect(() => {
    // Get the average rating on component mount
    const fetchAverageRating = async () => {
      const avgRating = await rating.getAverageRatingBySkuMaster(cartStore.sku?.id);
      setAverageRating(avgRating);
    };
    fetchAverageRating();
  }, [cartStore.sku?.id, rating.ratings]);

  useEffect(() => {
    const checkReviewVisibility = async () => {
      if (currentClient?.id && cartStore.sku?.id) {
        const visibility = await rating.checkReviewVisibility(currentClient?.id, cartStore.sku?.id);
        rating.reviewVisibility = visibility;
      }
    };
    checkReviewVisibility();
  }, [currentClient?.id, cartStore.sku?.id, rating.ratings]);

  const onWriteReviewClick = () => {
    setIsModalVisible(true);
  };

  // const handleOk = async () => {
  //   console.log(`Submitted review with rating: ${reviewRating} and text: "${reviewText}"`);
  //   setIsModalVisible(false);
  //   const isSuccess = await rating.postRating(ratingModel);
  //   if (isSuccess) {
  //     // Handle successful rating submission
  //   } else {
  //     // Handle failed rating submission
  //   }
  // };

  // const handleOk = async () => {
  //   setIsModalVisible(false);
  //   const existingReview = rating.ratings?.find((review) => review.clientId === currentClient?.id);
  //   if (existingReview) {
  //     ratingModel.id = existingReview.id;
  //     const isSuccess = await rating.updateRating(existingReview.id, ratingModel);
  //     if (isSuccess) {
  //       console.log('Updated review successfully');
  //       rating.updateReviewInStore(ratingModel);
  //     } else {
  //       console.error('Failed to update review');
  //     }
  //   } else {
  //   // If a review doesn't exist, create a new one
  //     const isSuccess = await rating.postRating(ratingModel);
  //     if (isSuccess) {
  //       console.log('Posted new review successfully');
  //     } else {
  //       console.error('Failed to post new review');
  //     }
  //   }
  // };

  const handleOk = async () => {
    setIsModalVisible(false);
    const existingReview = rating.ratings?.find((review) => review.clientId === currentClient?.id);
    if (existingReview) {
      ratingModel.id = existingReview.id;

      Modal.confirm({
        title: 'Are you sure you want to update this review?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          const isSuccess = await rating.updateRating(existingReview.id, ratingModel);
          if (isSuccess) {
            console.log('Updated review successfully');
            rating.updateReviewInStore(ratingModel);
          } else {
            console.error('Failed to update review');
          }
        },
        onCancel() {
          console.log('Update canceled');
        },
      });
    } else {
      // If a review doesn't exist, create a new one
      const isSuccess = await rating.postRating(ratingModel);
      if (isSuccess) {
        console.log('Posted new review successfully');
      } else {
        console.error('Failed to post new review');
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Card title="Average Rating" className={styles.averageRatingCard}>
      <div className={styles.ratingContainer}>
        {averageRating ? (
          <>
            <Rate allowHalf value={averageRating} className={styles.rating} disabled />
            <div className={styles.ratingValue}>{averageRating.toFixed(2)}</div>
          </>
        ) : (
          <div className={styles.noReviews}>No reviews yet</div>
        )}
      </div>

      {rating.reviewVisibility && (
      <Buttons.PrimaryBtn
        className={styles.reorderBtn}
        type="default"
        onClick={onWriteReviewClick}
      >
        Write review
      </Buttons.PrimaryBtn>
      )}
      <Modal title="Write a review" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Rate onChange={setReviewRating} />
        <TextArea rows={4} onChange={(e) => setReviewText(e.target.value)} />
      </Modal>
    </Card>
  );
});

export default AverageRatingCard;
