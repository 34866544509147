import { ITeamProps } from '../../components/Cards/TeamCard';
import Images from '../../assets';

export interface ITeams{
  Header:string
  items:ITeamProps[]
}
// eslint-disable-next-line import/prefer-default-export
export const TeamGen:ITeams [] = [

  {
    Header: 'Head Office',
    items: [{
      imageSrc: Images.SibusisoKhumalo,
      EmployeeName: 'Sibusiso Khumalo',
      EmployeePosition: 'Group Managing Director',
      phone: '011 417 9300',
      location: 'Johannesburg',
      Email: 'sibusisok@gfox.co.za',
    },
    {
      imageSrc: Images.AvinashRamnarain,
      EmployeeName: 'Avinash Ramnarain',
      EmployeePosition: 'Chief Financial Officer',
      phone: '011 417 9300',
      location: 'Johannesburg',
      Email: 'avinashr@gfox.co.za',
    },
    {
      imageSrc: Images.ManuPadayachee,
      EmployeeName: 'Manu Padayachee',
      EmployeePosition: 'National Operations Manager',
      phone: '011 417 9300',
      location: 'Johannesburg',
      Email: 'manup@gfox.co.za',
    },
    {
      imageSrc: Images.Dylan,
      EmployeeName: 'Dylan Thomas',
      EmployeePosition: 'National Commercial Manager',
      phone: '011 417 9300',
      location: 'Johannesburg',
      Email: 'dylant@gfox.co.za',
    },
    {
      imageSrc: Images.mlandvo,
      EmployeeName: 'Mlandvo Motsa',
      EmployeePosition: 'Group Transformation & Compliance Manager',
      phone: '011 417 9300',
      location: 'Johannesburg',
      Email: 'mlandvom@gfox.co.za',
    },
    {
      imageSrc: Images.SurangaAriyawansa,
      EmployeeName: 'Suranga Ariyawansa',
      EmployeePosition: 'General Manager Eswatini',
      phone: '+26 82 51 86396',
      location: 'Eswatini',
      Email: 'suranga@sz.gfox.co.za',
    },
    ],
  },
  {
    Header: 'Branch Managers',
    items: [
      {
        imageSrc: Images.Zenzele,
        EmployeeName: 'Zenzele Ntuli',
        EmployeePosition: 'General Manager – Inland',
        phone: '011 417 9300',
        location: 'Johannesburg',
        Email: 'zenzelen@gfox.co.za',
      },
      {
        imageSrc: Images.JackKukard,
        EmployeeName: 'Jack Kukard',
        EmployeePosition: 'Acting Branch Manager – Durban',
        phone: '031 274 0200',
        location: 'Durban',
        Email: 'jackk@gfox.co.za',
      },
      {
        imageSrc: Images.Simphiwe,
        EmployeeName: 'Simphiwe Nxumalo',
        EmployeePosition: 'Branch Manager – Eswatini',
        phone: '+26 82 51 86396',
        location: 'Eswatini',
        Email: 'SimphiweN@sz.gfox.co.za',
      },
      {
        imageSrc: Images.StephenKrain,
        EmployeeName: 'Stephen Krain',
        EmployeePosition: 'Branch Manager – Gqeberha',
        phone: '041 453 2178',
        location: 'Gqeberha',
        Email: 'stephenk@gfox.co.za',
      },
      {
        imageSrc: Images.ZukoTeto,
        EmployeeName: 'Zuko Teto',
        EmployeePosition: 'General Manager – Western Cape',
        phone: '021 514 1400',
        location: 'Cape Town',
        Email: 'zukot@gfox.co.za',
      },
      {
        imageSrc: Images.Ian,
        EmployeeName: 'Ian du Preez',
        EmployeePosition: 'Branch Manager – Middelburg',
        phone: '013 246 2402',
        location: 'Middelburg',
        Email: 'iandp@gfox.co.za',
      },
      {
        imageSrc: Images.Melanie,
        EmployeeName: 'Melanie Babbage',
        EmployeePosition: 'Branch Manager – Polokwane',
        phone: '015 297 6314',
        location: 'Polokwane',
        Email: 'melanieb@gfox.co.za',
      },
      {
        imageSrc: Images.DewaldVanDerSchyff,
        EmployeeName: 'Dewald Van Der Schyff',
        EmployeePosition: 'Branch Manager - Zambia',
        phone: '+26 02 1 1 847911/2',
        location: 'Bidvest Industrial Supplies (Z) Ltd',
        Email: 'dewaldv@gfox.co.zm',
      },
    ],
  },
];
