import React, { useState } from 'react';
import { Select as AntdSelect, SelectProps, Tag } from 'antd';
import { FiChevronDown } from 'react-icons/fi';
import styles from './styles.module.css';
import Buttons from '../Buttons';

export interface ISelectOptions {
  value: string | number,
  label: string,
}

export interface ISelectProps extends SelectProps {
  options: ISelectOptions[];
  rounded?: boolean;
}

const Select = ({
  className,
  options,
  placeholder,
  rounded,
  size,
  suffixIcon,
  ...rest
}: ISelectProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className={[
      styles.selectCon,
      rounded && styles.selectRounded,
    ].join(' ')}
    >
      <AntdSelect
        open={drawerOpen}
        onDropdownVisibleChange={(_) => setDrawerOpen(_)}
        suffixIcon={<></>}
        className={[styles.select, className].join(' ')}
        size="large"
        placeholder={placeholder}
        tagRender={(props) => (
          <Tag
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            closable={props.closable}
            onClose={props.onClose}
            style={{ marginRight: 3 }}
          >
            {props.label}
          </Tag>
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {options.map((item) => (
          <AntdSelect.Option
            key={item.value}
            value={item.value}
          >
            {item.label}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
      <Buttons.PrimaryBtn
        className={styles.selectSuffixBtn}
        onClick={() => { setDrawerOpen(true); }}
        icon={(
          suffixIcon ?? (
            <FiChevronDown
              className={styles.selectSuffixBtnIcon}
            />
          )
        )}
      />
    </div>
  );
};

Select.defaultProps = {
  rounded: false,
};

export default Select;
