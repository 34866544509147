import React, { ReactNode } from 'react';
import { Typography } from 'antd';

interface ITitleProps {
  children?: ReactNode;
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5
}

const Title = ({
  children,
  className,
  level,
}: ITitleProps) => (
  <Typography.Title
    level={level}
    className={[className].join(' ')}
  >
    { children}
  </Typography.Title>
);

Title.defaultProps = {
  children: undefined,
  className: undefined,
  level: 1,
};

export default Title;
