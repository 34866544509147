import React from 'react';
import { observer } from 'mobx-react-lite';
import Cards from '../../components/Cards';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import { formatPrice } from '../../utils/pricing';

const CheckoutDelivery = observer(() => {
  const { deliveryStore } = stores;

  return (
    <div>
      {deliveryStore.deliveryOptions.map((option) => (
        <Cards.DeliveryCard
          className={styles.ordersCard}
          deliveryType={option.description}
          price={formatPrice(option?.deliveryOrders[0]?.price ?? 0)}
          value={option.refNo}
          onSelect={() => deliveryStore.setSelectedDelivery(option)}
          isSelected={option.refNo === deliveryStore.selectedDelivery?.refNo}
        />
      ))}
    </div>
  );
});

export default CheckoutDelivery;
