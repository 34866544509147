/* eslint-disable import/prefer-default-export */

import { LegalDocuments } from '../../utils/legal';

export const generalDocuments = [
  {
    title: 'G.Fox Trading Terms',
    documentUrl: LegalDocuments.TRADING_TERMS,
  },
  {
    title: 'G.Fox Returns Policy',
    documentUrl: LegalDocuments.RETURN_POLICY,
  },
  {
    title: 'G.Fox Credit Application',
    documentUrl: LegalDocuments.CREDIT_APPLICATION,
  },
];

export const popiaDocuments = [
  {
    title: 'G.Fox Privacy Policy',
    documentUrl: LegalDocuments.PRIVACY_POLICY,
  },
  {
    title: 'GROUP PAIA Manual',
    documentUrl: LegalDocuments.GROUP_PAIA_MANUAL,
  },
  {
    title: 'G.Fox Cookies Policy',
    documentUrl: LegalDocuments.COOKIE_POLICY,
  },
  {
    title: 'G.Fox External Processing Notice',
    documentUrl: LegalDocuments.EXTERNAL_PROCESSING_NOTICE,
  },
  {
    title: 'G.Fox HR Processing Notice',
    documentUrl: LegalDocuments.HR_PROCESSING_NOTICE,
  },

  {
    title: 'G.Fox Objection To Processing of Personal Information Form',
    documentUrl: LegalDocuments.OBJECTION_TO_PROCESSING,
  },
  {
    title: 'G.Fox Request for Correction/Deletion of Personal Information Form',
    documentUrl: LegalDocuments.REQUEST_FOR_CORRECTION,
  },
  {
    title: 'G.Fox Request for Data Subjects Consent to Process Personal Information Form',
    documentUrl: LegalDocuments.REQUEST_FOR_CONSENT,
  },
  {
    title: 'G.Fox POPIA Complaint Form',
    documentUrl: LegalDocuments.POPIA_COMPLAINT_FORM,
  },
  {
    title: 'G.Fox POPIA Internal Complaint Form',
    documentUrl: LegalDocuments.POPIA_INTERNAL_COMPLAINT_FORM,
  },
];
