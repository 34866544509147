const quoteReorderKey = '@quote_reorder';

const saveRefNo = (refNo: string) => {
  localStorage.setItem(quoteReorderKey, refNo);
};

const getRefNo = () => localStorage.getItem(quoteReorderKey);

const removeRefNo = () => {
  localStorage.removeItem(quoteReorderKey);
};

const QuoteReorder = {
  saveRefNo,
  getRefNo,
  removeRefNo,
};

export default QuoteReorder;
