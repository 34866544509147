import { action, makeObservable, observable } from 'mobx';
import APIClient, { ILoginRequest } from '@bridgelabsdesign/gfox-api-client';

class EmailTokenStore {
    emailCodeVerified = false;

    constructor() {
      makeObservable(this, {
        emailCodeVerified: observable,
        setEmailCodeVerified: action,
        sendVerificationCode: action,
        verifyCode: action,
      });
    }

    setEmailCodeVerified(value: boolean) {
      this.emailCodeVerified = value;
    }

    async sendVerificationCode(body: ILoginRequest) {
      let queryParams = `createUserAuth=true&preferredVerificationMethod=${body.preferredVerificationMethod}`;
      if (body.registration !== undefined) {
        queryParams += `&isRegistration=${body.registration}`;
      }
      const userAuth = await APIClient.EmailToken.sendVerificationCode(body, queryParams);
      this.setEmailCodeVerified(true);
      return userAuth!;
    }

    // eslint-disable-next-line class-methods-use-this
    async verifyCode(verificaytionCode: string, body: ILoginRequest, isLogin: boolean = false) {
      await APIClient.EmailToken.verifyCode(verificaytionCode, body, isLogin);
    }
}
export default EmailTokenStore;
