/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Table, Button,
} from 'antd';
import './style.less';
import { ISurvey } from '@bridgelabsdesign/gfox-api-client';
import stores from '../../stores/stores';
import Spin from '../../components/Spin/Spin';
import ReviewDrawer from '../../components/Drawers/ReviewDrawer';

const Response = () => {
  const [loading, setLoading] = React.useState(false);
  const { surveyStore } = stores;
  const [selectedReview, setSelectedReview] = React.useState<ISurvey | null>(null);
  const [revDrawerVisible, setRevDrawerVisible] = React.useState(false);
  // const [managerArray, setManagerArray] = React.useState([]);
  // const [selfRevArray, setSelfRevArray] = React.useState([]);
  const [reportsRevArray, setReportsRevArray] = React.useState<ISurvey[]>([]);
  const [surveys, setSurveys] = React.useState<ISurvey[]>([]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = () => width <= 768;

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const displayReviewDrawer = (record:ISurvey) => {
    setSelectedReview(record);
    setRevDrawerVisible(true);
  };

  const employeeTableData = [
    {
      title: <div className="user-table-head">Job Applications</div>,
      dataIndex: 'name',
      key: 'name',
      // width: 350,
      ellipses: true,
    },

    {
      key: 'action',
      title: <div className="user-table-head">Action</div>,
      render: (action:any, record:ISurvey) => (
        <>

          {' '}
          <Button onClick={() => displayReviewDrawer(record)} className="edit-q"> Apply Now</Button>

        </>
      ),
    },
  ];

  const mobileReviewTableData = [
    {
      dataIndex: 'name',
      key: 'name',
      // render: (id:any) => <div className="table-text-mobile">{moment(findSurveyCreatedAt(id)).fromNow()}</div>,
    },
    {
      key: 'action',
      render: (action:any, record:ISurvey) => (
        <>
          <Button onClick={() => displayReviewDrawer(record)} className="edit-q edit-q-mobile">Apply Now</Button>
        </>
      ),
    },

  ];

  const getSurveys = async () => {
    await surveyStore.loadSurvey();
    setSurveys(surveyStore.surveys.value.data);
  };

  useEffect(() => {
    setLoading(true);
    if (surveys.length === 0) {
      getSurveys();
    }
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setReportsRevArray(surveyStore.surveys.value.data);
  }, [surveyStore.surveys.value.data]);

  return (
    <Spin tip="Getting reviews" spinning={loading}>
      <main id="reviews-root" className="productOfferCon">

        <div className="pending-rev ">
          Pending Reviews
        </div>

        {
        reportsRevArray.length > 0
          ? (
            <>

              <div className="rev-msg">
                Welcome to the exciting career opportunities available at GFox! If you are passionate about joining our team, please consider the following steps to submit your application:
                <div className="rev-next-steps" style={{ marginTop: '10px' }}>1) Explore Openings Below: Browse through the various positions listed on our careers page to find the perfect fit for your skills and interests. Whether you are an experienced professional or just starting your career, GFox has opportunities for you.</div>
                <div className="rev-next-steps" style={{ margin: '5px 0' }}>2) Fill Out the Job Application Form: Once you have found a suitable role, click on the Apply Now button to access our online application form. Complete all required fields, and do not hesitate to showcase your unique qualifications and experiences. This process should take around 15 minutes</div>
                <div className="rev-next-steps">3)Await Our Response: After submitting your application, our recruitment team will review your qualifications and get back to you as soon as possible. Make sure to keep an eye on your email for any follow-up questions or interview invitations.</div>
              </div>

              <div className="rev-table-contain">
                <div className="rev-table-label">Job Openings</div>
                <Table
                  className="review-table"
                  rowKey="id"
                  columns={isMobile() ? mobileReviewTableData : employeeTableData}
                  dataSource={reportsRevArray}
                />
              </div>

            </>
          )
          : (
            <div className="empty-review">
              There are no scheduled reviews at the moment
            </div>
          )
      }
        <ReviewDrawer visible={revDrawerVisible} setVisible={setRevDrawerVisible} reviewsList={[]} selectedReview={selectedReview} />

      </main>
    </Spin>
  );
};
export default Response;
