import React, { useMemo, useState } from 'react';
import {
  Input, Modal, Table, message,
} from 'antd';
import { IInventoryMaster, IQuote } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './quotes.module.css';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { formatDate, isEmptyString } from '../../utils/strings';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import { copyObjects } from '../../utils/copyObjects';
import { CheckoutPath } from '../../utils/checkout';

const { Link, Text } = Typography;

interface IQuotesTableProps {
  quotes: IQuote[]
}

const QuotesTable = observer(({
  quotes,
}: IQuotesTableProps) => {
  const { currentClient } = useAuthContext();
  const { cartStore, quotesStore } = stores;
  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState('');
  const [selectedQuote, setSelectedQuote] = useState<IQuote>();

  const handleOnDeclineQuoteClick = (quoteItem: IQuote) => {
    setSelectedQuote(quoteItem);
    setVisible(true);
  };

  const handleOk = async () => {
    if (selectedQuote) {
      try {
        await quotesStore.declineQuote(selectedQuote, reason);
        setVisible(false);
        setReason('');
        message.success('Quote declined successfully.');
      } catch (error) {
        console.error(error);
        message.error('Could not decline quote.');
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setReason('');
  };

  const history = useHistory();

  const handleAddToCart = async (quoteItem: IQuote) => {
    const invList = quoteItem.quoteInventories.map<IInventoryMaster>((x) => copyObjects(x.inventoryMaster, { orderQty: x.orderQty }));
    await cartStore.onReorder(currentClient?.id!, invList ?? [], quoteItem.refNo);
    history.push(CheckoutPath.CART);
  };

  const tableColumns = [
    {
      title: 'Quotes No:',
      dataIndex: 'refNo',
      showMobile: true,
      key: 'quoteNo',
      render: (text: string) => (
        <Text className={styles.colRefNo}>
          {`#${text}`}
        </Text>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      showMobile: false,
      key: 'date',
      render: (text: string) => (
        <Text className={styles.colStatus}>
          {formatDate(text)}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showMobile: true,
      key: 'status',
      render: (text: string, record: IQuote) => (
        <Text className={styles.colStatus}>
          {`Quote ${!isEmptyString(record.fileUrl) ? 'Ready' : 'Processing'}`}
        </Text>
      ),
    },
    {
      title: 'Action:',
      dataIndex: 'id',
      showMobile: true,
      key: 'action',
      render: (text: string, record: IQuote) => (
        <>
          <Buttons.SecondaryBtn
            className={[styles.OrderMonitorView, styles.colViewBtn].join(' ')}
            type="text"
            disabled={isEmptyString(record.fileUrl)}
            onClick={() => handleAddToCart(record)}
          >
            Accept Quote
          </Buttons.SecondaryBtn>

          <Buttons.SecondaryBtn
            className={[styles.OrderMobileView, styles.colViewBtn].join(' ')}
            type="text"
            disabled={isEmptyString(record.fileUrl)}
          >
            <Link
              href={record.fileUrl}
              external
            >
              View
            </Link>
          </Buttons.SecondaryBtn>
          <Buttons.SecondaryBtn
            className={[styles.OrderMonitorView, styles.colViewMonBtn].join(' ')}
            disabled={isEmptyString(record.fileUrl)}
          >
            <Link
              href={record.fileUrl}
              external
            >
              View
            </Link>
          </Buttons.SecondaryBtn>

          {record.deleted ? (
            <Buttons.SecondaryBtn disabled className={[styles.OrderMonitorView, styles.colViewBtn].join(' ')}>
              Declined
            </Buttons.SecondaryBtn>
          ) : (
            <Buttons.SecondaryBtn
              className={[styles.OrderMonitorView, styles.colViewBtn].join(' ')}
              type="text"
              onClick={() => handleOnDeclineQuoteClick(record)}
            >
              Decline
            </Buttons.SecondaryBtn>
          )}
        </>
      ),

    },

  ];

  const { isMobileWindow } = useWindowSize();
  // eslint-disable-next-line arrow-body-style
  const columns = useMemo(() => {
    return !isMobileWindow ? tableColumns : tableColumns.filter((x) => x.showMobile);
  }, [isMobileWindow, tableColumns]);

  return (
    <>
      <div className={styles.tableCon}>
        <Table<IQuote>
          className="account-table"
          dataSource={quotes}
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={styles.tableRow}
          scroll={{ y: 500 }}
        />
        {/* The modal component */}
        <Modal
          title="Are you sure you want to decline the quote?"
          visible={visible}
          width={570}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Input
            placeholder="Enter your reason for declining the quote"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Modal>
      </div>
    </>
  );
});

export default QuotesTable;
