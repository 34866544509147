/* eslint-disable max-len */

// branding
import BridgeLabsLogo from './BridgeLabsIcon.png';
import GFoxLogo from './branding/gfox-logo.svg';
import GFoxLogoWhite from './branding/gfox-logo-white.svg';
import GFoxLogoText from './branding/GFoxText.png';
import FoxLogo from './branding/FoxLogo.png';

import { ReactComponent as AddUserIcon } from './icons/add-user.svg';
import { ReactComponent as ArrowDownIcon } from './icons/arrow-down.svg';
import AngloAmericanIcon from './icons/anglo-american.png';
import { ReactComponent as BootIcon } from './icons/boots.svg';
import BidvestPrestigeIcon from './icons/bidvest-prestige.png';
import { ReactComponent as Boot2Icon } from './icons/boots2.svg';
import CardIcon from './icons/CardIcon';
import { ReactComponent as CleaningProductsIcon } from './icons/cleaning-products.svg';
import { ReactComponent as CleaningToolsIcon } from './icons/cleaning-tools.svg';
import { ReactComponent as CleaningIcon } from './icons/cleaning.svg';
import { ReactComponent as Covid19Icon } from './icons/covid-19.svg';
import { ReactComponent as CovidMaskIcon } from './icons/covid-mask.svg';
import { ReactComponent as FacebookIcon } from './icons/facebook.svg';
import { ReactComponent as FactoryIcon } from './icons/factory.svg';
import { ReactComponent as FaxMachineIcon } from './icons/fax.svg';
import { ReactComponent as FileIcon } from './icons/file.svg';
import { ReactComponent as FirstAidKitIcon } from './icons/first-aid-kit.svg';
import GroupFiveIcon from './icons/group-five.png';
import { ReactComponent as HeaderMenuIcon } from './icons/header-menu.svg';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import IllovoGroupIcon from './icons/illovo-group.png';
import { ReactComponent as InvoiceIcon } from './icons/invoice.svg';
import { ReactComponent as LockUnLockIcon } from './icons/lock-unlock.svg';
import { ReactComponent as HandProtectionIcon } from './icons/hand-protection.svg';
import { ReactComponent as LockIcon } from './icons/lock.svg';
import MasterCardIcon from './icons/master-card.png';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import NampakIcon from './icons/nampak.png';
import { ReactComponent as PaperNoteIcon } from './icons/paper-note.svg';
import { ReactComponent as PaperRollIcon } from './icons/paper-roll.svg';
import { ReactComponent as PolicemanIcon } from './icons/policeman.svg';
import { ReactComponent as ProtectiveWearIcon } from './icons/protective-wear.svg';
import RainbowChickensIcon from './icons/rainbow-chickens.png';
import { ReactComponent as SafetyEquipmentIcon } from './icons/safety-equipment.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as ShoppingCartIcon } from './icons/shopping-cart.svg';
import { ReactComponent as ShoppingListIcon } from './icons/shopping-list.svg';
import SouthAfricanBreweriesIcon from './icons/south-african-breweries.png';
import StefanuttiStocksIcon from './icons/stefanutti-stocks.png';
import { ReactComponent as UserIcon } from './icons/user-icon.svg';
import { ReactComponent as PdfIcon } from './icons/pdf-file.svg';
import PayFastIcon from './icons/pay-fast.png';
import EFTIcon from './icons/EFT.png';
import mastercard from './icons/Mastercard-logo.png';

import FallbackImage from './illustrations/fallback-image';

// Audio
export { default as NotificationSound } from './audio/sound-3.mp3';

export const Images = {
  FallbackImage,

  // banners
  BlackFridayCleaningMaterialsBanner: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/dashboard-account1.jpg',
  WorkWearExclusiveBannerMobile: 'https://gfox-cdn.bridgelabs.design/images/website-assets/banners/work-wear-exclusive-mobile.png',
  WorkWearExclusiveBanner: 'https://gfox-cdn.bridgelabs.design/images/website-assets/banners/work-wear-exclusive.png',
  ComingSoonBanner: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/dashboard-cover.jpg',

  // branding
  BridgeLabsLogo,
  GFoxLogo,
  GFoxLogoWhite,
  GFoxLogoText,
  FoxLogo,

  // icons,
  AddUserIcon,
  ArrowDownIcon,
  AngloAmericanIcon,
  BootIcon,
  BidvestPrestigeIcon,
  Boot2Icon,
  CardIcon,
  CleaningProductsIcon,
  CleaningToolsIcon,
  CleaningIcon,
  Covid19Icon,
  CovidMaskIcon,
  FacebookIcon,
  FactoryIcon,
  FaxMachineIcon,
  FileIcon,
  FirstAidKitIcon,
  GroupFiveIcon,
  HeaderMenuIcon,
  HandProtectionIcon,
  HomeIcon,
  IllovoGroupIcon,
  InvoiceIcon,
  LockUnLockIcon,
  LockIcon,
  MasterCardIcon,
  MenuIcon,
  NampakIcon,
  PaperRollIcon,
  PaperNoteIcon,
  PolicemanIcon,
  ProtectiveWearIcon,
  RainbowChickensIcon,
  SafetyEquipmentIcon,
  SearchIcon,
  ShoppingCartIcon,
  ShoppingListIcon,
  SouthAfricanBreweriesIcon,
  StefanuttiStocksIcon,
  UserIcon,
  PdfIcon,
  PayFastIcon,
  EFTIcon,
  mastercard,

  // Slider
  WinterSpecialMobile: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Mobile@Eskimo.webp',
  WinterSpecialTab: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Tablet@Eskimo.webp',
  WinterSpecialLaptop: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Laptop@Eskimo.webp',
  WinterSpecialLargeScreen: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/LargerScreen@Eskimo.webp',
  EasterSlidersMobile: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/EasterMobileWeb-min.webp',
  EasterSlidersTab: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/EasterTabletWeb-min.webp',
  EasterSliderLaptop: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/EasterLaptopMobileWeb-min.webp',
  EasterSliderLargeScreen: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/EasterLargeScreenWeb-min.webp',
  AllCleanSlidersV301: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Allclean%20Sliders%20V3-04.png',
  AllCleanSlidersV302: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Allclean%20Sliders%20V3-03.png',
  AllCleanSlidersV303: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Allclean%20Sliders%20V3-01.png',
  AllCleanSlidersV304: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Allclean%20Sliders%20V3-02.png',
  Alsafe20WebSliders01: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Alsafe%202.0%20Web%20Sliders-01.png',
  Alsafe20WebSliders02: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Alsafe%202.0%20Web%20Sliders-02.png',
  Alsafe20WebSliders03: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Alsafe%202.0%20Web%20Sliders-03.png',
  Alsafe20WebSliders04: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Alsafe%202.0%20Web%20Sliders-04.png',
  ButchersApronWebSliders01: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Butchers%20Apron%20Web%20Sliders-01.png',
  ButchersApronWebSliders02: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Butchers%20Apron%20Web%20Sliders-02.png',
  ButchersApronWebSliders03: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Butchers%20Apron%20Web%20Sliders-03.png',
  ButchersApronWebSliders04: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Butchers%20Apron%20Web%20Sliders-04.png',
  EconomyHandCleanerSliders01: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Economy%20Hand%20Cleaner%20Sliders-01.png',
  EconomyHandCleanerSliders02: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Economy%20Hand%20Cleaner%20Sliders-02.png',
  EconomyHandCleanerSliders03: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Economy%20Hand%20Cleaner%20Sliders-03.png',
  EconomyHandCleanerSliders04: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Economy%20Hand%20Cleaner%20Sliders-04.png',
  HiVizContiSliders01: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Hi%20Viz%20Conti%20Sliders-01.png',
  HiVizContiSliders02: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Hi%20Viz%20Conti%20Sliders-02.png',
  HiVizContiSliders03: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Hi%20Viz%20Conti%20Sliders-03.png',
  HiVizContiSliders04: 'https://gfox-cdn.bridgelabs.design/images/website-assets/sliders/Hi%20Viz%20Conti%20Sliders-04.png',

  // illustrations
  AfricanAmericanWomanHeavyMachinery: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/african-american-woman-worker-machine-heavy.png',
  HomeWelcome: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/home_welcome.png',
  BlackAfricanWomenWorkerCountChecking: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/black-african-professional-women-worker-working-count-checking.png',
  TwoMenWorkingOnSteel: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/G%20Fox%202%20011.jpg',
  // BlackFridaySpecial: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/black-friday-special.png',
  // BlackFridaySpecialMobile: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/black-friday-special-mobile.png',
  HomeSpecials: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/home_specials.png',
  FootwearMenWorking: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/footwear-men-working.png',
  KidsSocialDistancingClassroom: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/kids-social-distancing-classroom.png',
  LebomboSafetyBoot: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/lebombo-safety-boot.png',
  MainSlider1: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/New%20Website%20Sliders-01.jpg',
  MainSlider2: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Website%20Sliders-02.jpg',
  MainSlider3: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Website%20Sliders-03.jpg',
  MainSlider4: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/New%20Website%20Sliders%20V4-06.jpg',
  MainSlider5: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/New%20Website%20Sliders%20V2-02.jpg',
  MainAboutSlider1: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/main_slider1.png',
  MainSliderOld1: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/main_slider1.png',
  MainSliderOld2: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/main_slider2.png',
  MainSliderNew1: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/New-Website-Sliders-SafeRange-01-v2.jpg',
  MainSliderNew2: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/web-slider-8.jpg',
  MainSliderNew3: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/web-slider-7.jpg',
  BackToWork: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/back-to-school-slider3-Jan-2024.jpg',
  ContactUSSlider: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Contact%20Us.jpg',
  MainSliderSpecials: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Range.jpg',
  ClimbingWithSafetyEquipment: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/safety-equipment-climbing.png',
  MenInWorkhouse: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/men-in-workhouse.png',
  MenWearingBoot: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/men-wearing-boot.png',
  PPEDoctorFemale: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/ppe-doctor-female.png',
  RedDeliveryVanRowWhite: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/trucks.jpg',
  SafetyHeadEquipmentBanner: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/safety-head-equipment-banner.png',
  SafetyHelmet: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/safety-helmet.png',
  HomeSafetyEquipment: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/G%20Fox%204%20006.jpg',
  HomeCleaningChemicals: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/home_cleaning_chemicals.png',
  HomeFootWear: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/home_footwear.png',
  HomeWorkWear: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/home_workwear.png',
  HomePaper: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/home_paper.png',
  SerraLeeSoapDispenser: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/serra-lee-soap-dispenser.png',
  SewingMachinesEmbroidery: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/sewing-machines-embroidery.png',
  SewingMachinesEmbroidery2: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/sewing-machines-embroidery2.png',
  WorkWearMachinery: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/work-wear-machinery.png',
  WorkerPullingPalletTruck: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/work-wear-machinery.png',
  WorkerPullingTruckCounting: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/G%20Fox%208%20026.jpg',
  YellowGlovesCleaning: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/yellow-gloves-cleaning.png',
  InspectorAfricanAmerican: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/inspector-african-american-worker-factory.png',
  GroupWorkers: 'https://gfox-cdn.bridgelabs.design/images/website-assets/illustrations/GroupWorkers.png',

  // Temp images
  AntiBacterialWipes: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/anti-bacterial-wipes.png',
  DisposableFaceMask: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/disposable-face-mask.png',
  DuralightMens: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/duralight-mens.png',
  FaceMaskDIYKit: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/face-mask-diy-kit.png',
  FaceMaskDoctor: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/face-mask-doctor.png',
  FaceMaskSchoolChild: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/face-mask-school-child.png',
  HoneyNubuckBoot: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/honey-nubuck-boot.png',
  InfraredThermometer: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/infrared-thermometer.png',
  PolyCottonRedLabel: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/poly-cotton-red-label.png',
  ThreePlyFaceMask: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/three-ply-face-mask.png',
  EasterSaleSpecial: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/EasterSaleSpecial.png',
  GqeberhaBranchSpecial: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/GqeberhaBranchSpecial.png',
  HandSanitiserSpecial: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/HandSanitiserSpecial.png',
  PackHouseFarmSpecial: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/PackHouseFarmSpecial.png',
  ReducedToClearSpecial: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/ReducedToClearSpecial.png',
  ReducedToClearSpecial2: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/ReducedToClearSpecial2.png',

  beginning: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/The-beginning.png',
  handShake: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Hand-shake.png',
  Swaziland: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Swaziland-flag.png',
  Building: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Building.png',
  Growth: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Growth.png',

  // Employee images
  SibusisoKhumalo: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/SibusisoKhumalo.png',
  AlanMuir: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/AlanMuir.png',
  AvinashRamnarain: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/AvinashRamnarain.jpg',
  ManuPadayachee: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/ManuPadayachee.png',
  CraigBehrmann: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/CraigBehrmann.png',
  DewaldVanDerSchyff: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/DewaldVanDerSchyff.png',
  LeeAnnScanlen: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/LeeAnnScanlen.png',
  CraigBull: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/CraigBull.png',
  JackKukard: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/JackKukard.png',
  JackKukard2: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/JackKukard2.png',
  HettieHensberg: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/HettieHensberg.png',
  StephenKrain: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/StephenKrain.png',
  VanessaRensburg: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/VanessaRensburg.png',
  SurangaAriyawansa: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/SurangaAriyawansa.png',
  Bertus: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Bertus-Hatting-345-x-345-300x300.png',
  Dylan: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Dylan-Thomas-300-x-300-300x300.jpg',
  Zenzele: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/zenzele-300x300-1%20(1).jpg',
  Natasha: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Natasha-Beets-345-x-345-300x300.png',
  Niadria: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/niadri_phol-300x300-1.jpg',
  mlandvo: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/mlandvo.jpg',
  Ian: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Ian.jpg',
  Charles: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/charles.jpg',
  Simphiwe: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/simphiwe.jpeg',
  ZukoTeto: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Zuko.jpg',
  Melanie: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/melanie.jpg',

  // Notification image
  Notification: 'https://gfox-cdn.bridgelabs.design/images/website-assets/temp/Website%20Upgrade%20Notice.jpg',
};

export default Images;
