/* eslint-disable import/prefer-default-export */
import { IAddress } from '@bridgelabsdesign/gfox-api-client';

export const isEmptyString = (s?: string | null): boolean => s === undefined || s === null || (s.length === 0);

export const compareStrings = (s1: string, s2: string): boolean => s1.trim().toLowerCase() === s2.trim().toLowerCase();

const addressField = (value: string | null | undefined, end = false) => {
  if (isEmptyString(value)) return '';
  return `${value}${!end ? ', ' : ''}`;
};

export const addressLineString = (addr: IAddress | undefined | null) => {
  if (!addr) {
    return '';
  }
  let res = addressField(addr.address1);
  res += addressField(addr.address2);
  res += addressField(addr.address2);
  res += addressField(addr.city);
  res += addressField(addr.province);
  res += addressField(addr.country);
  res += addressField(addr.postalCode, true);
  return res;
};

export const formatDate = (date?: string | null, withTime = false) => {
  if (!date) {
    return ' ';
  }
  const newDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  if (withTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.hourCycle = 'h24';
  }
  return new Intl.DateTimeFormat('en-GB', options).format(newDate);
};

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);
