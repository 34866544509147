import APIClient, { IRatingModel } from '@bridgelabsdesign/gfox-api-client';
import { action, makeObservable, observable } from 'mobx';
import { message } from 'antd';

class RatingStore {
  ratings: IRatingModel[] | undefined;

  reviewVisibility: boolean | undefined;

  constructor() {
    makeObservable(this, {
      reviewVisibility: observable,
      checkReviewVisibility: action,
      ratings: observable,
      postRating: action,
      getRating: action,
      // getRatingsBySkuMaster: action,
      fetchRatingsBySkuMaster: action,
      updateRating: action,
      deleteRating: action,
      getAverageRatingBySkuMaster: action,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async postRating(ratingModel: IRatingModel): Promise<boolean> {
    const hide = message.loading('Submitting your rating...');
    let isSuccess = false;
    try {
      await APIClient.Ratings.postRating(ratingModel);
      isSuccess = true;
      message.success('Rating submitted successfully!');
    } catch (error) {
      console.error('Error posting rating:', error);
      message.error('Could not submit your rating. Please try again.');
    }
    hide();
    return isSuccess;
  }

  // eslint-disable-next-line class-methods-use-this
  async getRating(id: string): Promise<IRatingModel | null> {
    const hide = message.loading('Loading rating...');
    let rating: IRatingModel | null = null;
    try {
      const response = await APIClient.Ratings.getRating(id);
      if (response && response.data && response.data.length > 0) {
        [rating] = response.data;
      }
      message.success('Rating loaded successfully!');
    } catch (error) {
      console.error('Error loading rating:', error);
      message.error('Could not load rating. Please try again.');
    }
    hide();
    return rating;
  }

  async fetchRatingsBySkuMaster(id: string | undefined, clientId: string) {
    const hide = message.loading('Loading ratings...');
    try {
      const response = await APIClient.Ratings.getRatingsBySkuMaster(id, clientId);
      console.log('Response function', response);
      if (response && response.data) {
        this.ratings = response.data.flat(); // Save the fetched ratings to the ratings variable in the RatingStore
      }
      message.success('Ratings loaded successfully!');
    } catch (error) {
      console.error('Error loading ratings:', error);
      // message.error('Could not load ratings. Please try again.');
    }
    hide();
  }

  // eslint-disable-next-line class-methods-use-this
  async updateRating(id: string, ratingModel: IRatingModel): Promise<boolean> {
    const hide = message.loading('Updating rating...');
    let isSuccess = false;
    try {
      await APIClient.Ratings.updateRating(id, ratingModel);
      isSuccess = true;
      message.success('Rating updated successfully!');
    } catch (error) {
      console.error('Error updating rating:', error);
      message.error('Could not update rating. Please try again.');
    }
    hide();
    return isSuccess;
  }

  updateReviewInStore(review: IRatingModel) {
    if (this.ratings) {
      const index = this.ratings.findIndex((r) => r.id === review.id);
      if (index !== -1) {
        this.ratings[index] = review;
      } else {
        this.ratings.push(review);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteRating(id: string): Promise<boolean> {
    const hide = message.loading('Deleting rating...');
    let isSuccess = false;
    try {
      await APIClient.Ratings.deleteRating(id);
      isSuccess = true;
      message.success('Rating deleted successfully!');
    } catch (error) {
      console.error('Error deleting rating:', error);
      message.error('Could not delete rating. Please try again.');
    }
    hide();
    return isSuccess;
  }

  // eslint-disable-next-line class-methods-use-this
  async getAverageRatingBySkuMaster(id: string | undefined): Promise<number | null> {
  // const hide = message.loading('Loading average rating...');
    let averageRating: number | null = null;
    try {
      const response = await APIClient.Ratings.getAverageRatingBySkuMaster(id);
      if (response && response.averageRating) {
        averageRating = response.averageRating;
      }
    } catch (error) {
      console.error('Error loading average rating:', error);
    }
    // hide();
    return averageRating;
  }

  async checkReviewVisibility(clientId: string, skuId: string): Promise<boolean> {
    let visibility = false;
    try {
      const response = await APIClient.Ratings.checkReviewVisibility(clientId, skuId);
      if (response && response.isVisible) {
        // eslint-disable-next-line prefer-destructuring
        visibility = response.isVisible;
        this.reviewVisibility = visibility;
      }
    } catch (error) {
      console.error('Error checking review visibility:', error);
    }

    return visibility;
  }
}

export default RatingStore;
