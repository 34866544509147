/* eslint-disable import/prefer-default-export */
import Images from '../../assets';
import { ITransparentBgImageOpts } from '../../components/CTA/TransparentBgImage';

export interface ICarouselData {
  backgroundImage: ITransparentBgImageOpts;
  headerText: string;
  subHeaderText: string;
  subText: string[];
}
export const carouselData: ICarouselData[] = [
  {
    backgroundImage: { largeScreen: Images.ContactUSSlider },
    headerText: 'CONTACT US',
    subHeaderText: 'General and Embroidery Enquiries',
    subText: [
      '',
    ],
  },

];
export const branchesData = [
  {
    name: 'Bidvest Industrial Supplies (Z) Ltd',
    emailAddress: 'dewaldv@gfox.co.zm',
    telNumber: '+2602 11 847911/2',
    faxNumber: null,
    address: [
      'Shop 11-14 Mungwi Centre Mungwi Rd', 'Industrial Area', 'Lusaka',
    ],
  },
  {
    name: 'Cape Town',
    emailAddress: 'zukot@gfox.co.za',
    telNumber: '+27 (0)21 514-1400',
    faxNumber: '+27 (0)21 510 4494',
    address: [
      '2 Carlisle Str c/o Paarden Eiland Rd', 'Paarden Eiland', 'Cape Town',
    ],
  },
  {
    name: 'Durban',
    emailAddress: 'jackk@gfox.co.za',
    telNumber: '+27 (0)31 274-0200',
    faxNumber: '+27 (0)21 274-0208',
    address: [
      'Unit B, 1 Campbell Street Northfield Business Park, Gate 2 Glen Anil',
    ],
  },
  {
    name: 'Eswatini',
    emailAddress: 'simphiwen@sz.gfox.co.za',
    telNumber: '+268 2 (518)6396',
    faxNumber: 'n/a',
    address: [
      '11th Str Matsapha Industrial Site Eswatini',
    ],
  },
  {
    name: 'Gqeberha',
    emailAddress: 'stephenk@gfox.co.za',
    telNumber: '+27 (0)41 453-2178',
    faxNumber: '+27 (0)41 453-7766',
    address: [
      '3 Butterfield Crescent Fairview Gqeberha',
    ],
  },
  {
    name: 'Johannesburg',
    emailAddress: 'info@gfox.co.za',
    telNumber: '+27 (0)11 417-9300/9301',
    faxNumber: '+27 (0)11 417-9400/9401',
    address: [
      'Pencil Park Croxley Close Heriotdale Ext. 15 Germiston',
    ],
  },
  {
    name: 'Middelburg',
    emailAddress: 'iandp@gfox.co.za',
    telNumber: '+27 (0)13 246-2402',
    faxNumber: '+27 (0)13 246-1089',
    address: [
      '2 Rand Str Industria Middelburg',
    ],
  },
  {
    name: 'Nelspruit',
    emailAddress: 'niadrip@gfox.co.za',
    telNumber: '+27 (0)87 148-8210',
    faxNumber: 'n/a',
    address: [
      'Shop 3, Rapid Street Riverside Industrial Park',
    ],
  },
  {
    name: 'Polokwane',
    emailAddress: 'melanieb@gfox.co.za',
    telNumber: '+27 (0)15 297-6314',
    faxNumber: '+27 (0)15 297-8502',
    address: [
      '54 Silicon Str Ladine Pietersburg',
    ],
  },
  {
    name: 'Richards Bay',
    emailAddress: 'vanessaj@gfox.co.za',
    telNumber: '+27 (0)35 789-5735',
    faxNumber: '+27 (0)35 789-8122',
    address: [
      '46 Dollar Drive Richards Bay',
    ],
  },
  {
    name: 'Vredenburg',
    emailAddress: 'bertush@gfox.co.za',
    telNumber: '+27 (0)21 514-1400',
    faxNumber: '+27 (0)21 510 4494',
    address: [
      'Shop No 8 3 Sterling Str Maraisburg Industrial Vredenburg',
    ],
  },
  {
    name: 'George',
    emailAddress: 'natashab@gfox.co.za',
    telNumber: '+27 (0)87 807 9027/9',
    faxNumber: 'n/a',
    address: [
      'Shop 2c/o Glaze & Memorium Streets George',
    ],
  },
];
