import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Form as AntdForm, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react-lite';
import { IVoucherClaim } from '@bridgelabsdesign/gfox-api-client';
import Form from '../Form';
import { validateMessages } from '../../utils/form';
import { useAuthContext } from '../../context/AuthContext';
import stores from '../../stores/stores';
import Typography from '../Typography';

interface IClaimVoucherModal {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsVisible(_: boolean): void;
  // eslint-disable-next-line no-unused-vars
  onSubmit?(claim: IVoucherClaim): void;
}

const { Text } = Typography;

const ClaimVoucherModal = observer(({
  isVisible,
  setIsVisible,
  onSubmit,
}: IClaimVoucherModal) => {
  const { currentClient } = useAuthContext();
  const { vouchersStore } = stores;
  const [form] = useForm();

  const handleFormSubmit = async (values: any) => {
    if (!currentClient) {
      message.error('Could not claim voucher');
      return;
    }
    const claim = await vouchersStore.claimVoucher(currentClient.id, values.refNo);
    if (claim && onSubmit) {
      onSubmit(claim);
    }
    setIsVisible(false);
  };

  const handleOnFormFail = () => {
    message.error('Could not claim voucher');
  };

  return (
    <Modal
      title="Claim voucher"
      visible={isVisible}
      width={570}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => setIsVisible(false)}
      okText="Continue"
      cancelText="Cancel"
      okButtonProps={{ style: { height: '3em' } }}
      cancelButtonProps={{ style: { height: '3em' } }}
      closeIcon={(<IoClose />)}
    >
      <AntdForm
        form={form}
        name="account-vouchers"
        validateMessages={validateMessages}
        className="custom-form"
        onFinish={handleFormSubmit}
        onFinishFailed={handleOnFormFail}
        layout="vertical"
        requiredMark={false}
        style={{ width: '100%' }}
      >
        <Text style={{ color: 'red', fontSize: '1.2rem' }}>
          Please note: voucher cannot be used on quotes. Only orders.
        </Text>
        <br />
        <AntdForm.Item
          name="refNo"
          rules={[{ required: true }]}
        >
          <Form.Input placeholder="Enter your voucher code" rounded />
        </AntdForm.Item>
      </AntdForm>
    </Modal>
  );
});

export default ClaimVoucherModal;
