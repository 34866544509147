/* eslint-disable import/prefer-default-export */
import { ImageMapEditor, onCreateCallback } from '@bridgelabsdesign/gfox-image-tool';
import { message } from 'antd';
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import APIClient, {
  IMessage, IImageMap, LoadManager, IInventoryMaster,

} from '@bridgelabsdesign/gfox-api-client';
import { ILoadInventoryListParams } from './inventory';

export class ImageMapStore {
    products = new LoadManager<IImageMap>({ data: [] }, APIClient.ImageMap.getImages)

    inventory = new LoadManager<IInventoryMaster>({ data: [] }, APIClient.InventoryV2.getInventoryMasters);

    searchQuery = ''

    showPanel = false;

    showFormsPanel = false;

    currentNewProduct?: IImageMap;

    mapRefs: ImageMapEditor[] = [];

    mapImageUrl : { url: string, revokeUrl: boolean, index: 0 } | null = null;

    mapLayerCreateCallback: onCreateCallback | undefined;

    isSkuSelectionVisible = false;

    constructor() {
      makeObservable(this, {
        mapRefs: observable,
        mapImageUrl: observable,
        mapLayerCreateCallback: observable,
        isSkuSelectionVisible: observable,
        setIsSkuSelectionVisible: observable,
        isLoading: computed,
        showPanel: observable,
        showFormsPanel: observable,
        setMapImageUrl: action,
        addMapRefs: action,
      });
    }

    setIsSkuSelectionVisible(v: boolean) {
      this.isSkuSelectionVisible = v;
    }

    setMapImageUrl(obj: { url: string, revokeUrl: boolean, index: 0} | null) {
      if (!obj && this.mapImageUrl) {
        URL.revokeObjectURL(this.mapImageUrl.url);
      }
      this.mapImageUrl = obj;
    }

    get isLoading(): boolean {
      return this.products.isLoading;
    }

    loadImageMap() {
      this.products.fetch('newProduct=true');
    }

    get ImageMaps(): IImageMap[] {
      return this.products.value.data;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
      if (this.products.error || this.products.error) {
        message.error(errorMsg);
      } else {
        message.success(successMsg);
      }
    }

    private layerSkuToolTips(sku: string): string | undefined {
      const invItem = this.inventory.value.data.find((x) => x.sku === sku);
      if (!invItem?.description) {
        return undefined;
      }
      return `
          <span style='font-size: 1.2rem'>Purchase ${invItem.description}</span>
        `;
    }

    debounceLayerClick = false;

    private async layerSkuClick(sku: string) {
      if (this.debounceLayerClick === true) {
        return;
      }
      this.debounceLayerClick = true;
      const invItem = this.inventory.value.data.find((x) => x.sku === sku);
      if (!invItem?.skuId) {
        return;
      }
      const skuMasterList = await APIClient.SKUMasterV2.getSkuMasters(`search=${invItem.sku}`);
      const skuMaster = skuMasterList.data.find((x) => x.id === invItem.skuId);
      if (skuMaster?.sku) {
        window.open(`/products/category/sub-category/${skuMaster.sku}?stockCode=${invItem.masterStock?.stockCode}`, '_blank');
      }
      this.debounceLayerClick = false;
    }

    genMapId = (id: string) => `map-${id}`;

    async addMapRefs(imageMap: IImageMap): Promise<void> {
      let skuList: string[] = [];

      const mapId = this.genMapId(imageMap.id);
      const map = new ImageMapEditor(mapId, {
        controls: false,
        onLayerCreate: () => {},
        layerSkuClick: (v) => this.layerSkuClick(v),
        layerSkuToolTips: (v) => this.layerSkuToolTips(v),
      });

      try {
        await map.loadImage(imageMap.image, true);
        if (imageMap.geoJsonObject) {
          map.importGeoJSON(imageMap.geoJsonObject);
        }
      } catch (err) {
        console.error(err);
      }

      map.interactive();
      skuList = skuList.concat(map.skuList);
      this.mapRefs.push(map);

      if (this.mapRefs.length > 0 && skuList.length > 0) {
        await this.loadInventory({ skuList: skuList.join(',') });
      }
    }

    async loadInventory({
      active = true, parentSku, skuList, withPrice = true,
    }: ILoadInventoryListParams) {
      if (this.inventory.isLoading) {
        return;
      }
      let queryParams = '';
      queryParams += `active=${active ? 'true' : 'false'}&`;
      queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
      queryParams += parentSku ? `parentSku=${parentSku ?? ''}&` : '';
      queryParams += skuList ? `skuList=${skuList ?? ''}` : '';
      await this.inventory.fetch(queryParams);
    }

    removeMaps() {
      this.mapRefs.forEach((item) => item.remove());
      this.mapRefs = [];
    }
}
