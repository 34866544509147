/* eslint-disable import/prefer-default-export */
import namedColors from 'color-name-list/dist/colornames.json';
import { compareStrings, isEmptyString } from './strings';

const getColorHex = (colour: string) => {
  const selectedColour = namedColors?.find((c) => compareStrings(c.name, colour));
  return selectedColour?.hex;
};

export const colourNameToHex = (colour: string): string | undefined => {
  if (isEmptyString(colour)) {
    return undefined;
  }
  let colorHex = getColorHex(colour);
  if (colorHex !== undefined) {
    return colorHex;
  }

  // check for true colour name by splitting multiple words e.g. "Fluorescent Lime" -> ["Fluorescent", "Lime"]
  // color "Lime" will be used
  const colorsSplit = colour.split(' ');

  if (colorsSplit.length <= 1) {
    return undefined;
  }
  // eslint-disable-next-line for-direction, no-plusplus
  for (let i = colorsSplit.length - 1; i >= 0; i--) {
    colorHex = getColorHex(colorsSplit[i]);
    if (colorHex !== undefined) {
      return colorHex;
    }
  }
  return undefined;
};
