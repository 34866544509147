import { ICategory } from '@bridgelabsdesign/gfox-api-client';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import Typography from '../Typography';
import styles from './styles.module.css';

export type MobileCategoryType = {
  refNo: string,
  name: string,
  inverseParentCategory: [ { refNo: string, name: string} ]
}

interface IMobileCategoryOptions {
  category?: ICategory| null;
  onClose(): void;
  // eslint-disable-next-line no-unused-vars
  onSubCategoryClick(parentRefNo: string, refNo: string): void;
}

const { Text, Title } = Typography;

const MobileCategoryOptions = ({
  category,
  onSubCategoryClick,
  onClose,
}: IMobileCategoryOptions) => (
  <div>
    <div
      className={styles.mobileCategoryHeader}
      onClick={onClose}
      aria-hidden
    >
      <FiChevronLeft
        className={styles.mobileCategoryCloseIcon}
      />
      <Title
        level={3}
        className={styles.mobileCategoryHeaderText}
      >
        {category?.name}
      </Title>
    </div>

    {category?.inverseParentCategory?.map((item) => (
      <div
        key={item.refNo}
        className={styles.mobileCategoryItemCon}
        onClick={() => onSubCategoryClick(category.refNo, item.refNo)}
        aria-hidden
      >
        <Text
          key={item.refNo}
          className={styles.mobileCategoryItem}
        >
          {item.name.toLowerCase()}
        </Text>
      </div>
    ))}
  </div>
);

MobileCategoryOptions.defaultProps = {
  category: undefined,
};

export default MobileCategoryOptions;
