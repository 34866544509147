/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-multi-spaces */
import React, { useEffect } from 'react';
import { Carousel } from 'antd';
import {
  AndroidOutlined, AppleOutlined, LeftOutlined, RightOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { ContentSliderPosition, IContentSlider } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.css';
import Typography from '../../components/Typography';
import Buttons from '../../components/Buttons';
import {
  popularProducts, shopCategories,
} from './data';
import Images from '../../assets';
import ShopAllHeader from './ShopAllHeader';
import PopularProductCard from './PopularProductCard';
// import LatestSpecials from './LatestSpecials';
import CTA from '../../components/CTA';
import stores from '../../stores/stores';

const {
  Paragraph, Title, Text,
} = Typography;

const Home = observer(() => {
  const androidLink = 'https://play.google.com/store/apps/details?id=za.co.gfox.app';
  const iOSLink = 'https://apps.apple.com/us/app/g-fox/id1633431247';
  const {
    contentSliderStore,
  } = stores;
  const currentPageRefNo = 'home_page';

  useEffect(() => {
    contentSliderStore.loadContentSliders(); // Load sliders when component mounts
  }, [contentSliderStore]);

  const contentSliders = contentSliderStore.contentSliderList as IContentSlider[];

  const handleAndroidClick = () => {
    window.open(androidLink, '_blank');
  };

  const handleiOSClick = () => {
    window.open(iOSLink, '_blank');
  };

  const CustomNextArrow = (props: { onClick: any; }) => {
    const { onClick } = props;
    return (
      <div className={styles.arrowLeft} onClick={onClick}>
        <RightOutlined />
      </div>
    );
  };

  const CustomPrevArrow = (props: { onClick: any; }) => {
    const { onClick } = props;
    return (
      <div className={styles.arrowRight} onClick={onClick}>
        <LeftOutlined />
      </div>
    );
  };

  const getPositionOrDefault = (item: IContentSlider): ContentSliderPosition => item.positionEnum || ContentSliderPosition.RIGHT;

  const homePageContentSliders = contentSliders.filter((item) => item.refNo === currentPageRefNo);
  return (
    <main id="home">
      <Helmet>
        <title>Home - G. Fox</title>
      </Helmet>
      <Carousel
        autoplay
        autoplaySpeed={7000}
        arrows
        prevArrow={<CustomPrevArrow onClick={undefined} />}
        nextArrow={<CustomNextArrow onClick={undefined} />}
        dots
        effect="fade"
        className={styles.carousel}
      >
        {React.Children.toArray(
          homePageContentSliders.map((item: IContentSlider) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className={styles.cursorPointer}
              onClick={() => item.pageLink && window.open(item.pageLink, '_blank')}
            >
              <CTA.TransparentBgImage
                backgroundImage={{
                  mobileScreen: item.imageMobileUrl,
                  tabletScreen: item.imageTabletUrl,
                  laptopScreen: item.imageLaptopUrl,
                  largeScreen: item.imageLargeUrl,
                }}
                headerText={item.headerText}
                subHeaderText={item.subHeaderText}
                position={(getPositionOrDefault(item) as unknown) as 'left' | 'right' | 'center'}
                overlay={item.overlay}
                subText={item.subText && (
                <>
                  <Text
                    key={item.subText}
                    className={styles.carouselSubText}
                  >
                    {item.subText}
                  </Text>
                  {false && (
                  <div className={styles.playBtnCon}>
                    <div className={styles.rectangle}>
                      <p>Download our G.Fox app</p>
                    </div>
                    <div className={styles.iconContainer}>
                      <Buttons.PrimaryBtn
                        icon={(
                          <AppleOutlined />

                      )}
                        onClick={handleiOSClick}
                      />
                      <Buttons.PrimaryBtn
                        icon={(
                          <AndroidOutlined />
                      )}
                        onClick={handleAndroidClick}
                      />
                    </div>
                  </div>
                  )}
                </>

                )}
                extraNode={item.overlay && (
                <Buttons.ShopMoreBtn
                  shopCategories={shopCategories}
                />
                )}
              />
            </div>
          )),
        )}
      </Carousel>

      <div className={styles.welcomeContainer}>
        <div
          className={styles.welcomeImg}
          style={{ backgroundImage: `url(${Images.HomeWelcome})` }}
        />
        <div className={styles.welcomeContent}>
          <Title
            className={styles.welcomeTitle}
            level={1}
          >
            WELCOME TO G.FOX
          </Title>

          <Paragraph className={styles.welcomeText}>
            {/* eslint-disable-next-line max-len */}
            G. Fox is South Africa’s premier supplier of personal protective equipment (PPE), cleaning chemicals, paper products, and industrial consumable supplies. We distribute our products throughout South Africa, with our branch network of twelve branches, in and around the country.
          </Paragraph>

          <Paragraph className={styles.welcomeText}>
            As a proud member of the Bidvest Group, G. Fox has become a trusted supplier to over 12 000 South African companies.
          </Paragraph>

          <Buttons.LinkBtn
            containerClassName={styles.welcomeBtn}
            link={{ href: '/about' }}
          >
            Read More
          </Buttons.LinkBtn>
        </div>
      </div>

      <ShopAllHeader
        header="Popular products"
        btnTitle="Shop all"
      />

      <div className={styles.popularProdContainer}>
        {React.Children.toArray(
          popularProducts.slice(0, 2).map((item) => (
            <PopularProductCard item={item} />
          )),
        )}
      </div>

      <div className={styles.popularProdScroll}>
        {React.Children.toArray(
          popularProducts.slice(2).map((item) => (
            <PopularProductCard item={item} />
          )),
        )}
      </div>

      {/*
      <ShopAllHeader
        header="Latest Specials"
      />
      <LatestSpecials />
    */}

      <ShopAllHeader
        header="Customisation & Branding"
      />

      <div className={styles.otherOfferItemCon}>
        <div
          className={styles.otherOfferItemBg}
          style={{ backgroundImage: `url(${Images.SewingMachinesEmbroidery2})` }}
        />

        <div className={styles.otherOfferItemInfo}>
          <Title
            className={styles.otherOfferItemHeader}
            level={3}
          >
            EMBROIDERY
          </Title>

          <Paragraph
            className={styles.otherOfferItemText}
          >
            {/* eslint-disable-next-line max-len */}
            G. Fox offers a comprehensive customisation programme that spans simple branding on garments to fully bespoke garments manufactured to your specification.
          </Paragraph>

          <div>
            <Buttons.LinkBtn
              className={styles.otherOfferItemBtn}
              link={{ href: '/contact?tab=general-enquiries' }}
            >
              Enquire Now
            </Buttons.LinkBtn>
          </div>
        </div>
      </div>

      <ShopAllHeader
        header=""
      />

      {/* <div className={styles.recentNewsCon}>
      {React.Children.toArray(
        recentNews.map((item) => (
          <div
            className={styles.recentNewsItem}
            style={{ backgroundImage: `url(${item.backgroundImage})` }}
          >
            <Text className={styles.recentNewsDate}>
              {item.date}
            </Text>

            <Text className={styles.recentNewsTitle}>
              {item.title}
            </Text>

            <div className={styles.recentNewsBtnCon}>
              <Buttons.LinkBtn
                className={styles.recentNewsBtn}
                link={{ href: '/news' }}
                type="default"
              >
                Read More
              </Buttons.LinkBtn>
            </div>
          </div>
        )),
      )}
    </div> */}

    </main>
  );
});

export default Home;
