import React, { useState, useMemo } from 'react';
import { FormInstance, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { FiSearch } from 'react-icons/fi';
import { IAddress } from '@bridgelabsdesign/gfox-api-client';
import styles from '../styles.module.css';
import Form from '../../components/Form';
import Typography from '../../components/Typography';
import useWindowSize from '../../utils/hooks/useWindowSize';

const { Text } = Typography;

const FilteredAddressModal = observer(({
  isOpen,
  handleCancel,
  handleOk,
  form,
  addresses, // This is the pre-fetched list of addresses
}: {
  isOpen: boolean,
  handleCancel(): void,
  // eslint-disable-next-line no-unused-vars
  handleOk(selectedAddress: any): void,
  form: FormInstance,
  addresses: IAddress[],
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { isMobileWindow } = useWindowSize();

  // Filter addresses based on search query
  const filteredAddresses = useMemo(
    () => addresses.filter((addr) => `${addr.address1} ${addr.city} ${addr.province}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())),
    [addresses, searchQuery],
  );

  const handleAddressSelect = (value: string) => {
    const selectedAddress = addresses.find((addr) => addr.id === value);
    if (selectedAddress) {
      form.setFieldsValue({
        address1: selectedAddress.address1,
        address2: selectedAddress.address2,
        address3: selectedAddress.address3,
        city: selectedAddress.city,
        province: selectedAddress.province,
        country: selectedAddress.country,
        postalCode: selectedAddress.postalCode,
        hubCode: selectedAddress.hubCode,
      });
      // Pass the selected address back on OK
      handleOk(selectedAddress);
    }
  };

  return (
    <Modal
      title="Select delivery address"
      visible={isOpen}
      onOk={() => form.submit()} // Trigger form submission which will call handleOk with the selected address
      onCancel={handleCancel}
      width={!isMobileWindow ? '60%' : undefined}
    >
      <div>
        <Text className={styles.registerLabel}>
          Search and select your address
        </Text>
        {/* Search Select Component */}
        <Form.Select
          options={filteredAddresses.map((addr) => ({
            value: addr.id,
            label: `${addr.address1}, ${addr.city}, ${addr.province}.`,
          }))}
          onSearch={(value) => setSearchQuery(value)}
          onChange={handleAddressSelect}
          placeholder="Search and select your address"
          showSearch
          filterOption={false}
          suffixIcon={<FiSearch />}
        />
      </div>
    </Modal>
  );
});

export default FilteredAddressModal;
