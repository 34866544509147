import { IMasterStock } from '@bridgelabsdesign/gfox-api-client';
import { StockMessageContent } from './stockMessage';

// eslint-disable-next-line import/prefer-default-export
export const itemOutOfStock = (qty: number, item?: IMasterStock | null) => qty > (item?.qtyAvailable ?? 0);
export const getStockDifference = (qty: number, item?: StockMessageContent| null) => qty - (item?.qtyAvailable ?? 0);

// export const itemsOutOfStock = (items: IItemOutOfStock[]): boolean => {
//   // eslint-disable-next-line no-plusplus
//   for (let i = 0; i < items.length; i++) {
//     const element = items[i];
//     if (itemOutOfStock(element)) {
//       return true;
//     }
//   }
//   return false;
// };
