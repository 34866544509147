import React from 'react';
import Icon from '@ant-design/icons';
import { Tabs } from 'antd';
import { Helmet } from 'react-helmet';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import { generalDocuments, popiaDocuments } from './data';
import Images from '../../assets';

const { Link, Text } = Typography;

interface ILegalDocument {
  title: string;
  documentUrl: string;
}

const Legal = () => {
  const generateDocuments = (items: ILegalDocument[]) => (
    <>
      {React.Children.toArray(
        items.map((item) => (
          <Link
            href={item.documentUrl}
            external
            className={styles.documentLink}
          >
            <Icon
              className={styles.pdfIcon}
              component={(Images.PdfIcon)}
            />
            <Text>
              {item.title}
            </Text>
          </Link>
        )),
      )}
      ;
    </>
  );
  return (
    <main id="product-catalogue">
      <Helmet>
        <title>Legal - G. Fox</title>
      </Helmet>
      <div className={styles.filtersCon}>
        <Tabs>
          <Tabs.TabPane
            key="general"
            tab={(
              <Text className={styles.detailsHeaderText}>
                General Document
              </Text>
            )}
          >
            {generateDocuments(generalDocuments)}
          </Tabs.TabPane>
          <Tabs.TabPane
            key="popia"
            tab={(
              <Text className={styles.detailsHeaderText}>
                POPIA Documents
              </Text>
            )}
          >
            {generateDocuments(popiaDocuments)}
          </Tabs.TabPane>
          <Tabs.TabPane
            key="initiatives"
            tab={(
              <Text className={styles.detailsHeaderText}>
                Initiatives
              </Text>
            )}
          />
        </Tabs>
      </div>

      {/* TODO: sub to newsletter */}
    </main>
  );
};

export default Legal;
