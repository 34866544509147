import APIClient, { IWishList, ResponseFormat } from '@bridgelabsdesign/gfox-api-client';
import localforage from 'localforage';
import { isEmptyString } from './strings';

const storageInstance = localforage.createInstance({
  name: 'wish',
  version: 1,
  description: 'Local cart details for unauthenticated users',
});

const WISH_STORAGE_KEY = 'wish';

const getLocalWishId = (sku: string) => `${sku}-${Date.now()}`;

type LocalWishType = {
  [sku: string]: {
    id: string,
    qty: number;
    at?: string;
  }
}

export const hasItems = async (): Promise<boolean> => {
  const localWish = await storageInstance.getItem<LocalWishType>(WISH_STORAGE_KEY);
  return localWish !== null && Object.keys(localWish).length > 0;
};

export const clearItems = async () => {
  await storageInstance.setItem<LocalWishType>(WISH_STORAGE_KEY, {});
};

export const getWishes = async (): Promise<ResponseFormat<IWishList>> => {
  const localWish = await storageInstance.getItem<LocalWishType>(WISH_STORAGE_KEY);
  const skuKeys = Object.keys(localWish ?? {});
  if (localWish == null || skuKeys.length === 0) {
    return { data: [] };
  }

  const skuQueryList = skuKeys.map((x) => x).join(',');
  if (isEmptyString(skuQueryList)) {
    return { data: [] };
  }

  const queryParams = `withPrice=true&active=true&skuList=${skuQueryList}`;
  const wishInv = await APIClient.InventoryV2.getInventoryMasters(queryParams);

  const items: IWishList[] = [];
  for (let i = 0; i < skuKeys.length; i += 1) {
    const key = skuKeys[i];
    const idx = wishInv.data.findIndex((x) => x.sku === key);

    if (idx >= 0) {
      wishInv.data[idx].orderQty = localWish[key].qty;
      items.push({
        id: localWish[key].id,
        quantity: localWish[key].qty,
        inventoryMasterId: wishInv.data[idx].id,
        inventoryMaster: wishInv.data[idx],
      } as IWishList);
    }
  }

  return { data: items };
};

export const addWish = async (body: IWishList, items: IWishList[]): Promise<ResponseFormat<IWishList>> => {
  const localWish = await storageInstance.getItem<LocalWishType>(WISH_STORAGE_KEY) ?? {};
  console.log('skuu', body.inventoryMaster?.sku);
  if (body.inventoryMaster?.sku === undefined) {
    throw new Error('cart local storage err: inventory master sku not provided');
  }

  localWish[body.inventoryMaster.sku] = {
    id: getLocalWishId(body.inventoryMaster.sku),
    qty: body.quantity,
    at: new Date().toISOString(),
  };

  await storageInstance.setItem(WISH_STORAGE_KEY, localWish);

  items.push(body);
  return { data: items };
};

export const addRange = async (items: IWishList[]) => {
  // if (strageOpt === 'api') {
  //   await APIClient.Cart.addCartRange(items as IWishList[]);
  //   return;
  // }

  for (let i = 0; i < items.length; i += 1) {
    // eslint-disable-next-line
    await addWish(items[i], []);
  }
  console.log('itmes', items);
};

export const updateWish = async (body: IWishList, items: IWishList[]): Promise<ResponseFormat<IWishList>> => {
  const localWish = await storageInstance.getItem<LocalWishType>(WISH_STORAGE_KEY) ?? {};
  if (body.inventoryMaster?.sku === undefined) {
    throw new Error('cart local storage err: inventory master sku not provided');
  }

  localWish[body.inventoryMaster.sku] = {
    id: getLocalWishId(body.inventoryMaster.sku),
    qty: body.quantity,
    at: new Date().toISOString(),
  };

  await storageInstance.setItem(WISH_STORAGE_KEY, localWish);

  const elementPos = items.map((x) => x.id).indexOf(body.id);
  // eslint-disable-next-line
  items[elementPos!] = body;
  return { data: items };
};

export const deleteWish = async (body: IWishList, items: IWishList[]): Promise<ResponseFormat<IWishList>> => {
  const localWish = await storageInstance.getItem<LocalWishType>(WISH_STORAGE_KEY);
  if (body.inventoryMaster?.sku === undefined) {
    throw new Error('cart local storage err: inventory master sku not provided');
  }

  if (localWish !== null) {
    delete localWish[body.inventoryMaster.sku];
  }

  await storageInstance.setItem(WISH_STORAGE_KEY, localWish);
  return { data: items };
};

export const deleteRange = async (items: string[]) => {
  // if (strageOpt === 'api') {
  //   await APIClient.Cart.deleteCartRange(items);
  //   return;
  // }

  const localWish = await storageInstance.getItem<LocalWishType>(WISH_STORAGE_KEY) ?? {};
  const skuKeys = Object.keys(localWish ?? {});
  for (let i = 0; i < skuKeys.length; i += 1) {
    const key = skuKeys[i];
    const toDelete = items.some((x) => localWish[key].id === x);

    if (toDelete) {
      delete localWish[key];
    }
  }

  await storageInstance.setItem(WISH_STORAGE_KEY, localWish);
};
