/* eslint-disable import/prefer-default-export */
import { IClient, IFavoriteProducts, IInventoryMaster } from '@bridgelabsdesign/gfox-api-client/dist/utils/interfaces';
import { message } from 'antd';
import stores from '../../stores/stores';
import * as wishlistStorage from '../wishlistStorage';
import * as favoriteStorage from '../favoriteProductStorage';

export const autoWishlistMigration = async (currentClient?: IClient) => {
  const { favoriteStore } = stores;

  try {
    const wishListResponse = await wishlistStorage.getWishes();

    if (wishListResponse.data.length === 0) {
      console.log('No items to migrate');
      return;
    }

    const wishlistItems: IInventoryMaster[] = wishListResponse.data.map(
      (wishItem) => wishItem.inventoryMaster,
    );

    if (currentClient) {
      const itemsArray: Partial<IInventoryMaster>[] = wishlistItems.map((wishItem) => ({
        id: wishItem.id,
        inventoryMasterId: wishItem.skuId,
        orderQty: wishItem.orderQty,
        description: wishItem.description || '',
        webReference: wishItem.webReference || '',
        sku: wishItem.sku || '',
        active: wishItem.active,
        productImages: wishItem.productImages || [],
        categorySpecificInfo: wishItem.categorySpecificInfo || [],
      }));

      await favoriteStore.addToFavorites(currentClient.id, itemsArray as IInventoryMaster[]);
      message.success('Successfully migrated wishlist items to favorites');
      console.log('Successfully migrated items to server favorites');
    } else {
      const localItems = wishlistItems.map((wishItem) => ({
        id: '',
        clientId: '',
        inventoryMasterId: wishItem.id,
        quantity: wishItem.orderQty || 1,
        createdAt: new Date().toISOString(),
        client: null,
        inventoryMaster: {
          id: wishItem.id,
          skuId: wishItem.skuId,
          description: wishItem.description || '',
          webReference: wishItem.webReference || '',
          sku: wishItem.sku || '',
          active: wishItem.active,
          productImages: wishItem.productImages || [],
          categorySpecificInfo: wishItem.categorySpecificInfo || [],
          orderQty: wishItem.orderQty || 1,
        } as IInventoryMaster,
      })) as IFavoriteProducts[];

      await favoriteStorage.addRange(localItems, 'local');
      message.success('Successfully migrated wishlist items to favorites');
      console.log('Successfully migrated items to local favorites');
    }

    await wishlistStorage.clearItems();
  } catch (error) {
    console.error('Error during automatic wishlist migration:', error);
  }
};
