import APIClient, {
  AccountPermission,
  ILinkedAccount, IUserAuth, LoadManager,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import { isEmptyString } from '../utils/strings';

class LinkedAccountStore {
  linkedAccounts = new LoadManager<ILinkedAccount>({ data: [] },
    APIClient.LinkedAccount.getLinkedAccounts, undefined, APIClient.LinkedAccount.deleteLinkedAccount)

  constructor() {
    makeObservable(this, {
      linkedAccounts: observable,
      fetchLinkedAccounts: action,
      deleteLinkedAcc: action,
      reset: action,
    });
  }

  async deleteLinkedAcc(item: ILinkedAccount) {
    await this.linkedAccounts.remove(item);
    if (this.linkedAccounts.error) {
      message.error('Could not remove linked account.');
    }
  }

  async fetchLinkedAccounts({ clientId, userAuthId }: { clientId?: string, userAuthId?: string | null }) {
    let queryParameters = '';
    queryParameters = clientId ? `mainClientId=${clientId}&` : '';
    queryParameters = userAuthId ? `userAuthId=${userAuthId}&` : '';
    await this.linkedAccounts.fetch(queryParameters);
  }

  // eslint-disable-next-line class-methods-use-this
  async requestAccountLink(accountNumber: string, emailAddress: string, permissions: AccountPermission[]): Promise<boolean> {
    let success = false;
    if (isEmptyString(accountNumber)) {
      message.error('Account number is required');
    } else {
      const hide = message.loading('Requesting account link...', 0);
      try {
        const userAuth = { emailAddress } as IUserAuth;
        await APIClient.Client.linkAccountNumber(accountNumber!, userAuth, permissions);
        message.success('Email has been sent to owner to verify.');
        success = true;
      } catch (error) {
        message.error('Could not link to account number.');
        // eslint-disable-next-line no-console
        console.error('linked account number: ', error);
      }
      hide();
    }
    return success;
  }

  // eslint-disable-next-line class-methods-use-this
  async removeLinkedAccount(item: ILinkedAccount) {
    const hide = message.loading('Updating account...', 0);
    try {
      await APIClient.LinkedAccount.deleteLinkedAccount(item, []);
    } catch (error) {
      message.error('Could not update account');
      console.warn(error);
    } finally {
      hide();
    }
  }

  reset() {
    this.linkedAccounts.value.data = [];
  }
}

export default LinkedAccountStore;
