/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Empty, Typography } from 'antd';
import Spin from '../../components/Spin/Spin';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import Cards from '../../components/Cards';
import { imgURLPrefix } from '../../config/image';

const { Title } = Typography;

const SearchProducts = observer(() => {
  const { searchStore } = stores;
  const history = useHistory();

  useEffect(() => {
    searchStore.toggleShowSearchPanel(false);
  }, [searchStore.searchQuery]);

  const handleSearchItemClick = (sku: string, inventoryMasterSku?: string | null) => {
    const params = inventoryMasterSku ? `stockCode=${inventoryMasterSku}` : '';
    history.push(`/products/category/sub-category/${sku}?${params}`);
  };

  const resultsData = searchStore.searchResult.skuMaster || [];
  const totalResults = resultsData.length + (searchStore.searchResult.category?.length || 0);

  return (
    <main id="home">
      <Helmet>
        <title>Searched Products - G. Fox</title>
      </Helmet>

      <div className={styles.productShowcaseCon}>
        {searchStore.searchQuery && (
          <Title className={styles.productHeader} level={2}>
            {totalResults}
            {' '}
            Results for:
            {searchStore.searchQuery}
          </Title>
        )}
        <div className={styles.productResultCardCon}>
          {searchStore.isLoading ? (
            <div className={styles.searchSpinCon}>
              <Spin tip="Searching..." />
            </div>
          ) : totalResults > 0 ? (
            React.Children.toArray(
              resultsData.map((item) => (
                item.inventoryMasters.length > 0 ? (
                  item.inventoryMasters.map((invMaster) => (
                    <Cards.ProductCard
                      key={invMaster.sku}
                      className={styles.productResultCard}
                      color="red"
                      imageSrc={`${imgURLPrefix}${item.subCategoryLongImage}`}
                      imgClassName={styles.productResultImg}
                      productName={invMaster.description || item.productName}
                      pricing={{
                        masterStock: invMaster.masterStock,
                        accountPricing: item.accountPricing,
                      }}
                      shopLink={`/products/category/sub-category/${item.sku}?stockCode=${invMaster.sku}`}
                      productNameClassName={styles.productResultPName}
                      optType="viewBtn"
                      removeBg
                      separator
                      viewLink={`/products/category/sub-category/${item.sku}?stockCode=${invMaster.sku}`}
                      onViewLinkClick={(e) => {
                        e.preventDefault();
                        handleSearchItemClick(item.sku, invMaster.sku);
                      }}
                    />
                  ))
                ) : (
                  <Cards.ProductCard
                    key={item.sku}
                    className={styles.productResultCard}
                    color="red"
                    imageSrc={`${imgURLPrefix}${item.subCategoryLongImage}`}
                    imgClassName={styles.productResultImg}
                    productName={item.productName}
                    pricing={{
                      masterStock: item.masterStock,
                      accountPricing: item.accountPricing,
                    }}
                    shopLink={`/products/category/sub-category/${item.sku}`}
                    productNameClassName={styles.productResultPName}
                    optType="viewBtn"
                    removeBg
                    separator
                    viewLink={`/products/category/sub-category/${item.sku}`}
                    onViewLinkClick={(e) => {
                      e.preventDefault();
                      handleSearchItemClick(item.sku);
                    }}
                  />
                )
              )),
            )
          ) : (
            <Empty
              className={styles.emptyList}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={searchStore.error ? 'An error occurred while searching.' : `No results found for "${searchStore.searchQuery}"`}
            />
          )}
        </div>
      </div>
    </main>
  );
});

export default SearchProducts;
