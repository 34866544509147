import Input from './Input';
import InputPassword from './InputPassword';
import SearchInput from './SearchInput';
import Select from './Select';
import SubscribeNewsletter from './SubscribeNewsletter';
import TextArea from './TextArea';

const Form = {
  Input,
  InputPassword,
  SearchInput,
  Select,
  SubscribeNewsletter,
  TextArea,
};

export default Form;
