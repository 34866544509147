/* eslint-disable class-methods-use-this */
import APIClient, {
  IClient, ILoginRequest, IUserAuth,
} from '@bridgelabsdesign/gfox-api-client';
import { message } from 'antd';
import { action, makeObservable } from 'mobx';
import axios from 'axios';
import { isEmptyString } from '../utils/strings';
import history from '../utils/history';

export type UpdateClientValuesType = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  id: string;
  accountNumber: string;
  billingCompany: string;
  billingPhone: string;
  consent: boolean | null;
  shippingAddressId: string;
};

class UserAuth {
  constructor() {
    makeObservable(this, {
      // login
      handleEmailLogin: action,
      updateProfile: action,
      handleRecaptchaVerification: action,
    });
  }

  async handleEmailLogin(loginReq: ILoginRequest): Promise<IUserAuth | undefined> {
    const hide = message.loading('Logging in to your account...');
    let userAuth: IUserAuth | undefined;
    try {
      userAuth = await APIClient.UserAuth.verifyUser(loginReq);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401 && error.response?.data?.message === 'migration_needed') {
        history.push('/account/migrate');
        return userAuth;
      }
      message.error('Could not login. Please try again.');
    }
    hide();
    return userAuth;
  }

  async handleRecaptchaVerification(token: string): Promise<boolean> {
    try {
      const result = await APIClient.UserAuth.verifyRecaptcha(token);
      return result;
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
      return false;
    }
  }

  async updateProfile(values: UpdateClientValuesType, consent: boolean, currentClient:IClient) {
    const hide = message.loading('Updating profile...');
    const body: IClient = {
      accountNumber: values.accountNumber,
      billingCompany: values.billingCompany,
      billingPhone: values.billingPhone,
      communicationConsent: consent,
      userAuth: {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
      } as IUserAuth,
    } as IClient;

    const finalProfile = { ...currentClient, ...body };
    finalProfile.userAuth = { ...currentClient?.userAuth, ...body.userAuth };

    for (let i = 0; i < finalProfile.clientAddresses.length; i += 1) {
      if (finalProfile.clientAddresses[i].addressId === values.shippingAddressId) {
        finalProfile.clientAddresses[i].isShippingAddres = true;
        // eslint-disable-next-line no-continue
        continue;
      }
      finalProfile.clientAddresses[i].isShippingAddres = false;
    }

    try {
      // Make an API call to update the user's profile information
      await APIClient.Client.updateClient(finalProfile, []);
      if (!isEmptyString(currentClient.accountNumber) && (finalProfile.accountNumber !== currentClient.accountNumber)) {
        // TODO: should we remove linked accounts on account number removal ???
        await APIClient.Client.linkAccountNumber(finalProfile.accountNumber, finalProfile.userAuth);
      }
      await APIClient.Client.setPrimaryAddress({ clientId: currentClient.id, addressId: values.shippingAddressId });

      // Extract the updated client from the array
      hide();
      message.success('Your profile has been updated');
      return finalProfile;
    } catch (error) {
      hide();
      message.error('There was an error updating your profile');
      return undefined;
    }
  }

  async subscribeNewsletter(values: { emailAddress: string }) {
    const hide = message.loading('Subscribing to newsletter...');
    try {
      await APIClient.UserAuth.mailListSubscribe({ emailAddress: values.emailAddress });
      message.success('Subscribed to newsletter');
    } catch (error) {
      console.error('subscribe to newsletter error:', error);
      message.error('Could not subscribed to newsletter');
    } finally {
      hide();
    }
  }

  async unsubscribeNewsletter(values: { emailAddress: string }) {
    const hide = message.loading('Unsubscribing to newsletter...');
    try {
      await APIClient.UserAuth.mailListUnsubscribe({ emailAddress: values.emailAddress });
      message.success('Unsubscribed to newsletter');
    } catch (error) {
      console.error('subscribe to newsletter error:', error);
      message.error('Could not unsubscribe to newsletter');
    } finally {
      hide();
    }
  }
}

export default UserAuth;
