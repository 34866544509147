import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './styles.module.css';
import Tables from '../../components/Tables';

const CheckoutCart = () => (
  <main id="checkout-cart" className={styles.cartCon}>
    <Helmet>
      <title>Cart - G. Fox</title>
    </Helmet>
    <Tables.CheckoutItemsTable />
  </main>
);

export default CheckoutCart;
