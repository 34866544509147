import { ImageMapEditor } from '@bridgelabsdesign/gfox-image-tool';
import APIClient, {
  IBranch,
  IImageMap,
  IInventoryMaster,
  ISpecial,
  LoadManager,
} from '@bridgelabsdesign/gfox-api-client';
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import { ILoadInventoryListParams } from './inventory';

class SpecialStore {
  specials = new LoadManager<ISpecial>({ data: [] }, APIClient.Specials.getSpecials);

  inventory = new LoadManager<IInventoryMaster>({ data: [] }, APIClient.InventoryV2.getInventoryMasters);

  mapRefs: ImageMapEditor[] = [];

  currentBranch: IBranch | null | undefined;

  constructor() {
    makeObservable(this, {
      specials: observable,
      mapRefs: observable,
      inventory: observable,
      currentBranch: observable,
      mainPageData: computed,
      specialMaps: computed,
      addMapRefs: action,
      removeMaps: action,
      setCurrentBranch: action,
      loadSpecials: action,
      loadInventory: action,
    });
  }

  setCurrentBranch(value: IBranch | undefined | null) {
    this.currentBranch = value;
  }

  debounceLayerClick = false;

  private async layerSkuClick(sku: string) {
    if (this.debounceLayerClick === true) {
      return;
    }
    this.debounceLayerClick = true;
    const invItem = this.inventory.value.data.find((x) => x.sku === sku);
    if (!invItem?.skuId) {
      return;
    }
    const skuMasterList = await APIClient.SKUMasterV2.getSkuMasters(`search=${invItem.sku}`);
    const skuMaster = skuMasterList.data.find((x) => x.id === invItem.skuId);
    if (skuMaster?.sku) {
      window.open(`/products/special/national/${skuMaster.sku}?stockCode=${invItem.sku}`, '_blank');
    }
    this.debounceLayerClick = false;
  }

  private layerSkuToolTips(sku: string): string | undefined {
    const invItem = this.inventory.value.data.find((x) => x.sku === sku);
    if (!invItem?.description) {
      return undefined;
    }
    return `
      <span style='font-size: 1.2rem'>Purchase ${invItem.description}</span>
    `;
  }

  async addMapRefs(imageMaps: IImageMap[]): Promise<void> {
    let skuList: string[] = [];

    for (let i = 0; i < imageMaps.length; i += 1) {
      const item = imageMaps[i];
      const mapId = this.genMapId(item.id);
      const map = new ImageMapEditor(mapId, {
        controls: false,
        onLayerCreate: () => {},
        layerSkuClick: (v) => this.layerSkuClick(v),
        layerSkuToolTips: (v) => this.layerSkuToolTips(v),
      });
      try {
        // eslint-disable-next-line no-await-in-loop
        await map.loadImage(item.image, true);
        if (item.geoJsonObject) {
          map.importGeoJSON(item.geoJsonObject);
        }
      } catch (err) {
        console.error(err);
      }
      map.interactive();
      skuList = skuList.concat(map.skuList);
      this.mapRefs.push(map);
    }

    if (this.mapRefs.length > 0 && skuList.length > 0) {
      await this.loadInventory({ skuList: skuList.join(',') });
    }
  }

  removeMaps() {
    this.mapRefs.forEach((item) => item.remove());
    this.mapRefs = [];
  }

  genMapId = (id: string) => `map-${id}`;

  get specialMaps(): ISpecial[] {
    if (!this.currentBranch) {
      return this.specials.value.data.filter((x) => !x.branchId);
    }
    return this.specials.value.data.filter((x) => this.currentBranch!.id === x.branchId);
  }

  get mainPageData(): ISpecial[] {
    const national: Array<ISpecial> = [];
    const branches: Array<ISpecial> = [];
    const selectedBranches = new Set<string>();

    this.specials.value.data.forEach((item) => {
      if (!item.branchId) {
        national.push(item);
        return;
      }

      if (!selectedBranches.has(item.branchId)) {
        branches.push(item);
        selectedBranches.add(item.branchId);
      }
    });

    return national.slice(0, 1).concat(branches);
  }

  async loadSpecials() {
    const queryParameters = 'active=true';
    await this.specials.fetch(queryParameters);
  }

  async loadInventory({
    active = true, parentSku, skuList, withPrice = true,
  }: ILoadInventoryListParams) {
    let queryParams = '';
    queryParams += `active=${active ? 'true' : 'false'}&`;
    queryParams += `withPrice=${withPrice ? 'true' : 'false'}&`;
    queryParams += parentSku ? `parentSku=${parentSku ?? ''}&` : '';
    queryParams += skuList ? `skuList=${skuList ?? ''}` : '';
    await this.inventory.fetch(queryParams);
  }
}

export default SpecialStore;
