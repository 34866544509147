/* eslint-disable no-unused-vars */
export enum CheckoutPath {
  // eslint-disable-next-line no-unused-vars
  CART = '/checkout/cart',
  // eslint-disable-next-line no-unused-vars
  DELIVERY = '/checkout/delivery',
  // eslint-disable-next-line no-unused-vars
  LOGIN = '/checkout/login',
  // eslint-disable-next-line no-unused-vars
  CUSTOMER = '/checkout/customer',
  // eslint-disable-next-line no-unused-vars
  PAYMENT = '/checkout/payment',
  // eslint-disable-next-line no-unused-vars
  SUMMARY = '/checkout/summary',

  WISHLIST = '/checkout/wishlist'
}

export interface ICheckoutStep {
  header?: string,
  step: number,
  title: string,
  path: CheckoutPath
}

export const checkoutSteps: ICheckoutStep[] = [
  // {
  //   header: undefined,
  //   step: 1,
  //   title: 'Wishlist',
  //   path: CheckoutPath.WISHLIST,
  // },
  {
    header: undefined,
    step: 1,
    title: 'Shopping Cart',
    path: CheckoutPath.CART,
  },
  {
    header: undefined,
    step: 2,
    title: 'Delivery Option',
    path: CheckoutPath.DELIVERY,
  },
  {
    header: undefined,
    step: 3,
    title: 'Login/Sign up',
    path: CheckoutPath.LOGIN,
  },
  {
    header: 'Your billing details',
    step: 4,
    title: 'Customer details',
    path: CheckoutPath.CUSTOMER,
  },
  {
    header: 'Payment Method',
    step: 5,
    title: 'Payment',
    path: CheckoutPath.PAYMENT,
  },
  {
    header: 'Product Summary',
    step: 6,
    title: 'Order summary',
    path: CheckoutPath.SUMMARY,
  },
];
