import Images from '../../assets';
import { ITransparentBgImageOpts } from '../../components/CTA/TransparentBgImage';

export interface ICarouselData {
  backgroundImage: ITransparentBgImageOpts;
  headerText: string;
  subHeaderText: string;
  subText?: string[];
  showExtraNode?: boolean;
  position?: 'left' | 'right';
  link?:string;
  button?:boolean
  overlay?:boolean;
}

export const carouselData: ICarouselData[] = [
  {
    backgroundImage: {
      mobileScreen: Images.WinterSpecialMobile,
      tabletScreen: Images.WinterSpecialTab,
      laptopScreen: Images.WinterSpecialLaptop,
      largeScreen: Images.WinterSpecialLargeScreen,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      '',
    ],
    link: '/products/work-wear/AF',
    button: false,
    showExtraNode: true,
    overlay: false,
  },
  {
    backgroundImage: {
      mobileScreen: Images.EasterSlidersMobile,
      tabletScreen: Images.EasterSlidersTab,
      laptopScreen: Images.EasterSliderLaptop,
      largeScreen: Images.EasterSliderLargeScreen,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      '',
    ],
    link: '/specials/national',
    button: false,
    showExtraNode: true,
    overlay: false,
  },
  {
    backgroundImage: {
      mobileScreen: Images.AllCleanSlidersV301,
      tabletScreen: Images.AllCleanSlidersV302,
      laptopScreen: Images.AllCleanSlidersV303,
      largeScreen: Images.AllCleanSlidersV304,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      '',
    ],
    button: false,
    showExtraNode: true,
    overlay: false,
  },
  {
    backgroundImage: {
      mobileScreen: Images.HiVizContiSliders01,
      tabletScreen: Images.HiVizContiSliders02,
      laptopScreen: Images.HiVizContiSliders03,
      largeScreen: Images.HiVizContiSliders04,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      '',
    ],
    button: false,
    showExtraNode: true,
    overlay: false,
  },
  {
    backgroundImage: {
      mobileScreen: Images.Alsafe20WebSliders01,
      tabletScreen: Images.Alsafe20WebSliders02,
      laptopScreen: Images.Alsafe20WebSliders03,
      largeScreen: Images.Alsafe20WebSliders04,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      // eslint-disable-next-line max-len
      '',
    ],
    showExtraNode: true,
    button: false,
    overlay: false,
  },
  {
    backgroundImage: {
      mobileScreen: Images.EconomyHandCleanerSliders01,
      tabletScreen: Images.EconomyHandCleanerSliders02,
      laptopScreen: Images.EconomyHandCleanerSliders03,
      largeScreen: Images.EconomyHandCleanerSliders04,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      // eslint-disable-next-line
      '',
    ],
    button: false,
    showExtraNode: true,
    overlay: false,
  },
  {
    backgroundImage: {
      mobileScreen: Images.ButchersApronWebSliders01,
      tabletScreen: Images.ButchersApronWebSliders02,
      laptopScreen: Images.ButchersApronWebSliders03,
      largeScreen: Images.ButchersApronWebSliders04,
    },
    headerText: '',
    subHeaderText: '',
    subText: [
      // eslint-disable-next-line
      '',
    ],
    button: false,
    showExtraNode: true,
    overlay: false,
  },

];

export const shopCategories = [
  {
    title: 'Workwear',
    icon: Images.ProtectiveWearIcon,
    refNo: 'work-wear',
  },
  {
    title: 'Footwear',
    icon: Images.BootIcon,
    refNo: 'footwear',
  },
  // TODO: replace covid with other category
  // {
  //   title: 'COVID-19',
  //   icon: Images.CovidMaskIcon,
  //   refNo: 'covid-19',
  // },
];

export interface IPopularProducts {
  title: string;
  refNo: string;
  backgroundImage: string;
  products: string[];
}

export const popularProducts: IPopularProducts[] = [
  {
    title: 'WORKWEAR',
    refNo: 'work-wear',
    backgroundImage: Images.HomeWorkWear,
    products: [
      'Conti Suits',
      'Chef\'s Wear',
      'Corporate Clothing',
    ],
  },
  {
    title: 'SAFETY EQUIPMENT',
    refNo: 'safety',
    backgroundImage: Images.HomeSafetyEquipment,
    products: [
      'Head Protection',
      'Eye Protection',
      'Hearing Protection',
    ],
  },
  {
    title: 'FOOTWEAR',
    refNo: 'footwear',
    backgroundImage: Images.HomeFootWear,
    products: [
      'Gumboots',
      'Safety Boots',
      'Safety Shoes',
    ],
  },
  {
    title: 'CLEANING CONSUMABLES',
    refNo: 'cleaning-consumables',
    backgroundImage: Images.HomeCleaningChemicals,
    products: [
      'Wipes',
      'Sponge',
      'Scrubbing Pads',
    ],
  },
  {
    title: 'PAPER',
    refNo: 'paper',
    backgroundImage: Images.HomePaper,
    products: [
      'Hand Towels & Facial Tissues',
      'Paper Roll Towels',
      'Toilet Tissue & Kitchen Rolls',
    ],
  },
];

export interface ILatestSpecials {
  category: string;
  refNo: string;
  backgroundImage: string;
  backgroundImageMobile: string;
}

export const latestSpecials: ILatestSpecials[] = [
  {
    category: 'work-wear',
    refNo: 'AHC',
    backgroundImage: Images.HomeSpecials,
    backgroundImageMobile: Images.HomeSpecials,
  },
];

export const recentNews = [
  {
    date: '8 May 2020',
    title: 'Why You Need a 3-Ply Face Mask',
    refNo: 'article-1', // TODO: correct article refNo
    backgroundImage: Images.FaceMaskDoctor,
  },
  {
    date: '8 May 2020',
    title: 'Coronavirus Essential Equipment for Schools',
    refNo: 'article-2',
    backgroundImage: Images.FaceMaskSchoolChild,
  },
];
