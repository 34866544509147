import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import { IContentSlider, ISpecial } from '@bridgelabsdesign/gfox-api-client';
import Typography from '../../components/Typography';
import Buttons from '../../components/Buttons';
import CTA from '../../components/CTA';
import Images from '../../assets';
import styles from './styles.module.css';
import { shopCategories } from '../Home/data';
import Cards from '../../components/Cards';
import stores from '../../stores/stores';
import FallbackImage from '../../assets/illustrations/fallback-image';

const { Text } = Typography;

const Specials = observer(() => {
  const { specialsStore, contentSliderStore } = stores;

  const history = useHistory();
  const currentPageRefNo = 'special_page';

  const contentSliders = contentSliderStore.contentSliderList as IContentSlider[];

  useEffect(() => {
    contentSliderStore.loadContentSliders(); // Load sliders when component mounts
  }, [contentSliderStore]);

  const catalogueLink = (item: ISpecial | undefined | null) => {
    if (item?.redirect === true) {
      return '/specials/national';
    }
    if (!item?.branchId && !item?.branch?.branchName) {
      return '/specials/national';
    }
    const branchName = item.branch!.branchName.trim().replaceAll(' ', '-');
    return `/specials/branch/${branchName}/${item.branchId}`;
  };

  useEffect(() => {
    (async () => {
      const hide = message.loading('Getting Specials', 0);
      await specialsStore.loadSpecials();
      hide();
    })();
  }, []);
  const specialPageContentSliders = contentSliders.filter((item) => item.refNo === currentPageRefNo)[0];

  return (
    <main id="specials">
      <Helmet>
        <title>Specials - G. Fox</title>
      </Helmet>

      <CTA.TransparentBgImage
        backgroundImage={{
          mobileScreen: specialPageContentSliders?.imageMobileUrl,
          tabletScreen: specialPageContentSliders?.imageTabletUrl,
          laptopScreen: specialPageContentSliders?.imageLaptopUrl,
          largeScreen: Images.MainSliderSpecials,
        }}
        headerText="CURRENT SPECIALS"
        // subHeaderText="A vast, varied and extensive product range."
        itemClassName={styles.carouselItem}
        overlay
        subText={(
          <Text
            className={styles.ctaSubText}
          >
            {specialPageContentSliders?.subText}
            {/* eslint-disable-next-line max-len */}
            {/* Footwear, workwear, gloves, respiratory equipment, harnesses, hearing protection, cleaning chemicals, janitorial products, paper products, cleaning equipment as well as other quality products. */}
          </Text>
        )}
        extraNode={(
          <Buttons.ShopMoreBtn
            shopCategories={shopCategories}
          />
        )}
      />

      <div className={styles.specialsListCon}>
        {React.Children.toArray(
          specialsStore.mainPageData.map((item) => (
            <Cards.CatalogueCard
              imageSrc={item.imageMaps.find((x) => x.position === 0)?.image ?? item.imageMaps[0]?.image ?? FallbackImage}
              title={item.title}
              subTitle={item.branch?.branchName ?? 'National Promotion'}
              href={catalogueLink(item)}
              onClick={() => {
                const link = catalogueLink(item);
                history.push(link);
              }}
            />
          )),
        )}
      </div>
    </main>
  );
});

export default Specials;
