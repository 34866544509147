import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import stores from '../../stores/stores';
import styles from './styles.module.css';

const NewProductsMap = observer(() => {
  const { ImageMapStore } = stores;
  const mapSetRef = useRef(true);
  const retryCount = useRef(0);

  useEffect(() => {
    ImageMapStore.loadImageMap();
  }, []);

  const handleLoadMapElements = async () => {
    if (retryCount.current >= 3) {
      return;
    }
    try {
      ImageMapStore.removeMaps();
      // eslint-disable-next-line no-restricted-syntax
      await Promise.all(ImageMapStore.ImageMaps.map((item) => ImageMapStore.addMapRefs(item)));
      mapSetRef.current = true;
    } catch {
      retryCount.current += 1;
      setTimeout(() => handleLoadMapElements(), 1000);
    }
  };

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        handleLoadMapElements();
      }, 1000);
    })();
  }, [ImageMapStore.ImageMaps]);

  useEffect(() => () => {
    ImageMapStore.removeMaps();
    mapSetRef.current = false;
  }, [ImageMapStore]);

  return (
    <main id="new-products-map">
      <Helmet>
        <title>New Products</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
          integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
          crossOrigin=""
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/@geoman-io/leaflet-geoman-free@latest/dist/leaflet-geoman.css"
        />
      </Helmet>
      <div className={styles.specialsCon}>
        {ImageMapStore.ImageMaps.map((item) => (
          <div key={item.id}>
            <div
              key={item.id}
              id={ImageMapStore.genMapId(item.id)}
              className={styles.mapCon}
            />
          </div>
        ))}
      </div>
    </main>
  );
});

export default NewProductsMap;
