import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { Typography } from 'antd';
import stores from '../../stores/stores';
import Tables from '../../components/Tables';
import styles from './styles.module.css';
import { useAuthContext } from '../../context/AuthContext';

const {
  Title,
} = Typography;
const Favoriteslist = observer(() => {
  const { favoriteStore } = stores;
  const { currentClient } = useAuthContext();
  useEffect(() => {
    if (currentClient) {
      favoriteStore.loadFavoritesItems(currentClient.id);
    }
  }, [currentClient]);

  // useEffect(() => {
  //   favoriteStore.setSelectedItems([]);
  // }, []);

  return (
    <main id="checkout-cart" className={styles.cartCon}>
      <Helmet>
        <title>Favorites - G. Fox</title>
      </Helmet>
      <Title
        className={styles.productHeader}
        level={2}
      >
        My favorite products:
      </Title>
      <div className={styles.con}>
        <Tables.FavoritesTable />
      </div>

    </main>
  );
});

export default Favoriteslist;
