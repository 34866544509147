import React from 'react';
import { Form as AntdForm, message, Modal } from 'antd';
import { IoClose } from 'react-icons/io5';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { validateMessages } from '../../utils/form';
import stores from '../../stores/stores';
import Form from '../Form';
import Typography from '../Typography';

const { Text } = Typography;

type FormValues = {
  emailAddress: string;
  accountNumber: string | undefined | null;
}

const MigrateAccountModal = ({
  isVisible,
  setIsVisible,
} : {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsVisible: (v: boolean) => void;
}) => {
  const { authStore, clientStore } = stores;
  const [form] = useForm<FormValues>();
  const history = useHistory();

  const handleOnOk = async (values: FormValues) => {
    const client = await clientStore.migrateSysProImports(values);
    if (!client) {
      return;
    }

    const authRegisterValues = {
      ...client.userAuth,
      ...client,
    };

    authStore.valuesForm(authRegisterValues, client.branchId!, client.communicationConsent ?? false);
    authStore.setIsAccountMigration(true);
    history.push('/account/register#login');
  };

  return (
    <Modal
      title="Migrate My Account"
      visible={isVisible}
      onOk={() => form.submit()}
      onCancel={() => setIsVisible(false)}
      okText="Continue"
      cancelText="Cancel"
      okButtonProps={{ style: { height: '3em' } }}
      cancelButtonProps={{ style: { height: '3em' } }}
      closeIcon={(<IoClose />)}
      width="50%"
    >
      <div>
        <AntdForm<FormValues>
          form={form}
          name="login-reset"
          validateMessages={validateMessages}
          className="custom-form"
          onFinish={handleOnOk}
          onFinishFailed={() => message.error('Could not migrate account')}
          layout="vertical"
          requiredMark={false}
          style={{ width: '100%' }}
        >
          <Text style={{ fontSize: '1.2rem' }}>
            Email address
          </Text>
          <AntdForm.Item
            name="emailAddress"
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Form.Input placeholder="Email address" />
          </AntdForm.Item>
          <Text style={{ fontSize: '1.2rem' }}>
            If you have an account with G.FOX enter your account number below
            <br />
            Note: your account will not be active until your account number has been verified
          </Text>
          <AntdForm.Item
            name="accountNumber"
            rules={[{ required: false }]}
          >
            <Form.Input placeholder="Account number" />
          </AntdForm.Item>
        </AntdForm>
      </div>
    </Modal>
  );
};

export default MigrateAccountModal;
