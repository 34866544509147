import { IPaystackTransactionData } from '@bridgelabsdesign/gfox-api-client';
import {
  action, computed, makeObservable, observable,
} from 'mobx';
import { checkoutSteps, ICheckoutStep } from '../utils/checkout';

class CheckoutStore {
  acceptTsAndCs = false;

  subNewsletter = false;

  orderComments = '';

  purchaseQuoteNo = '';

  customerOrderNo = '';

  requestedShipDate = new Date();

  totalPrice = 0;

  totalPriceVat = 0;

  stepIndex = 0;

  isPayStackProcessing?:boolean;

  selectedPaystackMethod?: IPaystackTransactionData;

  selectedAccountPaymentMethod?:boolean

  constructor() {
    makeObservable(this, {
      customerOrderNo: observable,
      purchaseQuoteNo: observable,
      requestedShipDate: observable,
      acceptTsAndCs: observable,
      isPayStackProcessing: observable,
      subNewsletter: observable,
      orderComments: observable,
      totalPrice: observable,
      totalPriceVat: observable,
      stepIndex: observable,
      selectedPaystackMethod: observable,
      selectedAccountPaymentMethod: observable,
      checkoutStep: computed,
      nextCheckoutStep: computed,
      previousCheckoutStep: computed,
      setAcceptTsAndCs: action,
      setPurchaseQuoteNo: action,
      setPayStackProcess: action,
      setSubNewsletter: action,
      setSelectedPaystackMethod: action,
      setSelectedAccountPaymentMethod: action,
      setOrderComments: action,
      setCustomerOrderNo: action,
      setRequestedShipDate: action,
      setTotalPrice: action,
      setTotalPriceVat: action,
      setStepIndex: action,
      setStepIndexFromPath: action,
    });
  }

  get checkoutStep(): ICheckoutStep | undefined {
    return checkoutSteps.find((x) => x.step === this.stepIndex);
  }

  get nextCheckoutStep(): ICheckoutStep | undefined {
    return checkoutSteps.find((x) => x.step === this.stepIndex + 1);
  }

  get previousCheckoutStep(): ICheckoutStep | undefined {
    return checkoutSteps.find((x) => x.step === this.stepIndex - 1);
  }

  setPayStackProcess(value:boolean) {
    this.isPayStackProcessing = value;
  }

  setAcceptTsAndCs(value: boolean) {
    this.acceptTsAndCs = value;
  }

  setSubNewsletter(value: boolean) {
    this.subNewsletter = value;
  }

  setSelectedPaystackMethod(value?: IPaystackTransactionData) {
    this.selectedPaystackMethod = value;
  }

  setSelectedAccountPaymentMethod(value: boolean) {
    this.selectedAccountPaymentMethod = value;
    // Optionally, clear selectedPaystackMethod here if value is true
    if (value) {
      this.selectedPaystackMethod = undefined;
    }
  }

  setOrderComments(s: string) {
    this.orderComments = s;
  }

  setCustomerOrderNo(s: string) {
    this.customerOrderNo = s;
  }

  setPurchaseQuoteNo(s: string) {
    this.purchaseQuoteNo = s;
  }

  setRequestedShipDate(s: Date) {
    this.requestedShipDate = s;
  }

  setTotalPrice(n: number) {
    this.totalPrice = n;
  }

  setTotalPriceVat(n: number) {
    this.totalPriceVat = n;
  }

  setStepIndex(idx: number) {
    this.stepIndex = idx;
  }

  setStepIndexFromPath(path: string) {
    const idx = checkoutSteps.find((x) => x.path === path)?.step;
    if (idx === undefined) { return; }
    this.setStepIndex(idx);
  }
}

export default CheckoutStore;
