import APIClient, { ICart, ResponseFormat } from '@bridgelabsdesign/gfox-api-client';
import localforage from 'localforage';
import { isEmptyString } from './strings';

const storageInstance = localforage.createInstance({
  name: 'cart',
  version: 1,
  description: 'Local cart details for unauthenticated users',
});

const CART_STORAGE_KEY = 'cart';

const getLocalCartId = (sku: string) => `${sku}-${Date.now()}`;

type LocalCartType = {
  [sku: string]: {
    id: string,
    qty: number;
    at?: string;
  }
}

export const hasItems = async (): Promise<boolean> => {
  const localCart = await storageInstance.getItem<LocalCartType>(CART_STORAGE_KEY);
  return localCart !== null && Object.keys(localCart).length > 0;
};

export const clearItems = async () => {
  await storageInstance.setItem<LocalCartType>(CART_STORAGE_KEY, {});
};

export const getCarts = async (): Promise<ResponseFormat<ICart>> => {
  const localCart = await storageInstance.getItem<LocalCartType>(CART_STORAGE_KEY);
  const skuKeys = Object.keys(localCart ?? {});
  if (localCart == null || skuKeys.length === 0) {
    return { data: [] };
  }

  const skuQueryList = skuKeys.map((x) => x).join(',');
  if (isEmptyString(skuQueryList)) {
    return { data: [] };
  }

  const queryParams = `active=true&skuList=${skuQueryList}`;
  const cartInv = await APIClient.InventoryV2.getInventoryMasters(queryParams);

  const items: ICart[] = [];
  for (let i = 0; i < skuKeys.length; i += 1) {
    const key = skuKeys[i];
    const idx = cartInv.data.findIndex((x) => x.sku === key);

    if (idx >= 0) {
      cartInv.data[idx].orderQty = localCart[key].qty;
      items.push({
        id: localCart[key].id,
        quantity: localCart[key].qty,
        inventoryMasterId: cartInv.data[idx].id,
        inventoryMaster: cartInv.data[idx],
      } as ICart);
    }
  }

  return { data: items };
};

export const addCart = async (body: ICart, items: ICart[]): Promise<ResponseFormat<ICart>> => {
  const localCart = await storageInstance.getItem<LocalCartType>(CART_STORAGE_KEY) ?? {};
  if (body.inventoryMaster?.sku === undefined) {
    throw new Error('cart local storage err: inventory master sku not provided');
  }

  localCart[body.inventoryMaster.sku] = {
    id: getLocalCartId(body.inventoryMaster.sku),
    qty: body.quantity,
    at: new Date().toISOString(),
  };

  await storageInstance.setItem(CART_STORAGE_KEY, localCart);

  items.push(body);
  return { data: items };
};

export const addRange = async (items: ICart[], strageOpt: 'local' | 'api') => {
  if (strageOpt === 'api') {
    await APIClient.Cart.addCartRange(items as ICart[]);
    return;
  }

  for (let i = 0; i < items.length; i += 1) {
    // eslint-disable-next-line
    await addCart(items[i], []);
  }
};

export const updateCart = async (body: ICart, items: ICart[]): Promise<ResponseFormat<ICart>> => {
  const localCart = await storageInstance.getItem<LocalCartType>(CART_STORAGE_KEY) ?? {};
  if (body.inventoryMaster?.sku === undefined) {
    throw new Error('cart local storage err: inventory master sku not provided');
  }

  localCart[body.inventoryMaster.sku] = {
    id: getLocalCartId(body.inventoryMaster.sku),
    qty: body.quantity,
    at: new Date().toISOString(),
  };

  await storageInstance.setItem(CART_STORAGE_KEY, localCart);

  const elementPos = items.map((x) => x.id).indexOf(body.id);
  // eslint-disable-next-line
  items[elementPos!] = body;
  return { data: items };
};

export const deleteCart = async (body: ICart, items: ICart[]): Promise<ResponseFormat<ICart>> => {
  const localCart = await storageInstance.getItem<LocalCartType>(CART_STORAGE_KEY);
  if (body.inventoryMaster?.sku === undefined) {
    throw new Error('cart local storage err: inventory master sku not provided');
  }

  if (localCart !== null) {
    delete localCart[body.inventoryMaster.sku];
  }

  await storageInstance.setItem(CART_STORAGE_KEY, localCart);
  return { data: items };
};

export const deleteRange = async (items: string[], strageOpt: 'local' | 'api') => {
  if (strageOpt === 'api') {
    await APIClient.Cart.deleteCartRange(items);
    return;
  }

  const localCart = await storageInstance.getItem<LocalCartType>(CART_STORAGE_KEY) ?? {};
  const skuKeys = Object.keys(localCart ?? {});
  for (let i = 0; i < skuKeys.length; i += 1) {
    const key = skuKeys[i];
    const toDelete = items.some((x) => localCart[key].id === x);

    if (toDelete) {
      delete localCart[key];
    }
  }

  await storageInstance.setItem(CART_STORAGE_KEY, localCart);
};
